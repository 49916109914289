import React, {createContext} from 'react';
import useState from 'react-usestateref';
import {deepAssign} from "../../fit_widget/src/utils/common";
import LinkList from "../../components/Common/Layouts/Sidebar/LinkList";

export const AppContext = createContext({
    business: null,
    setBusiness: () => {
    },

    user: null,
    setUser: () => {
    },

    stripe: null,
    setStripe: () => {
    },

    products: null,
    setProducts: () => {
    },

    sizeCharts: null,
    setSizeCharts: () => {
    },

    fabrics: null,
    setFabrics: () => {
    },

    syncTasks: null,
    setSyncTasks: () => {
    },

    setupInstructions: null,
    setSetupInstructions: () => {
    },

    sideBarLinks: LinkList,
    setSideBarLinks: () => {
    },
});

const AppProvider = ({children}) => {
    const [business, setBusiness] = useState(null);
    const [user, setUser] = useState(null);
    const [stripe, setStripe] = useState(null);
    const [products, setProducts] = useState(null);
    const [sizeCharts, setSizeCharts] = useState(null);
    const [fabrics, setFabrics] = useState(null);
    const [syncTasks, setSyncTasks] = useState(null);
    const [title, setTitle] = useState(null);
    const [sideBarLinks, setSideBarLinks] = useState(LinkList);
    const [setupInstructions, setSetupInstructions, setupInstructionsRef] = useState({});

    return (
        <AppContext.Provider
            value={{
                business,
                setBusiness,
                user,
                setUser,
                stripe,
                setStripe,
                products,
                setProducts,
                sizeCharts,
                fabrics,
                syncTasks,
                setSyncTasks,
                setSizeCharts,
                setFabrics,
                setTitle,
                title,
                sideBarLinks,
                setSideBarLinks,
                setupInstructions,
                setSetupInstructions: (newSetupInstructions) => setSetupInstructions(deepAssign({}, setupInstructionsRef.current, newSetupInstructions))
            }}
        >
            {children}
        </AppContext.Provider>
    )
};

export default AppProvider;
