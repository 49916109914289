import React, {Fragment} from 'react';
import Button from "../../Common/UIKit/Form/Button";
import {WithContext as ReactTags} from "react-tag-input";
import classnames from "classnames";
import {validMeasures} from "./Constants";
import {measurementLabel} from "../../../utilities/String";
import {KeyCodes} from "../../../utilities/KeyHelper";
import Input from "../../Common/UIKit/Form/Input";
import useState from "react-usestateref";

const ChartDimensions = (
    {
        sizes,
        sizesError,
        handleDelete,
        handleAddition,
        handleDrag,
        measures,
        setMeasures,
        isOpen,
        setSizes,
        handleClose,
        handleOpen,
        isTable = false
    }) => {

    const [measuresText, setMeasuresText] = useState(measures.join(","));

    const delimiters = [...KeyCodes.ENTER, KeyCodes.COMMA];

    const extraMeasures = measures.filter(measure => !validMeasures.includes(measure));

    return <div className="c-list">
        {
            !isTable && <div className="container">
                <h3 className="c-list__title">2. Chart rows and columns</h3>
                {
                    isOpen ? (
                        <Button color="primary" className="float-right" onClick={handleClose}>
                            Next
                        </Button>
                    ) : (
                        <Button color="light" className="float-right" onClick={handleOpen}>
                            <span className="fa fa-pencil"></span>
                        </Button>
                    )
                }
            </div>
        }
        <div className="c-list__item">
            {
                isOpen ? (
                    <div className="c-list-split2">
                        <div className="col-12">
                            <div className="container px-0">
                                <div className="container px-0">
                                    <h4 className="c-list__title">Garment sizes (comma separated)</h4>
                                </div>
                                <ReactTags
                                    tags={sizes.filter(size => size[0] !== "_").map(size => ({
                                        id: size,
                                        text: size
                                    }))}
                                    classNames={{
                                        tagInput: classnames('ReactTags__tagInput', {
                                            'c-form__group--error': sizesError
                                        }),
                                        tagInputField: classnames('ReactTags__tagInputField', {
                                            'c-form__controller': sizesError
                                        })
                                    }}
                                    placeholder="Small, Medium, Large"
                                    handleDelete={handleDelete(sizes, setSizes)}
                                    handleAddition={handleAddition("sizes", sizes, setSizes)}
                                    handleDrag={handleDrag(sizes, setSizes)}
                                    delimiters={delimiters}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="container px-2">
                                <h4 className="c-list__title">Dimensions</h4>
                            </div>
                            {
                                isTable ? (
                                    <Input

                                        value={measuresText}
                                        onChange={({target: {value}}) => {
                                            setMeasuresText(value);
                                            setMeasures(value.split(",").filter(m => !!m.trim()))
                                        }}
                                    />
                                ) : (
                                    <Fragment>
                                        {
                                            validMeasures.map((measure, index) => (
                                                <Button key={index}
                                                        className="m-2 d-inline"
                                                        color={measures.includes(measure) ? "dark" : "light"}
                                                        onClick={() => {
                                                            if (measures.includes(measure)) {
                                                                handleDelete(measures, setMeasures)(measures.indexOf(measure));
                                                            } else {
                                                                handleAddition("measures", measures, setMeasures)({
                                                                    id: measure,
                                                                    text: measurementLabel(measure)
                                                                });
                                                            }
                                                        }}
                                                >
                                                    {measurementLabel(measure)}
                                                </Button>
                                            ))
                                        }
                                        {
                                            extraMeasures.map((measure, index) => (
                                                <Button key={index}
                                                        className="m-2 d-inline"
                                                        color="warning"
                                                >
                                                    {measurementLabel(measure)}
                                                </Button>
                                            ))
                                        }
                                    </Fragment>
                                )
                            }
                        </div>
                    </div>
                ) : (
                    <Fragment>
                        {
                            sizes?.length > 0 ? (
                                <Fragment>
                                    {
                                        measures?.length > 0 ? (
                                            <Fragment>
                                                Garment sizes: {sizes.join(", ")}<br/>
                                                Chart dimensions: {measures.join(", ")}<br/>
                                            </Fragment>
                                        ) : (
                                            <div className="px-3 text-warning">
                                                Chart dimensions are required
                                            </div>
                                        )
                                    }
                                </Fragment>
                            ) : (
                                <div className="px-3 text-warning">
                                    Garment sizes are required
                                </div>
                            )
                        }
                    </Fragment>
                )
            }
        </div>
    </div>
};

export default ChartDimensions;