import React, {useCallback, useContext, useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {AppContext} from "../../../context/providers";
import NotFoundData from "../../Common/UIKit/NotFoundData";
import {PageLoading} from "../../Common/UIKit/Spinner";
import Link from "../../Common/UIKit/Link";
import Button from "../../Common/UIKit/Form/Button";
import {handleApiDeleteById} from "../../../utilities/useApi";
import toaster from "toasted-notes";
import {deleteFabric, getFabrics} from "../../../api/fabric";


const FabricListPage = withRouter(({history}) => {

    const {fabrics, setFabrics} = useContext(AppContext);

    const [loading, setLoading] = useState(false);

    const fetchFabrics = useCallback(() => {
        if (loading) return;
        setLoading(true);
        getFabrics().then(data => {
            setFabrics(data.results);
            setLoading(false);
        });
    }, [loading, setLoading, setFabrics]);

    useEffect(() => {
        if (fabrics === null) fetchFabrics();
    }, [fabrics, fetchFabrics]);

    useEffect(() => {
        if (fabrics === null) fetchFabrics();
    }, [fabrics, fetchFabrics]);

    if (loading || fabrics === null) {
        return <div className="c-panel-content"><PageLoading/></div>
    }

    return <div className="c-panel-content">
        <div className="c-table c-table--sm">
            <div className="c-table-filter">
                <Link
                    className="c-table-filter__item c-table-filter__item-right"
                    onClick={() => {
                        history.push(`/fabric/create`);
                    }}>
                    <Button color="primary">
                        Create a fabric
                    </Button>
                </Link>
            </div>
            <table>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Fabric stretch</th>
                    <th colSpan={2}>Edit</th>
                </tr>
                </thead>
                <tbody>
                {
                    fabrics && fabrics.length > 0 ? (
                        fabrics.map(fabric => (
                            <tr key={fabric.id}>
                                <td>
                                    {fabric.name}
                                </td>
                                <td>
                                    {10 - fabric.fit_toughness}
                                </td>
                                <td>
                                    {
                                        fabric.name === "Default fabric" ? "-" : (
                                            <Link onClick={() => {
                                                history.push(`/fabric/edit/${fabric.id}`);
                                            }}>
                                                <Button>
                                                    Edit
                                                </Button>
                                            </Link>
                                        )
                                    }
                                </td>
                                <td>
                                    {
                                        fabric.name === "Default fabric" ? "-" : (
                                            <Button color="light" onClick={() => {
                                                const options = {
                                                    message: `Are you sure to delete "${fabric.name}"?`,
                                                    loadingFn: () => {
                                                    }
                                                };
                                                handleApiDeleteById(deleteFabric, fabric.id, options).then(isSuccess => {
                                                    setFabrics(null);
                                                    if (isSuccess)
                                                        toaster.notify(() => <div
                                                            className="alert alert-success">{"Fabric deleted"}</div>);
                                                });
                                            }}>
                                                Delete
                                            </Button>
                                        )
                                    }

                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={6}>
                                <NotFoundData icon="fas fa-search"
                                              title="No fabric added yet"/>
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </table>
        </div>
    </div>
})

export default FabricListPage;
