import config from "../../baseConfig";
import {GENDER} from "../../../components/BodyShape/Constants";
import {deepAssign} from "../../../utils/common";

export default deepAssign({}, config, {
    integrationButton: {
        selector: ".variant-wrapper",
        wrapperClass: "ziba"
    },
    interview: {
        forceGender: GENDER.FEMALE,
        askBodyShapes: true,
        skipBraSize: true,
    }
});