import {callApi} from "../utilities/ApiHandler";
import {ApiMethods, ApiRoutes, createURL} from "../config/apiConfig";
import {getParams} from "./common";

const {GET, PATCH, POST, DELETE} = ApiMethods;

export const getStores = async () => {
    const response = await callApi({
        method: GET,
        url: ApiRoutes.business.store,
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};


export const getStoreDetails = async (id) => {
    const response = await callApi({
        method: GET,
        url: `${ApiRoutes.business.store}?hash=${id}`
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const createStore = async (body) => {
    const response = await callApi({
        method: POST,
        url: ApiRoutes.business.store,
        params: body,
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};


export const updateStore = async (id, body) => {
    const response = await callApi({
        method: PATCH,
        url: `${ApiRoutes.business.store}?hash=${id}`,
        params: body,
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const deleteStore = async (id) => {
    const response = await callApi({
        method: DELETE,
        url: `${ApiRoutes.business.store}?hash=${id}`,
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

