import React from 'react';

export const DotsSpinner = ({ sm }) => (
    <div className="c-spinner c-spinner-dots__container">
        <div className={`c-spinner-dots ${sm ? 'sm' : ''}`}>
            <div />
            <div />
            <div />
            <div />
        </div>
    </div>
);

export const CircleSpinner = ({ dark, light, sm, lg }) => {
    const classes = ['c-spinner-circle'];
    if(dark) classes.push('c-spinner-circle--dark');
    if(light) classes.push('c-spinner-circle--light');
    if(lg) classes.push('c-spinner-circle--lg');
    if(sm) classes.push('c-spinner-circle--sm');

    return (
        <div className="c-spinner">
            <div className={classes.join(' ')} />
        </div>
    );
};

export const BoxSpinner = ({ dark, light }) => {
    const classes = ['c-spinner-box'];
    if(dark) classes.push('c-spinner-box--dark');
    if(light) classes.push('c-spinner-box--light');

    return (
        <div className="c-spinner c-spinner-box__container">
            <div className={classes.join(' ')} />
        </div>
    );
};

export const PageLoading = ({ over, type = 'box', style, ...otherProps }) => {
    const classes = ['c-spinner-page'];
    if(over) classes.push('c-spinner-page--over');

    return (
        <div className={classes.join(' ')} style={style}>
            {type === 'dot'
                ? <DotsSpinner {...otherProps} />
                : type === 'box'
                    ? <BoxSpinner {...otherProps} />
                    : <CircleSpinner {...otherProps} />}
        </div>
    )
};
