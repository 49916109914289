import React from 'react';
import Button from "../../../Common/UIKit/Form/Button";
import {BLOCK_TYPE} from "../../../../fit_widget/src/components/SizeChart/Constants";
import classnames from "classnames";

export default ({
                    children,
                    block,
                    saveBlock,
                    index,
                    moveBlockUp,
                    moveBlockDown,
                    removeBlock,
                    cloneBlock,
                    newSizeChart,
                    setEditBlock,
                    setEditBlockIndex,
                    setHideRecommender,
                }) => {

    const blockCount = ((newSizeChart.layout || {}).blocks || []).length;

    return <div className="if_container c-chart-layout__container">
        <div className="c-chart-layout__container__actions">
            {
                ![BLOCK_TYPE.DIVIDER, BLOCK_TYPE.SIZE_CHART].includes(block.type) &&
                <Button color="light" className="mr-1 d-inline" onClick={() => {
                    setEditBlock(block);
                    setEditBlockIndex(index);
                }}><span className="fa fa-edit"></span></Button>
            }
            {
                index > 0 && <Button color="light" className="mr-1 d-inline" onClick={() => {
                    moveBlockUp(block, index);
                }}><span className="fa fa-chevron-up"></span></Button>
            }
            {
                index < blockCount - 1 && <Button color="light" className="mr-1 d-inline" onClick={() => {
                    moveBlockDown(block, index);
                }}><span className="fa fa-chevron-down"></span></Button>
            }
            {
                ![BLOCK_TYPE.SIZE_CHART].includes(block.type) &&
                <Button color="light" className="mr-1 d-inline" onClick={() => {
                    cloneBlock(block);
                }}><span className="fa fa-copy"></span></Button>
            }
            {
                ![BLOCK_TYPE.SIZE_CHART].includes(block.type) ? (
                    <Button color="dark" className="float-right" onClick={() => {
                        removeBlock(index);
                    }}><span className="fa fa-trash"></span></Button>
                ) : (
                    <Button color="dark" className="float-right" onClick={() => {
                        setHideRecommender(!block.isHidden);
                        saveBlock(Object.assign({}, block, {isHidden: !block.isHidden}), index);
                    }}>
                        <span className={classnames("fa", {
                            "fa-eye": block.isHidden,
                            "fa-eye-slash": !block.isHidden
                        })}></span>
                    </Button>
                )
            }
        </div>
        {children}
    </div>

};
