import React, {Fragment, useContext, useEffect, useState} from 'react';
import {AppContext} from "../../../context/providers";
import {BoxSpinner} from "../../Common/UIKit/Spinner";
import {withRouter} from "react-router-dom";
import {
    activatePendingShopifySubscription,
    createCharge,
    getCharge,
    getPendingSubscriptionDetails,
    getShopifySubscriptionDetails
} from "../../../api/payment";
import {getAllQueryParams} from "../../../utilities/URI";
import Button from "../../Common/UIKit/Form/Button";
import toaster from "toasted-notes";
import {capitalize} from "@material-ui/core";

const ShopifyCustomSubscriptionPage = () => {
    const {business} = useContext(AppContext);

    const subsId = getAllQueryParams().id;

    const [subs, setSubs] = useState(null);
    const [loading, setLoading] = useState(false);
    const [activating, setActivating] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (loading) return;
        if (subs === null && subsId) {
            setLoading(true);
            getPendingSubscriptionDetails(subsId).then(charge => {
                setSubs(charge);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                setError(error);
            })
        }
    }, []);

    useEffect(() => {
        if (subs && business && subs?.business !== business?.id) {
            setError("This charge is not for your business. Please check the link first.");
        }
    }, [subs, business]);

    if (!subsId) {
        toaster.notify(() => <div className="alert alert-danger">
            Invalid link, please specify the subscription ID.
        </div>);
        return <Fragment/>
    }

    return <div className="c-panel-content">
        <div className="container text-center mb-3">
            <h4>Application subscription activation</h4>
        </div>
        <div className="container text-center">
            <br/>
            {
                subs === null ? (
                    <BoxSpinner/>
                ) : (
                    <div className="pt-3">
                        {
                            error !== null ? (
                                <div className="alert alert-danger">
                                    {error}
                                </div>
                            ) : (
                                <Fragment>
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td>
                                                <b>Plan name</b>
                                            </td>
                                            <td>
                                                {capitalize(subs.plan_name)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <b>Price</b>
                                            </td>
                                            <td>
                                                ${subs.price.toFixed(2)}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <div className="container text-center py-3">
                                        <Button className="d-inline" color="primary"
                                                loading={activating}
                                                onClick={() => {
                                                    setActivating(true);
                                                    activatePendingShopifySubscription(subs.id).then(resp => {
                                                        setActivating(false);
                                                        if (resp.confirm_url) {
                                                            window.location.replace(resp.confirm_url);
                                                        } else {
                                                            window.location.reload();
                                                        }
                                                    })
                                                }}>
                                            Activate
                                        </Button>
                                    </div>
                                </Fragment>
                            )
                        }
                    </div>
                )
            }
        </div>
    </div>

};

export default ShopifyCustomSubscriptionPage;