import {callApi} from "../utilities/ApiHandler";
import {ApiMethods, ApiRoutes} from "../config/apiConfig";

const {GET} = ApiMethods;

export const getUsageReport = async (params) => {
    const response = await callApi({
        method: GET,
        url: ApiRoutes.report.usage,
        params
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const getPopularProducts = async () => {
    const response = await callApi({
        method: GET,
        url: ApiRoutes.report.popularProducts,
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const getRecommendations = async () => {
    const response = await callApi({
        method: GET,
        url: ApiRoutes.report.recommendations,
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

