import config from "../../baseConfig";
import {GENDER} from "../../../components/BodyShape/Constants";
import {deepAssign} from "../../../utils/common";

export default deepAssign({}, config, {
    integrationButton: {
        selector: '.product__quantity',
        selectorBefore: true,
        showSizeChart: true,
    },
    interview: {
        forceGender: GENDER.FEMALE,
        askBodyShapes: true,
        skipBraSize: true,
        recommendSizeOnly: true,
        basicQuestions: {
            hideAge: true,
        },
        recommendation: {
            recommendProducts: true,
        }
    }
});