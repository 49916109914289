import React, {useCallback, useState} from 'react';
import {
    INSIGHT_FILTER_FIELDS,
    INSIGHT_FILTER_OPERATORS,
    INSIGHT_FILTER_OPERATORS_VISUAL, INSIGHT_FILTER_VALUE_MAP, INSIGHT_FILTER_VALUE_TYPES,
    INSIGHT_FILTERS
} from "./constants";
import toaster from "toasted-notes";

const useInsightFilters = () => {

    const [query, setQuery] = useState('');
    const [filters, setFilters] = useState([]);

    const showErrorMessage = useCallback((message) => {
        toaster.notify(() => <div
            className="alert alert-warning">{message}</div>);
    }, []);

    const validateField = useCallback((field) => {
        if (!INSIGHT_FILTERS[field]) {
            showErrorMessage(`Field ${field} is not allowed. Allowed fields: ${Object.keys(INSIGHT_FILTERS).join(', ')}.`);
            return false;
        }
        return true;
    }, [showErrorMessage]);

    const validateOperator = useCallback((field, operator) => {
        if (!operator) return true;
        const allowedOperators = INSIGHT_FILTERS[field].operators;
        if (!allowedOperators.includes(operator)) {
            const allowedOperatorsString = allowedOperators.map(operator => INSIGHT_FILTER_OPERATORS_VISUAL[operator]).join(', ')
            showErrorMessage(`Operator ${operator} is not allowed for field ${field}. Allowed operators: ${allowedOperatorsString}.}`);
            return false;
        }
        return true;
    }, [showErrorMessage]);

    const validateValue = useCallback((field, value) => {
        if (INSIGHT_FILTERS[field].value_type === INSIGHT_FILTER_VALUE_TYPES.STRING) {
            if (field === INSIGHT_FILTER_FIELDS.FIT_PREFERENCE && !['tight', 'regular', 'loose'].includes(value)) {
                return false;
            }
            if (field === INSIGHT_FILTER_FIELDS.GENDER && !['male', 'female'].includes(value)) {
                return false;
            }
        } else {
            try {
                const numberValue = parseFloat(value);
                if (isNaN(numberValue)) {
                    showErrorMessage(`Value ${value} is not allowed for field ${field}.`);
                    return false;
                }
                if (numberValue <= 0) {
                    showErrorMessage(`Value ${value} must be greater than 0.`);
                    return false;
                }
            } catch (e) {
                showErrorMessage(`Value ${value} is not allowed for field ${field}.`);
                return false;
            }
        }
        return true;
    }, [showErrorMessage]);

    const mapFilterValue = useCallback((field, value) => {
        if (INSIGHT_FILTERS[field].value_type === INSIGHT_FILTER_VALUE_TYPES.NUMBER) return parseFloat(value);
        if (field === INSIGHT_FILTER_FIELDS.FIT_PREFERENCE) {
            return INSIGHT_FILTER_VALUE_MAP[field][value]
        } else if (field === INSIGHT_FILTER_FIELDS.GENDER) {
            return value;
        }
    }, []);

    const handleNewFilter = useCallback((input) => {
        if (!input.includes('=')) {
            showErrorMessage('Invalid filter, must be in format key=value');
            return null;
        }
        const [key, value] = input.split('=');
        if (!key) {
            showErrorMessage('Key cannot be empty in format key=value');
            return null;
        }
        if (!value) {
            showErrorMessage('Value cannot be empty in format key=value');
            return null;
        }
        let field = key, operator = '';
        if (key.includes('__')) {
            const keyParts = key.split('__');
            field = keyParts[0];
            operator = keyParts[1];
        }
        if (!validateField(field)) return null;
        if (!validateOperator(field, operator)) return null;
        if (!validateValue(field, value)) return null;

        return {
            field,
            operator: operator === INSIGHT_FILTER_OPERATORS.EXACT ? '' : `__${operator}`,
            value: mapFilterValue(field, value)
        }
    }, [showErrorMessage, validateField, validateOperator, validateValue]);

    const handleQueryChange = useCallback(({target: {value}}) => {
        const lastChar = value[value.length - 1];
        if (lastChar === ',') {
            const newFilter = handleNewFilter(value.slice(0, -1));
            if (newFilter) {
                filters.push(newFilter);
                setFilters([...filters]);
                setQuery('');
            }
        } else {
            setQuery(value);
        }
    }, [filters, handleNewFilter]);

    const handleDeleteFilter = useCallback((index) => {
        filters.splice(index, 1);
        setFilters([...filters]);
    }, [filters]);

    const clearFilters = useCallback(() => {
        setFilters([]);
    }, []);

    return {
        query,
        handleQueryChange,
        filters,
        handleDeleteFilter,
        clearFilters
    }

};

export default useInsightFilters;
