import React from 'react';
import PropTypes from 'prop-types';

const Backdrop = ({ show = false, transparent = false, tabIndex, ...otherProps }) => {
    const classes = ['c-backdrop'];
    if(transparent) classes.push('c-backdrop--transparent');
    if(tabIndex) classes.push(`c-backdrop--tab-${tabIndex}`);
    return show ? <div className={classes.join(' ')} {...otherProps} /> : null;
};

Backdrop.propTypes = {
    tabIndex: PropTypes.number,
    show: PropTypes.bool,
    transparent: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};

export default Backdrop;
