import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import NotFoundData from "../../Common/UIKit/NotFoundData";
import {BoxSpinner, PageLoading} from "../../Common/UIKit/Spinner";
import Button from "../../Common/UIKit/Form/Button";
import Link from "../../Common/UIKit/Link";
import Pagination from "../../Common/UIKit/Pagination";
import {getQueryUri} from "../../../utilities/URI";
import {deleteStore, getStores} from "../../../api/store";
import {handleApiDeleteById} from "../../../utilities/useApi";
import toaster from "toasted-notes";

const StoreListPage = withRouter(({history}) => {

    const [stores, setStores] = useState(null);

    const [page, setPage] = useState(getQueryUri("page") || 1);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchStores = () => {
        setLoading(true);
        getStores().then(stores => {
            setData(stores);
            setStores(stores.results);
            setLoading(false);
        });
    }

    useEffect(() => {
        if (stores !== null) return;
        fetchStores();
    }, [stores]);

    if (loading || data == null) {
        return <div className="c-panel-content"><PageLoading/></div>
    }

    return <div className="c-panel-content">
        <div>
            <Fragment>
                <div className="c-table c-table--sm">
                    <div className="c-table-filter">
                        <div className="c-table-filter__item c-table-filter__item-right">
                            <Link
                                onClick={() => {
                                    history.push(`/store/create`);
                                }}>
                                <Button color="primary">
                                    Create a new store
                                </Button>
                            </Link>
                        </div>
                    </div>
                    <table>
                        <thead>
                        <tr>
                            <th>Domain</th>
                            <th>Config</th>
                            <th>Settings</th>
                            <th>CSS</th>
                            <th>Status</th>
                            <th/>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            loading ? (
                                <tr>
                                    <td colSpan={6}>
                                        <BoxSpinner/>
                                    </td>
                                </tr>
                            ) : (
                                stores?.length > 0 ? (
                                    stores.map(store => (
                                        <tr key={store.id}>
                                            <td>
                                                {store.domain}
                                            </td>
                                            <td>
                                                <div className='single-row'>
                                                    {JSON.stringify(store.config).slice(0, 20)}
                                                    {JSON.stringify(store.config).length > 20 ? "..." : ""}
                                                </div>
                                            </td>
                                            <td className=''>
                                                <div className='single-row'>
                                                    {JSON.stringify(store.settings).slice(0, 20)}
                                                    {JSON.stringify(store.settings).length > 20 ? "..." : ""}
                                                </div>
                                            </td>
                                            <td className=''>
                                                <div className='single-row'>
                                                    {JSON.stringify(store.custom_css).slice(0, 20)}
                                                    {JSON.stringify(store.custom_css).length > 20 ? "..." : ""}
                                                </div>
                                            </td>
                                            <td>
                                                {store.is_active ? "Active" : "Inactive"}
                                            </td>
                                            <td>
                                                <Link onClick={() => {
                                                    history.push(`/store/${store.id}`);
                                                }}>
                                                    <Button>
                                                        Edit
                                                    </Button>
                                                </Link>
                                            </td>
                                            <td>
                                                <Button color="light" onClick={() => {
                                                    const options = {
                                                        message: `Are you sure to delete "${store.domain}"?`,
                                                        loadingFn: () => {
                                                        }
                                                    };
                                                    handleApiDeleteById(deleteStore, store.id, options).then(isSuccess => {
                                                        setStores(null);
                                                        if (isSuccess)
                                                            toaster.notify(() => <div
                                                                className="alert alert-success">Store deleted</div>);
                                                    });
                                                }}>
                                                    Delete
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={6}>
                                            <NotFoundData icon="fas fa-shopping-basket" title="No store added yet"/>
                                        </td>
                                    </tr>
                                ))
                        }
                        </tbody>
                    </table>
                </div>
                {
                    data && !loading && <Pagination data={data} onChange={(page) => {
                        setPage(page);
                        setLoading(true);
                        setStores(null);
                    }}/>
                }
            </Fragment>
        </div>
    </div>
})

export default StoreListPage;
