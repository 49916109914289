import React, {Fragment, useCallback, useContext, useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import {AppContext} from "../../../context/providers";
import {getProducts} from "../../../api";
import {PAGE_SIZE} from "../../../utilities/ApiHandler";
import {PageLoading} from "../../Common/UIKit/Spinner";
import AllSetupInstructions from "../../Common/UIKit/SetupInstruction/AllSetupInstructions";
import SizeChartListPage from "../SizeChart/SizeChartListPage";
import PopularProductsList from "../Report/PopularProductsList";
import NoChartProductAlert from "./NoChartProductAlert";
import HelpArticlesIndex from "../Help/HelpArticlesIndex";


const DashboardPage = withRouter(() => {

    const {setupInstructions} = useContext(AppContext);

    const [products, setProducts] = useState(null);
    const [loadingProducts, setLoadingProducts] = useState(false);

    const [noChartProducts, setNoChartProducts] = useState(null);
    const [loadingNoChartProducts, setLoadingNoChartProducts] = useState(false);

    const planSelected = setupInstructions?.plan_selected;
    const sizeChartsAdded = setupInstructions?.size_charts_added;
    const appEmbedAdded = setupInstructions?.app_embed_added;

    useEffect(() => {
        const fetchProducts = () => {
            if (loadingProducts) return;
            setLoadingProducts(true);
            getProducts(0, PAGE_SIZE, "").then(response => {
                setProducts(response.results);
                setLoadingProducts(false);
            });
        };

        const fetchNoChartProducts = () => {
            if (loadingNoChartProducts) return;
            setLoadingNoChartProducts(true);
            getProducts(0, PAGE_SIZE, "", true).then(response => {
                setNoChartProducts(response.results);
                setLoadingNoChartProducts(false);
            });
        };

        if (products === null) {
            fetchProducts();
        }
        if (noChartProducts === null) {
            fetchNoChartProducts();
        }
    }, [products, noChartProducts]);

    if (products === null || loadingProducts) {
        return <PageLoading/>
    }

    const showingSetupInstructions = !planSelected || !sizeChartsAdded || !appEmbedAdded

    return <Fragment>
        {sizeChartsAdded && noChartProducts?.length > 0 && <NoChartProductAlert/>}
        <div className="container-fluid px-0">
            <div className="row">
                {showingSetupInstructions && (
                    <div className="col-sm-7 pr-0">
                        <AllSetupInstructions/>
                    </div>
                )}
                <div className={showingSetupInstructions ? "col-sm-5" : "col-sm-12"}>
                    <HelpArticlesIndex/>
                </div>
            </div>
        </div>
        <SizeChartListPage compactView={true}/>
        {products?.length > 0 && <div className="c-panel-content"><PopularProductsList compactView={true}/></div>}
    </Fragment>
});

export default DashboardPage;