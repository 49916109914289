import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {NavLink} from "react-router-dom";

const SidebarLink = ({url, icon, name, subLinks}) => {
    const isActive = window.location.pathname.includes(url);
    return (
        <div className={classnames('c-panel-sidebar__list-item', {
            'c-panel-sidebar__list-item--active': isActive
        })}>
            <NavLink
                to={url}
                className={classnames("c-panel-sidebar__list-link", {
                    'c-panel-sidebar__list-item--active active': isActive
                })}
            >
                <i className={icon}/>
                <span>{name}</span>
            </NavLink>
            {subLinks && subLinks.length > 0 && (
                <div className="c-panel-sidebar__sub-list">
                    {subLinks.map((item, key) => (
                        <NavLink to={item.url} key={key} className="c-panel-sidebar__sub-list-item">
                            <i className={item.icon}/>
                            <span>{item.name}</span>
                        </NavLink>
                    ))}
                </div>
            )}
        </div>
    )
};

SidebarLink.propTypes = {
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
};

export default SidebarLink;
