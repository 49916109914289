import React, { useState } from 'react';
import classnames from 'classnames';
import { NavLink } from "react-router-dom";
import PropTypes from 'prop-types';
import Backdrop from "./Backdrop";

const Dropdown = ({ children, block = false, fixed = false, position = 'left', positionMenu = 'bottom' ,closeable = true, options = [], className }) => {
    const [show, setShow] = useState(false);

    const handleToggle = () => setShow(!show);
    const handleClose = () => setShow(false);

    const DropDownOpt = ({url, type, label, node = null, textColor, className, onClick}) => {
        if(!type && url && label) type = 'link';
        const classes = classnames('c-dropdown__item', textColor && textColor[0] !== '#' ? `text-${textColor}` : null , className);
        const styles = (textColor && textColor[0] === '#') ? { color: textColor } : undefined;
        switch (type) {
            case 'divider':
                return <div className="c-dropdown__divider" />;
            case 'link':
                return <NavLink onClick={() => {
                                    if(closeable) handleClose();
                                    if(onClick) onClick();
                                }}
                                to={url}
                                style={styles}
                                className={classes}>{label}</NavLink>;
            case 'node':
                return <div onClick={() => {
                                if(closeable) handleClose();
                                if(onClick) onClick();
                            }}
                            style={styles}
                            className={classes}>{node}</div>;
            default:
                return null;
        }
    };
    return (
        <>
            <Backdrop transparent show={show} onClick={handleClose} />
            <div
                className={classnames('c-dropdown', {
                    'open': show,
                    'c-dropdown--block': block
                }, className)}
            >
                <div className={'c-dropdown__main'} onClick={handleToggle}>
                    {children}
                </div>
                <div className={classnames('c-dropdown__menu', {
                    'c-dropdown__menu--right': position === 'right',
                    'c-dropdown__menu--top': positionMenu === 'top',
                    'c-dropdown__menu--fixed': fixed,
                })}>
                    {options.map((opt, key) => <DropDownOpt key={key} {...opt} />)}
                </div>
            </div>
        </>
    )
};

Dropdown.propTypes = {
    position: PropTypes.string,
    positionMenu: PropTypes.string,
    block: PropTypes.bool,
    className: PropTypes.any,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.oneOf(['divider', 'link', 'node']),
            url: PropTypes.string,
            label: PropTypes.string
        }
    ))
};

export default Dropdown;
