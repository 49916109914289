import {callApi} from "../utilities/ApiHandler";
import {ApiMethods, ApiRoutes} from "../config/apiConfig";

const { POST, PUT, GET } = ApiMethods;

const getParams = (params, imageName = 'avatar') => {
    if(!!params[imageName]) {
        const formData = new FormData();
        Object.keys(params).forEach(key => {
            if(!!params[key] && key !== imageName) formData.append(key, params[key]);
        });
        formData.append(imageName, params[imageName].file);
        return formData;
    }
    return params;
};

export const sendVerificationIdentity = async ({ identity_type }) => {
    const response = await callApi({
        method: POST,
        params: { identity_type },
        url: ApiRoutes.user.sendVerification,
    });
    if(response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const verifyCodeIdentity = async params => {
    const response = await callApi({
        method: POST,
        params,
        url: ApiRoutes.user.verify,
    });
    if(response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const changeEmail = async params => {
    const response = await callApi({
        method: PUT,
        params,
        url: ApiRoutes.user.changeEmail,
    });
    if(response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const changeIdentity = async params => {
    const response = await callApi({
        method: POST,
        params,
        url: ApiRoutes.user.changeIdentity,
    });
    if(response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const getProfile = async () => {
    const response = await callApi({
        method: GET,
        url: ApiRoutes.user.getProfile,
    });
    if(response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const updateProfile = async params => {
    const response = await callApi({
        method: PUT,
        params: getParams(params),
        headers: !!params.avatar ? {
            'Content-Type': 'undefined'
        } : undefined,
        url: ApiRoutes.user.updateProfile,
    });
    if(response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};
