import React, {useContext} from 'react';
import {AppContext} from "../../../../context/providers";
import SelectPlanInstruction from "./SelectPlanInstruction";
import CreateSizeChartInstruction from "./CreateSizeChartInstruction";
import AddAppEmbedInstruction from "./AddAppEmbedInstruction";

const AllSetupInstructions = () => {

    const {business} = useContext(AppContext);

    const requiresAppEmbed = business?.is_app_embed;

    return <div className="c-panel-content">
        <div className="container px-0">
            <div className="h4 p-3">Setup instructions</div>
            <div className="container pt-1">
                Let's get your started by following this guide:
            </div>
        </div>
        <div className="container px-0 py-3">
            <div className="c-card py-1">
                <SelectPlanInstruction/>
            </div>
            <div className="c-card py-1">
                <CreateSizeChartInstruction/>
            </div>
            {
                requiresAppEmbed && (
                    <div className="c-card py-1">
                        <AddAppEmbedInstruction/>
                    </div>
                )
            }
            {/*<div className="c-card py-1">*/}
            {/*    <CustomizeWidgetInstruction/>*/}
            {/*</div>*/}
        </div>
    </div>
};

export default AllSetupInstructions;