import React, {Fragment, useContext, useMemo} from 'react';
import {ExitToApp} from '@material-ui/icons'
import Dropdown from "../../UIKit/Dropdown";
import {IMAGE_DEFAULT_WOMAN_AVATAR} from "../../../../assets/Images";
import {Types} from "../../../../config/actionTypes";
import {AppContext} from "../../../../context/providers";
import FreshChat from "react-freshchat";

const UserHeader = ({only_logout}) => {
    const {user, business} = useContext(AppContext);

    const handleLogout = () => {
        localStorage.removeItem(Types.USER_TOKEN);
        localStorage.removeItem(Types.USER_REFRESH);
        window.location.reload();
    };

    const options = useMemo(() => {
        if (!only_logout) {
            return [
                {
                    type: 'node',
                    node: <span className="link" onClick={handleLogout}>Logout</span>
                },
            ]
        }
        return [
            {
                type: 'node',
                node: (
                    <span className="link" onClick={handleLogout}>
                        <ExitToApp className="mr-3"/> Sign out
                    </span>
                )
            },
        ]
    }, [only_logout]);
    const username = user ?
        user.full_name : business ? business.title : '';
    return (
        <Fragment>
            {
                user?.email && window.location.host === "partners.robosize.com222" && <FreshChat
                    token="df3e5c35-4bd4-4319-84af-5a690cd29704"
                    email={user.email}
                    first_name={username}
                    faqTags={[
                        "get started"
                    ]}
                    onInit={widget => {
                        widget.user.setProperties({
                            email: user.email,
                            first_name: user.first_name,
                            last_name: user.last_name,
                            phone: null,
                            subscription: business?.subscription?.plan_name,
                            is_app_embed: business?.is_app_embed,
                            domain: business?.domain,
                        })
                    }}
                />
            }
            <div className="c-panel-header__user">
                <Dropdown options={options}>
                    <div className="c-panel-header__user-info">
                        <img
                            src={user?.avatar ? user.avatar : IMAGE_DEFAULT_WOMAN_AVATAR}
                            alt={username}
                            className="c-panel-header__user-avatar"
                        />
                        <span className="c-panel-header__user-name">{username}</span>
                    </div>
                </Dropdown>
            </div>
        </Fragment>
    )
};


export default UserHeader;
