import {
    BUTTON_PLACEMENT__BEFORE_AFTER,
    BUTTON_PLACEMENT__COPY_SELECTOR,
    BUTTON_PLACEMENT__FILL_FORM,
    BUTTON_PLACEMENT__SELECT_ELEMENT,
    BUTTON_PLACEMENT__START,
} from "../../../../assets/Images";

export const ButtonPlacementArticle = {
    id: "how-to-customize-button-placement",
    is_popular: true,
    title: "How to customize button placement?",
    body: [
        {
            tag: "p",
            body: "You can see a tutorial video for this <a href='https://youtu.be/oR0y9lFbw1A' target='_blank'>here</a>."
        },
        {
            tag: "h5",
            body: "Step 1:"
        },
        {
            tag: "p",
            body: "On the left panel click on <b>Settings</b> > <b>View button placement</b>. "
        },
        {
            tag: "img",
            attrs: {
                "src": BUTTON_PLACEMENT__START
            }
        },
        {
            tag: "h5",
            body: "Step 2:"
        },
        {
            tag: "p",
            body: "Click on start button placement and move your mouse around to select the element you would like to " +
                "add the button next to. A green highlight will show the elements you can place the button next to."
        },
        {
            tag: "img",
            attrs: {
                "src": BUTTON_PLACEMENT__SELECT_ELEMENT
            }
        },
        {
            tag: "h5",
            body: "Step 3:"
        },
        {
            tag: "p",
            body: "Furthermore, you can select if you would like to add the button before or after the selected element."
        },
        {
            tag: "img",
            attrs: {
                "src": BUTTON_PLACEMENT__BEFORE_AFTER
            }
        },
        {
            tag: "h5",
            body: "Step 4:"
        },
        {
            tag: "p",
            body: "Remember the value in the first two boxes on the right and copy the value of the third box " +
                "(in this case <b>Before</b>, <b>Class</b>, and <b>product-form__input</b>) and go back the settings."
        },
        {
            tag: "img",
            attrs: {
                "src": BUTTON_PLACEMENT__COPY_SELECTOR
            }
        },
        {
            tag: "h5",
            body: "Step 5:"
        },
        {
            tag: "p",
            body: "Enter the values from the boxes and paste the value from the third box."
        },
        {
            tag: "img",
            attrs: {
                "src": BUTTON_PLACEMENT__FILL_FORM
            }
        },
        {
            tag: "p",
            body: "Congratulations! You have customized the placement of the button on your product pages."
        },
    ],
};