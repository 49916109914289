const {RECOMMENDATION_METHOD} = require("../utils/constants");
const {UNIT_METHOD} = require("../components/BodyShape/Constants");
const {INTERVIEW_METHODS} = require("../utils/constants");
export default {
    appClass: "",
    integrationButton: {
        selector: null,
        chart_selector: null,
        render_chart: null,
        class: "",
        containerClass: "",
        wrapperClass: "",
        showSizeChart: false,
        showAIFitting: true,
    },
    productPageIntegration: {
        enabled: false
    },
    sizeChart: {
        roundTo: 0.25
    },
    sizeChangeIntegration: {
        enabled: false,
        filterSizeOptions: false,
        appId: "if_integration_images_app",
        imageAppContainerSelector: null,
        getSizeChangeListener: null,
        mapSizeOptionValue: null,
        hideUnavailableSizes: null,
    },
    interview: {
        showTutorial: false,
        interviewMethod: INTERVIEW_METHODS.SIZE_FIT_ADJUST,
        forceGender: null,
        skipBraSize: false,
        askBodyShapes: false,
        askBodyShapeAdjustments: false,
        askKnownBrand: false,
        askTShirt: false,
        askFitPreference: false,
        manualMeasurement: false,
        localUnit: UNIT_METHOD.METRIC,
        basicQuestions: {
            defaultUnit: UNIT_METHOD.METRIC,
            hideAge: false,
            ageOptional: false,
            askNeck: false,
            neckOptional: true,
            askLength: false,
            lengthOptional: true
        },
        bodyShape: {
            hideHip: false,
            askLength: false,
            lengthOptional: true
        },
        bodyForm: {
            images: {},
        },
        recommendation: {
            method: RECOMMENDATION_METHOD.MEASURES,
            recommendSizeOnly: false,
            recommendProducts: false,
        }
    }
}