import React, {useEffect, useState} from 'react';
import ls from "local-storage";

import {
    bodyMeasurementInit,
    bodyShapeInit,
    businessInit,
    interviewInit,
    knownBrandInit,
    modalInit,
    productInit,
    profileInit,
    STORE_KEY,
    typicalSizeInit,
    config,
    data,
    getBodyShapeInit, cameraInit,
} from "./constants";
import actions from "./actions";
import {postToWidgetApi, recordActivityPath} from "../utils/request";
import {GENDER, INTERVIEW_STEP, UNIT_METHOD} from "../components/BodyShape/Constants";

export const AppConfig = React.createContext(config);

const AppStoreContext = React.createContext({
    modal: modalInit,
    interview: interviewInit,
    business: businessInit,
    product: productInit,
    bodyShape: bodyShapeInit,
    bodyMeasurement: bodyMeasurementInit,
    knownBrand: knownBrandInit,
});

export const sessionShare = {
    cameraEnabled: false
};

export const AppStore = ({App}) => {
    const [modal, setModal] = useState(modalInit);
    const [interview, setInterview] = useState(interviewInit);
    const [business, setBusiness] = useState(businessInit);
    const [profile, setProfile] = useState(profileInit);
    const [product, setProduct] = useState(productInit);
    const [bodyShape, setBodyShape] = useState(bodyShapeInit);
    const [bodyMeasurement, setBodyMeasurement] = useState(bodyMeasurementInit);
    const [knownBrand, setKnownBrand] = useState(knownBrandInit);
    const [typicalSize, setTypicalSize] = useState(typicalSizeInit);
    const [tryOn, setTryOn] = useState({});
    const [camera, setCamera] = useState(cameraInit);

    const appData = {
        modal,
        interview,
        business,
        profile,
        product,
        bodyShape,
        bodyMeasurement,
        knownBrand,
        typicalSize,
        tryOn,
        camera,
    };

    const stopVideo = () => {
        if (camera?.stream !== null) {
            camera.stream.getTracks().forEach(function (track) {
                track.stop();
            });
        }
        if (camera?.interval !== null) {
            clearInterval(camera.interval);
        }
        sessionShare.cameraEnabled = false;
    }

    actions.modal.show = () => {
        postToWidgetApi({
            path: `${recordActivityPath}/o/`,
        });
        setModal(Object.assign({}, modal, {show: true}));
    }
    actions.modal.hide = () => {
        postToWidgetApi({
            path: `${recordActivityPath}/c/`,
        });
        setModal(Object.assign({}, modal, {show: false}));
        stopVideo();
    }
    actions.modal.setClass = (c) => setModal(Object.assign({}, modal, {class: c}));
    actions.modal.setSize = (s) => setModal(Object.assign({}, modal, {size: s}));
    actions.modal.updateFields = newFields => setModal(Object.assign({}, modal, newFields));
    actions.interview.setStep = (step, untracked) => {
        if (!untracked) {
            switch (step) {
                case INTERVIEW_STEP.GENDER:
                    postToWidgetApi({
                        path: `${recordActivityPath}/g/`,
                    });
                    break;
                case INTERVIEW_STEP.BASIC_QUESTIONS:
                    postToWidgetApi({
                        path: `${recordActivityPath}/b/`,
                    });
                    break;
                case INTERVIEW_STEP.BODY_SHAPE:
                    postToWidgetApi({
                        path: `${recordActivityPath}/s/`,
                    });
                    break;
                case INTERVIEW_STEP.BRA_CUP:
                    postToWidgetApi({
                        path: `${recordActivityPath}/bc/`,
                    });
                    break;
                case INTERVIEW_STEP.FITTING:
                    postToWidgetApi({
                        path: `${recordActivityPath}/f/`,
                    });
                    break;
                case INTERVIEW_STEP.SELFIE:
                    postToWidgetApi({
                        path: `${recordActivityPath}/sf/`,
                    });
                    break;
                case INTERVIEW_STEP.VIRTUAL_TRY_ON:
                    postToWidgetApi({
                        path: `${recordActivityPath}/to/`,
                    });
                    break;
                case INTERVIEW_STEP.MEASURE_ADJUSTMENT:
                    postToWidgetApi({
                        path: `${recordActivityPath}/m/`,
                    });
                    break;
            }
        }
        setInterview(Object.assign({}, interview, {step}));
    }
    actions.interview.reset = () => {
        setInterview(Object.assign({}, interview, {
            step: config.interview.manualMeasurement ? INTERVIEW_STEP.MEASURE_MANUAL : INTERVIEW_STEP.GENDER,
        }));
        setBodyShape(Object.assign({}, getBodyShapeInit({}), {unit: config.localUnit || UNIT_METHOD.METRIC}));
    }
    actions.interview.next = ({
                                  untracked = false,
                                  option = null
                              } = {}) => {
        switch (interview.step) {
            case INTERVIEW_STEP.GENDER:
                if (config.interview.manualMeasurement) {
                    actions.interview.setStep(INTERVIEW_STEP.MEASURE_MANUAL, untracked);
                } else {
                    actions.interview.setStep(INTERVIEW_STEP.BASIC_QUESTIONS, untracked);
                }
                break;
            case INTERVIEW_STEP.BASIC_QUESTIONS:
                actions.interview.setStep(INTERVIEW_STEP.BODY_SHAPE, untracked);
                break;
            case INTERVIEW_STEP.BODY_SHAPE:
                if (bodyShape.gender === GENDER.FEMALE && !config.interview.skipBraSize) {
                    actions.interview.setStep(INTERVIEW_STEP.BRA_CUP, untracked);
                } else if (config.interview.askKnownBrand) {
                    actions.interview.setStep(INTERVIEW_STEP.KNOWN_BRAND, untracked);
                } else if (config.interview.askFitPreference) {
                    actions.interview.setStep(INTERVIEW_STEP.FIT_PREFERENCE, untracked);
                } else if (product?.has_virtual_model) {
                    actions.interview.setStep(INTERVIEW_STEP.SELFIE_MENU, untracked);
                } else {
                    actions.interview.setStep(INTERVIEW_STEP.FITTING, untracked);
                }
                break;
            case INTERVIEW_STEP.BRA_CUP:
                if (config.interview.askKnownBrand) {
                    actions.interview.setStep(INTERVIEW_STEP.KNOWN_BRAND, untracked);
                } else if (config.interview.askFitPreference) {
                    actions.interview.setStep(INTERVIEW_STEP.FIT_PREFERENCE, untracked);
                } else if (product?.has_virtual_model) {
                    actions.interview.setStep(INTERVIEW_STEP.SELFIE_MENU, untracked);
                } else {
                    actions.interview.setStep(INTERVIEW_STEP.FITTING, untracked);
                }
                break;
            case INTERVIEW_STEP.KNOWN_BRAND:
            case INTERVIEW_STEP.KNOWN_CHART:
            case INTERVIEW_STEP.FIT_PREFERENCE:
                if (product?.has_virtual_model) {
                    actions.interview.setStep(INTERVIEW_STEP.SELFIE_MENU, untracked);
                } else {
                    actions.interview.setStep(INTERVIEW_STEP.FITTING, untracked);
                }
                break;
            case INTERVIEW_STEP.FITTING:
            case INTERVIEW_STEP.VIRTUAL_TRY_ON:
                if (config.interview.manualMeasurement) {
                    actions.interview.setStep(INTERVIEW_STEP.MEASURE_MANUAL, untracked);
                } else {
                    actions.interview.setStep(INTERVIEW_STEP.MEASURE_ADJUSTMENT, untracked);
                }
                break;
            case INTERVIEW_STEP.MEASURE_ADJUSTMENT:
            case INTERVIEW_STEP.MEASURE_MANUAL:
                if (product?.has_virtual_model) {
                    actions.interview.setStep(INTERVIEW_STEP.SELFIE_MENU, untracked);
                } else {
                    actions.interview.setStep(INTERVIEW_STEP.FITTING, untracked);
                }
                break;
            case INTERVIEW_STEP.SELFIE_MENU:
                if (option == "skip") {
                    actions.interview.setStep(INTERVIEW_STEP.FITTING, untracked);
                } else {
                    actions.interview.setStep(INTERVIEW_STEP.SELFIE, untracked);
                }
                break;
            case INTERVIEW_STEP.SELFIE:
                actions.interview.setStep(INTERVIEW_STEP.VIRTUAL_TRY_ON, untracked);
                break;
        }
    }
    actions.interview.back = () => {
        switch (interview.step) {
            case INTERVIEW_STEP.BASIC_QUESTIONS:
                actions.interview.setStep(INTERVIEW_STEP.GENDER);
                break;
            case INTERVIEW_STEP.BODY_SHAPE:
                actions.interview.setStep(INTERVIEW_STEP.BASIC_QUESTIONS);
                break;
            case INTERVIEW_STEP.BRA_CUP:
                actions.interview.setStep(INTERVIEW_STEP.BODY_SHAPE);
                break;
            case INTERVIEW_STEP.KNOWN_BRAND:
                if (bodyShape.gender === GENDER.FEMALE && !config.interview.skipBraSize) {
                    actions.interview.setStep(INTERVIEW_STEP.BRA_CUP);
                } else {
                    actions.interview.setStep(INTERVIEW_STEP.BODY_SHAPE);
                }
                break;
            case INTERVIEW_STEP.KNOWN_CHART:
                actions.interview.setStep(INTERVIEW_STEP.KNOWN_BRAND);
                break;
            case INTERVIEW_STEP.FIT_PREFERENCE:
                if (config.interview.askKnownBrand) {
                    actions.interview.setStep(INTERVIEW_STEP.KNOWN_BRAND);
                } else if (config.interview.manualMeasurement) {
                    actions.interview.setStep(INTERVIEW_STEP.MEASURE_MANUAL);
                } else if (bodyShape.gender === GENDER.FEMALE && !config.interview.skipBraSize) {
                    actions.interview.setStep(INTERVIEW_STEP.BRA_CUP);
                } else {
                    actions.interview.setStep(INTERVIEW_STEP.BODY_SHAPE);
                }
                break;
            case INTERVIEW_STEP.FITTING:
                if (product?.has_virtual_model) {
                    actions.interview.setStep(INTERVIEW_STEP.SELFIE_MENU);
                } else if (config.interview.askKnownBrand) {
                    actions.interview.setStep(INTERVIEW_STEP.KNOWN_BRAND);
                } else if (config.interview.manualMeasurement) {
                    actions.interview.setStep(INTERVIEW_STEP.MEASURE_MANUAL);
                } else if (bodyShape.gender === GENDER.FEMALE && !config.interview.skipBraSize) {
                    actions.interview.setStep(INTERVIEW_STEP.BRA_CUP);
                } else if (config.interview.askFitPreference) {
                    actions.interview.setStep(INTERVIEW_STEP.FIT_PREFERENCE);
                } else {
                    actions.interview.setStep(INTERVIEW_STEP.BODY_SHAPE);
                }
                break;
            case INTERVIEW_STEP.SELFIE_MENU:
                if (config.interview.askKnownBrand) {
                    actions.interview.setStep(INTERVIEW_STEP.KNOWN_BRAND);
                } else if (config.interview.manualMeasurement) {
                    actions.interview.setStep(INTERVIEW_STEP.MEASURE_MANUAL);
                } else if (bodyShape.gender === GENDER.FEMALE && !config.interview.skipBraSize) {
                    actions.interview.setStep(INTERVIEW_STEP.BRA_CUP);
                } else if (config.interview.askFitPreference) {
                    actions.interview.setStep(INTERVIEW_STEP.FIT_PREFERENCE);
                } else {
                    actions.interview.setStep(INTERVIEW_STEP.BODY_SHAPE);
                }
                break;
            case INTERVIEW_STEP.SELFIE:
                stopVideo();
                actions.interview.setStep(INTERVIEW_STEP.SELFIE_MENU);
                break;
            case INTERVIEW_STEP.MEASURE_ADJUSTMENT:
                if (config.interview.manualMeasurement) {
                    actions.interview.setStep(INTERVIEW_STEP.MEASURE_MANUAL);
                } else {
                    actions.interview.setStep(INTERVIEW_STEP.FITTING);
                }
                break;
            case INTERVIEW_STEP.MEASURE_MANUAL:
                actions.interview.setStep(INTERVIEW_STEP.GENDER);
                break;
            case INTERVIEW_STEP.VIRTUAL_TRY_ON:
                actions.interview.setStep(INTERVIEW_STEP.SELFIE_MENU);
                break;
        }
    }
    actions.business.set = setBusiness;
    actions.profile.set = setProfile;
    actions.profile.setId = (id) => setProfile(Object.assign({}, profile, {id}));
    actions.profile.setLocalUnit = (unit) => setProfile(Object.assign({}, profile, {unit}));
    actions.product.set = setProduct;
    actions.bodyShape.setGender = (gender) => setBodyShape(Object.assign({}, bodyShape, {gender}));
    actions.bodyShape.setUnit = (unit) => setBodyShape(Object.assign({}, bodyShape, {unit}));
    actions.bodyShape.setHeight = (height) => setBodyShape(Object.assign({}, bodyShape, {height}));
    actions.bodyShape.setWeight = (weight) => setBodyShape(Object.assign({}, bodyShape, {weight}));
    actions.bodyShape.setAge = (age) => setBodyShape(Object.assign({}, bodyShape, {age}));
    actions.bodyShape.setNeck = (neck) => setBodyShape(Object.assign({}, bodyShape, {neck}));
    actions.bodyShape.setLength = (length) => setBodyShape(Object.assign({}, bodyShape, {length}));
    actions.bodyShape.set = setBodyShape;
    actions.bodyShape.reset = () => setBodyShape(getBodyShapeInit({}));
    actions.bodyMeasurement.set = setBodyMeasurement;
    actions.bodyMeasurement.reset = () => setBodyMeasurement(null);
    actions.knownBrand.set = (newKnownBrand) => setKnownBrand(Object.assign({}, knownBrand || {}, newKnownBrand));
    actions.knownBrand.setBrand = (brand) => setKnownBrand(Object.assign({}, knownBrand || {}, {brand}));
    actions.knownBrand.setChart = (chart) => setKnownBrand(Object.assign({}, knownBrand || {}, {chart}));
    actions.knownBrand.setSize = (size) => setKnownBrand(Object.assign({}, knownBrand || {}, {size}));
    actions.tryOn.set = newTryOn => setTryOn(Object.assign({}, tryOn, newTryOn));
    actions.camera.setStream = (stream) => setCamera(Object.assign({}, camera, {stream}));
    actions.camera.setInterval = (interval) => {
        setCamera(Object.assign({}, camera, {interval}));
    }
    actions.knownBrand.reset = () => setKnownBrand({});
    actions.typicalSize.set = setTypicalSize;

    useEffect(() => {
        ls.set(STORE_KEY, Object.assign(data, {
            business,
            product,
            profile,
            bodyShape,
            bodyMeasurement,
            knownBrand,
            typicalSize,
        }));
    }, [business, product, profile, bodyShape, bodyMeasurement, knownBrand, typicalSize]);

    return <AppStoreContext.Provider value={{
        data: appData,
        actions
    }}>
        {<App/>}
    </AppStoreContext.Provider>
}

export const withAppStore = Comp => props => (
    <AppStoreContext.Consumer>
        {({data, actions}) => <Comp {...props} data={data} actions={actions}/>}
    </AppStoreContext.Consumer>
)
