import React, {useCallback, useContext, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {AppContext} from "../../../context/providers";
import {deleteSizeChart} from "../../../api/sizeChart";
import Checkbox from "../../Common/UIKit/Form/Checkbox";
import NotFoundData from "../../Common/UIKit/NotFoundData";
import {SearchOutlined} from "@material-ui/icons";
import Input from "../../Common/UIKit/Form/Input";
import Link from "../../Common/UIKit/Link";
import Button from "../../Common/UIKit/Form/Button";
import {handleApiDeleteById} from "../../../utilities/useApi";
import toaster from "toasted-notes";
import {getOrderedKeys} from "../../../fit_widget/src/utils/common";
import {getQueryUri} from "../../../utilities/URI";
import {PAGE_SIZE} from "../../../utilities/ApiHandler";
import Pagination from "../../Common/UIKit/Pagination";
import useFetchSizeCharts from "../../../hooks/useFetchSizeCharts";
import {PageLoading} from "../../Common/UIKit/Spinner";

const COMPACT_SIZE_CHART_LENGTH = 4;


const SizeChartListPage = withRouter(({history, compactView}) => {

    const {setSizeCharts, setProducts, business} = useContext(AppContext);

    const [selectedSizeCharts, setSelectedSizeCharts] = useState([]);
    const [query, setQuery] = useState("");
    const [page, setPage] = useState(getQueryUri("page") || 1);

    const {loading, sizeCharts, data} = useFetchSizeCharts({
        page: page ? parseInt(page) - 1 : 0,
        query,
        limit: PAGE_SIZE
    })

    const allSelected = sizeCharts !== null && selectedSizeCharts ? sizeCharts.length === selectedSizeCharts.length : false;

    const toggleSelectAll = useCallback(() => setSelectedSizeCharts(allSelected ? [] : sizeCharts.map(sizeChart => sizeChart.id)), [allSelected, sizeCharts])

    const toggleSelectedSizeChart = useCallback(id => () => {
        const index = selectedSizeCharts.indexOf(id);
        let newSelectedSizeCharts = selectedSizeCharts.slice();
        if (index < 0) {
            newSelectedSizeCharts.push(id);
        } else {
            newSelectedSizeCharts.splice(index, 1);
        }
        setSelectedSizeCharts(newSelectedSizeCharts);
    }, [selectedSizeCharts])

    const plan_name = business?.subscription?.plan_name;
    const can_add_chart = (plan_name && plan_name !== "free") || sizeCharts?.length < 4;

    return <div className="c-panel-content">
        <div>
            {
                (
                    <>
                        <div className="c-table c-table--sm">
                            <div className="c-table-filter">
                                <div className="c-table-filter__item c-table-filter__search">
                                    <Input
                                        onChange={e => {
                                            setQuery(e.target.value);
                                        }}
                                        initialvalue={query}
                                        value={query}
                                        icon={<SearchOutlined/>}
                                        placeholder="Search"
                                        clearable="true"
                                    />
                                </div>
                                <div className="c-table-filter__item c-table-filter__item-right">
                                    {
                                        !can_add_chart ? (
                                            <Link
                                                onClick={() => {
                                                    history.push(`/billing`);
                                                }}>
                                                <Button color="primary"
                                                >
                                                    Upgrade to add more
                                                </Button>
                                            </Link>
                                        ) : (
                                            <Link
                                                onClick={() => {
                                                    history.push(`/size-chart/create`);
                                                }}>
                                                <Button color="primary"
                                                        disabled={!can_add_chart}
                                                >
                                                    Create a new chart
                                                </Button>
                                            </Link>
                                        )
                                    }
                                </div>
                            </div>
                            {loading ? <PageLoading/> :
                                (sizeCharts?.length > 0 ? (
                                        <table>
                                            <thead>
                                            <tr>
                                                <th>
                                                    <Checkbox
                                                        label={`${allSelected ? "Deselect all" : "Select all"} ${selectedSizeCharts.length > 0 ? `(${selectedSizeCharts.length})` : ""} `}
                                                        onChange={toggleSelectAll}
                                                        checked={allSelected}
                                                    />
                                                </th>
                                                <th>Name</th>
                                                <th className="d-table-cell d-sm-none">Details</th>
                                                <th colSpan={2}>Edit</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                sizeCharts.length > 0 ? (
                                                    sizeCharts.map((sizeChart, index) => (
                                                        (index < COMPACT_SIZE_CHART_LENGTH || !compactView) ? (
                                                            <tr key={sizeChart.id}>
                                                                <td>
                                                                    <Checkbox
                                                                        onChange={toggleSelectedSizeChart(sizeChart.id)}
                                                                        checked={selectedSizeCharts.includes(sizeChart.id)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    {sizeChart.brand_name}
                                                                </td>
                                                                <td className="d-table-cell d-sm-none">
                                                                    <b>Sizes:</b> {getOrderedKeys(sizeChart.data).join(", ")}<br/>
                                                                    <b>Measures: </b> {getOrderedKeys(sizeChart.data[getOrderedKeys(sizeChart.data)[0]]).join(", ")}
                                                                </td>
                                                                <td>
                                                                    <Link onClick={() => {
                                                                        history.push(`/size-chart/edit/${sizeChart.id}`);
                                                                    }}>
                                                                        <Button>
                                                                            Edit
                                                                        </Button>
                                                                    </Link>
                                                                </td>
                                                                <td>
                                                                    <Button color="light" onClick={() => {
                                                                        const options = {
                                                                            message: `Are you sure to delete "${sizeChart.brand_name}"?`,
                                                                            loadingFn: () => {
                                                                            }
                                                                        };
                                                                        handleApiDeleteById(deleteSizeChart, sizeChart.id, options).then(isSuccess => {
                                                                            setSizeCharts(null);
                                                                            setProducts(null);
                                                                            if (isSuccess)
                                                                                toaster.notify(() => <div
                                                                                    className="alert alert-success">{"Size chart deleted"}</div>);
                                                                        });
                                                                    }}>
                                                                        Delete
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        ) : <tr key={sizeChart.id}/>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan={6}>
                                                            <NotFoundData icon="fas fa-search"
                                                                          title="No size chart matched the filter"/>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            </tbody>
                                        </table>
                                    ) : <NotFoundData icon="fas fa-shopping-basket" title="No size chart added yet"/>
                                )}
                        </div>
                        {
                            !compactView && data && !loading && <Pagination data={data} onChange={setPage}/>
                        }
                    </>
                )
            }
        </div>
        {
            compactView && sizeCharts?.length > COMPACT_SIZE_CHART_LENGTH && (
                <div className="container pt-4 pb-3 text-center">
                    <Button color="light" link="/size-chart" className="d-inline">
                        View all <span className="fa fa-chevron-right"></span>
                    </Button>
                </div>
            )
        }
    </div>
})

export default SizeChartListPage;
