import React from 'react';
import {SizeChartArticle} from "./Articles/SizeChart";
import {FabricArticle} from "./Articles/Fabric";
import {ButtonPlacementArticle} from "./Articles/ButtonPlacement";
import {ButtonPlacementPageBuilderArticle} from "./Articles/ButtonPlacementPageBuilder";
import {AppEmbedBlockArticle} from "./Articles/AppEmbedBlock";
import {IntegrationApiArticle} from "./Articles/Integration";
import {withRouter} from "react-router-dom";

export const HelpArticles = [
  SizeChartArticle,
  FabricArticle,
  ButtonPlacementArticle,
  ButtonPlacementPageBuilderArticle,
  AppEmbedBlockArticle,
  IntegrationApiArticle,
];

const HelpArticlesIndex = withRouter(({history}) => {
  return <div className="c-panel-content">
    <div className="h3 p-3">How to questions</div>
    {
      HelpArticles.map((article, index) => (
        <div key={index} className="c-card-item c-card-body c-card-item--clickable mb-3" onClick={() => {
          history.push(`/help/${article.id}`);
        }}>
          <b>{index + 1}.</b> {article.title}
        </div>
      ))
    }
  </div>
});

export default HelpArticlesIndex;