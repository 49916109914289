import React, {memo, useContext, useEffect} from 'react';
import SidebarLogo from "./SidebarLogo";
import SidebarLink from "./SidebarLink";
import {AppContext} from "../../../../context/providers";


const Sidebar = ({onClose}) => {

    const {
        sideBarLinks=[]
    } = useContext(AppContext);

    const onClickLinks = () => {
        if (window.innerWidth <= 768) onClose();
    };

    return (
        <div className="c-panel-sidebar">
            <SidebarLogo onClick={onClickLinks}/>
            <div className="c-panel-sidebar__list" onClick={onClickLinks}>
                <div className="c-panel-sidebar__list-software">
                    {sideBarLinks.map((item, key) => <SidebarLink key={key} {...item} />)}
                </div>
            </div>
        </div>
    )
};

export default memo(Sidebar);
