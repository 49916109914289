import {getDomain} from "../utils/common";

import zibacoutureConfig from "./integrations/zibacouture";
import parkConfig from "./integrations/parkConfig";
import tulikaConfig from "./integrations/tulika";
import prefectureConfig from "./integrations/theprefecture";
import blowHammerConfig from "./integrations/blowhammer";
import poshConfig from "./integrations/posh";
import shepherdsConfig from "./integrations/shepherds";
import amWorkShirtsConfig from "./integrations/6amWorkShirts";
import miloConfig from "./integrations/milo";
import demoConfig from "./integrations/demo";
import baseConfig from "./baseConfig";
import ls from "local-storage";
import {BODY_SHAPE_OFFSET_FIELD, INTERVIEW_STEP} from "../components/BodyShape/Constants";

const domain = getDomain();
export let config;
switch (domain) {
    case "zibacouture.com":
        config = zibacoutureConfig;
        break;
    case "poshshop.ca":
        config = poshConfig;
        break;
    case "www.shepherdsfashions.com":
        config = shepherdsConfig;
        break;
    case "6am-workshirts.myshopify.com":
        config = amWorkShirtsConfig;
        break;
    case "www.miloanddexter.com":
        config = miloConfig;
        break;
    case "www.tulikamode.com":
        config = tulikaConfig;
        break;
    case "www.1ereavenue.com":
        config = demoConfig;
        break;
    case "theprefecture.myshopify.com":
        config = prefectureConfig;
        break;
    case "park-fifth-clothing-co.myshopify.com":
        config = parkConfig;
        break;
    default:
        if (domain.includes("blowhammer")) {
            config = blowHammerConfig;
        } else {
            config = baseConfig;
        }
        break;
}

export const STORE_KEY = "RSStore";
const VERSION = "1.0";
export const data = ls.get(STORE_KEY) || {};
data.VERSION = VERSION;
ls.set(STORE_KEY, data);

export const KEY = {
    MODAL_CLASS_KEY: "modalClass",
    MODAL_SHOW_KEY: "modalShow",
    BUSINESS_KEY: "business",
    PROFILE_KEY: "profile",
    PRODUCT_KEY: "product",
    INTERVIEW_KEY: "interview",
    BODY_SHAPE_KEY: "bodyShape",
    TYPICAL_SIZE: "typicalSize",
    BODY_MEASUREMENTS_KEY: "bodyMeasurement",
    KNOWN_BRAND_KEY: "knownBrand",
    UNIT_METHOD_KEY: "unitMethod",
}

export const modalInit = {
    show: false,
    class: data[KEY.MODAL_CLASS_KEY],
    size: "lg"
}
export const interviewInit = {
    step: INTERVIEW_STEP.GENDER
};
export const businessInit = data[KEY.BUSINESS_KEY];
export const profileInit = data[KEY.PROFILE_KEY] || {};
export const productInit = null;
export const savedBodyShape = data[KEY.BODY_SHAPE_KEY] || {};
export const getBodyShapeInit = (savedBodyShape) => ({
    gender: savedBodyShape.gender || "",
    unit: savedBodyShape.unit || config.interview.basicQuestions.defaultUnit,
    height: savedBodyShape.height || "",
    weight: savedBodyShape.weight || "",
    age: savedBodyShape.age || "",
    bodyType: savedBodyShape.bodyType || "",
    [BODY_SHAPE_OFFSET_FIELD.BUST]: savedBodyShape[BODY_SHAPE_OFFSET_FIELD.BUST] || 0,
    [BODY_SHAPE_OFFSET_FIELD.WAIST]: savedBodyShape[BODY_SHAPE_OFFSET_FIELD.WAIST] || 0,
    [BODY_SHAPE_OFFSET_FIELD.HIP]: savedBodyShape[BODY_SHAPE_OFFSET_FIELD.HIP] || 0,
    band: savedBodyShape.band || "",
    cup: savedBodyShape.cup || "",
    neck: savedBodyShape.neck || "",
    length: savedBodyShape.length || "",
    fitPreference: savedBodyShape.fitPreference || 0,
});

export const bodyShapeInit = getBodyShapeInit(savedBodyShape);
export const bodyMeasurementInit = data[KEY.BODY_MEASUREMENTS_KEY] || null;
export const knownBrandInit = data[KEY.KNOWN_BRAND_KEY] || {};
export const typicalSizeInit = data[KEY.TYPICAL_SIZE] || "";
export const cameraInit = {
    stream: null,
    interval: null,
}
