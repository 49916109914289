import {getOrderedKeys} from "./common";

export const nonUnitMeasures = ["height", "weight"];

const roundToDigit = (value, digits, doRound) => {
    const roundedValue = Math.round(value * 10 ** digits) / 10 ** digits;
    if (doRound && (Math.abs(Math.round(roundedValue) - roundedValue) <= 0.1 ** digits * 2)) {
        return Math.round(roundedValue);
    }
    return roundedValue;
};

export const castChartValues = (data, castFunc, castAllMeasures) => {
    getOrderedKeys(data).forEach(size => getOrderedKeys(data[size]).forEach(measure => {
        if (!nonUnitMeasures.includes(measure) || castAllMeasures) {
            data[size][measure] = castFunc(data[size][measure]);
        }
    }));
}

export const castChartUnit = (data, multiplier) => {
    castChartValues(data, (v) => {
        if (isNaN(parseFloat(v))) {
            return "";
        }
        return roundToDigit(parseFloat(v) * multiplier, 2, true).toString();
    });
}

export const getSizeChartFields = (size_chart) => Object.keys(Object.values(size_chart)[0]);

