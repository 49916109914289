export const IntegrationApiArticle = {
  id: "integration-doc",
  is_popular: true,
  title: "Robosize Integration Documentation",
  body: [
    {
      tag: "h4",
      body: "Snippet integration"
    },
    {
      tag: "p",
      body: "To integrate Robosize widget add the following script to your product details page at the start of the body tag:"
    },
    {
      tag: "well",
      body: `<script>
    window.Robosize = window.Robosize === undefined ? {} : window.Robosize;
    Robosize.shop = "DOMAIN";
    Robosize.product = "PRODUCT_ID";
    
    !function () {
        var t = document.createElement("link");
        t.setAttribute("href", "https://d3elw2t9mvtwj8.cloudfront.net/app_bundle.css");
        t.setAttribute("id", "if-css");
        t.setAttribute("rel", "stylesheet");
        t.setAttribute("type", "text/css");
        document.body.insertBefore(t, document.body.firstChild);
        var e = document.createElement("script");
        e.setAttribute("id", "if-script");
        e.setAttribute("src", "https://d3elw2t9mvtwj8.cloudfront.net/app_bundle.js");
        e.setAttribute("defer", "defer");
        document.head.insertBefore(e, document.head.firstChild);
    }();
</script>`
    },
    {
      tag: "p",
      body: "<b>Note</b>: Make sure to replace <b>DOMAIN</b> with your store's domain and <b>PRODUCT_ID</b> with the product's ID in Robosize."
    },
    {
      tag: "h4",
      body: "Product APIs"
    },
    {
      tag: "p",
      body: "All product API calls are made to the following endpoint. Make sure to replace <b>API_KEY</b> with yours:"
    },
    {
      tag: "well",
      body: "https://api.robosize.com/api/v1/product/base/?api_key=API_KEY"
    },
    {
      tag: "h5",
      body: "List products:"
    },
    {
      tag: "p",
      body: "To list all products in your account, make a GET request to the endpoint above."
    },
    {
      tag: "h5",
      body: "Create product:"
    },
    {
      tag: "p",
      body: "To create a product, make a POST request to the endpoint above with the following body:"
    },
    {
      tag: "well",
      body: JSON.stringify({
        "name": "New product",
        "store_id": "123456",
        "tags": ["tag 1", "tag 2", "tag 3"],
        "image_url": "https://api.robosize.com/media/business/1833ab6953/product/81e29b31a34ece8/top_81e29b31a34ece8_md.jpg",
        "type": "top"
      }, null, 2),
    },
    {
      tag: "p",
      body: "<b>Note</b>: The <b>store_id</b> is the product's ID in your store. The <b>image_url</b> is the URL of the product's image."
    },
    {
      tag: "h5",
      body: "Update product:"
    },
    {
      tag: "p",
      body: "To update a product, make a PATCH request to the endpoint above with the following body:"
    },
    {
      tag: "well",
      body: JSON.stringify({
        "products": [
          "edcff75c6a92d01"
        ],
        "store_id": "new-store-id",
        "name": "New product name",
        "tags": ["tag 1", "tag 2"],
        "image_url": "https://cdn.shopify.com/s/files/1/0579/6954/1270/products/417_3.jpg?v=1674281178",
        "type": "top"
      }, null, 2),
    },
    {
      tag: "p",
      body: "<b>Note</b>: The product's ID is the ID of the product in Robosize. " +
        "This ID is returned when you create a product. " +
        "The PATCH API is partial, meaning that it will only update the fields that you send in the body. " +
        "If you don't send a field, it will not be updated."
    },
    {
      tag: "h5",
      body: "Delete product:"
    },
    {
      tag: "p",
      body: "To delete a product, make a DELETE request to the endpoint above with hash=PRODUCT_ID in the query string."
    },
  ],
};