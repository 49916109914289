import { useEffect, useState } from 'react';

const MOBILE_SIZE = 576;

export const useIsDesktop = () => {
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > MOBILE_SIZE);

    useEffect(() => {
        const handler = () => {
            const width = window.innerWidth;
            setIsDesktop(width > MOBILE_SIZE);
        };

        window.addEventListener('resize', handler);

        return () => window.removeEventListener('resize', handler);
    }, []);

    return isDesktop;
};
