export const bulkAction = {
    UPDATE_FABRIC: "update_fabric",
    UPDATE_SIZE_CHART: "update_size_chart",
    UPDATE_STATUS: "update_status",
};

export const bulkActions = [
    {
        label: "Update fabrics",
        value: bulkAction.UPDATE_FABRIC
    },
    {
        label: "Update size charts",
        value: bulkAction.UPDATE_SIZE_CHART
    },
    // {
    //     label: "Update status",
    //     value: bulkAction.UPDATE_STATUS
    // }
];

export const productStatus = {
    ACTIVE: "active",
    NEEDS_CHART: "needs_chart",
}

export const statusOptions = [
    {label: "All", value: null},
    {label: "Live", value: "active"},
    {label: "Needs chart", value: "needs_chart"},
];