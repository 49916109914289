import {useEffect, useRef, useState} from "react";
import {getSizeCharts} from "../api/sizeChart";
import useStateRef from "react-usestateref";

const useFetchSizeCharts = ({page, limit, query, options} = {}) => {
    const [loading, setLoading, loadingRef] = useStateRef(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const timerRef = useRef()
    const {
        queryDebounceTime = 500
    } = options || {}


    const fetchSizeCharts = async () => {
        if (loadingRef.current) return
        setLoading(true);
        try {
            const response = await getSizeCharts(page, limit === Infinity ? 999999 : limit, query)
            setData(response);
        } catch (e) {
            setError(e)
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchSizeCharts()
    }, [page, limit]);

    useEffect(() => {
        timerRef.current = setTimeout(() => fetchSizeCharts(), queryDebounceTime);
        return () => {
            clearTimeout(timerRef.current)
        };
    }, [query])

    return {
        error,
        loading,
        data,
        sizeCharts: data?.results || null
    }
}
export default useFetchSizeCharts