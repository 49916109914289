import React, {useState} from 'react';
import ReactTooltip from 'react-tooltip';
import * as classnames from "classnames";
import {capitalize} from "@material-ui/core";
import {roundToTwoDigits} from "../../../utilities/number";
import FAQList from "../Help/FAQList";
import {FAQItemsBilling} from "../Help/FAQItems";
import {plans} from "./plans";
import Button from "../../Common/UIKit/Form/Button";
import toaster from "toasted-notes";

const ShopifySetupPage = ({currentPlan, saveFunc}) => {
    const [planDuration, setPlanDuration] = useState("monthly");
    const [subscribingPlan, setSubscribingPlan] = useState(null);

    const toastSubsFailed = () => {
        toaster.notify(() => <div className="alert alert-danger mt-2">
            Could not complete the request. Please contact support@robosize.com
        </div>);
    }

    const handleSelectPlan = plan => {
        if (subscribingPlan !== null) return;
        setSubscribingPlan(plan);
        saveFunc({
            name: plan.name,
        }).then(resp => {
            setSubscribingPlan(null);
            if (resp.subscribed) {
                if (resp.confirm_url) {
                    window.location.replace(resp.confirm_url);
                } else {
                    window.location.reload();
                }
            } else {
                toastSubsFailed();
            }
        }).catch(error => {
            console.error(error);
            toastSubsFailed();
            setSubscribingPlan(null);
        })
    }

    const getPriceValue = (plan, planDuration) => {
        if (plan.name === "free") {
            return <span>&nbsp;</span>
        }
        return `$${plan.name === "basic" || planDuration === "monthly" ? plan.price : roundToTwoDigits(plan.price * 0.8)}`;
    }

    return <div className="c-panel-content">
        <div className="container py-3 text-center">
            <h4>Simple pricing model</h4>
            All paid plans have a 14-days trial.<br/>
            {/*<ControlSwitch*/}
            {/*    control={control}*/}
            {/*    name="term"*/}
            {/*    defaultValue={planDuration === "annual"}*/}
            {/*    value={planDuration === "annual"}*/}
            {/*    onChangeValue={(v) => {*/}
            {/*        setPlanDuration(v ? "annual" : "monthly");*/}
            {/*    }}*/}
            {/*    label={planDuration === "annual" ? "Billed annually (save 20% on premium plans)" : "Billed monthly"}*/}
            {/*    formGroup*/}
            {/*/>*/}
        </div>
        <div className="row py-3">
            {
                plans.map((plan, index) => (
                    <div key={index} className="col-12 col-md-6 col-lg-4">
                        <div className="card">
                            <div className="card-body text-center">
                                <h4>{capitalize(plan.name)}</h4>
                                <br/><br/>
                                <h5>{getPriceValue(plan, planDuration)}</h5>
                                <div className="hint">{plan.name === "free" ? "forever free" : "per month"}</div>
                                {/*<br/><br/>*/}
                                {/*<div*/}
                                {/*    className="hint">{planDuration === "annual" ? "Billed annually" : "Billed monthly"}</div>*/}
                            </div>
                        </div>
                        <div className="pt-3 mb-5 text-center">
                            <Button
                                className="btn btn-block d-inline-block"
                                color="primary"
                                loading={subscribingPlan === plan}
                                onClick={() => handleSelectPlan(plan)}
                                disabled={plan === currentPlan}
                            >
                                {plan === currentPlan ? "Current plan" : "Start free trial"}
                            </Button>
                        </div>
                    </div>
                ))
            }
        </div>
        <div className="container pt-5 pb-2 text-center">
            <h4>Compare our pricing plans</h4>
        </div>
        <div className="container">
            <div className="c-table">
                <table>
                    <thead className="text-center">
                    <tr>
                        <th></th>
                        {
                            plans.map((plan, index) => (
                                <td key={index}>{capitalize(plan.name)}</td>
                            ))
                        }
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            Monthly visitors
                            <span
                                className="fa fa-info-circle ml-2 cursor-pointer"
                                data-tip="Generic product page view.<br/>This is used to estimate the size of the shop."
                            ></span>
                        </td>
                        {
                            plans.map((plan, index) => (
                                <td key={index} className="text-center">{plan.views}</td>
                            ))
                        }
                    </tr>
                    <tr>
                        <td>
                            AI recommender
                            <span
                                className="fa fa-info-circle ml-2 cursor-pointer"
                                data-tip="Predict accurate fit using state-of-the-art machine learning engine."
                            ></span>
                        </td>
                        {
                            plans.map((plan, index) => (
                                <td key={index} className="text-center">
                                    <span className={classnames("fa", {
                                        "fa-check text-success": plan.MLAdvisor,
                                        "fa-times text-danger": !plan.MLAdvisor,
                                    })}></span>
                                </td>
                            ))
                        }
                    </tr>
                    <tr>
                        <td>
                            Unlimited size charts
                        </td>
                        {
                            plans.map((plan, index) => (
                                <td key={index} className="text-center">
                                    {
                                        plan.unlimitedSizeCharts ? (
                                            <span className={classnames("fa", {
                                        "fa-check text-success": plan.unlimitedSizeCharts,
                                        "fa-times text-danger": !plan.unlimitedSizeCharts,
                                    })}></span>
                                        ) : (
                                            4
                                        )
                                    }

                                </td>
                            ))
                        }
                    </tr>
                    <tr>
                        <td>
                            Unit conversion
                            <span
                                className="fa fa-info-circle ml-2 cursor-pointer"
                                data-tip="Enter your size chart in metric or imperial and we will convert it for you."
                            ></span>
                        </td>
                        {
                            plans.map((plan, index) => (
                                <td key={index} className="text-center">
                                    <span className="fa fa-check text-success"></span>
                                </td>
                            ))
                        }
                    </tr>
                    <tr>
                        <td>
                            Analytics
                            <span
                                className="fa fa-info-circle ml-2 cursor-pointer"
                                data-tip="See analytics on your customers using the fitting room."
                            ></span>
                        </td>
                        {
                            plans.map((plan, index) => (
                                <td key={index} className="text-center">{capitalize(plan.reports)}</td>
                            ))
                        }
                    </tr>
                    <tr>
                        <td>
                            Customization
                            <span
                                className="fa fa-info-circle ml-2 cursor-pointer"
                                data-tip="Customize your customer's experience in the fitting room.<br/>Fully customized and experience design includes custom developed experience."
                            ></span>
                        </td>
                        {
                            plans.map((plan, index) => (
                                <td key={index} className="text-center">{capitalize(plan.customization)}</td>
                            ))
                        }
                    </tr>
                    <tr>
                        <td>
                            Social proof
                            <span
                                className="fa fa-info-circle ml-2 cursor-pointer"
                                data-tip="Show customer what size others with similar body composition bought and kept to give them extra confidence."
                            ></span>
                        </td>
                        {
                            plans.map((plan, index) => (
                                <td key={index} className="text-center">
                                    <span className={classnames("fa", {
                                        "fa-check text-success": plan.socialProof,
                                        "fa-times text-danger": !plan.socialProof,
                                    })}></span>
                                </td>
                            ))
                        }
                    </tr>
                    <tr>
                        <td>
                            Product recommendation
                            <span
                                className="fa fa-info-circle ml-2 cursor-pointer"
                                data-tip="We offer them your customers the products that fit them best. <br/> So, they can buy even more."
                            ></span>
                        </td>
                        {
                            plans.map((plan, index) => (
                                <td key={index} className="text-center">
                                    <span className={classnames("fa", {
                                        "fa-check text-success": plan.productRecommender,
                                        "fa-times text-danger": !plan.productRecommender,
                                    })}></span>
                                </td>
                            ))
                        }
                    </tr>
                    <tr>
                        <td></td>
                        {
                            plans.map((plan, index) => (
                                <td key={index} className="text-center">
                                    <Button
                                        className="btn btn-block"
                                        color="primary"
                                        loading={subscribingPlan === plan}
                                        onClick={() => handleSelectPlan(plan)}
                                        disabled={plan === currentPlan}
                                    >
                                        {plan === currentPlan ? "Current plan" : "Get started"}
                                    </Button>
                                </td>
                            ))
                        }
                    </tr>
                    </tbody>
                </table>
            </div>
            <ReactTooltip html={true} effect="solid" place="top"/>
        </div>
        <div className="container py-3">
            <div className="container text-center">
                <h4>Robosize FAQ</h4>
            </div>
            <FAQList items={FAQItemsBilling}/>
        </div>
    </div>
}

export default ShopifySetupPage;