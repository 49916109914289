import React, {useState} from 'react';
import {handleApiSave} from "../../../utilities/useApi";
import AuthenticationLayout from "../../Common/Authentication/AuthenticationLayout";
import {loginShopify} from "../../../api/auth";
import {getAllQueryParams} from "../../../utilities/URI";
import {BoxSpinner} from "../../Common/UIKit/Spinner";
import toaster from "toasted-notes";


const LoginShopifyPage = () => {

    const [loading, setLoading] = useState(false);
    const params = getAllQueryParams();

    if (!loading) {
        setLoading(true);
        loginShopify(params).then(response => {
            if (response.status === 400) {
                toaster.notify(() => <div className="alert alert-danger mt-2">{response.data.message}</div>);
            } else if (response.redirect_url) {
                window.location.replace(response.redirect_url);
            } else {
                window.location.replace("/");
            }
        });
    }

    return (
        <AuthenticationLayout>
            <div className="c-auth__head">
                <h1>Authenticating...</h1>
                <p>Shop: {params.shop}</p>
            </div>
            <BoxSpinner/>
        </AuthenticationLayout>
    );
};

export default LoginShopifyPage;
