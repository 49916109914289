import config from "../../baseConfig";
import {UNIT_METHOD} from "../../../components/BodyShape/Constants";
import {deepAssign} from "../../../utils/common";

export default deepAssign({}, config, {
    appClass: "milo",
    integrationButton: {
        selector: ".swatch-items-wrapper",
        wrapperClass: "milo"
    },
    interview: {
        basicQuestions: {
            defaultUnit: UNIT_METHOD.IMPERIAL,
        }
    }
});