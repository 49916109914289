import React, {Fragment, useContext, useEffect, useState} from 'react';
import {AppContext} from "../../../context/providers";
import {BoxSpinner} from "../../Common/UIKit/Spinner";
import {withRouter} from "react-router-dom";
import {createCharge, getCharge} from "../../../api/payment";
import {getAllQueryParams} from "../../../utilities/URI";
import Button from "../../Common/UIKit/Form/Button";
import toaster from "toasted-notes";

const ShopifyPaymentCompletePage = withRouter(({history}) => {
    const {business} = useContext(AppContext);

    const chargeId = getAllQueryParams().id;

    if (!chargeId) {
        toaster.notify(() => <div className="alert alert-danger">
            Invalid link, please specify the charge ID.
        </div>);
        return <Fragment/>
    }

    const [charge, setCharge] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (loading) return;
        if (charge === null && chargeId) {
            setLoading(true);
            getCharge(chargeId).then(charge => {
                setCharge(charge);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                setError(error);
            })
        }
    }, []);

    useEffect(() => {
        if (charge && business && charge?.business !== business?.id) {
            setError("This charge is not for your business. Please check the link first.");
        }
    }, [charge, business]);

    return <div className="c-panel-content">
        <div className="container text-center mb-3">
            <h4>Application charge</h4>
        </div>
        <div className="container text-center">
            <div className="alert alert-success">
                Thank you, the charge has been completed successfully.
            </div>
        </div>
        <div className="container text-center py-3">
            <Button className="d-inline" link="/dashboard" color="primary">
                Go to dashboard
            </Button>
        </div>
    </div>

});

export default ShopifyPaymentCompletePage;