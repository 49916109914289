import React, {Fragment} from 'react';
import Input from "../../Common/UIKit/Form/Input";
import Button from "../../Common/UIKit/Form/Button";
import Select from "../../Common/UIKit/Form/Select";
import {Unit} from "../../../utilities/UnitHelper";
import capitalize from "@material-ui/core/utils/capitalize";
import {measurementLabel} from "../../../utilities/String";
import {castChartUnit} from "../../../fit_widget/src/utils/sizeChart";

const ChartData = ({
                       unit,
                       setUnit,
                       sizes,
                       measures,
                       dataErrors,
                       editSizeChartField,
                       newSizeChart,
                       setNewSizeChart,
                       isOpen,
                       handleClose = () => {
                       },
                       handleOpen = () => {
                       },
                       isPreview = false,
                   }) => {

    const chartDataEntered = sizes?.length > 0 && measures?.length > 0;
    const hasDataError = JSON.stringify(dataErrors) !== "{}";

    return <div className="c-list">
        {
            !isPreview && <Fragment>
                <div className="container">
                    <h3 className="c-list__title">3. Chart data</h3>
                    {
                        isOpen ? (
                            <Button color="primary" className="float-right" onClick={handleClose}>
                                Next
                            </Button>
                        ) : (
                            <Button color="light" className="float-right" onClick={handleOpen}>
                                <span className="fa fa-pencil"></span>
                            </Button>
                        )
                    }
                </div>
                <hr className="mt-4"/>
            </Fragment>
        }
        <div className="c-list__item">
            {
                !isOpen && (!chartDataEntered || hasDataError) ? (
                    <Fragment>
                        {
                            (!chartDataEntered) ? (
                                <div className="px-3 text-warning">
                                    Chart data is required
                                </div>
                            ) : (
                                <div className="px-3 text-danger">
                                    Chart data is invalid
                                </div>
                            )
                        }
                    </Fragment>
                ) : (
                    <Fragment>
                        <div className="container-fluid mb-3">
                            <div className="c-list__right">
                                <div className="c-list__right-item">
                                    <Select
                                        id="unit"
                                        options={[Unit.METRIC, Unit.IMPERIAL].map(unit => ({
                                            label: capitalize(unit),
                                            value: unit
                                        }))}
                                        onChangeValue={(newUnit) => {
                                            if (unit !== newUnit) {
                                                castChartUnit(newSizeChart.data, newUnit === Unit.METRIC ? 2.54 : 1 / 2.54);
                                                setNewSizeChart(Object.assign({}, newSizeChart));
                                            }
                                            setUnit(newUnit);
                                        }}
                                        defaultValue={Unit.METRIC}
                                        value={unit}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="c-table c-table--sm">
                            <table>
                                <thead>
                                <tr>
                                    <th>Size</th>
                                    {
                                        measures.map((measure, index) => (
                                            <th key={index}>{measurementLabel(measure)}</th>
                                        ))
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    sizes.map((size, sizeIndex) => (
                                        <tr key={sizeIndex}>
                                            <td>
                                                <b>{size}</b>
                                            </td>
                                            {
                                                measures.map((measure, measureIndex) => (
                                                    <td key={`${sizeIndex}-${measureIndex}`}>
                                                        {
                                                            isOpen ? (
                                                                    <Input
                                                                        value={newSizeChart.data[size] && !!newSizeChart.data[size][measure] ? newSizeChart.data[size][measure] : ""}
                                                                        error={!!dataErrors[`${size}-${measure}`]}
                                                                        onChange={(event) => {
                                                                            const value = event.target.value;
                                                                            if (!value) {
                                                                                editSizeChartField(size, measure, null);
                                                                            } else {
                                                                                try {
                                                                                    if (!isNaN(value)) {
                                                                                        editSizeChartField(size, measure, value);
                                                                                    }
                                                                                } catch (e) {
                                                                                }
                                                                            }
                                                                        }}
                                                                    />
                                                                ) :
                                                                <Fragment>{newSizeChart.data[size] && !!newSizeChart.data[size][measure] ? newSizeChart.data[size][measure] : ""}</Fragment>
                                                        }
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>
                    </Fragment>
                )
            }
        </div>
    </div>
};

export default ChartData;