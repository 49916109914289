import React, {Fragment} from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

const FAQList = ({items}) => {
    return <Accordion allowZeroExpanded>
        {items.map((item, index) => (
            <AccordionItem key={index}>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        <span className="fa fa-chevron-right"></span>
                        {item.question}
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    {item.answer}
                </AccordionItemPanel>
            </AccordionItem>
        ))}
    </Accordion>
}

export default FAQList;