import React, {useCallback, useContext, useEffect, useState} from 'react';
import {AppContext} from "../../../context/providers";
import {getPaymentDetails, getSubscription} from "../../../api/payment";
import {BoxSpinner} from "../../Common/UIKit/Spinner";
import toaster from "toasted-notes";
import SubscriptionDetails from "./SubscriptionDetails";
import {capitalize} from "@material-ui/core";
import * as classnames from "classnames";

const BillingDetailsPage = () => {

    const {stripe} = useContext(AppContext);

    const [paymentMethod, setPaymentMethod] = useState((stripe && stripe.method) || null);
    const [loadingPaymentMethod, setLoadingPaymentMethod] = useState(false);
    const [subscription, setSubscription] = useState(null);
    const [loadingSubscription, setLoadingSubscription] = useState(false);

    useEffect(() => {
        const fetchSubscription = () => {
            if (loadingSubscription) return;
            setLoadingSubscription(true);
            getSubscription().then(subscriptions => {
                setSubscription(subscriptions[0]);
                setLoadingSubscription(false);
            }).catch(() => {
                toaster.notify(() => <div className="alert alert-danger mt-2">Could not load subscription details.
                    Please contact us for support</div>);
            })
        }
        if (subscription === null) fetchSubscription();
    }, [subscription, loadingSubscription]);

    useEffect(() => {
        const fetchPaymentMethod = () => {
            setLoadingPaymentMethod(true);
            getPaymentDetails().then(response => {
                setPaymentMethod(response);
                setLoadingPaymentMethod(false);
            });
        }
        if (!loadingPaymentMethod && !paymentMethod) {
            fetchPaymentMethod();
        }
    }, [loadingPaymentMethod, paymentMethod]);

    const formatMonth = month => {
        let res = String(month);
        return (res.length) === 1 ? `0${res}` : res;
    }

    const getPaymentMethodDetails = () => (
        <div className="c-table c-table--bordered text-center">
            <table>
                <tbody>
                <tr>
                    <th>
                        Method
                    </th>
                    <td>
                        <div className="fa fa-credit-card"></div>
                        &nbsp; Card
                    </td>
                </tr>
                <tr>
                    <th>
                        Brand
                    </th>
                    <td>
                        {paymentMethod.brand}
                    </td>
                </tr>
                <tr>
                    <th>
                        Expiry
                    </th>
                    <td>
                        {formatMonth(paymentMethod.exp_month)}/{paymentMethod.exp_year}
                    </td>
                </tr>
                <tr>
                    <th>
                        Last 4 digits
                    </th>
                    <td>
                        {paymentMethod.last4}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    )


    if (loadingPaymentMethod || !paymentMethod) {
        return <div className="c-panel-content"><BoxSpinner/></div>
    }

    return (
        <div className="c-panel-content">
            <div className="h4 pl-3">Subscription details</div>
            <SubscriptionDetails subscription={subscription}/>
            <div className="h4 pl-3">Payment method</div>
            {getPaymentMethodDetails()}
            <div className="p-3">
                To update this information please contact us.
            </div>
        </div>
    );

};

export default BillingDetailsPage;