export const planNames = {
    FREE: "free",
    BASIC: "basic",
    PREMIUM: "premium",
    ULTIMATE: "ultimate",
    ENTERPRISE: "enterprise"
}

export const plans = [
    {
        name: planNames.FREE,
        price: 0.0,
        MLAdvisor: false,
        unlimitedSizeCharts: false,
        views: "5,000",
        reports: "basic",
        customization: "customizable",
        socialProof: false,
        productRecommender: false,
    },
    // {
    //     name: planNames.BASIC,
    //     price: 9.99,
    //     MLAdvisor: true,
    //     unlimitedSizeCharts: true,
    //     views: "5,000",
    //     reports: "basic",
    //     customization: "customizable",
    //     socialProof: false,
    //     productRecommender: false,
    // },
    {
        name: planNames.PREMIUM,
        price: 49.99,
        MLAdvisor: true,
        unlimitedSizeCharts: true,
        views: "10,000",
        reports: "advanced",
        customization: "customizable",
        socialProof: true,
        productRecommender: false,
    },
    {
        name: planNames.ULTIMATE,
        price: 149.99,
        MLAdvisor: true,
        unlimitedSizeCharts: true,
        views: "50K",
        reports: "advanced",
        customization: "fully customized",
        socialProof: true,
        productRecommender: true,
    },
    // {
    //     name: planNames.ULTIMATE",
    //     price: 299.99,
    //     MLAdvisor: true,
    //     unlimitedSizeCharts: true,
    //     views: "1M",
    //     reports: "advanced",
    //     customization: "experience design",
    //     socialProof: true,
    //     productRecommender: true,
    // },
];