import config from "../../baseConfig";
import {GENDER} from "../../../components/BodyShape/Constants";
import {deepAssign} from "../../../utils/common";

export default deepAssign({}, config, {
    appClass: "",
    integrationButton: {
        selector: ".selector-wrapper",
        wrapperClass: "posh"
    },
    interview: {
        forceGender: GENDER.FEMALE,
    }
});