export const IMAGE_DEFAULT= require('./images/default-image.jpg').default;
export const IMAGE_DEFAULT_WOMAN_AVATAR = require('./images/default-woman-avatar.jpg').default;
export const IMAGE_LOGO = require('./images/logo.png').default;
export const IMAGE_EMAIL_INBOX = require('./images/email.png').default;

export const IMAGE_SUCCESS_CHECK = require('./images/success-check.png').default;

export const IMAGE_GOOGLE_LOGO = require('./images/google-logo.png').default;

export const MEASURES_FIT = require('./images/measures-fit.png').default;

export const SOCIAL_PROOF = require('./images/social-proof.png').default;

export const WIDGET_MEASURES_NO_REC = require('./images/widget_preview/measures_0.png').default;
export const WIDGET_MEASURES_WITH_REC = require('./images/widget_preview/measures_1.png').default;
export const WIDGET_SOCIAl_NO_REC = require('./images/widget_preview/social_0.png').default;
export const WIDGET_SOCIAL_WITH_REC = require('./images/widget_preview/social_1.png').default;
export const WIDGET_TOP_TOW_NO_REC = require('./images/widget_preview/top_two_0.png').default;
export const WIDGET_TOP_TOW_WITH_REC = require('./images/widget_preview/top_two_1.png').default;

export const SIZE_CHART__BRAND = require('./images/help/size_chart_brand.png').default;
export const SIZE_CHART__DIMENSIONS = require("./images/help/size_chart_dimensions.png").default;
export const SIZE_CHART__DATA = require("./images/help/size_chart_data.png").default;
export const SIZE_CHART__MATCHING = require("./images/help/size_chart_matching.png").default;

export const FABRIC__NAME = require("./images/help/fabric__name.png").default;
export const FABRIC__STRETCH = require("./images/help/fabric__stretch.png").default;

export const PRODUCT__SEARCH = require("./images/help/product__search.png").default;
export const PRODUCT__UPDATE_FABRIC = require("./images/help/product__update_fabric.png").default;

export const BUTTON_PLACEMENT__START = require("./images/help/button_placement__start.png").default;
export const BUTTON_PLACEMENT__SELECT_ELEMENT = require("./images/help/button_placement__select_element.png").default;
export const BUTTON_PLACEMENT__BEFORE_AFTER = require("./images/help/button_placement__before_after.png").default;
export const BUTTON_PLACEMENT__COPY_SELECTOR = require("./images/help/button_placement__copy_selector.png").default;

export const BUTTON_PLACEMENT_SETTINGS = require("./images/help/button_placement_settings.png").default;
export const BUTTON_PLACEMENT__FILL_FORM = require("./images/help/button_placement__fill_form.png").default;

export const APP_EMBED_THEME_CUSTOMIZE = require("./images/help/app_embed__theme_customization.png").default;
export const APP_EMBED_ENABLE_SWITCH = require("./images/help/app_embed_enable_switch.png").default;

export const T_SHIRT = require("./icons/clothing/t-shirt.png").default;
export const SHIRT = require("./icons/clothing/shirt.png").default;
export const JACKET = require("./icons/clothing/jacket.png").default;
export const BOTTOMS = require("./icons/clothing/pants.png").default;
export const SHORTS = require("./icons/clothing/shorts.png").default;
export const DRESS = require("./icons/clothing/dress.png").default;
