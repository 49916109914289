import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {AppContext} from "../../../context/providers";
import {PageLoading} from "../../Common/UIKit/Spinner";
import Button from "../../Common/UIKit/Form/Button";
import {withRouter} from "react-router-dom";
import Link from "@material-ui/core/Link";
import Input from "../../Common/UIKit/Form/Input";
import {handleApiDeleteById, handleApiSave} from "../../../utilities/useApi";
import toaster from "toasted-notes";
import Select from "../../Common/UIKit/Form/Select";
import {createFabric, deleteFabric, getFabric, updateFabric} from "../../../api/fabric";

const EditFabricPage = withRouter(({history, match}) => {

    const {setFabrics} = useContext(AppContext);

    const isEdit = !!match?.params?.id;


    const [originalFabric, setOriginalFabric] = useState(null);
    const [newFabric, setNewFabric] = useState(null);

    const [loading, setLoading] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [nameError, setNameError] = useState(null);
    const [fitToughnessError, setFitToughnessError] = useState(null);
    const [saveLoading, setSaveLoading] = useState(false);

    const setName = useCallback(name => setNewFabric(Object.assign({}, newFabric, {name})), [newFabric]);

    const updateIsDirty = useCallback(() => {
        if (!isEdit) {
            setIsDirty(true);
            return;
        }
        if (originalFabric === null || newFabric === null) return;
        let isDirty = false;
        if (newFabric.name !== originalFabric.name) isDirty = true;
        if (newFabric.fit_toughness !== originalFabric.fit_toughness) isDirty = true;
        setIsDirty(isDirty);
    }, [isEdit, newFabric, originalFabric]);

    const fetchFabric = useCallback(() => {
        if (!isEdit) return;
        setLoading(true);
        getFabric(match.params.id).then(data => {
            setOriginalFabric(data);
            setNewFabric({
                id: data.id,
                name: data.name,
                fit_toughness: data.fit_toughness,
            });
            setIsDirty(false);
            setLoading(false);
        });
    }, [isEdit, match?.params?.id]);

    const fitToughnessOptions = useMemo(() => [...Array(10).keys()].map(toughness => ({
        value: toughness + 1,
        label: <>{toughness + 1}</>
    })), []);

    const validate = useCallback(() => !nameError && !fitToughnessError, [nameError, fitToughnessError]);

    const handleSave = useCallback(() => {
        if (validate()) {
            handleApiSave(
                createFabric,
                updateFabric,
                newFabric,
                match.params.id,
                {
                    loadingFn: setSaveLoading,
                }
            ).then((resp) => {
                setFabrics(null);
                setOriginalFabric(null);
                toaster.notify(() => <div
                    className="alert alert-success">{isEdit ? "Fabric updated" : "Fabric created"}</div>);
                if (!isEdit) {
                    history.push(`/fabric/edit/${resp.id}`);
                }
            });
        }
    }, [history, isEdit, match?.params?.id, newFabric, setFabrics, validate]);

    useEffect(() => {
        if (newFabric === null) return;
        setNameError(!newFabric.name);
        setFitToughnessError(!newFabric.fit_toughness);
        updateIsDirty();
    }, [originalFabric, newFabric, updateIsDirty]);

    useEffect(() => {
        if (originalFabric === null && !loading) fetchFabric();
        if (!isEdit && newFabric === null) setNewFabric({name: "", fit_toughness: 5});
    }, [fetchFabric, isEdit, loading, newFabric, originalFabric]);

    useEffect(() => {
        if (originalFabric !== null || loading === false) return;
        fetchFabric();
    }, [originalFabric, fetchFabric, loading]);

    if ((isEdit && originalFabric === null) || newFabric === null) {
        if (!loading) fetchFabric();
        return <div className="c-panel-content"><PageLoading/></div>
    }

    return <div className="c-panel-content">
        <div className="c-table-action">
            <Link
                className="c-table-filter__item"
                onClick={() => {
                    history.push(`/fabric`);
                }}>
                <Button color="light">
                    All fabrics
                </Button>
            </Link>
            {
                isEdit && (
                    <div className="c-table-filter__item c-table-filter__item-right">
                        <Button color="light" onClick={() => {
                            const options = {
                                message: `Are you sure to delete "${originalFabric.name}"?`,
                                loadingFn: () => {
                                }
                            };
                            handleApiDeleteById(deleteFabric, originalFabric.id, options).then(isSuccess => {
                                setFabrics(null);
                                if (isSuccess)
                                    toaster.notify(() => <div
                                        className="alert alert-success">{"Fabric deleted"}</div>);
                                history.push(`/fabric`);
                            });
                        }}>
                            Delete
                        </Button>
                    </div>
                )
            }
        </div>
        <hr/>
        <div className="c-list">
            <div className="c-list__item">
                <div className="c-list-split">
                    <div className="col">
                        <h3 className="c-list__title">Name:</h3>
                        <Input value={newFabric.name}
                               disabled={newFabric.name === "Default fabric"}
                               error={!!nameError}
                               onChange={(e) => {
                                   setName(e.target.value);
                               }}/>
                    </div>
                    <div className="col">
                        <h3 className="c-list__title">Fabric stretch</h3>
                        <Select id="fit-toughness"
                                isDisabled={newFabric.name === "Default fabric"}
                                options={fitToughnessOptions}
                                onChangeValue={v => {
                                    setNewFabric(Object.assign({}, newFabric, {fit_toughness: 10 - v}));
                                }}
                                defaultValue={null}
                                value={10 - newFabric.fit_toughness}/>
                        <div className="hint">
                            Fabric stretch is how much the fabric gives (1 is least stretchy). In most cases the default of 5 will do,
                            but in others feel free to play with the number and try the effect on the recommendation.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr/>
        <div className="c-table-action">
            <div className="c-table-filter__item">
                <Button
                    loading={saveLoading}
                    disabled={!isDirty || !validate() || newFabric.name === "Default fabric"}
                    onClick={handleSave}>
                    Save
                </Button>
            </div>
        </div>
    </div>
})

export default EditFabricPage;
