import React, {Fragment, useContext, useEffect, useState} from 'react';
import ConnectCompletePage from "./ConnectCompletePage";
import BillingDetailsPage from "./BillingDetailsPage";
import ConnectStartPage from "./ConnectStartPage";
import {loadStripe} from "@stripe/stripe-js";
import {StripeApiKey} from "../../../config/billingConfig";
import {Elements} from "@stripe/react-stripe-js";
import {AppContext} from "../../../context/providers";
import {getSubscription} from "../../../api/payment";
import toaster from "toasted-notes";
import {BoxSpinner} from "../../Common/UIKit/Spinner";

const BillingStripePage = ({match}) => {

    const {stripe} = useContext(AppContext);

    const [subscription, setSubscription] = useState(null);
    const [loading, setLoading] = useState(false);
    const [view, setView] = useState(null);

    if (match?.params?.view && view === null) {
        setView(match.params.view);
    }

    useEffect(() => {
        const fetchSubscription = () => {
            if (loading) return;
            setLoading(true);
            getSubscription().then(subscriptions => {
                setSubscription(subscriptions[0]);
                setLoading(false);
            }).catch(() => {
                toaster.notify(() => <div className="alert alert-danger mt-2">Could not load billing info. Please
                    contact us for support</div>);
            })
        }
        if (subscription === null) fetchSubscription();
    }, [subscription, loading]);

    const getViewComponent = () => {
        switch (view) {
            case "connect":
                return <ConnectCompletePage/>;
            default:
                if (stripe && stripe.payment_method) {
                    return <Fragment>
                        <BillingDetailsPage/>
                    </Fragment>
                }
                return <ConnectStartPage/>;
        }
    }
    const stripePromise = loadStripe(StripeApiKey);
    return (
        <Elements stripe={stripePromise}>
            {
                loading || subscription === null ? (
                    <div className="c-panel-content">
                        <BoxSpinner/>
                    </div>
                ) : (
                    getViewComponent()
                )
            }
        </Elements>
    );
}

export default BillingStripePage;
