import React from 'react';
import { IMAGE_LOGO } from "../../../assets/Images";

const AuthenticationLayout = ({ children }) => (
    <div className="c-auth">
        <div className="c-auth__container">
            <div className="c-auth__body">
                <img
                    src={IMAGE_LOGO}
                    className="c-auth__logo"
                    alt="Robosize Business"
                />
                {children}
            </div>
        </div>
    </div>
);

export default AuthenticationLayout;
