import config from "../../baseConfig";
import {deepAssign} from "../../../utils/common";
import {GENDER} from "../../../components/BodyShape/Constants";

export default deepAssign({}, config, {
    appClass: "demo",
    integrationButton: {
        selector: '.product-form',
        selectorBefore: true,
        class: "",
        wrapperClass: "demo"
    },
    interview: {
        askBodyShapes: false,
        forceGender: GENDER.FEMALE,
        askBodyShapeAdjustments: true,
        skipBraSize: true,
    }
});