import React, {useCallback, useEffect, useMemo, useState} from 'react';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {measurementLabel} from "../../../../utilities/String";
import Input from "../../../Common/UIKit/Form/Input";
import ChartDimensions from "../ChartDimensions";
import {validMeasures} from "../Constants";
import toaster from "toasted-notes";
import {deepAssign, deepClone, getOrderedKeys} from "../../../../fit_widget/src/utils/common";
import {validateSizeChartData} from "../../../../utilities/SizeChart";

export default ({
                    block,
                    index,
                    saveBlock,
                }) => {

    const [sizesError, setSizesError] = useState(null);
    const [measuresError, setMeasuresError] = useState(null);
    const [dataErrors, setDataErrors] = useState([]);

    const sizes = block?.data ? getOrderedKeys(block.data) : [];
    const measures = useMemo(() => (block.data[sizes[0]]) ? getOrderedKeys(block.data[sizes[0]]) : [], [block, sizes]);

    const setSizes = sizes => {
        const data = {};
        const emptyRow = {};
        measures.forEach(measure => emptyRow[measure] = null);
        sizes.forEach(size => {
            data[size] = block.data[size] || emptyRow;
        });
        saveBlock(Object.assign({}, block, {data: {...data, _order: sizes}}), index);
    }

    const setMeasures = measures => {
        const data = block.data;
        sizes.forEach(size => {
            const sizeData = {};
            measures.forEach(measure => {
                sizeData[measure] = data[size][measure] || null;
            });
            sizeData._order = measures;
            data[size] = sizeData;
        });
        saveBlock(Object.assign({}, block, {data}), index);
    }

    const validateData = useCallback(() => {
        return validateSizeChartData(block, sizes, measures, dataErrors, setDataErrors);
    }, [dataErrors, measures, block?.data, sizes]);

    const editSizeChartField = useCallback((size, measure, value) => {
        const data = deepClone(block.data)
        data[size][measure] = value;
        saveBlock(deepAssign({}, block, {data}), index);
    }, [block]);

    const handleDelete = (tags, setTags) => i => setTags(tags.filter((tag, index) => index !== i));

    const handleAddition = (fieldName, tags, setTags) => tag => {
        if (fieldName === "measures" && !validMeasures.includes(tag.id.toLowerCase())) {
            toaster.notify(() => <div className="alert alert-warning">
                Please use a valid dimension:
                <ul>
                    {validMeasures.map(measure => <li key={measure}>{measure}</li>)}
                </ul>
            </div>);
        }
        setTags([...tags, tag.id]);
    }

    const handleDrag = (tags, setTags) => (tag, currPos, newPos) => {
        const newTags = tags.slice();
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag.id);
        setTags(newTags);
    };

    useEffect(() => {
        setSizesError(sizes.length === 0 ? "Sizes are required" : null);
        setMeasuresError(measures.length === 0 ? "Measures are required" : null);
        validateData();
    }, [block, measures?.length, sizes?.length, validateData]);

    return <div className="c-chart-layout">
        <div className="container px-0">
            <ChartDimensions
                {...{
                    sizes,
                    sizesError,
                    handleDelete,
                    handleAddition,
                    handleDrag,
                    measures,
                    setMeasures,
                    setSizes,
                    isTable: true
                }}
                handleClose={() => {
                }} handleOpen={() => {
            }} isOpen={true}/>
        </div>
        <hr/>
        <div className="c-table c-table--sm">
            <table>
                <thead>
                <tr>
                    <th>Size</th>
                    {
                        measures.map((measure, index) => (
                            <th key={index}>{measurementLabel(measure)}</th>
                        ))
                    }
                </tr>
                </thead>
                <tbody>
                {
                    sizes.map((size, sizeIndex) => (
                        <tr key={sizeIndex}>
                            <td>
                                <b>{size}</b>
                            </td>
                            {
                                measures.map((measure, measureIndex) => (
                                    <td key={`${sizeIndex}-${measureIndex}`}>
                                        <Input
                                            value={block.data[size][measure] || ""}
                                            error={!!dataErrors[`${size}-${measure}`]}
                                            onChange={({target: {value}}) => {
                                                if (!value) {
                                                    editSizeChartField(size, measure, "");
                                                } else {
                                                    try {
                                                        editSizeChartField(size, measure, value);
                                                    } catch (e) {
                                                    }
                                                }
                                            }}
                                        />
                                    </td>
                                ))
                            }
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </div>
    </div>

};
