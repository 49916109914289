import {callApi} from "../utilities/ApiHandler";
import {ApiMethods, ApiRoutes, StatusCodes} from "../config/apiConfig";
import asyncLocalStorage from "../utilities/AsyncLocalStorage";
import {Types} from "../config/actionTypes";

const {GET, POST, PUT} = ApiMethods;

export const authGoogle = async ({token}) => {
    const response = await callApi({
        method: POST,
        params: {token},
        url: ApiRoutes.auth.google,
        authorization: false
    });
    if (response.data) {
        await asyncLocalStorage.setItem(Types.USER_TOKEN, response.data.access);
        await asyncLocalStorage.setItem(Types.USER_REFRESH, response.data.refresh);
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const loginShopify = async (ar) => {
    const response = await callApi({
        method: POST,
        params: ar,
        url: ApiRoutes.auth.loginShopify,
        authorization: false
    });
    if (response.data?.redirect_url) {
        return Promise.resolve(response.data);
    } else if (response.data) {
        await asyncLocalStorage.setItem(Types.USER_TOKEN, response.data.access);
        await asyncLocalStorage.setItem(Types.USER_REFRESH, response.data.refresh);
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const login = async (ar) => {
    const {email, password} = ar;
    const response = await callApi({
        method: POST,
        params: {username: email, password},
        url: ApiRoutes.auth.login,
        authorization: false
    });
    if (response.data) {
        await asyncLocalStorage.setItem(Types.USER_TOKEN, response.data.access);
        await asyncLocalStorage.setItem(Types.USER_REFRESH, response.data.refresh);
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const register = async params => {
    const response = await callApi({
        method: POST,
        params,
        url: ApiRoutes.auth.register,
        authorization: false
    });
    if (response.data) {
        await asyncLocalStorage.setItem(Types.USER_TOKEN, response.data.access);
        await asyncLocalStorage.setItem(Types.USER_REFRESH, response.data.refresh);
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const finalize = async (search) => {
    const response = await callApi({
        method: GET,
        url: `${ApiRoutes.auth.finalize}${search}`,
        authorization: false,
    });
    if (response.data) {
        await asyncLocalStorage.setItem(Types.USER_TOKEN, response.data.access);
        await asyncLocalStorage.setItem(Types.USER_REFRESH, response.data.refresh);
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const forgot = async params => {
    const response = await callApi({
        method: POST,
        params,
        url: ApiRoutes.auth.forgot,
        authorization: false
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const forgotToken = async params => {
    const response = await callApi({
        method: POST,
        params,
        url: ApiRoutes.auth.forgotToken,
        authorization: false
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const forgotConfirm = async params => {
    const response = await callApi({
        method: POST,
        params,
        url: ApiRoutes.auth.forgotConfirm,
        authorization: false
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const changePassword = async params => {
    const response = await callApi({
        method: PUT,
        params,
        url: ApiRoutes.auth.changePassword,
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

