import React, {forwardRef, useEffect, useState} from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Delete } from '@material-ui/icons';
import {IMAGE_DEFAULT} from "../../../assets/Images";

const ImageSelector = forwardRef(({ id, description, className, onChange, onDelete, value, ...otherProps }, ref) => {
    const [imgSrc, setImageSrc] = useState(null);
    id = id ? id : 'image-selector';

    useEffect(() => {
        if(!!value && typeof value === 'string') {
            setImageSrc(value);
        }
    }, [value]);

    const handleChange = (e) => {
        const file = e.target.files[0];
        if(file) {
            const reader = new FileReader();
            // const url = reader.readAsDataURL(file);
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setImageSrc(reader.result);
                onChange({ file, url: reader.result });
            };
        }
    };

    const handleDelete = () => {
        setImageSrc(null);
        onChange(null);
        onDelete(null);
    };

    return (
        <div className={classnames('c-image-selector', className)}>
            <div className="c-image-selector__controller">
                <label htmlFor={id} className="c-image-selector__file-container">
                    <label htmlFor={id} className="c-button c-button--main c-button--sm c-image-selector__button">
                        {
                            imgSrc ? "Change Image" : "Choose Image"
                        }
                    </label>
                    <input
                        ref={ref}
                        id={id}
                        type="file"
                        accept="image/x-png,image/jpg,image/jpeg"
                        // accept="image/*"
                        onChange={handleChange}
                        {...otherProps}
                    />
                </label>
                {description && <p>{description}</p>}
            </div>

            <div className="c-image-selector__image-container">
                <img className="c-image-selector__image" src={imgSrc ? imgSrc : IMAGE_DEFAULT} alt={id} />
                {(imgSrc && onDelete) && (
                    <span className="c-image-selector__image-delete" onClick={handleDelete}>
                        <Delete />
                    </span>
                )}
            </div>
        </div>
    )
});

ImageSelector.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.any,
    className: PropTypes.string,
    onChange: PropTypes.func,
};

export default ImageSelector;
