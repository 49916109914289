import React, {Fragment} from 'react';
import {withRouter} from "react-router-dom";
import toaster from "toasted-notes";
import * as classnames from "classnames";
import Link from "@material-ui/core/Link";
import Button from "../../Common/UIKit/Form/Button";
import {HelpArticles} from "./HelpArticlesIndex";


const HelpArticlePage = withRouter(({history, match}) => {
    let article = HelpArticles.filter(article => article.id === match.params.id);

    if (article.length === 0) {
        toaster.notify(() => <div className="alert alert-warning">Help article not found</div>);
        history.push("/help");
    }

    article = article[0];

    const renderSection = (section) => {
        let attrs = section.attrs || {};
        switch (section.tag) {
            case "h5":
                attrs.className = attrs.className || "";
                attrs.className += "pt-4 mb-2";
                return <h5 {...attrs} dangerouslySetInnerHTML={{__html: section.body}}></h5>
            case "h4":
                attrs.className = attrs.className || "";
                attrs.className += "pt-4 mb-2";
                return <h4 {...attrs} dangerouslySetInnerHTML={{__html: section.body}}></h4>
            case "p":
                attrs.className = attrs.className || "";
                attrs.className += "pt-2 mb-2";
                return <p {...attrs} dangerouslySetInnerHTML={{__html: section.body}}/>
            case "code":
                attrs.className = attrs.className || "";
                attrs.className += "pt-4 mb-2";
                return <code {...attrs}>{section.body}</code>
            case "well":
                attrs.className = attrs.className || "";
                attrs.className += "well";
                return <div {...attrs}>{section.body}</div>
            case "img":
                attrs.className = attrs.className || "";
                attrs.className += "img-fluid img-thumbnail c-help-img my-3";
                return <div className="text-center"><img {...attrs}/></div>
        }
    }

    // noinspection JSAnnotator
    return <Fragment>
        {!window.location.pathname.includes("public-help") && (
            <div className="container-fluid px-0">
                <div className="c-table-action">
                    <Link
                        className="c-table-filter__item"
                        onClick={() => {
                            // history.push(`/help`);
                            history.goBack();
                        }}>
                        <Button color="light">
                            Back
                        </Button>
                    </Link>
                </div>
            </div>
        )}
        <div className="c-panel-content">
            <div className="p-3">
                <div className="container py-3">
                    <h3 className="h3">{article.title}</h3>
                </div>
                {
                    article.body.map((section, index) => (
                        <div key={index} className={classnames("container", {
                            "a": false
                        })}>
                            {renderSection(section)}
                        </div>
                    ))
                }
            </div>
        </div>
    </Fragment>
});

export default HelpArticlePage;