import React from 'react';
import {withRouter} from "react-router-dom";
import Button from "../../Common/UIKit/Form/Button";


const NoChartProductAlert = withRouter(() => {

    return <div className="container-fluid py-2">
        <div className="alert alert-warning">
            You have products without a size chart.
            <span className="float-right">
                <Button color="light" link="/product?needs_chart=true" xs={true}>
                    Fix it
                </Button>
            </span>
        </div>
    </div>

});

export default NoChartProductAlert;