import {withRouter} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {PageLoading} from "../../Common/UIKit/Spinner";
import Link from "@material-ui/core/Link";
import Button from "../../Common/UIKit/Form/Button";
import {Checkbox, TextField} from "@material-ui/core";
import {createStore, getStoreDetails, updateStore} from "../../../api/store";
import {handleApiSave} from "../../../utilities/useApi";
import toaster from "toasted-notes";
import {AppContext} from "../../../context/providers";
import {deepAssign} from "../../../fit_widget/src/utils/common";


const EditStorePage = withRouter(({history, match}) => {

    const isEdit = !!match?.params?.id;

    const {business} = useContext(AppContext);

    const [store, setStore] = useState(null);
    const [originalStore, setOriginalStore] = useState(null);
    const [loading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    const [isConfigValid, setIsConfigValid] = useState(true);
    const [isSettingsValid, setIsSettingsValid] = useState(true);

    const isValidJson = (json) => {
        try {
            JSON.parse(json);
            return true;
        } catch (e) {
            return false;
        }
    }

    const isValidStore = () => {
        return !!store.domain && isValidJson(store.config) && isValidJson(store.settings);
    }

    useEffect(() => {
        if (!isEdit) {
            if (store === null && business) {
                setStore({
                    config: "{}",
                    settings: "{}",
                    business,
                    is_active: true
                });
            }
            return;
        }
        if (store !== null || loading) return;
        setLoading(true);
        getStoreDetails(match.params.id).then(response => {
            const store = response.results[0];
            store.config = JSON.stringify(store.config || {});
            store.settings = JSON.stringify(store.settings || {});
            setStore(store);
            setOriginalStore(JSON.parse(JSON.stringify(store)));
            setLoading(false);
            setIsDirty(false);
            setIsDirty(false);
        });
    }, [store, loading, isEdit, business]);

    useEffect(() => {
        if (store === null) return;
        setIsConfigValid(!!store.config && isValidJson(store.config));
        setIsSettingsValid(!!store.settings && isValidJson(store.settings));
        setIsDirty(isEdit ? JSON.stringify(originalStore) !== JSON.stringify(store) : true);
    }, [store]);

    const handleSave = () => {
        if (!isValidStore()) return;
        setSaveLoading(true);
        handleApiSave(
            createStore,
            updateStore,
            {
                domain: store.domain,
                config: JSON.parse(store.config),
                settings: JSON.parse(store.settings),
                custom_css: store.custom_css,
                is_active: !!store.is_active,
            },
            match.params?.id,
            {
                loadingFn: setSaveLoading,
            }
        ).then((resp) => {
            setSaveLoading(false);
            setStore(null);
            toaster.notify(() => <div
                className="alert alert-success">{isEdit ? "Store updated" : "Store created"}</div>);
            history.push("/store");
        });
    }

    const setProperty = (property) => (value) => {
        setStore(deepAssign({}, store, {
            [property]: value
        }));
    }

    if (loading || store == null) {
        return <div className="c-panel-content"><PageLoading/></div>
    }

    return <div className="c-panel-content">
        <div className="c-table-action">
            <Link
                className="c-table-filter__item"
                onClick={() => {
                    history.push(`/store`);
                }}>
                <Button color="light">
                    All stores
                </Button>
            </Link>
            <div className="c-table-filter__item-right">
                <Button
                    loading={saveLoading}
                    disabled={!isDirty || !isConfigValid || !isSettingsValid}
                    onClick={handleSave}>
                    Save
                </Button>
            </div>
        </div>
        <div className="c-table c-table--sm c-table-details">
            <table>
                <thead>
                <tr>
                    <th>Domain</th>
                    <th>Config</th>
                    <th>Settings</th>
                    <th>CSS</th>
                    <th>Active</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <TextField
                            id="domain"
                            placeholder="The store's domain"
                            value={store.domain}
                            error={!store.domain}
                            onChange={({target: {value}}) => setProperty("domain")(value)}
                        />
                    </td>
                    <td>
                        <TextField
                            id="config"
                            placeholder="Config json"
                            value={store.config}
                            error={!isConfigValid}
                            onChange={({target: {value}}) => setProperty("config")(value)}
                        />
                    </td>
                    <td>
                        <TextField
                            id="settings"
                            value={store.settings}
                            error={!isSettingsValid}
                            onChange={({target: {value}}) => setProperty("settings")(value)}
                        />
                    </td>
                    <td>
                        <TextField
                            id="css"
                            value={store.custom_css}
                            onChange={({target: {value}}) => setProperty("custom_css")(value)}
                        />
                    </td>
                    <td>
                        <Checkbox
                            placeholder="Settings json"
                            label={""}
                            onChange={({target: {checked}}) => setProperty("is_active")(checked)}
                            checked={store.is_active}
                        />
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>

});

export default EditStorePage;
