import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../../../context/providers";
import {BoxSpinner} from "../../Common/UIKit/Spinner";
import {withRouter} from "react-router-dom";
import {capitalize} from "@material-ui/core";
import * as classnames from "classnames";
import Button from "../../Common/UIKit/Form/Button";
import {
    CancelShopifySubscription,
    getShopifySubscriptionDetails,
    getStripeSubscriptionDetails
} from "../../../api/payment";
import toaster from "toasted-notes";
import SubscriptionDetails from "./SubscriptionDetails";
import Modal from "../../Common/UIKit/Modal";

const ShopifySubscriptionPage = withRouter(({history}) => {
    const {business} = useContext(AppContext);

    const [subscription, setSubscription] = useState(null);
    const [loading, setLoading] = useState(null);

    const [showCancellationModal, setShowCancellationModal] = useState(false);

    useEffect(() => {
        const fetchSubscription = () => {
            setLoading(true);
            const fetchFunc = business.has_custom_pricing ? getStripeSubscriptionDetails : getShopifySubscriptionDetails;
            fetchFunc().then(subscription => {
                if (subscription) {
                    setSubscription(subscription);
                }
                setLoading(false);
            })
        }

        if (subscription === null) fetchSubscription();
    }, [subscription]);

    if (loading || subscription === null) {
        return <div className="c-panel-content">
            <BoxSpinner/>
        </div>
    }

    const handleChangePlan = () => {
        history.push("/billing/update");
    };

    const handleCancelPlan = () => {
        CancelShopifySubscription(subscription.id).then(() => {
            toaster.notify(() => <div className="alert alert-success">Subscription cancelled successfully.</div>);
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        });
    };

    if (!subscription || !["active", "suspended", "cancelled"].includes(subscription.status)) {
        history.push("/");
        return <div className="c-panel-content">
            <BoxSpinner/>
        </div>
    }

    return <div className="c-panel-content">
        <div className="container text-center mb-3">
            <h4>Subscription</h4>
        </div>
        <SubscriptionDetails subscription={subscription}/>
        {
            showCancellationModal && <Modal show
                                            auto
                                            onClose={() => setShowCancellationModal(false)}
                                            onSave={handleCancelPlan}
            >
                <div className="px-3 py-3 text-center">
                    Are you sure you would like to cancel your subscription?
                </div>
            </Modal>
        }
        <div className="container py-3 text-center">
            <div className="d-inline">
                <Button color="light" className="d-inline m-3" onClick={() => setShowCancellationModal(true)}>Cancel plan</Button>
                {subscription.plan_name !== "basic2" && (
                    <Button color="primary" className="d-inline m-3" onClick={handleChangePlan}>Change plan</Button>
                )}
            </div>
        </div>
    </div>

});

export default ShopifySubscriptionPage;