import React, {Fragment, useContext, useEffect, useState} from 'react';
import {getBusinessSettings, saveBusinessSettings, validateBusinessIntegration} from "../../../api/business";
import {BoxSpinner, DotsSpinner} from "../../Common/UIKit/Spinner";
import Select from "../../Common/UIKit/Form/Select";
import {
    IBSelectorPositionOptions,
    IBSelectorType,
    IBSelectorTypeOptions,
    recommendationMethod,
    recommendationMethodOptions
} from "./Constants";
import Input from "../../Common/UIKit/Form/Input";
import {AppContext} from "../../../context/providers";
import Button from "../../Common/UIKit/Form/Button";
import toaster from "toasted-notes";
import {getProducts} from "../../../api";
import {PAGE_SIZE} from "../../../utilities/ApiHandler";
import {planNames} from "../Billing/plans";
import Checkbox from "../../Common/UIKit/Form/Checkbox";
import {
    WIDGET_MEASURES_NO_REC,
    WIDGET_MEASURES_WITH_REC,
    WIDGET_SOCIAl_NO_REC,
    WIDGET_SOCIAL_WITH_REC, WIDGET_TOP_TOW_NO_REC, WIDGET_TOP_TOW_WITH_REC
} from "../../../assets/Images";
import YouTube from "react-youtube";
import {confirmAlert} from "react-confirm-alert";

const SettingsPage = () => {

    const {business} = useContext(AppContext);

    const subscription = business?.subscription || {};
    const planName = subscription?.plan_name || "free";

    const [originalSettings, setOriginalSettings] = useState(null);
    const [settings, setSettings] = useState(null);
    const [fetchingSettings, setFetchingSettings] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    const [products, setProducts] = useState(null);
    const [loadingProducts, setLoadingProducts] = useState(false);

    const [loadingButtonPlacement, setLoadingButtonPlacement] = useState(false);

    useEffect(() => {
        const fetchSettings = () => {
            if (fetchingSettings) return;
            getBusinessSettings().then(response => {
                const settings = response.results[0];
                setSettings(settings);
                setOriginalSettings(settings);
                setFetchingSettings(false);
            });
        }
        const fetchProducts = () => {
            setLoadingProducts(true);
            getProducts(0, PAGE_SIZE, "").then(products => {
                setProducts(products.results);
                setLoadingProducts(false);
            });
        }
        if (settings === null) fetchSettings();
        if (products === null) fetchProducts();
    }, [settings, products]);

    useEffect(() => {
        setIsDirty(JSON.stringify(settings) !== JSON.stringify(originalSettings));
    }, [settings, originalSettings]);

    const handleSave = () => {
        saveBusinessSettings(settings).then(response => {
            setSettings(response);
            setOriginalSettings(response);
            toaster.notify(() => <div className="alert alert-success">Settings updated</div>);
        })
    }

    const getWidgetPreviewRender = () => {
        let previewImage;
        if (settings.recommendation_method === recommendationMethod.MEASURES) {
            previewImage = settings.recommend_products ? WIDGET_MEASURES_WITH_REC : WIDGET_MEASURES_NO_REC;
        } else if (settings.recommendation_method === recommendationMethod.SOCIAL) {
            previewImage = settings.recommend_products ? WIDGET_SOCIAL_WITH_REC : WIDGET_SOCIAl_NO_REC;
        } else {
            previewImage = settings.recommend_products ? WIDGET_TOP_TOW_WITH_REC : WIDGET_TOP_TOW_NO_REC;
        }
        return <img className="img-fluid img-thumbnail" src={previewImage} alt="Widget preview"/>
    }

    const handleViewButtonPlacement = () => {
        setLoadingButtonPlacement(true);
        validateBusinessIntegration().then(resp => {
            if (resp.is_integration_valid) {
                window.open(`${resp.product_link}?robosize_inject=1&fers=0`, "_blank").focus();
            } else if (resp.error_source === "product_not_found") {
                toaster.notify(() => <div className="alert alert-danger mt-2">Please make sure your products are
                    sync</div>);
                setTimeout(() => {
                    window.location.replace("/sync");
                }, 2000);
            } else {
                confirmAlert({
                    title: "",
                    message: "Setup is incomplete. Please enable App Embed Block in order to add the button to your page.",
                    buttons: [
                        {
                            label: 'See how to enable it',
                            onClick: () => {
                                window.open('https://partners.robosize.com/help/how-to-enable-app-embed-block');
                            }
                        },
                        {
                            label: 'Cancel',
                        }
                    ]
                })
            }
            setLoadingButtonPlacement(false);
        }).catch(error => {
            toaster.notify(() => <div className="alert alert-danger mt-2">There was a problem loading live editor.
                Please make sure the setup has been completed.</div>);
            setLoadingButtonPlacement(false);
        })
    }

    if (fetchingSettings || !settings || !business || !planName) {
        return <div className="c-panel-content"><BoxSpinner/></div>
    }

    return <Fragment>
        <div className="c-table-action">
            <div className="c-table-filter__item c-table-filter__item-right">
                <Button color="danger" onClick={handleSave} disabled={!isDirty}>
                    Save changes
                </Button>
            </div>
        </div>
        <div className="c-panel-content">
            <div className="h4 pl-3">Button Settings</div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col col-md-6">
                        <div className="p-3">
                            Here you can change where the "Find my size" button will show on the product page
                        </div>
                        {
                            business?.domain && products?.length > 0 && (
                                <div className="p-3">
                                    <div className="mb-3">
                                        Start by clicking on the following button:
                                    </div>
                                    <Button color="info" onClick={handleViewButtonPlacement}>
                                        {
                                            !loadingButtonPlacement ? <Fragment>
                                                View button placement <span className="fa fa-external-link"></span>
                                            </Fragment> : <DotsSpinner/>
                                        }

                                    </Button>
                                </div>
                            )
                        }
                    </div>
                    <div className="col col-md-6">
                        <div className="container-fluid auto-resizable-iframe">
                            <YouTube videoId="oR0y9lFbw1A" opts={{
                                height: '195',
                                width: '320',
                                playerVars: {
                                    start: 27
                                }
                            }}/>
                        </div>
                        <div className="container-fluid pt-3 text-center">
                            <Button color="light" className="d-inline-block" onClick={() => {
                                window.open('https://youtu.be/oR0y9lFbw1A', '_blank').focus();
                            }}>
                                Zoom in the video
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-3">
                Placement information:
            </div>
            <div className="container">
                <div className="c-form">
                    <div className="c-form__group-row c-form__group-row-3">
                        <div className="c-form__group">
                            <Select
                                id="selector_position"
                                options={IBSelectorPositionOptions}
                                onChangeValue={v => {
                                    setSettings(Object.assign({}, settings, {
                                        selector_position: v
                                    }));
                                }}
                                defaultValue={null}
                                value={settings.selector_position}
                            />
                        </div>
                        <div className="c-form__group">
                            <Select
                                id="selector_type"
                                options={IBSelectorTypeOptions}
                                onChangeValue={v => {
                                    setSettings(Object.assign({}, settings, {
                                        selector_type: v
                                    }));
                                }}
                                defaultValue={null}
                                value={settings.selector_type}
                            />
                        </div>
                        <div className="c-form__group">
                            <Input value={settings.selector || ""}
                                // error={!!nameError}
                                   placeholder={settings.selector_type === IBSelectorType.ID ? "Element ID" : (
                                       settings.selector_type === IBSelectorType.CLASS ? "Element class" : "CSS selector"
                                   )}
                                   onChange={(e) => {
                                       setSettings(Object.assign({}, settings, {
                                           selector: e.target.value
                                       }))
                                   }}/>
                        </div>
                    </div>
                </div>
            </div>
            {
                planName && planName !== planNames.FREE && (
                    <Fragment>
                        <hr/>
                        <Checkbox
                            id="show_size_chart"
                            label="Show product size chart"
                            checked={settings.show_size_chart}
                            onChange={(showSizeChart) => {
                                setSettings(Object.assign({}, settings, {
                                    show_size_chart: showSizeChart
                                }));
                            }}
                        />
                        <div className="hint">
                            Show the size chart of the product along with the fit recommendation button.
                        </div>
                    </Fragment>
                )
            }
        </div>
        <div className="c-panel-content">
            <div className="h4 pl-3">Recommendation Settings</div>
            <div className="row">
                <div className="col-12 col-lg-8">
                    <div className="container">
                        <Select
                            id="recommendation_method"
                            options={recommendationMethodOptions}
                            isOptionDisabled={(option) => {
                                if (recommendationMethod.SOCIAL === option.value) {
                                    if ([planNames.FREE, planNames.BASIC].includes(planName)) {
                                        if (!option.label.includes("(premium plan)")) {
                                            option.label += " (premium plan)"
                                        }
                                        return true;
                                    }
                                }
                                return false;
                            }}
                            label="Recommendation method"
                            onChangeValue={v => {
                                setSettings(Object.assign({}, settings, {
                                    recommendation_method: v
                                }));
                            }}
                            value={settings.recommendation_method || recommendationMethod.MEASURES}
                        />
                        <div className="hint">
                            {settings.recommendation_method === recommendationMethod.MEASURES ? (
                                <div>
                                    Show how the customer fits the best size according to your size chart
                                </div>
                            ) : (
                                <div>
                                    Give you customer more confidence by comparing them to others with similar body
                                    composition
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="container">
                        <hr/>
                        <Checkbox
                            id="recommend_products"
                            label={`Recommend products ${![planNames.ULTIMATE, planNames.ENTERPRISE].includes(planName) ? " (ultimate plan)" : ""}`}
                            disabled={![planNames.ULTIMATE, planNames.ENTERPRISE].includes(planName)}
                            checked={settings.recommend_products}
                            onChange={(recommendProducts) => {
                                setSettings(Object.assign({}, settings, {
                                    recommend_products: recommendProducts
                                }));
                            }}
                        />
                        <div className="hint">
                            Encourage your customers to purchase more by offering them other products that fit their
                            unique body composition.
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-4">
                    {getWidgetPreviewRender()}
                </div>
            </div>
        </div>
    </Fragment>
}

export default SettingsPage;