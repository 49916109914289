import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {getRecommendationEstimate, getRevenueEstimate, getStoreCount} from "../../../api/bi";
import {BoxSpinner} from "../../Common/UIKit/Spinner";
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import toaster from "toasted-notes";
import DatePicker from "../../Common/UIKit/Form/DatePicker";
import {Chart} from "react-charts";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const BIHomepage = () => {

    const initialStartDate = new Date();
    initialStartDate.setDate(initialStartDate.getDate() - 7);
    const endDateLimit = useMemo(() => new Date(), []);
    const [startDate, setStartDate] = useState(initialStartDate);
    const [endDate, setEndDate] = useState(new Date());

    const [storeCount, setStoreCount] = useState(null);
    const [loadingStoreCount, setLoadingStoreCount] = useState(false);

    const [revenueEstimate, setRevenueEstimate] = useState(null);
    const [loadingRevenueEstimate, setLoadingRevenueEstimate] = useState(false);

    const [recommendationEstimate, setRecommendationEstimate] = useState(null);
    const [loadingRecommendationEstimate, setLoadingRecommendationEstimate] = useState(false);

    const validateDateRange = () => {
        if (!startDate || !endDate) return;
        if (startDate > endDate) return;
        if (endDate > endDateLimit) {
            toaster.notify(() => <div className="alert alert-warning">Cannot get report for future</div>);
            return false;
        }
        return true;
    }

    const fetchStoreCount = () => {
        if (!validateDateRange) return;
        if (loadingStoreCount) return;
        setLoadingStoreCount(true);
        getStoreCount(startDate, endDate).then(response => {
            setStoreCount(response);
            setLoadingStoreCount(false);
        });
    };

    const fetchRevenueEstimate = () => {
        if (!validateDateRange) return;
        if (loadingRevenueEstimate) return;
        setLoadingRevenueEstimate(true);
        getRevenueEstimate(startDate, endDate).then(response => {
            setRevenueEstimate(response);
            setLoadingRevenueEstimate(false);
        });
    };

    const fetchRecommendationEstimate = () => {
        if (!validateDateRange) return;
        if (loadingRecommendationEstimate) return;
        setLoadingRecommendationEstimate(true);
        getRecommendationEstimate(startDate, endDate).then(response => {
            setRecommendationEstimate(response);
            setLoadingRecommendationEstimate(false);
        });
    };

    const refreshData = () => {
        setStoreCount(null);
        setRevenueEstimate(null);
        setRecommendationEstimate(null);
    }

    useEffect(() => {
        if (storeCount === null) {
            fetchStoreCount();
        }
    }, [storeCount]);

    useEffect(() => {
        if (revenueEstimate === null) {
            fetchRevenueEstimate();
        }
    }, [revenueEstimate]);

    useEffect(() => {
        if (recommendationEstimate === null) {
            fetchRecommendationEstimate();
        }
    }, [recommendationEstimate]);

    const renderData = (chartData, loading, legend) => {
        if (loading || chartData === null) {
            return <BoxSpinner/>
        } else {
            const datasets = [
                {
                    label: legend,
                    data: chartData
                }
            ]
            return <div
                style={{
                    height: '300px',
                    width: '100%'
                }}
            >
                <Chart
                data={datasets}
                axes={[
                    {primary: true, type: 'ordinal', position: 'bottom'},
                    {type: 'linear', position: 'left'}
                ]}
                series={{
                    type: 'line'
                }}
                tooltip={{
                    align: "auto",
                    anchor: "closest"
                }}/>
            </div>
        }
    }

    return <Fragment>
        <div className="c-panel-content">
            <div className="p-3 row">
                <div className="col">
                    <label>Date range:</label>
                    <DatePicker
                        selected={startDate}
                        onChange={(dates) => {
                            const [start, end] = dates;
                            setStartDate(start);
                            setEndDate(end);
                            if (end) {
                                refreshData();
                            }
                        }}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                    />
                </div>
                <div className="col-md"></div>
            </div>
        </div>
        <div className="row">
            <div className="col-12 col-md-6">
                <div className="c-panel-content">
                    <h4>Store count</h4>
                    {renderData(storeCount, loadingStoreCount, "Store count")}
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="c-panel-content">
                    <h4>Revenue estimate</h4>
                    {renderData(revenueEstimate, loadingRevenueEstimate, "Revenue estimate")}
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="c-panel-content">
                    <h4>Daily recommendation estimate</h4>
                    {renderData(recommendationEstimate, loadingRecommendationEstimate, "Daily recommendation estimate")}
                </div>
            </div>
        </div>
    </Fragment>
};

export default BIHomepage;
