import React, {Fragment, useCallback, useContext, useEffect, useState} from 'react';
import {getQueryUri} from "../../../utilities/URI";
import {PAGE_SIZE} from "../../../utilities/ApiHandler";
import {BoxSpinner} from "../../Common/UIKit/Spinner";
import NotFoundData from "../../Common/UIKit/NotFoundData";
import Pagination from "../../Common/UIKit/Pagination";
import {getOrders} from "../../../api/order";
import {getFormatDate} from "../../../utilities/DateHelpers";
import {AppContext} from "../../../context/providers";

const RECOMMENDATION_FIELDS = ["gender", "height", "weight", "age", "bustOffset", "waistOffset", "hipOffset", "fitPreference"];

const OrderListPage = () => {

    const {business} = useContext(AppContext);

    const [orders, setOrders] = useState(null);
    const [page, setPage] = useState(getQueryUri("page") || 1);
    const [data, setData] = useState(null);
    const [loadingOrders, setLoadingOrders] = useState(false);

    const [query, setQuery] = useState("");

    const fetchOrders = useCallback(() => {
        if (loadingOrders) return;
        setLoadingOrders(true);
        getOrders(page ? parseInt(page) - 1 : 0, PAGE_SIZE, query).then(orders => {
            setData(orders);
            setOrders(orders.results);
            setLoadingOrders(false);
        });
    }, [page, query, setOrders]);

    useEffect(() => {
        if (orders === null) {
            fetchOrders();
        }
    }, [orders, setOrders]);

    const renderCustomer = (order) => {
        const customer = order.data?.customer;
        if (customer) {
            return <Fragment>
                {customer.first_name} {customer.last_name}<br/>
                {customer.email || "-"}
            </Fragment>
        }
    }

    const processFieldName = field => field.replace(/([A-Z])/g, ' $1').trim().toLowerCase();

    const renderRecommendation = (rec) => {
        const data = rec.data;
        const bestSize = data.recommendation.best_size;
        const areSizesArray = data.recommendation.sizes.constructor === Array;
        const bestSizeIndex = areSizesArray ? data.recommendation.sizes.map(rec => rec.size).indexOf(bestSize) : bestSize;
        return <Fragment>
            {RECOMMENDATION_FIELDS.map((field, index) => (
                data[field] ? (
                    <h5 key={index} className="mb-3 mr-1 d-inline"><span
                        className="badge badge-pill badge-secondary"><b>{processFieldName(field)}: </b>{data[field]}</span>
                    </h5>
                ) : <Fragment key={index}/>
            ))}
            <h5 className="mb-3 mr-1 d-inline"><span
                className="badge badge-pill badge-secondary"><b>recommendation: </b> {bestSize}</span></h5>
            {Object.keys((data.recommendation.sizes[bestSizeIndex] || {})).map((field, index) => (
                data.recommendation.sizes[bestSizeIndex][field] ? (
                    <h5 key={index} className="mb-3 mr-1 d-inline"><span
                        className="badge badge-pill badge-secondary"><b>{processFieldName(field)}: </b>{data.recommendation.sizes[bestSizeIndex][field]}</span>
                    </h5>
                ) : <Fragment key={index}/>
            ))}
        </Fragment>
    }

    return <Fragment>
        <div className="c-panel-content">
            {
                loadingOrders || (orders && (orders.length > 0 || query !== "")) ? (
                    <Fragment>
                        <div className="c-table c-table--sm">
                            <table>
                                <thead>
                                <tr>
                                    <th>Date</th>
                                    {/*<th>Customer</th>*/}
                                    <th>Product</th>
                                    <th>Recommendation</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    loadingOrders ? (
                                        <tr>
                                            <td colSpan={4}>
                                                <BoxSpinner/>
                                            </td>
                                        </tr>
                                    ) : (
                                        orders.length > 0 ? (
                                            orders.map((order, orderIndex) => (
                                                <Fragment key={orderIndex}>
                                                    <tr>
                                                        <td rowSpan={Math.max(order.recommendations.length, 1)}>
                                                            {getFormatDate(new Date(order.data.created_at))}
                                                        </td>
                                                        {/*<td rowSpan={Math.max(order.recommendations.length, 1)}>*/}
                                                        {/*    {renderCustomer(order)}*/}
                                                        {/*</td>*/}
                                                        <td>
                                                            {
                                                                order.recommendations.length > 0 ? (
                                                                    order.recommendations[0].product.name
                                                                ) : "-"
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                order.recommendations.length > 0 ? (
                                                                    renderRecommendation(order.recommendations[0])
                                                                ) : "-"
                                                            }
                                                        </td>
                                                        <td rowSpan={Math.max(order.recommendations.length, 1)}>
                                                            {
                                                                business?.domain &&
                                                                <a href={`https://${business.domain}/admin/orders/${order.store_id}`}
                                                                   target="_blank">View</a>
                                                            }
                                                        </td>
                                                    </tr>
                                                    {
                                                        order.recommendations.length > 1 && (
                                                            Array.from(order.recommendations.slice(1)).map((rec, index) => (
                                                                    <tr key={index}>
                                                                        <td>{rec.product.name}</td>
                                                                        <td>{renderRecommendation(rec)}</td>
                                                                    </tr>
                                                                )
                                                            )
                                                        )
                                                    }
                                                </Fragment>

                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={4}>
                                                    <NotFoundData icon="fas fa-search"
                                                                  title="No order matched the filter"/>
                                                </td>
                                            </tr>
                                        ))
                                }
                                </tbody>
                            </table>
                        </div>
                        {
                            data && !loadingOrders && <Pagination data={data} onChange={(page) => {
                                setPage(page);
                                setOrders(null);
                            }}/>
                        }
                    </Fragment>
                ) : <NotFoundData icon="fas fa-receipt" title="No order recorded yet"/>
            }
        </div>
    </Fragment>
};

export default OrderListPage;
