import {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {confirmAlert} from "react-confirm-alert";
import PropTypes from 'prop-types';
import {getQueryUri} from "./URI";
import {PER_PAGE} from "../config/apiConfig";
import {handleFormError} from "./HandleErrors";

const optionsDelete = {
    title: 'Confirm to delete this item',
    message: 'Are you sure to delete this item.',
    loadingFn: () => {}
};
export const handleApiDeleteById = async (fn, id = null, options = optionsDelete) => {
    return new Promise((resolve, reject) => {
        if(!!id) {
            confirmAlert({
                title: options.title,
                message: options.message,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            options.loadingFn(true);
                            fn(id).then(() => resolve(true)).catch(e => {
                                if (+e.status !== 406) {
                                    reject(e);
                                } else {
                                    resolve(true);
                                }
                            }).finally(() => {
                                options.loadingFn(false);
                            });
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {
                            reject(false);
                        }
                    }
                ]
            });
        }
    })
};

const optionsSave = {
    loadingFn: () => {},
    setError: null,
};
export const handleApiSave = async (fnCreate, fnUpdate, body, id = null, options = optionsSave) => {
    const { loadingFn, setError } = options;
    return new Promise((resolve, reject) => {
        try {
            loadingFn(true);
            if(!!id && fnUpdate) {
                fnUpdate(id, body).then(data => {
                    resolve(data);
                }).catch(e => {
                    handleFormError(e, setError);
                    reject(e);
                    // reject(e.errors);
                })
                    .finally(() => loadingFn(false));
            } else {
                fnCreate(body).then(data => {
                    resolve(data);
                }).catch(e => {
                    handleFormError(e, setError);
                    reject(e);
                    // reject(e.errors);
                })
                    .finally(() => loadingFn(false));
            }
        } catch (e) {
            console.error('catch error', e);
            loadingFn(false)
        }
    })
};

const optionsApi = {
    defaultData: null,
    infinitePage: false,
    limit: 20,
    page: 1,
    list: true,
    loadingFn: () => {},
};

export const handleApi = (fn, params, options = optionsApi) => {
    let { loadingFn, infinitePage, limit, list, defaultData } = {...optionsApi, ...options};
    const perPage = limit ? limit : PER_PAGE;
    list = typeof list === "boolean" ? list : true;
    if(!!infinitePage) {
        let page = options?.page ? options.page : 1;
        return new Promise((resolve, reject) => {
            if(loadingFn) loadingFn(true);
            if(!defaultData || defaultData.next) {
                fn(list ? { ...params, page: +page, limit: +perPage } : params).then(res => {
                    const updatedData = (!!defaultData && defaultData.results) ? { ...res, results: [...defaultData.results, ...res.results]} : res;
                    if(!res.next) resolve(updatedData);
                    if(res.next) {
                        const newPage = +page + 1;
                        handleApi(fn, params, {
                            ...options,
                            list,
                            page: newPage,
                            defaultData: updatedData,
                            loadingFn: () => {},
                        }).then(resolve).catch(reject);
                    }
                })
                    .catch(reject)
                    .finally(() => {
                        if(loadingFn) loadingFn(false)
                    });
            } else {
                if(loadingFn) loadingFn(false);
            }
        });
    }
    return new Promise((resolve, reject) => {
        if(loadingFn) loadingFn(true);
        fn(list ? { limit: PER_PAGE, ...params } : params).then(res => {
            resolve(res);
        }).catch(reject)
            .finally(() => {
                if(loadingFn) loadingFn(false)
            });
    });
};
handleApi.propTypes = {
    fn: PropTypes.func.isRequired,
    params: PropTypes.any,
    options: PropTypes.any,
};

export const useApiById = (fn, id = null, cb) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const history = useHistory();
    const params = useParams();
    if(typeof id === 'boolean') id = false;
    else if(!id) id = params.id;
    useEffect(() => {
        if(!!id) {
            setLoading(true);
            fn(id).then(res => {
                setData(res);
                if(cb) cb(res);
            }).catch(err => {
                if(cb) cb(null, err);
                if(err.status && +err.status === 404) {
                    history.replace('/notfound')
                }
            }).finally(() => {
                setLoading(false);
            });
        } else if(typeof id === 'boolean') {
            cb(null);
            return [data, false];
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return [data, loading];
};

const useApi = (fn, params, cb) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [prev, setPrev] = useState(null);

    const history = useHistory();
    let locationParams = useParams();
    const infinitePage = params?.infinitePage;
    const perPage = params?.limit ? params?.limit : PER_PAGE;
    let page;
    let urlParams = {};

    if(params?.page) page = getQueryUri('page') || 1;
    if(params?.search)  urlParams.search = getQueryUri('q') || undefined;
    if(params?.status)  urlParams.status = getQueryUri('status') || undefined;
    if(params?.staff)  urlParams.staff = getQueryUri('staff') || undefined;
    if(params?.service)  urlParams.service = getQueryUri('service') || undefined;
    if(params?.to_date)  urlParams.to_date = getQueryUri('to_date') || undefined;
    if(params?.from_date)  urlParams.from_date = getQueryUri('from_date') || undefined;

    const paramsBody = {...params};
    delete paramsBody.page;
    delete paramsBody.limit;
    delete paramsBody.search;
    delete paramsBody.status;
    delete paramsBody.staff;
    delete paramsBody.service;
    delete paramsBody.infinitePage;


    if(Object.keys(urlParams).length === 0 && typeof page === undefined) locationParams = null;

    if(typeof page === undefined) page = 1;

    const getData = (infinite, data = null) => {
        fn({ page: +page, limit: +perPage, ...paramsBody, ...urlParams }).then(res => {
            if(!infinite) window.scrollTo(0, 0);
            const updatedData = (infinite && !!data && data.results) ? { ...res, results: [...data.results, ...res.results]} : res;
            setData(updatedData);
            if(cb) cb(updatedData);
            if(infinite && res.next) {
                ++page;
                getData(infinite, updatedData);
            }
        }).catch(err => {
            if(cb) cb(null, err);
            if(err.status && +err.status === 404) {
                history.replace('/notfound')
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        if(!prev || (!!prev && (JSON.stringify(prev.urlParams) !== JSON.stringify(urlParams) || page !== prev.page))) {
            setPrev({ urlParams, page });
            setLoading(true);
            getData(infinitePage)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fn, locationParams, urlParams]);

    return [data, loading];
};

useApi.propTypes = {
    fn: PropTypes.func.isRequired,
    params: PropTypes.shape({
        limit: PropTypes.number,
        page: PropTypes.bool,
        search: PropTypes.bool,
        infinitePage: PropTypes.bool
    }),
    cb: PropTypes.func,
};




export default useApi;
