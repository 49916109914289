import React, {Fragment, useCallback, useEffect, useState} from 'react';
import Tab from "../../../Common/UIKit/Tab";
import ImageSelector from "../../../Common/UIKit/ImageSelector";
import * as classnames from "classnames";
import {handleApiSave} from "../../../../utilities/useApi";
import {saveSizeChartImage} from "../../../../api/sizeChart";
import {Controller, useForm} from "react-hook-form";
import {deepClone} from "../../../../fit_widget/src/utils/common";
import Slider from "@material-ui/core/Slider";

export default ({
                    block,
                    index,
                    saveBlock,
                }) => {

    const [videoURL, setVideoURL] = useState(block.src || "");
    const [fetchVideoError, setFetchVideoError] = useState(false);

    const fetchImageFromURL = useCallback(() => {
        try {
            const _ = videoURL.substr(videoURL.indexOf("/embed/") + 7).split("?")[0];
            setFetchVideoError(false);
            saveBlock(Object.assign({}, block, {src: videoURL}), index);
        } catch (e) {
            setFetchVideoError(true);
        }
    }, [videoURL]);

    useEffect(() => {
        const timeOutId = setTimeout(() => fetchImageFromURL(), 300);
        return () => clearTimeout(timeOutId);
    }, [videoURL, fetchImageFromURL]);

    return <div className="c-chart-layout c-chart-layout-image">
        <div className="container">
            <h4>Video</h4>
        </div>
        <div className="container px-0 py-3">
            <label>Youtube embed url</label>
            <input className={classnames("form-control", {
                "is-invalid": fetchVideoError,
                "is-valid": videoURL && !fetchVideoError
            })} type="url" value={videoURL || ""} onChange={({target: {value}}) => {
                setVideoURL(value);
            }}/>
        </div>
        <hr/>
        <div className="container py-3">
            <div className="row">
                <div className="col-md-6">
                    <label>
                        Margin top
                        ({block.style?.marginTop ? block.style?.marginTop : "0px"})
                    </label>
                    <Slider
                        defaultValue={0}
                        value={(block.style?.marginTop || "").replace("px", "")}
                        min={0}
                        max={50}
                        step={5}
                        color="primary"
                        onChange={(e, value) => {
                            const style = deepClone(block.style || {});
                            style.marginTop = value > 0 ? `${value}px` : "";
                            saveBlock(Object.assign({}, block, {style}), index);
                        }}
                    />
                </div>
                <div className="col-md-6">
                    <label>
                        Margin bottom
                        ({block.style?.marginBottom ? block.style?.marginBottom : "0px"})
                    </label>
                    <Slider
                        defaultValue={0}
                        value={(block.style?.marginBottom || "").replace("px", "")}
                        min={0}
                        max={50}
                        step={5}
                        color="primary"
                        onChange={(e, value) => {
                            const style = deepClone(block.style || {});
                            style.marginBottom = value > 0 ? `${value}px` : "";
                            saveBlock(Object.assign({}, block, {style}), index);
                        }}
                    />
                </div>
            </div>
        </div>
    </div>

};
