import qs from 'qs';
// import { getCookies } from '../utilities/Cookie';
import {ApiErrorTypes, ApiMethods, ApiUrl, StatusCodes} from '../config/apiConfig';
import { isDevelopment } from '../utilities/Environment';
import fetch from 'isomorphic-unfetch';

class APIService {
    constructor() {
        // const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        this._defaultHeaders = {
            // 'Content-Type': 'application/json',
            // 'X-Timezone': timeZone,
            ...(isDevelopment ? {} : {}),
        };
    }

    /**
     *
     * @param headers
     * @param isPost
     * @returns {Object}
     */
    _getHeaders(headers, isPost = false) {
        Object.keys((headers || {})).forEach(key => {
            if(headers[key] === undefined || headers[key] === 'undefined') {
                delete headers[key];
            }
        });
        return {
            ...this._defaultHeaders,
            ...(headers || {}),
        };
    }

    /**
     *
     * @param url
     * @param method
     * @param params
     * @param headers
     * @param absolute
     * @returns {Promise<T>}
     */
    request(url, method, params, headers = null, absolute = false) {
        const fullUrl = `${absolute ? url : ApiUrl + url}${
            method === ApiMethods.GET ? this.createQueryString(params) : ''
        }`;
        const isFormData = params && (method === ApiMethods.POST || method.toLowerCase() === ApiMethods.PUT || method.toLowerCase() === ApiMethods.PATCH)
        return Promise.race([
            fetch(fullUrl, {
                method,
                rejectUnauthorized: false,
                headers: this._getHeaders(headers),
                body: params instanceof FormData ? params : isFormData ? JSON.stringify(params) : undefined,
                credentials: 'include',
                // body: isFormData
                //         ? headers?.['Content-Type']?.includes('form-data')
                //         ? params
                //         : JSON.stringify(params)
                //         : undefined,
            }).then(async res => {
                if(res.status === StatusCodes.Error) {
                    return { status: res.status, data: await res };
                }
                if(res.status === StatusCodes.Deleted) {
                    return { status: res.status, data: true };
                }
                if(res.status === StatusCodes.NotFound) {
                    return { status: res.status, data: true };
                }
                return { status: res.status, data: await res.json() };
            })
                // .then(async res => {
                //     const data = res.status !== 500 ? await res.json() : await res;
                //     return { status: res.status, data }
                // })
                .then(res => {
                    return { status: res.status, data: res.data }
                })
                .catch(err => {
                    console.error('err', err)
                    return { status: err.status ? err.status : '406', errors: err}
                })
            ,
            new Promise((resolve, reject) => {
                setTimeout(() => {
                    reject(ApiErrorTypes.TimeOut);
                }, 30000);
            }),
        ]);
    }

    /**
     *
     * @param obj
     * @returns {String}
     */
    createQueryString(obj) {
        if (!obj) return '';
        let result = '?';
        let hasArray = false
        Object.keys(obj).forEach((key) => {
            const item = obj[key];
            if(item) {
                if(Array.isArray(item)) {
                    hasArray = true;
                    // item.forEach((item2, i) => {
                    //     result += `${key}[${i}]=${item2}&`;
                    // });
                    result += `${key}=${item}&`
                } else {
                    result += `${key}=${item}&`;
                }
            }
        });
        result = result.substr(0, result.length-1)
        return hasArray ? result : '?' + qs.stringify(obj);
        // return '?' + qs.stringify(obj);
    }

    /**
     *
     * @param url
     * @param params
     * @param headers
     * @param absolute
     * @returns {Promise<T>}
     */
    post(url, params, headers, absolute) {
        return this.request(url, ApiMethods.POST, params, headers, absolute);
    }

    /**
     *
     * @param url
     * @param params
     * @param headers
     * @param absolute
     * @returns {Promise<T>}
     */
    put(url, params, headers, absolute) {
        return this.request(url, ApiMethods.PUT, params, headers, absolute);
    }

    /**
     *
     * @param url
     * @param params
     * @param headers
     * @param absolute
     * @returns {Promise<T>}
     */
    patch(url, params, headers, absolute) {
        return this.request(url, 'PATCH', params, headers, absolute);
    }

    /**
     *
     * @param url
     * @param params
     * @param headers
     * @param absolute
     * @returns {Promise<T>}
     */
    delete(url, params, headers, absolute) {
        return this.request(url, ApiMethods.DELETE, params, headers, absolute);
    }

    /**
     *
     * @param url
     * @param params
     * @param headers
     * @param absolute
     * @returns {Promise<T>}
     */
    get(url, params, headers, absolute) {
        return this.request(url, ApiMethods.GET, params, headers, absolute);
    }
}

export default new APIService();
