export const INSIGHT_FILTER_FIELDS = {
    GENDER: "gender",
    AGE: "age",
    HEIGHT: "height",
    WEIGHT: "weight",
    FIT_PREFERENCE: "fit_preference",
    BUST: "bust",
    WAIST: "waist",
    HIPS: "hip",
}

export const INSIGHT_FILTER_OPERATORS = {
    EXACT: "",
    GREATER_THAN: "gt",
    LESS_THAN: "lt",
    GREATER_THAN_OR_EQUAL: "gte",
    LESS_THAN_OR_EQUAL: "lte",
}

export const INSIGHT_FILTER_OPERATORS_VISUAL = {
    [INSIGHT_FILTER_OPERATORS.EXACT]: "equals (no operator)",
    [INSIGHT_FILTER_OPERATORS.GREATER_THAN]: "greater than (__gt)",
    [INSIGHT_FILTER_OPERATORS.LESS_THAN]: "less than (__lt)",
    [INSIGHT_FILTER_OPERATORS.GREATER_THAN_OR_EQUAL]: "greater than or equal (__gte)",
    [INSIGHT_FILTER_OPERATORS.LESS_THAN_OR_EQUAL]: "less than or equal (__lte)"
}

export const INSIGHT_FILTER_VALUE_TYPES = {
    NUMBER: "number",
    STRING: "string",
}

export const INSIGHT_FILTER_VALUE_MAP = {
    [INSIGHT_FILTER_FIELDS.FIT_PREFERENCE]: {
        "tight": -1,
        "regular": 0,
        "loose": 1,
    }
}

export const INSIGHT_FILTERS = {
    [INSIGHT_FILTER_FIELDS.GENDER]: {
        "value_type": INSIGHT_FILTER_VALUE_TYPES.STRING,
        "operators": [
            INSIGHT_FILTER_OPERATORS.EXACT
        ],
        values: ["male", "female"]
    },
    [INSIGHT_FILTER_FIELDS.AGE]: {
        "value_type": INSIGHT_FILTER_VALUE_TYPES.NUMBER,
        "operators": [
            INSIGHT_FILTER_OPERATORS.EXACT,
            INSIGHT_FILTER_OPERATORS.GREATER_THAN,
            INSIGHT_FILTER_OPERATORS.LESS_THAN,
            INSIGHT_FILTER_OPERATORS.GREATER_THAN_OR_EQUAL,
            INSIGHT_FILTER_OPERATORS.LESS_THAN_OR_EQUAL
        ],
        values: []
    },
    [INSIGHT_FILTER_FIELDS.HEIGHT]: {
        "value_type": INSIGHT_FILTER_VALUE_TYPES.NUMBER,
        "operators": [
            INSIGHT_FILTER_OPERATORS.EXACT,
            INSIGHT_FILTER_OPERATORS.GREATER_THAN,
            INSIGHT_FILTER_OPERATORS.LESS_THAN,
            INSIGHT_FILTER_OPERATORS.GREATER_THAN_OR_EQUAL,
            INSIGHT_FILTER_OPERATORS.LESS_THAN_OR_EQUAL
        ],
        values: []
    },
    [INSIGHT_FILTER_FIELDS.WEIGHT]: {
        "value_type": INSIGHT_FILTER_VALUE_TYPES.NUMBER,
        "operators": [
            INSIGHT_FILTER_OPERATORS.EXACT,
            INSIGHT_FILTER_OPERATORS.GREATER_THAN,
            INSIGHT_FILTER_OPERATORS.LESS_THAN,
            INSIGHT_FILTER_OPERATORS.GREATER_THAN_OR_EQUAL,
            INSIGHT_FILTER_OPERATORS.LESS_THAN_OR_EQUAL
        ],
        values: []
    },
    [INSIGHT_FILTER_FIELDS.FIT_PREFERENCE]: {
        "value_type": INSIGHT_FILTER_VALUE_TYPES.STRING,
        "operators": [
            INSIGHT_FILTER_OPERATORS.EXACT,
        ],
        values: ["tight", "regular", "loose"]
    },
    [INSIGHT_FILTER_FIELDS.BUST]: {
        "value_type": INSIGHT_FILTER_VALUE_TYPES.NUMBER,
        "operators": [
            INSIGHT_FILTER_OPERATORS.EXACT,
            INSIGHT_FILTER_OPERATORS.GREATER_THAN,
            INSIGHT_FILTER_OPERATORS.LESS_THAN,
            INSIGHT_FILTER_OPERATORS.GREATER_THAN_OR_EQUAL,
            INSIGHT_FILTER_OPERATORS.LESS_THAN_OR_EQUAL
        ],
        values: []
    },
    [INSIGHT_FILTER_FIELDS.WAIST]: {
        "value_type": INSIGHT_FILTER_VALUE_TYPES.NUMBER,
        "operators": [
            INSIGHT_FILTER_OPERATORS.EXACT,
            INSIGHT_FILTER_OPERATORS.GREATER_THAN,
            INSIGHT_FILTER_OPERATORS.LESS_THAN,
            INSIGHT_FILTER_OPERATORS.GREATER_THAN_OR_EQUAL,
            INSIGHT_FILTER_OPERATORS.LESS_THAN_OR_EQUAL
        ],
        values: []
    },
    [INSIGHT_FILTER_FIELDS.HIPS]: {
        "value_type": INSIGHT_FILTER_VALUE_TYPES.NUMBER,
        "operators": [
            INSIGHT_FILTER_OPERATORS.EXACT,
            INSIGHT_FILTER_OPERATORS.GREATER_THAN,
            INSIGHT_FILTER_OPERATORS.LESS_THAN,
            INSIGHT_FILTER_OPERATORS.GREATER_THAN_OR_EQUAL,
            INSIGHT_FILTER_OPERATORS.LESS_THAN_OR_EQUAL
        ],
        values: []
    },
}