import React, {Fragment, useCallback, useContext, useEffect, useMemo} from 'react';
import useState from 'react-usestateref';
import {AppContext} from "../../../context/providers";
import {PageLoading} from "../../Common/UIKit/Spinner";
import {createSizeChart, deleteSizeChart, getSizeChart, updateSizeChart} from "../../../api/sizeChart";
import Button from "../../Common/UIKit/Form/Button";
import {withRouter} from "react-router-dom";
import Link from "@material-ui/core/Link";
import {Unit} from "../../../utilities/UnitHelper";
import {handleApiDeleteById, handleApiSave} from "../../../utilities/useApi";
import toaster from "toasted-notes";
import {roundToDigit, roundToTwoDigitsWithRounding} from "../../../utilities/number";
import {validMeasures} from "./Constants";
import ChartBrand from "./ChartBrand";
import ChartDimensions from "./ChartDimensions";
import ChartData from "./ChartData";
import ChartProductMatch from "./ChartProductMatch";
import CreateChartSetup from "./CreateChart/CreateChartSetup";
import SizeChartView from "../../../fit_widget/src/components/SizeChart/SizeChartView";
import {INTERVIEW_STEP_MODAL_CLASS, UNIT_METHOD} from "../../../fit_widget/src/components/BodyShape/Constants";
import LayoutBlockEditWrapper from "./Layout/LayoutBlockEditWrapper";
import {deepAssign, deepClone, getOrderedKeys} from "../../../fit_widget/src/utils/common";
import BlockEditor from "./Layout/BlockEditor";
import {castChartUnit, castChartValues} from "../../../fit_widget/src/utils/sizeChart";
import {BLOCK_TYPE} from "../../../fit_widget/src/components/SizeChart/Constants";
import {validateSizeChartData} from "../../../utilities/SizeChart";

const EditSizeChartPage = withRouter(({history, match}) => {

    const {setSizeCharts, setProducts} = useContext(AppContext);

    const isEdit = !!match?.params?.id;

    const [step, setStep] = useState(1);

    const [originalSizeChart, setOriginalSizeChart] = useState(null);
    const [_, setNewSizeChart, newSizeChartRef] = useState(null);
    const newSizeChart = newSizeChartRef.current;
    const [unit, setUnit] = useState(Unit.IMPERIAL);

    const [loading, setLoading] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [nameError, setNameError] = useState(null);
    const [sizesError, setSizesError] = useState(null);
    const [measuresError, setMeasuresError] = useState(null);
    const [dataErrors, setDataErrors] = useState([]);
    const [productMatchError, setProductMatchError] = useState(null);
    const [saveLoading, setSaveLoading, saveLoadingRef] = useState(false);
    const [showCreateSetup, setShowCreateSetup] = useState(!isEdit);
    const [showStyleEditor, setShowStyleEditor] = useState(false);

    const [editBlock, setEditBlock] = useState(null);
    const [editBlockIndex, setEditBlockIndex] = useState(null);

    const sizes = newSizeChart?.data ? getOrderedKeys(newSizeChart.data) : [];
    const measures = useMemo(() => (newSizeChart?.data && newSizeChart?.data[sizes[0]]) ? getOrderedKeys(newSizeChart.data[sizes[0]]) : [], [newSizeChart, sizes]);

    const setName = name => setNewSizeChart(Object.assign({}, newSizeChart, {brand_name: name}));
    const setGender = gender => setNewSizeChart(Object.assign({}, newSizeChart, {gender}))
    const setSizes = sizes => {
        const data = {};
        const emptyRow = {};
        measures.forEach(measure => emptyRow[measure] = null);
        sizes.forEach(size => {
            data[size] = newSizeChart.data[size] || emptyRow;
        });
        newSizeChart.data = data;
        setNewSizeChart(Object.assign({}, newSizeChart, {data: {...data, _order: sizes}}));
    }
    const setMeasures = measures => {
        const data = newSizeChart.data;
        sizes.forEach(size => {
            const sizeData = {};
            measures.forEach(measure => {
                sizeData[measure] = data[size][measure] || null;
            });
            sizeData._order = measures;
            data[size] = sizeData;
        });
        setNewSizeChart(Object.assign({}, newSizeChart, {data}));
    }
    const setProductMatchCondition = ({
                                          method,
                                          operator,
                                          conditions
                                      }) => setNewSizeChart(Object.assign({}, newSizeChart, {
        method,
        operator,
        conditions
    }));

    const setHideRecommender = (show) => {
        setNewSizeChart(Object.assign({}, newSizeChart, {
                layout: Object.assign(
                    newSizeChartRef.current.layout,
                    {hideRecommender: false}
                )
            }
        ));
    }

    const saveBlock = (block, index) => {
        const layout = deepClone(newSizeChart.layout);
        layout.blocks.splice(index, 1, block);
        setNewSizeChart(Object.assign({}, newSizeChart, {layout}));
        setEditBlock(block);
    }
    const moveBlockUp = (block, index) => {
        if (index === 0) return;
        const layout = deepClone(newSizeChart.layout);
        layout.blocks.splice(index, 1);
        layout.blocks.splice(index - 1, 0, block);
        setNewSizeChart(Object.assign({}, newSizeChart, {layout}));
        setEditBlockIndex(index - 1);
    }
    const moveBlockDown = (block, index) => {
        if (index === newSizeChart.layout.blocks.length - 1) return;
        const layout = deepClone(newSizeChart.layout);
        layout.blocks.splice(index, 1);
        layout.blocks.splice(index + 1, 0, block);
        setNewSizeChart(Object.assign({}, newSizeChart, {layout}));
        setEditBlockIndex(index + 1);
    }
    const removeBlock = (index) => {
        const layout = deepClone(newSizeChart.layout);
        layout.blocks.splice(index, 1);
        setNewSizeChart(Object.assign({}, newSizeChart, {layout}));
    }
    const cloneBlock = (block) => {
        const layout = deepClone(newSizeChart.layout);
        layout.blocks.push(deepClone(block));
        setNewSizeChart(Object.assign({}, newSizeChart, {layout}));
    }
    const addBlock = (block) => {
        const layout = deepClone(newSizeChart.layout);
        const newBlock = deepClone(block);
        layout.blocks.push(newBlock);
        setNewSizeChart(Object.assign({}, newSizeChart, {layout}));
        setEditBlock(block);
        setEditBlockIndex(layout.blocks.indexOf(block));
    }

    const updateIsDirty = useCallback(() => {
        if (!isEdit) {
            setIsDirty(true);
            return;
        }
        if (originalSizeChart === null || newSizeChart === null) return;
        let isDirty = false;
        if (newSizeChart.brand_name !== originalSizeChart.brand_name) isDirty = true;
        if (newSizeChart.gender !== originalSizeChart.gender) isDirty = true;
        const originalSizes = getOrderedKeys(originalSizeChart.data);
        const newSizes = getOrderedKeys(newSizeChart.data);
        if (JSON.stringify(originalSizes) !== JSON.stringify(newSizes)) isDirty = true;
        if (originalSizes.length !== newSizes.length) {
            isDirty = true
        } else if (originalSizes.length > 0 && newSizes.length > 0) {
            if (JSON.stringify(getOrderedKeys(originalSizeChart.data[originalSizes[0]])) !== JSON.stringify(getOrderedKeys(newSizeChart.data[newSizes[0]]))) isDirty = true;
        }
        if (JSON.stringify(originalSizeChart.data) !== JSON.stringify(newSizeChart.data)) isDirty = true;
        if (JSON.stringify(originalSizeChart.layout) !== JSON.stringify(newSizeChart.layout)) isDirty = true;
        if (newSizeChart.method !== originalSizeChart.method) isDirty = true;
        if (newSizeChart.operator !== originalSizeChart.operator) isDirty = true;
        if (JSON.stringify(newSizeChart.conditions) !== JSON.stringify(originalSizeChart.conditions)) isDirty = true;
        setIsDirty(isDirty);
    }, [isEdit, newSizeChart, originalSizeChart]);

    const validateData = useCallback(() => {
        return validateSizeChartData(newSizeChart, sizes, measures, dataErrors, setDataErrors);
    }, [dataErrors, measures, newSizeChart?.data, sizes]);

    const validateProductMatch = useCallback(() => {
        let error = null;
        if (newSizeChart.method === "automatic") {
            if (newSizeChart.conditions.length === 0) error = "Automatic method requires rules";
            newSizeChart.conditions.forEach(condition => {
                if (condition.subject !== "all_products" && !condition.value) error = "Rule value cannot be empty";
            });
        }
        setProductMatchError(error);
        return !error;
    }, [productMatchError, newSizeChart])

    const fetchSizeChart = useCallback(() => {
        if (!isEdit) return;
        setLoading(true);
        getSizeChart(match.params.id).then(sizeChart => {
            castChartValues(sizeChart.data, roundToTwoDigitsWithRounding);
            setOriginalSizeChart(sizeChart);
            castChartValues(sizeChart.data, v => sizeChart.unit === Unit.METRIC ? v.toString() : roundToDigit(v / 2.54, 2, true).toString());
            setUnit(sizeChart.unit);
            setNewSizeChart({
                id: sizeChart.id,
                brand_name: sizeChart.brand_name,
                gender: sizeChart.gender,
                data: deepClone(sizeChart.data),
                method: sizeChart.method,
                operator: sizeChart.operator,
                conditions: JSON.parse(JSON.stringify(sizeChart.conditions)),
                layout: sizeChart.layout
            });
            setIsDirty(false);
            setLoading(false);
        });
    }, [isEdit, match?.params?.id, roundToTwoDigitsWithRounding, unit, roundToDigit]);

    const validate = useCallback(
        () => !nameError && !sizesError && !measuresError && dataErrors !== {} && !productMatchError,
        [dataErrors, productMatchError, measuresError, nameError, sizesError]
    );

    const handleSave = useCallback(() => {
        if (saveLoadingRef.current) return;
        if (validate()) {
            const savingChart = deepClone(newSizeChart);
            if (unit === Unit.IMPERIAL) {
                castChartValues(savingChart.data, v => parseFloat(v) * 2.54, true);
            } else {
                castChartValues(savingChart.data, parseFloat, true);
            }
            castChartValues(savingChart.data, roundToTwoDigitsWithRounding);
            if (!savingChart.data) {
                toaster.notify(() => <div
                    className="alert alert-warning">Please enter chart's data</div>);
                return;
            }
            handleApiSave(
                createSizeChart,
                updateSizeChart,
                deepAssign(savingChart, {unit}),
                match.params.id,
                {
                    loadingFn: setSaveLoading,
                }
            ).then((resp) => {
                setSizeCharts(null);
                setOriginalSizeChart(null);
                toaster.notify(() => <div
                    className="alert alert-success">{isEdit ? "Size chart updated" : "Size chart created"}</div>);
                history.push("/size-chart");
            });
        }
    }, [
        history,
        isEdit,
        match?.params?.id,
        newSizeChart,
        roundToTwoDigitsWithRounding,
        setSizeCharts,
        unit,
        validate,
    ]);

    const editSizeChartField = useCallback((size, measure, value) => {
        newSizeChart.data[size][measure] = value;
        setNewSizeChart(Object.assign({}, newSizeChart));
        updateLayoutBlockForSizeChart(newSizeChart);
    }, [newSizeChart]);

    const handleDelete = (tags, setTags) => i => setTags(tags.filter((tag, index) => index !== i));

    const handleAddition = (fieldName, tags, setTags) => tag => {
        if (fieldName === "measures" && !validMeasures.includes(tag.id.toLowerCase())) {
            toaster.notify(() => <div className="alert alert-warning">
                Please use a valid dimension:
                <ul>
                    {validMeasures.map(measure => <li key={measure}>{measure}</li>)}
                </ul>
            </div>);
        }
        setTags([...tags, tag.id]);
    }

    const handleDrag = (tags, setTags) => (tag, currPos, newPos) => {
        const newTags = tags.slice();
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag.id);
        setTags(newTags);
    };

    const handleSizeChartSelect = (size_chart) => {
        setUnit(size_chart.unit);
        setNewSizeChart(Object.assign({}, newSizeChart, {
            brand_name: size_chart.full_name,
            data: size_chart.data
        }));
    }

    const updateLayoutBlockForSizeChart = (newSizeChart) => {
        if (newSizeChart?.layout?.blocks) {
            const chartBlocks = newSizeChart.layout.blocks.filter((block) => block.type === BLOCK_TYPE.SIZE_CHART);
            chartBlocks.forEach((block) => {
                const blockData = deepClone(newSizeChart.data);
                if (unit === UNIT_METHOD.IMPERIAL) {
                    castChartUnit(blockData, 2.54);
                }
                block.data = blockData;
                block.unit = unit;
            })
        }
    }

    useEffect(() => {
        updateLayoutBlockForSizeChart(newSizeChart);
    }, [newSizeChart, unit]);

    useEffect(() => {
        if (newSizeChart === null) return;
        setNameError(!newSizeChart.brand_name);
        setSizesError(sizes.length === 0 ? "Sizes are required" : null);
        setMeasuresError(measures.length === 0 ? "Measures are required" : null);
        validateData();
        validateProductMatch();
        updateIsDirty();
    }, [originalSizeChart, newSizeChart, measures?.length, sizes?.length, updateIsDirty, validateData, validateProductMatch]);

    useEffect(() => {
        if (originalSizeChart === null && !loading) fetchSizeChart();
        if (!isEdit && newSizeChart === null) setNewSizeChart({
            brand_name: "",
            data: {},
            layout: {},
            method: "manual",
            operator: "all",
            conditions: []
        });
    }, [fetchSizeChart, isEdit, loading, newSizeChart, originalSizeChart]);

    useEffect(() => {
        if (originalSizeChart !== null || loading === false) return;
        fetchSizeChart();
    }, [loading, originalSizeChart, fetchSizeChart]);

    if ((isEdit && originalSizeChart === null) || sizes === null || measures === null || newSizeChart === null) {
        if (!loading) fetchSizeChart();
        return <div className="c-panel-content"><PageLoading/></div>
    }

    if (showCreateSetup) {
        return <CreateChartSetup newSizeChart={newSizeChart} setNewSizeChart={setNewSizeChart}
                                 setShowCreateSetup={setShowCreateSetup}/>
    }

    return <div className="container-fluid px-0">
        <div className="c-table-action">
            <Link
                className="c-table-filter__item"
                onClick={() => {
                    history.push(`/size-chart`);
                }}>
                <Button color="light">
                    All size charts
                </Button>
            </Link>
            {
                isEdit && (
                    <div className="c-table-filter__item c-table-filter__item-right">
                        <Button color="light" onClick={() => {
                            const options = {
                                message: `Are you sure to delete "${originalSizeChart.brand_name}"?`,
                                loadingFn: () => {
                                }
                            };
                            handleApiDeleteById(deleteSizeChart, originalSizeChart.id, options).then(isSuccess => {
                                setSizeCharts(null);
                                setProducts(null);
                                if (isSuccess)
                                    toaster.notify(() => <div
                                        className="alert alert-success">{"Size chart deleted"}</div>);
                                history.push(`/size-chart`);
                            });
                        }}>
                            Delete
                        </Button>
                        <Button
                            loading={saveLoading}
                            disabled={!isDirty || !validate()}
                            onClick={handleSave}>
                            Save
                        </Button>
                    </div>
                )
            }
        </div>
        <div className="row">
            <div className="col-12 col-lg-6">
                {
                    showStyleEditor ? <BlockEditor
                        {...{
                            block: editBlock,
                            index: editBlockIndex,
                            saveBlock,
                            addBlock,
                            unit,
                            setUnit,
                            sizes,
                            measures,
                            dataErrors,
                            editSizeChartField,
                            newSizeChart,
                            setNewSizeChart
                        }}
                    /> : <Fragment>
                        <div className="c-panel-content">
                            <ChartBrand name={newSizeChart.brand_name} gender={newSizeChart.gender}
                                        error={nameError} setName={setName} setGender={setGender}
                                        handleSizeChartSelect={handleSizeChartSelect} isOpen={step === 1}
                                        handleClose={() => setStep(2)} handleOpen={() => setStep(1)}/>
                        </div>
                        <div className="c-panel-content">
                            <ChartDimensions
                                {...{
                                    sizes,
                                    sizesError,
                                    handleDelete,
                                    handleAddition,
                                    handleDrag,
                                    measures,
                                    setMeasures,
                                    setSizes
                                }}
                                handleClose={() => setStep(3)} handleOpen={() => setStep(2)} isOpen={step === 2}/>
                        </div>
                        <div className="c-panel-content">
                            <ChartData
                                {...{
                                    unit,
                                    setUnit,
                                    sizes,
                                    measures,
                                    dataErrors,
                                    editSizeChartField,
                                    castChartValues,
                                    newSizeChart,
                                    setNewSizeChart
                                }}
                                handleClose={() => setStep(4)} handleOpen={() => setStep(3)} isOpen={step === 3}/>
                        </div>
                        <div className="c-panel-content">
                            <ChartProductMatch
                                condition={{
                                    method: newSizeChart.method,
                                    operator: newSizeChart.operator,
                                    conditions: newSizeChart.conditions,
                                }}
                                productMatchError={productMatchError}
                                onConditionChange={setProductMatchCondition}
                                handleOpen={() => setStep(4)} isOpen={step === 4}/>
                        </div>
                    </Fragment>
                }
            </div>
            <div className="col-12 col-lg-6">
                <div className="c-panel-content">
                    <div className="c-list">
                        <h3 className="c-list__title">Preview</h3>
                        <div className="float-right">
                            <Button color="light" onClick={() => {
                                if (!showStyleEditor) {
                                    const layout = newSizeChart.layout || {};
                                    const blocks = layout.blocks || [];
                                    if (blocks.length === 0 || blocks.filter(block => block.type === BLOCK_TYPE.SIZE_CHART).length === 0) {
                                        const block = {
                                            type: BLOCK_TYPE.SIZE_CHART,
                                            data: newSizeChart.data
                                        };
                                        blocks.push(block);
                                        layout.blocks = blocks;
                                        setNewSizeChart(Object.assign(
                                            {},
                                            newSizeChart,
                                            {layout}
                                        ))
                                    }
                                }
                                setShowStyleEditor(!showStyleEditor);
                            }}>
                                {showStyleEditor ? "Stop editing" : "Edit style"}
                            </Button>
                        </div>
                        <div className="c-list__item">
                            {newSizeChart.method === "automatic" ? "This chart is automatically used for products." : "This chart is manually assigned to products."}
                        </div>
                        <div id="if_app" className="if_app pt-3">
                            <div className={INTERVIEW_STEP_MODAL_CLASS.SIZE_CHART}>
                                <SizeChartView
                                    product={{
                                        size_chart: unit === Unit.METRIC ? newSizeChart.data : (() => {
                                            const data = deepClone(newSizeChart.data);
                                            castChartUnit(data, 2.54);
                                            return data;
                                        })(),
                                        size_chart_layout: newSizeChart.layout,
                                    }}
                                    editWrapper={showStyleEditor ? LayoutBlockEditWrapper : null}
                                    editWrapperProps={{
                                        saveBlock,
                                        moveBlockUp,
                                        moveBlockDown,
                                        removeBlock,
                                        cloneBlock,
                                        newSizeChart,
                                        setEditBlock,
                                        setEditBlockIndex,
                                        setHideRecommender,
                                    }}
                                />
                            </div>
                            {
                                showStyleEditor && <div className="container">
                                    <Button color="primary" onClick={() => {
                                        setEditBlock(null);
                                        setEditBlockIndex(-1);
                                    }}>
                                        Add block
                                    </Button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="c-table-action">
            <div className="c-table-filter__item">
                <Button
                    loading={saveLoading}
                    disabled={!isDirty || !validate()}
                    onClick={handleSave}>
                    Save
                </Button>
            </div>
        </div>
    </div>
})

export default EditSizeChartPage;
