import React from 'react';
import {capitalize} from "@material-ui/core";
import * as classnames from "classnames";

const SubscriptionDetails = ({subscription}) => {
    return <div className="c-table c-table--bordered text-center">
        <table>
            <tbody>
            <tr>
                <th>
                    Plan
                </th>
                <td>
                    {capitalize(subscription.plan_name)}
                </td>
            </tr>
            <tr>
                <th>
                    {subscription.plan_type === "subscription" && "Monthly price"}
                    {subscription.plan_type === "subscription_annual" && "Annual price"}
                    {subscription.plan_type === "usage_based" && "Per use"}
                </th>
                <td>
                    ${subscription.price}
                </td>
            </tr>
            <tr>
                <th>
                    Trial
                </th>
                <td>
                        <span className={classnames("fa", {
                            "fa-check": subscription.is_trial,
                            "fa-times": !subscription.is_trial
                        })}></span>
                    {
                        subscription.is_trial && <span className="ml-2">Ends on {subscription.trial_end}</span>
                    }
                </td>
            </tr>
            <tr>
                <th>
                    Status
                </th>
                <td>
                    {capitalize(subscription.status)}
                </td>
            </tr>
            <tr>
                <th>
                    Subscription ID
                </th>
                <td>
                    {subscription.id.toUpperCase()}
                </td>
            </tr>
            </tbody>
        </table>
    </div>
}

export default SubscriptionDetails;