import React, {Fragment} from 'react';
import Input from "../../Common/UIKit/Form/Input";
import Button from "../../Common/UIKit/Form/Button";
import {getProductSuggestion} from "../../../api/product";
import AutoCompleteInput from "../../Common/UIKit/AutoCompleteInput";
import {getVerifiedSizeChartSuggestion} from "../../../api/sizeChart";
import {bulkAction, bulkActions} from "../Product/ProductConstants";
import Select from "../../Common/UIKit/Form/Select";
import {SIZE_CHART_GENDER} from "./Constants";

const ChartBrand = ({
                        name,
                        gender,
                        error,
                        setName,
                        setGender,
                        handleSizeChartSelect,
                        isOpen,
                        handleClose,
                        handleOpen
                    }) => {

    return <div className="c-list">
        <div className="container">
            <h3 className="c-list__title">1. Size chart's name and gender</h3>
            {
                isOpen ? (
                    <Button color="primary" className="float-right" onClick={handleClose}>
                        Next
                    </Button>
                ) : (
                    <Button color="light" className="float-right" onClick={handleOpen}>
                        <span className="fa fa-pencil"></span>
                    </Button>
                )
            }
        </div>
        <div className="c-list__item">
            <div className="c-list-split">
                {
                    isOpen ? (
                        <div className="col">
                            <Input value={name}
                                   error={!!error}
                                   onChange={(e) => {
                                       setName(e.target.value);
                                   }}/>
                            <AutoCompleteInput
                                value={name}
                                subject={"verified_size_chart"}
                                onSelect={handleSizeChartSelect}
                                itemToLabel={item => item.full_name}
                                lookupFunc={getVerifiedSizeChartSuggestion}/>
                        </div>
                    ) : (
                        <Fragment>
                            {
                                name?.length > 0 ? (
                                    <div className="col">
                                        Brand: {name}
                                    </div>
                                ) : (
                                    <div className="px-3 text-warning">
                                        Brand name is required
                                    </div>
                                )
                            }
                        </Fragment>
                    )
                }
            </div>
        </div>
        <div className="c-list__item">
            <div className="c-list-split">
                {
                    isOpen ? (
                        <div className="col">
                            <div className='container px-0'>
                                <Select
                                    className="c-form__select-sized"
                                    id="sizeChartGender"
                                    label="Gender"
                                    options={Object.entries(SIZE_CHART_GENDER).map(([key, value]) => ({
                                        label: key,
                                        value
                                    }))}
                                    value={gender}
                                    onChangeValue={setGender}
                                />
                            </div>
                        </div>
                    ) : (
                        <></>
                    )
                }
            </div>
        </div>
    </div>
};

export default ChartBrand;