import React from 'react';
import classnames from 'classnames';
import {Controller} from "react-hook-form";
import PropTypes from "prop-types";
import FormGroup from "./FormGroup";

export const ControlCheckbox = ({ control, name, rules, defaultValue, id, onChangeValue, ...checkoutProps }) => control ? (
    <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={defaultValue}
        render={({ onChange, onBlur, value }, { invalid }) => (
            <Checkbox {...checkoutProps}
                      id={id ? id : name}
                      onChange={val => {
                          onChange(val);
                          if(onChangeValue) onChangeValue(val);
                      }}
                      onBlur={onBlur}
                      checked={!!value}
                      error={!!invalid}
            />
        )}
    />
) : null;
ControlCheckbox.propTypes = {
    id: PropTypes.string,
    control: PropTypes.any.isRequired,
    name: PropTypes.any,
    rules: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    defaultValue: PropTypes.any,
    formGroup: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string,PropTypes.object]),
    errorMessage: PropTypes.string,
    onChangeValue: PropTypes.func,
};

const Checkbox = ({ id, label, formGroup, type, onChange, formGroupClass, ...otherProps }) => {
    const handleOnChange = e => onChange(e.target.checked);
    type = (type === 'radio' || type === 'checkbox') ? type : 'checkbox';
    const renderCheckbox = (
        <input type={type}
               id={id}
               onChange={handleOnChange}
               className={'c-form__checkbox-input'}
               {...otherProps}
        />
    );

    return formGroup ? (
        <FormGroup id={id} className={classnames('c-form__group-checkbox', formGroupClass)} label={label}>
            {renderCheckbox}
        </FormGroup>
    ) : renderCheckbox;
};

Checkbox.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    formGroup: PropTypes.bool,
    formGroupClass: PropTypes.any,
    type: PropTypes.oneOf(['radio', 'checkbox']),
    onChange: PropTypes.func
};

Checkbox.defaultProps = {
    id: null,
    label: null,
    formGroup: true,
    type: 'checkbox',
    onChange: () => {}
};
export default Checkbox;
