import React from 'react';
import { NavLink } from "react-router-dom";
import {
    IMAGE_LOGO,
} from "../../../../assets/Images";
import {APP_NAME} from "../../../../config/appConfig";

const SidebarLogo = ({onClick}) => {
    return (
        <NavLink to="/" className="c-panel-sidebar__logo" onClick={onClick}>
            <img
                src={IMAGE_LOGO}
                alt={APP_NAME}
                className="c-panel-sidebar__logo-img"
            />
            <img
                src={IMAGE_LOGO}
                alt={APP_NAME}
                className="c-panel-sidebar__logo-img logo-min-size"
            />
        </NavLink>
    );
}

export default SidebarLogo;
