import React, {Fragment, useEffect} from 'react';
import {BoxSpinner} from "../../Common/UIKit/Spinner";
import {getAllQueryParams} from "../../../utilities/URI";
import asyncLocalStorage from "../../../utilities/AsyncLocalStorage";
import {Types} from "../../../config/actionTypes";

const LoginAsPartner = () => {

    useEffect(async () => {
        const params = getAllQueryParams();
        await asyncLocalStorage.setItem(Types.USER_TOKEN, params.access);
        await asyncLocalStorage.setItem(Types.USER_REFRESH, params.refresh);
        window.location.replace("/");
    }, [])

    return <Fragment>
        <div className="c-panel-content">
            <BoxSpinner/>
        </div>
    </Fragment>
};

export default LoginAsPartner;
