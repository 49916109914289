import React, {useContext, useEffect} from 'react';
import UserHeader from "./UserHeader";
import Logo from "./Logo";
import BurgerMenu from "./BurgerMenu";
import {useIsDesktop} from "../../../../utilities/useIsDesktop";
import {AppContext} from "../../../../context/providers";
import {Types} from "../../../../config/actionTypes";
import {handleApi} from "../../../../utilities/useApi";
import {getProfile} from "../../../../api";
import {withRouter} from "react-router-dom";
import CurrentSetupInstruction from "../../UIKit/SetupInstruction/CurrentSetupInstruction";
import {getBusinessSettings} from "../../../../api/business";
import {deepAssign} from "../../../../fit_widget/src/utils/common";
import {AppConfig} from "../../../../fit_widget/src/context";
import classnames from "classnames";

const Header = withRouter(({history, onToggle}) => {

    const isDesktop = useIsDesktop();

    const {
        business,
        sideBarLinks,
        setUser,
        setBusiness,
        setStripe,
        setSetupInstructions,
        setSideBarLinks
    } = useContext(AppContext);

    const token = localStorage.getItem(Types.USER_TOKEN);

    const config = useContext(AppConfig);

    useEffect(() => {
        if (token) {
            handleApi(getProfile).then(user => {
                const {
                    business: {
                        stripe, ...business
                    } = {}, info, stripe_id, ...userInfo
                } = user;
                setUser({...userInfo, ...info});
                setBusiness(business);
                const {
                    local_unit,
                    ...businessConfig
                } = business.config || {};
                if (business.config) deepAssign(config, businessConfig);
                if (stripe) {
                    setStripe(stripe);
                }
                if (business.subscription === null) history.push("/billing");
                if (window.location.hostname === "partners.robosize.com") {
                    try {
                        window.FS.identify(business.id, {
                            displayName: business.title,
                            email: user.email,
                            plan_name: business.subscription?.plan_name
                            // TODO: Add your own custom user variables here, details at
                        });
                    } catch (e) {
                    }
                }
            });
        }
    }, [setBusiness, setStripe, setUser, token]);

    const linkExists = (url) => sideBarLinks.map(link => link.url).includes(url);

    useEffect(() => {
        if (!business) return;
        getBusinessSettings().then(response => {
            const settings = response.results[0];
            const customSettings = settings.custom_settings;
            if (customSettings) {
                deepAssign(business, {
                    settings: customSettings
                });
                setBusiness(business);
                if (business.subscription?.status === "active") {
                    const plan = business.subscription?.plan_name;
                    if (["ultimate", "enterprise"].includes(plan)) {
                        setSideBarLinks();
                        sideBarLinks.splice(6, 0, {
                            icon: 'fas fa-eye',
                            name: 'Insights',
                            url: '/insight',
                        });
                    setSideBarLinks(sideBarLinks);
                    }
                }
                if (customSettings.orders?.show && !linkExists('/order')) {
                    setSideBarLinks();
                    sideBarLinks.splice(4, 0, {
                        icon: 'fas fa-receipt',
                        name: 'Orders',
                        url: '/order',
                    },);
                    setSideBarLinks(sideBarLinks);
                }
                if (customSettings.alternative_businesses?.show && !linkExists('/store')) {
                    setSideBarLinks();
                    sideBarLinks.splice(4, 0, {
                        icon: 'fas fa-store',
                        name: 'Stores',
                        url: '/store',
                    },);
                    setSideBarLinks(sideBarLinks);
                }
            }
            setSetupInstructions({
                plan_selected: settings.plan_selected,
                size_charts_added: settings.size_charts_added,
                app_embed_added: settings.app_embed_added,
                widget_customized: settings.widget_customized,
            });
        });
    }, [business])

    if (window.location.pathname.includes("public-help")) {
        return null;
    }

    return (
        <div className={classnames('c-panel-header', {
            'full-width': window.location.pathname.includes("public-help")
        })}>
            <Logo/>
            {business && <CurrentSetupInstruction/>}
            <div className="c-panel-header__items">
                {isDesktop ? <UserHeader/> : <BurgerMenu onToggle={onToggle}/>}
            </div>
        </div>
    )
});

export default Header;
