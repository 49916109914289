import React, {useContext} from 'react';
import ShopifySetupPage from "./ShopifySetupPage";
import {subscribeToShopify, updateSubscriptionToShopify} from "../../../api/payment";
import {AppContext} from "../../../context/providers";
import {plans} from "./plans";

const ShopifyUpdateSubscriptionPage = () => {

    const {business} = useContext(AppContext);
    let nameMatches = plans.filter(plan => plan.name === business?.subscription?.plan_name);
    const plan = nameMatches.length > 0 ? nameMatches[0] : null;

    return <ShopifySetupPage currentPlan={plan} saveFunc={updateSubscriptionToShopify}/>
}

export default ShopifyUpdateSubscriptionPage;