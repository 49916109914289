import React, {Fragment, useEffect} from 'react';
import UsageReport from "./UsageReport";
import PopularProductsList from "./PopularProductsList";
import RecommendationsList from "./RecommendationsList";

const ReportPage = () => {

    useEffect(() => {
    }, []);


    return <Fragment>
        <div className="c-panel-content">
            <UsageReport/>
        </div>
        <div className="c-panel-content">
            <PopularProductsList/>
        </div>
        <div className="c-panel-content">
            <RecommendationsList/>
        </div>
    </Fragment>
};

export default ReportPage;
