import React from 'react';
import {BoxSpinner} from "../../Common/UIKit/Spinner";
import {Chart} from "react-charts";

const InsightCharts = ({data}) => {

    const axes = React.useMemo(
        () => [
            {primary: true, type: 'ordinal', position: 'bottom'},
            {type: 'linear', position: 'left'}
        ],
        []
    );

    const series = React.useMemo(
        () => ({
            type: 'bar'
        }),
        []
    )

    const tooltip = {
        align: "top",
        anchor: "closest"
    }

    const transformData = (data, field) => ({
        label: field,
        data: data[field].counts.map((item, index) => [`${data[field].bins[index]} - ${data[field].bins[index + 1]}`, item])
    })

    if (data === null) {
        return <BoxSpinner/>
    }

    if (data.count === 0) {
        return <h5 className='text-center py-3'>No data matched.</h5>
    }

    return (
        <div>
            <div style={{
                display: 'flex',
                height: '350px',
                paddingBottom: '30px',
                paddingRight: '30px',
                marginBottom: '30px',
            }}>
                <div className='col-6'>
                    <h5 className='text-center'>Gender</h5>
                    <Chart
                        data={[{
                            label: 'Gender',
                            data: [['Male', data.gender.male], ['Female', data.gender.female]]
                        }]}
                        axes={axes}
                        series={series}
                        tooltip={tooltip}/>
                </div>
                <div className='col-6'>
                    <h5 className='text-center'>Age</h5>
                    <Chart
                        data={[transformData(data, "age")]}
                        axes={axes}
                        series={series}
                        tooltip={tooltip}/>
                </div>
            </div>
            <div style={{
                display: 'flex',
                height: '350px',
                paddingBottom: '30px',
                paddingRight: '30px',
                marginBottom: '30px',
            }}>
                <div className='col-6'>
                    <h5 className='text-center'>Height</h5>
                    <Chart
                        data={[transformData(data, "height")]}
                        axes={axes}
                        series={series}
                        tooltip={tooltip}/>
                </div>
                <div className='col-6'>
                    <h5 className='text-center'>Weight</h5>
                    <Chart
                        data={[transformData(data, "weight")]}
                        axes={axes}
                        series={series}
                        tooltip={tooltip}/>
                </div>
            </div>
            <div style={{
                display: 'flex',
                height: '350px',
                paddingBottom: '30px',
                paddingRight: '30px',
                marginBottom: '30px',
            }}>
                <div className='col-6'>
                    <h5 className='text-center'>Fit preference</h5>
                    <Chart
                        data={[{
                            label: 'Gender',
                            data: [
                                ['Tight', data.fit_preference.tight],
                                ['Regular', data.fit_preference.regular],
                                ['Loose', data.fit_preference.loose],
                            ]
                        }]}
                        axes={axes}
                        series={series}
                        tooltip={tooltip}/>
                </div>
                <div className='col-6'>
                    <h5 className='text-center'>Bust</h5>
                    <Chart
                        data={[transformData(data, "bust")]}
                        axes={axes}
                        series={series}
                        tooltip={tooltip}/>
                </div>
            </div>
            <div style={{
                display: 'flex',
                height: '350px',
                paddingBottom: '30px',
                paddingRight: '30px',
                marginBottom: '30px',
            }}>
                <div className='col-6'>
                    <h5 className='text-center'>Waist</h5>
                    <Chart
                        data={[transformData(data, "waist")]}
                        axes={axes}
                        series={series}
                        tooltip={tooltip}/>
                </div>
                <div className='col-6'>
                    <h5 className='text-center'>Hip</h5>
                    <Chart
                        data={[transformData(data, "hip")]}
                        axes={axes}
                        series={series}
                        tooltip={tooltip}/>
                </div>
            </div>
        </div>
    )
};

export default InsightCharts;
