import React from 'react';
import {withRouter} from 'react-router-dom';
import {IMAGE_LOGO} from "../../../../assets/Images";
import Link from "../../UIKit/Link";

const Logo = withRouter(({history}) => {
    return (
        <div className="c-logo d-none d-sm-block">
            <Link onClick={() => {
                history.push("/");
            }}>
                <img src={IMAGE_LOGO} alt="Robosize logo"/>
            </Link>
        </div>
    )
});


export default Logo;
