export const LinkList = [
    {
        icon: 'fas fa-tachometer-alt',
        name: 'Dashboard',
        url: '/dashboard',
    },
    {
        icon: 'fas fa-table',
        name: 'Size charts',
        url: '/size-chart',
    },
    {
        icon: 'fas fa-tag',
        name: 'Products',
        url: '/product',
    },
    {
        icon: 'fas fa-tshirt',
        name: 'Fabrics',
        url: '/fabric',
    },
    {
        icon: 'fas fa-credit-card',
        name: 'Billing',
        url: '/billing',
    },
    {
        icon: 'fas fa-chart-line',
        name: 'Analytics',
        url: '/report',
    },
    {
        icon: 'fas fa-cog',
        name: 'Settings',
        url: '/settings',
    },
    {
        icon: 'fas fa-question-circle',
        name: 'Help',
        url: '/help',
    },
];

export default LinkList;
