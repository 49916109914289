import React, {Fragment, useContext, useEffect} from 'react';
import {withRouter} from "react-router-dom";
import {AppContext} from "../../../../context/providers";
import * as classnames from "classnames";
import Button from "../Form/Button";
import {saveBusinessSettings} from "../../../../api/business";

const SelectPlanInstruction = withRouter(({history, compact}) => {

    const {business, setupInstructions, setSetupInstructions} = useContext(AppContext);
    const {plan_selected} = setupInstructions || {};

    const handleDone = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        setSetupInstructions({plan_selected: true});
        saveBusinessSettings({plan_selected: true}).then(() => {
        });
    }

    useEffect(() => {
        if (!plan_selected && business?.subscription?.plan_name && business?.subscription?.plan_name !== "free") {
            handleDone();
        }
    }, [plan_selected, business])

    return <div className={classnames("c-card-item c-card-body", {
            "c-card-item--disabled": plan_selected,
            "c-card-item--clickable": !plan_selected
        }
    )} onClick={() => {
        history.push("/billing/update");
    }}>
        <b>Step 1: </b>
        Select the plan that works best for you
        {
            plan_selected ?
                <span className="fa fa-lg fa-check-circle float-right ml-2 text-success"></span> : (
                    <Fragment>
                        <Button color="success" xs={compact} className="d-inline-block ml-2 float-right"
                                onClick={handleDone}>
                            Mark as done
                        </Button>
                    </Fragment>
                )
        }
    </div>

});

export default SelectPlanInstruction;