import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Chart} from 'react-charts';
import {getUsageReport} from "../../../api/report";
import {BoxSpinner} from "../../Common/UIKit/Spinner";
import capitalize from "@material-ui/core/utils/capitalize";
import DatePicker from "../../Common/UIKit/Form/DatePicker";
import {getStringFromDate} from "../../../utilities/DateHelpers";
import toaster from "toasted-notes";
import {getStores} from "../../../api/store";
import Select from "../../Common/UIKit/Form/Select";


const UsageReport = () => {

    const EVENTS = useMemo(() => ["open", "fit", "view recommendation"], []);

    const mapEventName = event => {
        if (event === "load") return "Product view";
        return event;
    }

    const [usage, setUsage] = useState(null);
    const [stores, setStores] = useState(null);
    const [selectedStore, setSelectedStore] = useState(null);
    const [loadingUsage, setLoadingUsage] = useState(false);

    const startDateLimit = new Date('2023-06-25:00:00:00'),
      endDateLimit = new Date();
    const initialStartDate = new Date();
    initialStartDate.setDate(initialStartDate.getDate() - 7);
    const initialEndDate = new Date();
    initialEndDate.setDate(initialEndDate.getDate() - 1);
    const [startDate, setStartDate] = useState(new Date(Math.max(initialStartDate, startDateLimit)));
    const [endDate, setEndDate] = useState(initialEndDate);

    const container = useRef(null);

    const fetchUsage = useCallback(({
                                        start = startDate,
                                        end = endDate
                                    } = {}) => {
        if (!start || !end) return;
        if (start > end) return;
        if (start < startDateLimit) {
            toaster.notify(() => <div className="alert alert-warning">Reports are available for the last 30 days</div>);
            return;
        }
        if (end > endDateLimit) {
            toaster.notify(() => <div className="alert alert-warning">Cannot get report for future</div>);
            return;
        }
        if (loadingUsage) return;
        setLoadingUsage(true);
        getUsageReport({
            store_id: selectedStore,
            start: start ? getStringFromDate(start) : undefined,
            end: end ? getStringFromDate(end) : undefined
        }).then(response => {
            setUsage(response);
            setLoadingUsage(false);
        });
    }, [endDate, endDateLimit, loadingUsage, startDate, startDateLimit, selectedStore]);

    const fetchStores = useCallback(() => {
        getStores().then(response => {
            const stores = response.results;
            setStores(stores);
            setSelectedStore(stores[0].id);
        });
    }, []);

    const data = React.useMemo(
        () => usage ? (
            EVENTS.map(action => ({
                label: capitalize(action),
                data: Object.keys(usage).map(day => [day, usage[day][action]])
            }))
        ) : [],
        [usage, EVENTS]
    )

    const axes = React.useMemo(
        () => [
            {primary: true, type: 'ordinal', position: 'bottom'},
            {type: 'linear', position: 'left'}
        ],
        []
    );

    const series = React.useMemo(
        () => ({
            type: 'bar'
        }),
        []
    )

    useEffect(() => {
        if (selectedStore === null) return;
        if (usage === null) fetchUsage();
    }, [fetchUsage, usage, selectedStore]);

    useEffect(() => {
        if (stores === null) fetchStores();
    }, [fetchStores, stores])

    if (stores === null) {
        return <div className="c-list__item">
            <BoxSpinner/>
        </div>
    }

    return <div className="c-list__item" ref={container}>
        <div className="mb-3">
            Usage:
        </div>
        <div className="p-3 row">
            <div className="col">
                <label>Date range:</label>
                <DatePicker
                    selected={startDate}
                    onChange={(dates) => {
                        const [start, end] = dates;
                        setStartDate(start);
                        setEndDate(end);
                        fetchUsage({
                            start, end
                        });
                    }}
                    minDate={startDateLimit}
                    maxDate={endDateLimit}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                />
            </div>
            <div className="col">
                <label>Store:</label>
                <Select id="stores"
                        options={stores.map(store => ({
                            value: store.id,
                            label: store.domain
                        }))}
                        onChangeValue={storeId => {
                            setSelectedStore(storeId);
                            setUsage(null);
                        }}
                        defaultValue={null}
                        value={selectedStore}/>
            </div>
        </div>
        {
            loadingUsage || usage === null ? <BoxSpinner/> : (
                <div>
                    <div
                        style={{
                            height: '300px',
                            width: container.current ? `${container.current.offsetWidth}px` : '100%'
                        }}
                    >
                        <Chart
                            data={data}
                            axes={axes}
                            series={series}
                            tooltip={{
                                align: "auto",
                                anchor: "closest"
                            }}/>
                    </div>
                    <div className="p-3 pt-5 row">
                        <div className="c-table c-table--bordered">
                            <table>
                                <thead>
                                <tr>
                                    <th></th>
                                    {EVENTS.map(event => (
                                        <th key={event}>{capitalize(mapEventName(event))}</th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>Unique users</td>
                                    {
                                        EVENTS.map(event => (
                                            <td key={event}>
                                                {Object.values(usage).map(v => v[event]).reduce((a, b) => a + b)}
                                            </td>
                                        ))
                                    }
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )
        }
    </div>
};

export default UsageReport;
