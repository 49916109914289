import config from "../../baseConfig";
import {deepAssign} from "../../../utils/common";
import {GENDER} from "../../../components/BodyShape/Constants";

export default deepAssign({}, config, {
    appClass: "prefecture",
    integrationButton: {
        class: "",
        wrapperClass: "prefecture"
    },
});