import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../context/providers";
import {withRouter} from "react-router-dom";
import {BoxSpinner} from "../../Common/UIKit/Spinner";
import {completeConnectToStripe} from "../../../api/payment";

const ConnectCompletePage = withRouter(({history}) => {

    const {stripe, setStripe} = useContext(AppContext);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!loading && (!stripe || !stripe.payment_method)) {
            setLoading(true);
            completeConnectToStripe().then(response => {
                setStripe(response);
                history.push("/billing-custom");
                setLoading(false);
            }).catch(err => {
                setLoading(false);
            });
        }
    }, [stripe, history, loading, setStripe]);

    if (loading || !stripe || !stripe.payment_method) {
        return <div className="c-panel-content">
            <BoxSpinner/>
        </div>
    }

    window.location.replace("/billing-custom");

    return (
        <div className="c-panel-content">
            <BoxSpinner/>
        </div>
    );
});

export default ConnectCompletePage;