import React, {useContext, useMemo, useState} from "react";
import {AppContext} from "../../../context/providers";
import Modal from "../../Common/UIKit/Modal";
import Select from "../../Common/UIKit/Form/Select";
import {handleApiSave} from "../../../utilities/useApi";
import {updateProducts} from "../../../api/product";
import toaster from "toasted-notes";


const AssignFabricModal = ({
                               products, onClose, onSave
                           }) => {

    const {setProducts, fabrics} = useContext(AppContext);

    const [fabric, setFabric] = useState(null);
    const [saveLoading, setSaveLoading] = useState(false);

    const fabricOptions = useMemo(() => {
        const options = [{
            value: null,
            label: "--"
        }];
        if (fabrics === null) return options;
        options.push(...fabrics.map(chart => ({
            value: chart.id,
            label: chart.name
        })));
        return options;
    }, [fabrics]);

    const handleSave = () => {
        if (fabric === null) return;
        handleApiSave(
            updateProducts,
            null,
            {
                products,
                fabric
            },
            null,
            {
                loadingFn: setSaveLoading,
            }
        ).then(() => {
            setProducts(null);
            onClose();
            onSave();
            toaster.notify(() => <div
                className="alert alert-success">{`Product ${products.length > 1 ? "fabrics" : "fabric"} updated`}</div>);
        });
    };

    return <Modal show
                  auto
                  onClose={onClose}
                  onSave={handleSave}
                  saveLoading={saveLoading}
                  title={`Updating ${products.length} ${products.length === 1 ? "product" : "products"}`}
    >
        <div className="px-3 py-3 c-modal-drop-down">
            <Select
                id="fabric"
                label="Fabric"
                options={fabricOptions}
                onChangeValue={(e) => {
                    setFabric(e)
                }}
                menuPlacement="bottom"
            />
        </div>
    </Modal>
};

export default AssignFabricModal;