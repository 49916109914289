const actions = {
    modal: {
        setClass: null,
        setSize: null,
        updateFields: null,
        show: null,
        hide: null
    },
    interview: {
        setStep: null,
        next: null,
        back: null,
        reset: null,
    },
    business: {
        set: null,
    },
    profile: {
        set: null,
        setId: null,
        setLocalUnit: null,
    },
    product: {
        set: null,
    },
    bodyShape: {
        setGender: null,
        setUnit: null,
        setHeight: null,
        setWeight: null,
        setAge: null,
        setNeck: null,
        setLength: null,
        set: null,
        reset: null,
    },
    bodyMeasurement: {
        set: null,
        reset: null
    },
    knownBrand: {
        set: null,
        setBrand: null,
        setChart: null,
        setSize: null,
        reset: null,
    },
    typicalSize: {
        set: null
    },
    tryOn: {
        set: null,
    },
    camera: {
        setStream: null,
        setInterval: null,
    },
}

export default actions;
