import {callApi} from "../utilities/ApiHandler";
import {ApiMethods, ApiRoutes} from "../config/apiConfig";

const {POST} = ApiMethods;

export const getParams = params => {
    if (!!params.attachment) {
        const formData = new FormData();
        Object.keys(params).forEach(key => {
            if (!!params[key] && key !== 'attachment') formData.append(key, params[key]);
        });
        formData.append('attachment', params.attachment);
        return formData;
    }
    return params;
};

export const saveSizeChartImport = async (params) => {
    const response = await callApi({
        method: POST,
        url: ApiRoutes.sizeChart.import,
        headers: {'Content-Type': 'undefined'},
        params
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
}