import {
    SIZE_CHART__BRAND,
    SIZE_CHART__DATA,
    SIZE_CHART__DIMENSIONS,
    SIZE_CHART__MATCHING
} from "../../../../assets/Images";

export const SizeChartArticle = {
    id: "how-to-create-size-chart",
    is_popular: true,
    title: "How to create a size chart?",
    body: [
        {
            tag: "p",
            body: "You can watch a tutorial video on this <a href='https://youtu.be/otV-hlHVjNo' target='_blank'>here</a>."
        },
        {
            tag: "h5",
            body: "Step 1:"
        },
        {
            tag: "p",
            body: "On the left panel click on <b>Size charts</b> > <b>Create a new chart</b>."
        },
        {
            tag: "h5",
            body: "Step 2:"
        },
        {
            tag: "p",
            body: "Give your size chart a name. We have collected size charts of numerous brands, " +
                "so you might just find the chart if you start typing the name of the brand."
        },
        {
            tag: "img",
            attrs: {
                "src": SIZE_CHART__BRAND
            }
        },
        {
            tag: "h5",
            body: "Step 3:"
        },
        {
            tag: "p",
            body: "Define the size chart dimensions. On the left side you should enter the sizes " +
                "the item comes in and <b>put a comma after each size to add it to the list</b>. If this brand " +
                "comes in different sizing models (e.g. S, M, L and 2, 4, 5) you should create two size charts, one " +
                "for each sizing model. On the right side choose the dimensions of the size chart (inside leg is " +
                "the same as inseam)"
        },
        {
            tag: "img",
            attrs: {
                "src": SIZE_CHART__DIMENSIONS
            }
        },
        {
            tag: "h5",
            body: "Step 4:"
        },
        {
            tag: "p",
            body: "Then enter the size chart's data in the next step. You can choose the unit system " +
                "you are comfortable with and the system will automatically convert the sizes for you. Once the " +
                "chart's data is complete, you will see a preview of the chart on the right side."
        },
        {
            tag: "img",
            attrs: {
                "src": SIZE_CHART__DATA
            }
        },
        {
            tag: "h5",
            body: "Step 5:"
        },
        {
            tag: "p",
            body: "At the last step you can specify which products should use this size chart. The " +
                "manual option means that you will match the products to this size chart manually (watch a tutorial " +
                "video on how to match products manually " +
                "<a href='https://youtu.be/otV-hlHVjNo?t=106' target='_blank'>here</a>). If " +
                "you bring new products of the same brand next season, you can simply use the automatic option and " +
                "the new products will use the size chart automatically. <br/><br/>" +
                "You can match products to the size chart using their vendor, collection, product type, tags, or " +
                "any combination of these. Once you define the conditions you will see how many products currently " +
                "will be matched to this chart (usually you have collections for each brand and you can simply use " +
                "that)"
        },
        {
            tag: "img",
            attrs: {
                "src": SIZE_CHART__MATCHING
            }
        },
        {
            tag: "p",
            body: "Click save. Congratulations! You have created your first size chart."
        },
    ],
};