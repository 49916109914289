import {callApi} from "../utilities/ApiHandler";
import {ApiMethods, ApiRoutes, createURL} from "../config/apiConfig";

const {GET, PATCH, POST, DELETE} = ApiMethods;

export const getFabrics = async () => {
    const response = await callApi({
        method: GET,
        url: ApiRoutes.fabric.list
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};


export const getFabric = async (id) => {
    const response = await callApi({
        method: GET,
        url: createURL(ApiRoutes.fabric.get, {id})
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const createFabric = async (body) => {
    const response = await callApi({
        method: POST,
        url: ApiRoutes.fabric.create,
        params: body,
    });
    if(response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const updateFabric = async (id, body) => {
    const response = await callApi({
        method: PATCH,
        url: createURL(ApiRoutes.fabric.update, {id}),
        params: body,
    });
    if(response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};



export const deleteFabric = async id => {
    const response = await callApi({
        method: DELETE,
        url: createURL(ApiRoutes.fabric.delete, {id}),
        params: {},
    });
    if(response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};