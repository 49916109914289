import React, {useCallback, useContext, useEffect, useState} from 'react';
import {AppContext} from "../../../context/providers";
import NotFoundData from "../../Common/UIKit/NotFoundData";
import {PageLoading} from "../../Common/UIKit/Spinner";
import Button from "../../Common/UIKit/Form/Button";
import {createSyncTask, getPendingProducts, getSyncTasks} from "../../../api/product";
import {withRouter} from "react-router-dom";


const SyncListPage = withRouter(({history}) => {

    const {syncTasks, setSyncTasks} = useContext(AppContext);

    const [loading, setLoading] = useState(false);
    const [pendingProducts, setPendingProducts] = useState(null);
    const [fetchingPendingProducts, setFetchingPendingProducts] = useState(false);

    const fetchPendingProducts = () => {
        if (fetchingPendingProducts) return;
        setFetchingPendingProducts(true);
        getPendingProducts().then(response => {
            setPendingProducts(response.results);
        });
    }

    const fetchSyncTasks = useCallback((ignoreLoading, callback) => {
        if (!ignoreLoading) setLoading(true);
        getSyncTasks().then(response => {
            setSyncTasks(response.results);
            if (!ignoreLoading) setLoading(false);
            if (callback) callback();
        });
    }, [setSyncTasks]);

    useEffect(() => {
        if (syncTasks === null) fetchSyncTasks();
        if (pendingProducts === null) fetchPendingProducts();
    });

    useEffect(() => {
        if (syncTasks === null) fetchSyncTasks();
    }, [syncTasks, fetchSyncTasks]);

    const startSyncing = () => {
        createSyncTask().then(() => {
            setSyncTasks(null);
        });
    }

    if (loading || syncTasks === null) {
        return <div className="c-panel-content"><PageLoading/></div>
    }

    return <div className="c-panel-content">
        <div>
            {
                <div className="c-table c-table--sm">
                    <div className="c-table-filter">
                        <div className="c-table-filter__item">
                            <Button
                                onClick={startSyncing}>
                                Sync products
                            </Button>
                        </div>
                        {
                            pendingProducts?.length > 0 && (
                                <div className="c-table-filter__item">
                                    <Button
                                        onClick={() => {
                                            history.push("/sync/process");
                                        }}>
                                        Process new products
                                        ({pendingProducts.length}{pendingProducts.length === 100 ? "+" : ""})
                                    </Button>
                                </div>
                            )
                        }
                        <div className="c-table-filter__item-right">
                            <Button
                                color="info"
                                onClick={() => {
                                    setSyncTasks(null);
                                    fetchPendingProducts();
                                }}>
                                <i className="fa fa-sync"/>
                            </Button>
                        </div>
                    </div>
                    {
                        syncTasks.length > 0 ? (
                            <table>
                                <thead>
                                <tr>
                                    <th>Start time</th>
                                    <th>Progress</th>
                                    <th>Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    syncTasks.map(record => (
                                        <tr key={record.id}>
                                            <td>
                                                {record.creation_time} UTC
                                            </td>
                                            <td>
                                                {record.progress}%
                                            </td>
                                            <td>
                                                {record.status}
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        ) : (
                            <NotFoundData icon="fas fa-shopping-basket" title="Products not synced yet"/>
                        )
                    }
                </div>
            }
        </div>
    </div>
});

export default SyncListPage;
