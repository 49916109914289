import {isNode} from '../utilities/Environment';
import dotenv from 'dotenv';

if (isNode) console.info({E: dotenv.config()});

export const ReactAppBaseAPIUrl = process.env.REACT_APP_BASE_API_URL;
export const ReactAppBaseNodeApiUrl = isNode && process.env.REACT_APP_BASE_NODE_API_URL;
export const ApiUrl = isNode ? ReactAppBaseNodeApiUrl : ReactAppBaseAPIUrl;
export const PER_PAGE = 10;

export const ApiRoutes = {
    user: {
        csrf: '/user/get-cookie/',
        sendVerification: '/user/auth/resend-verification/',
        verify: '/user/auth/verify-identity/',
        changeEmail: '/user/change-email/',
        changeIdentity: '/user/auth/change-identity/',
        getProfile: '/user/profile/',
        updateProfile: '/user/profile/',
    },
    auth: {
        refresh: '/user/auth/login/refresh/',
        register: '/user/auth/register/',
        login: '/user/auth/login/',
        loginShopify: '/shopify/login/',
        finalize: '/shopify/finalize/',
        forgot: '/user/auth/reset-password/',
        forgotToken: '/user/auth/reset-password/validate_token',
        forgotConfirm: '/user/auth/reset-password/confirm/',
        changePassword: '/user/auth/change-password/',
    },
    product: {
        base: '/product/base/',
        sync: {
            list: '/product/sync/',
            create: '/product/sync/',
            import: '/product/import/',
        },
        suggest: '/product/suggest/{subject}/'
    },
    sizeChart: {
        list: '/size-chart/',
        create: '/size-chart/',
        get: '/size-chart/{id}/',
        update: '/size-chart/{id}/',
        delete: '/size-chart/{id}/',
        productMatch: '/size-chart/product-match/count/',
        listVerified: '/size-chart/verified/',
        image: '/size-chart/image/',
        import: '/size-chart/import/',
    },
    fabric: {
        list: '/size-chart/fabric/',
        get: '/size-chart/fabric/{id}/',
        create: '/size-chart/fabric/',
        update: '/size-chart/fabric/{id}/',
        delete: '/size-chart/fabric/{id}/',
    },
    report: {
        usage: '/report/usage/',
        popularProducts: '/report/popular-products/',
        recommendations: '/report/recommendation/',
        insight: {
            recommendation: '/report/insight/recommendation/',
        },
    },
    payment: {
        connect: '/payment/stripe/connect/',
        complete: '/payment/stripe/complete/',
        method: '/payment/stripe/payment-method/',
        subscription: {
            get: '/payment/subscription/get/',
            shopify: {
                details: '/payment/subscription/shopify/details/',
                cancel: '/payment/subscription/shopify/cancel/',
                subscribe: '/payment/shopify/subscription/subscribe/',
                subscribeEnterprise: '/payment/shopify/subscription/subscribe-enterprise/',
                update: '/payment/shopify/subscription/update/',
                activate: '/payment/shopify/subscription/activate/',
                pending: {
                    details: '/payment/subscription/shopify/pending/details/'
                },
            },
            stripe: {
                details: '/payment/subscription/stripe/details/',
            }
        },
        charge: {
            shopify: {
                get: '/payment/shopify/app-charge/get/',
                create: '/payment/shopify/app-charge/create/',
            }
        }
    },
    business: {
        order: {
            list: '/business/order/'
        },
        settings: {
            list: '/business/settings/'
        },
        integration: {
            validate: '/business/integration/validate/'
        },
        store: '/business/store/',
    },
    bi: {
        store: {
            count: '/report/bi/store/count/'
        },
        revenue: {
            estimate: '/report/bi/revenue/estimate/'
        },
        recommendation: {
            estimate: '/report/bi/recommendation/estimate/'
        },
    }
};

export const createURL = (url, params = {}) => {
    Object.keys(params).forEach(item => {
        url = url.replace(`{${item}}`, params[item]);
    });
    return url;
};

export const StatusCodes = {
    Success: 200,
    Created: 201,
    Deleted: 204,
    UnAuthorized: 401,
    Forbidden: 403,
    Error: 500,
    NotFound: 404,
    ExpiredToken: 402,
};

export const ApiErrorTypes = {
    TimeOut: 'Timeout',
    UnAuthorized: 'Not Authorized',
    UnKnown: 'Somehow Failed',
    ExpiredToken: 'Token Expired',
};

export const ApiMethods = {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    PATCH: 'patch',
    DELETE: 'delete'
};
