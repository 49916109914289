export const getCookies = () => {
    return (global?.document?.cookie || '')
        .split(';')
        .map(i => i.trim())
        .map(i => i.split('='))
        .reduce((acc, i) => {
            acc[i[0]] = i[1];
            return acc;
        }, {});
};

export const setCookie = (name, value, days = null) => {
    let expires = '';
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = `; expires=${date.toUTCString()}`;
    }
    global.document.cookie = `${name}=${value || ''}${expires}; path=/`;
}