import React, {useContext} from 'react';
import {AppContext} from "../../../context/providers";
import RecommendationsTable from "./RecommendationsTable";

const PopularProductsList = ({compactView}) => {

    const {business} = useContext(AppContext);
    const show = business?.subscription?.plan_name !== "free" && business?.subscription?.plan_name !== "basic";

    return <div className="c-list">
        <div className="c-list-title mb-3">
            Latest recommendations:
        </div>
        {
            show ? <RecommendationsTable compactView={compactView}/> :
                <div className="container text-center py-3">Premium plan required</div>
        }
    </div>
};

export default PopularProductsList;
