import React, {Fragment, useContext, useState} from 'react';
import {withRouter} from "react-router-dom";
import {AppContext} from "../../../../context/providers";
import * as classnames from "classnames";
import Button from "../Form/Button";
import {confirmAlert} from "react-confirm-alert";
import {saveBusinessSettings} from "../../../../api/business";

const AddAppEmbedInstruction = ({compact}) => {

    const {business, setupInstructions, setSetupInstructions} = useContext(AppContext);
    const {
        plan_selected,
        size_charts_added,
        app_embed_added,
    } = setupInstructions || {};

    const isCurrentStep = plan_selected && size_charts_added;

    const handleDone = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setSetupInstructions({app_embed_added: true});
        saveBusinessSettings({app_embed_added: true}).then(() => {});
    }

    return <div className={classnames("c-card-item c-card-body", {
            "c-card-item--disabled": app_embed_added,
            "c-card-item--clickable": !app_embed_added
        }
    )} onClick={() => {
        confirmAlert({
            title: "",
            message: "You should enable Robosize's App Embed Block in you Shopify's admin panel. " +
                "You can watch a video on how to do that or just do it if you know how.",
            buttons: [
                {
                    label: 'Watch video',
                    onClick: () => {
                        window.open('https://youtu.be/iySC3EnEKRw', '_blank').focus();
                    }
                },
                {
                    label: 'View Shopify Admin',
                    onClick: () => {
                        window.open(`https://${business.domain}/admin`, '_blank').focus();
                    }
                },
                {
                    label: 'Cancel',
                }
            ]
        })
    }}>
        <b>Step 3: </b>
        Enable RoboSize's App Block in your theme
        {
            isCurrentStep && (
                <Fragment>
                    {
                        app_embed_added ?
                            <span className="fa fa-lg fa-check-circle float-right ml-2 text-success"></span> : (
                                <Button color="success" xs={compact} className="d-inline-block ml-2 float-right"
                                        onClick={handleDone}>
                                    Mark as done
                                </Button>
                            )
                    }
                </Fragment>
            )
        }
    </div>

};

export default AddAppEmbedInstruction;