import React, {useState} from 'react';
import {Editor} from "react-draft-wysiwyg";
import {ContentState, convertToRaw, EditorState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {blockIcons, blockInit} from "../Constants";
import * as classnames from "classnames";
import capitalize from "@material-ui/core/utils/capitalize";

export default ({
                    addBlock
                }) => {

    return <div className="c-chart-layout row px-3">
        {
            Object.keys(blockInit).map((init, index) => (
                <div key={index} className="col-4 col-md-3 px-0">
                    <div className="c-card-item c-card-body c-card-item--clickable text-center"
                         onClick={() => {
                             const block = blockInit[init];
                             addBlock(block);
                         }}
                    >
                        <div className="container px-0">
                            <span className={`c-chart-layout__block fa ${blockIcons[init]}`}/>
                        </div>
                        <div className="container px-0 pt-2">
                            {capitalize(init)}
                        </div>
                    </div>
                </div>
            ))
        }
    </div>

};
