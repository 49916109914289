import React, {useState, useContext} from 'react';
import {GENDER, LENGTH_UNIT, UNIT_METHOD} from "../BodyShape/Constants";
import {localize} from "../../i18n";
import {getOrderedKeys} from "../../utils/common";
import {AppConfig} from "../../context";

export default ({sizeChartData, defaultUnit = null, isTable = false}) => {

    const config = useContext(AppConfig);

    const roundMeasureMultiplier = config.sizeChart.roundTo || 0.5;

    const sizes = getOrderedKeys(sizeChartData);

    const measures = sizes.length > 0 ? getOrderedKeys(sizeChartData[sizes[0]]) : [];
    const [unit, setUnit] = useState(defaultUnit || UNIT_METHOD.METRIC);
    const [focus, setFocus] = useState(null);


    const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);
    const roundMeasure = v => Math.round(Math.round(v * 100) / 100 / roundMeasureMultiplier) * roundMeasureMultiplier;

    const mapMeasure = measure => {
        if (measure === "bust" && config.interview.forceGender === GENDER.MALE) {
            return "chest";
        }
        return measure.replace("_", " ");
    }

    return <div className="if_fit-container">
        <div className="if_container if_mb-3">
            {
                !isTable && <div className="if_row if_mb-3">
                    <div className="if_container if_px-3">
                        <div className="if_unit-wrap">
                            <div
                                className={`if_btn ${unit === UNIT_METHOD.METRIC ? 'if_btn-primary' : 'if_btn-light'}`}
                                onClick={() => {
                                    if (unit !== UNIT_METHOD.METRIC) {
                                        setUnit(UNIT_METHOD.METRIC);
                                    }
                                }}
                            >{LENGTH_UNIT.CM}</div>
                            <div
                                className={`if_btn ${unit === UNIT_METHOD.IMPERIAL ? 'if_btn-primary' : 'if_btn-light'}`}
                                onClick={() => {
                                    if (unit !== UNIT_METHOD.IMPERIAL) {
                                        setUnit(UNIT_METHOD.IMPERIAL);
                                    }
                                }}
                            >{LENGTH_UNIT.INCH}</div>
                        </div>
                    </div>
                </div>
            }
            <div className="if_row">
                <div className="if_container if_px-3 if_table-row">
                    <table className="if_table">
                        <thead>
                        <tr>
                            <th></th>
                            {
                                measures.map((measure, colIndex) => (
                                    <th
                                        key={colIndex}
                                        className={`${focus && focus[1] === colIndex ? "if_highlight" : ""}`}
                                    >
                                        {capitalize(localize(mapMeasure(measure)))}
                                    </th>
                                ))
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {
                            sizes.map((size, rowIndex) => (
                                <tr key={rowIndex}>
                                    <td
                                        onMouseEnter={() => {
                                            setFocus([rowIndex, -1]);
                                        }}
                                        onMouseLeave={() => {
                                            if (focus && focus[0] === rowIndex && focus[1] === -1) setFocus(null);
                                        }}
                                        className={`${focus && focus[0] === rowIndex ? "if_highlight" : ""}`}
                                    >
                                        {size}
                                    </td>
                                    {
                                        measures.map((measure, colIndex) => (
                                            <td
                                                onMouseEnter={() => {
                                                    setFocus([rowIndex, colIndex]);
                                                }}
                                                onMouseLeave={() => {
                                                    if (focus && focus[0] === rowIndex && focus[1] === colIndex) setFocus(null);
                                                }}
                                                key={colIndex}
                                                className={`${focus && (focus[0] === rowIndex || focus[1] === colIndex) ? "if_highlight" : ""}`}
                                            >
                                                {
                                                    isTable ? sizeChartData[size][measure] : (
                                                        unit === UNIT_METHOD.METRIC || ["height", "weight"].includes(measure) ?
                                                            roundMeasure(sizeChartData[size][measure]) :
                                                            roundMeasure(sizeChartData[size][measure] / 2.54)
                                                    )
                                                }
                                            </td>
                                        ))
                                    }
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
};
