import React, {useContext, useMemo, useState} from "react";
import {AppContext} from "../../../context/providers";
import Modal from "../../Common/UIKit/Modal";
import Select from "../../Common/UIKit/Form/Select";
import {handleApiSave} from "../../../utilities/useApi";
import {updateProducts} from "../../../api/product";
import toaster from "toasted-notes";
import useFetchSizeCharts from "../../../hooks/useFetchSizeCharts";


const AssignSizeChartModal = ({
                                  products, onClose, onSave
                              }) => {


    const {setProducts} = useContext(AppContext);
    const [selectedSizeChart, setSelectedSizeChart] = useState(null);
    const [saveLoading, setSaveLoading] = useState(false);

    const {sizeCharts, loading: loadingSizeCharts} = useFetchSizeCharts({limit: Infinity})

    const sizeChartOptions = useMemo(() => {
        const options = [{
            value: null,
            label: "--"
        }];
        if (sizeCharts === null) return options;
        options.push(...sizeCharts.map(chart => ({
            value: chart.id,
            label: chart.brand_name
        })));
        return options;
    }, [sizeCharts]);

    const handleSave = () => {
        handleApiSave(
            updateProducts,
            null,
            {
                products,
                size_chart: selectedSizeChart
            },
            null,
            {
                loadingFn: setSaveLoading,
            }
        ).then(() => {
            setProducts(null);
            onClose();
            onSave();
            toaster.notify(() => <div
                className="alert alert-success">{`Product ${products.length > 1 ? "size charts" : "size chart"} updated`}</div>);
        });
    };

    return <Modal show
                  auto
                  onClose={onClose}
                  onSave={handleSave}
                  saveLoading={saveLoading}
                  title={`Updating ${products.length} ${products.length === 1 ? "product" : "products"}`}
    >
        <div className="px-3 py-3 c-modal-drop-down">
            <Select
                id="size_charts"
                label="Size chart"
                options={sizeChartOptions}
                onChangeValue={(e) => {
                    setSelectedSizeChart(e)
                }}
                maxMenuHeight={200}
                placeholder={loadingSizeCharts ? "Loading Size Charts List..." : "Search Size Chart"}
                isSearchable
                menuPlacement="bottom"
                isLoading={loadingSizeCharts}
                isDisabled={loadingSizeCharts}
            />
        </div>
    </Modal>
};

export default AssignSizeChartModal;