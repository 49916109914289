import {BOTTOMS, DRESS, JACKET, SHIRT, SHORTS, T_SHIRT} from "../../../../assets/Images";
import {measures} from "../Constants";

export const MERGED_SIZE_CHART = {
    "XS": {
        [measures.BUST]: 86.36,
        [measures.WAIST]: 69.85,
        [measures.HIP]: 92.71,
        [measures.INSIDE_LEG]: 74.93,
        [measures.NECK]: 38.1,
        [measures.SLEEVE]: 64.77,
        [measures.LENGTH]: 74.93,
    },
    "S": {
        [measures.BUST]: 95.25,
        [measures.WAIST]: 77.47,
        [measures.HIP]: 100.33,
        [measures.INSIDE_LEG]: 75.69,
        [measures.NECK]: 39.37,
        [measures.SLEEVE]: 66.04,
        [measures.LENGTH]: 76.2,
    },
    "M": {
        [measures.BUST]: 104.14,
        [measures.WAIST]: 88.9,
        [measures.HIP]: 109.22,
        [measures.INSIDE_LEG]: 76.2,
        [measures.NECK]: 40.64,
        [measures.SLEEVE]: 67.31,
        [measures.LENGTH]: 77.47,
    },
    "L": {
        [measures.BUST]: 114.3,
        [measures.WAIST]: 101.6,
        [measures.HIP]: 119.38,
        [measures.INSIDE_LEG]: 77.47,
        [measures.NECK]: 44.45,
        [measures.SLEEVE]: 69.85,
        [measures.LENGTH]: 78.74,
    },
    "XL": {
        [measures.BUST]: 124.46,
        [measures.WAIST]: 116.84,
        [measures.HIP]: 129.54,
        [measures.INSIDE_LEG]: 78.74,
        [measures.NECK]: 44.45,
        [measures.SLEEVE]: 73.66,
        [measures.LENGTH]: 80,
    },
    "_order": ["XS", "S", "M", "L", "XL"]
}

export const CLOTH_TYPES = [
    {
        name: "Tops",
        image: T_SHIRT,
        dimensions: [measures.BUST, measures.WAIST, measures.HIP, measures.LENGTH],
    },
    {
        name: "Bottoms",
        image: BOTTOMS,
        dimensions: [measures.WAIST, measures.HIP, measures.INSIDE_LEG],
    },
    {
        name: "Dress",
        image: DRESS,
        dimensions: [measures.BUST, measures.WAIST, measures.HIP],
    },
    {
        name: "Shirts",
        image: SHIRT,
        dimensions: [measures.BUST, measures.WAIST, measures.NECK, measures.SLEEVE, measures.LENGTH],
    },
    {
        name: "Jacket",
        image: JACKET,
        dimensions: [measures.BUST, measures.WAIST, measures.HIP],
    },
    {
        name: "Shorts",
        image: SHORTS,
        dimensions: [measures.WAIST, measures.HIP],
    },
]