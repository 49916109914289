import {callApi} from "../utilities/ApiHandler";
import {ApiMethods, ApiRoutes, createURL} from "../config/apiConfig";

const {GET} = ApiMethods;

export const getStoreCount = async (start, end) => {
    const response = await callApi({
        method: GET,
        url: ApiRoutes.bi.store.count,
        params: {start, end},
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const getRevenueEstimate = async (start, end) => {
    const response = await callApi({
        method: GET,
        url: ApiRoutes.bi.revenue.estimate,
        params: {start, end},
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};


export const getRecommendationEstimate = async (start, end) => {
    const response = await callApi({
        method: GET,
        url: ApiRoutes.bi.recommendation.estimate,
        params: {start, end},
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

