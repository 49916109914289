import React, {useState} from 'react';
import {PageLoading} from "../../Common/UIKit/Spinner";
import {finalize} from "../../../api/auth";

let finalizing = false;

const FinalizePage = () => {

    if (!finalizing) {
        finalizing = true;
        finalize(window.location.search).then(response => {
            window.location.replace("/");
        }).catch(reason => {
            console.error(reason);
        });
    }

    return <PageLoading/>;
};

export default FinalizePage;
