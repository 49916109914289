import React, {Fragment, useContext, useState} from 'react';
import Tab from "../../../Common/UIKit/Tab";
import {CLOTH_TYPES, MERGED_SIZE_CHART} from "./CreateChartConstants";
import {roundToTwoDigits} from "../../../../utilities/number";
import UploadFileInput from "../../../Common/UIKit/UploadFileBox";
import Button from "../../../Common/UIKit/Form/Button";
import {handleApiSave} from "../../../../utilities/useApi";
import {saveSizeChartImport} from "../../../../api/common";
import toaster from "toasted-notes";
import {BoxSpinner} from "../../../Common/UIKit/Spinner";
import {deepAssign, getOrderedKeys} from "../../../../fit_widget/src/utils/common";
import {AppContext} from "../../../../context/providers";
import {createCharge} from "../../../../api/payment";
import Modal from "../../../Common/UIKit/Modal";

const CreateChart = ({newSizeChart, setNewSizeChart, setShowCreateSetup}) => {

    const {business, setBusiness} = useContext(AppContext);

    const tabs = ['Template charts', 'Import charts'];
    const [tabIndex, setTabIndex] = useState(0);
    const [files, setFiles] = useState([]);
    const [uploading, setUploading] = useState(false);

    const [showPurchaseModal, setShowPurchaseModal] = useState(false);
    const [comment, setComment] = useState('');


    const handleSelectType = (cloth_type) => {
        const data = {};
        getOrderedKeys(MERGED_SIZE_CHART).forEach(size => {
            const sizeData = {};
            cloth_type.dimensions.forEach(dim => {
                sizeData[dim] = roundToTwoDigits(MERGED_SIZE_CHART[size][dim] / 2.54);
            });
            data[size] = sizeData;
        });
        setNewSizeChart(Object.assign({}, newSizeChart, {data}));
        setShowCreateSetup(false);
    }


    const renderCreate = () => {
        return <div className="container-fluid">
            <div className="row">
                {CLOTH_TYPES.map((cloth_type, index) => (
                    <div key={index} className="col-4 col-md-2 px-0">
                        <div className="c-card py-1">
                            <div className="c-card-item c-card-body c-card-item--clickable text-center" onClick={() => {
                                handleSelectType(cloth_type);
                            }}>
                                <img className="img-fluid c-chart-layout-image" src={cloth_type.image}/>
                                <div className="container pt-3 px-0">
                                    {cloth_type.name}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    }

    const handleChangeFile = ({target}) => {
        setFiles(Array.from(target.files));
    }

    const handleSubmit = () => {
        const formData = new FormData();
        formData.append("file", files[0]);
        formData.append("comment", comment);
        setUploading(true);
        handleApiSave(
            saveSizeChartImport,
            null,
            formData,
            null,
        ).then(res => {
            setBusiness(deepAssign({}, business, {import_chart_credit: business.import_chart_credit - 1}));
            toaster.notify(() => <div className="alert alert-success mt-4">
                File uploaded. You will receive an email once the chart is processed.
            </div>);
            setFiles([]);
            setUploading(false);
        }).catch(err => {
            setUploading(false);
        });
    }

    const renderImport = () => {
        return <div className="row">
            <div className="container-fluid px-0 py-3 text-center">
                You have {business?.import_chart_credit} credits left.
                <Button xs className="d-inline ml-2" onClick={() => {
                    setShowPurchaseModal(true);
                }}>Buy more</Button>
            </div>
            <div className="col-sm-2"></div>
            <div className="col-sm-4">
                <div className="d-inline-flex">
                    {
                        files?.length > 0 && <Fragment>
                            {
                                uploading ? <BoxSpinner/> : (
                                    <Fragment>
                                        <div className="text-center py-3">
                                            <Button color="primary" onClick={handleSubmit}>
                                                Submit file
                                            </Button>
                                        </div>
                                        {
                                            files.map((file, index) => (
                                                <div key={index} className="py-4 ml-3">
                                                    {file.name}
                                                </div>
                                            ))
                                        }
                                    </Fragment>
                                )
                            }
                        </Fragment>
                    }
                </div>

                <label>Select files to import</label>
                <UploadFileInput
                    onChange={handleChangeFile}
                    accept="image/x-png,image/jpg,image/jpeg/,image/gif,application/pdf,image/x-eps"
                />
            </div>
            <div className="col-sm-4 pt-4">
                <label>Comment:</label>
                <textarea
                    className="form-control"
                    onChange={({target: {value}}) => {
                        setComment(value);
                    }}
                    value={comment}
                />
            </div>
        </div>
    }

    const renderTabContent = () => {
        switch (tabIndex) {
            case 0:
                return renderCreate();
            case 1:
                return renderImport();
            default:
                return renderCreate();
        }
    }

    const renderPurchaseCreditModal = () => {
        return <Modal
            show
            auto
            saveBtnText="Buy credits"
            onClose={() => {
                setShowPurchaseModal(false);
            }}
            onSave={() => {
                createCharge().then(resp => {
                    if (resp.confirm_url) {
                        window.location.replace(resp.confirm_url);
                    } else {
                        toaster.notify(() => <div className="alert alert-danger mt-4">
                            Could not request import credit purchase. If the problem persists, please contact
                            support.
                        </div>);
                    }
                })
            }}
        >
            <div className="container-fluid pt-3">
                You can purchase 10 credits for $10
            </div>
        </Modal>
    }


    return <div className="c-panel-content">
        {showPurchaseModal && renderPurchaseCreditModal()}
        <Tab tabs={tabs}
             tabClassName="py-3"
             tabHeadClassName={'p-0'}
             onSelect={setTabIndex}
             selected={tabIndex}
        />
        {renderTabContent()}
    </div>
}

export default CreateChart;