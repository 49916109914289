import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import DDatePicker from 'react-datepicker';
import {Controller} from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import FormGroup from "./FormGroup";
import {MonthNames, YearsRange} from "../../../../utilities/DateHelpers";

export const ControlDatePicker = ({ control, name, rules, defaultValue, id, onChangeValue, ...dateProps }) => (
    <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={defaultValue}
        render={({ onChange, onBlur, value }, { invalid }) => (
            <DatePicker id={id ? id : name}
                        onChange={val => {
                            onChange(val);
                            if(onChangeValue) onChangeValue(val);
                        }}
                        onBlur={onBlur}
                        error={!!invalid}
                        selected={value}
                        {...dateProps}
            />
        )}
    />
);

ControlDatePicker.propTypes = {
    id: PropTypes.string,
    control: PropTypes.any.isRequired,
    name: PropTypes.any,
    rules: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    defaultValue: PropTypes.any,
    formGroup: PropTypes.bool,
    description: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    errorMessage: PropTypes.string,
    onChangeValue: PropTypes.func,
};

const DatePickerHeader = props => {
    const years = YearsRange;
    const months = MonthNames;
    return (
        <div className={'l-date-picker-custom'}>
            <button onClick={props.decreaseMonth}
                    type="button"
                    className={'l-date-picker__arrow-prev'}
                    disabled={props.prevMonthButtonDisabled}><i className="fas fa-chevron-left" /></button>
            <select value={props.date.getFullYear()}
                    onChange={({ target: { value } }) => props.changeYear(value)}>
                {years.map(option => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>

            <select value={months[props.date.getMonth()]}
                    onChange={({ target: { value } }) => props.changeMonth(months.indexOf(value))}>
                {months.map(option => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>

            <button onClick={props.increaseMonth}
                    type="button"
                    className={'l-date-picker__arrow-next'}
                    disabled={props.nextMonthButtonDisabled}><i className="fas fa-chevron-right" /></button>
        </div>
    )
};

const DatePicker = ({ id, label, iconLabel, arrowLabel, formGroup, selected, placeholder, dateFormat, customHeader, onChange, error, errorMessage, className, ...otherProps }) => {
    const renderDatePicker = (
        <>
            <div className={classnames('l-date-picker-container', className)}>
                {
                    iconLabel && (
                        <label htmlFor={id} className={'l-date-picker__label'}>
                            <i className="fas fa-calendar-alt" />
                        </label>
                    )
                }
                <DDatePicker
                    id={id}
                    placeholderText={placeholder}
                    dateFormat={dateFormat}
                    renderCustomHeader={customHeader ? DatePickerHeader : undefined}
                    selected={selected}
                    onChange={onChange}
                    {...otherProps}
                />
                {arrowLabel && (
                    <label htmlFor={id} className="l-date-picker__label">
                        <i className="fas fa-chevron-down" />
                    </label>
                )}
            </div>
        </>
    );

    return formGroup ? (
        <FormGroup id={id} label={label} error={error} errorMessage={errorMessage}>
            {renderDatePicker}
        </FormGroup>
    ) : renderDatePicker;
};

DatePicker.propTypes = {
    id: PropTypes.string,
    formGroup: PropTypes.bool,
    customHeader: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string,PropTypes.object]),
    selected: PropTypes.any,
    placeholder: PropTypes.string,
    dateFormat: PropTypes.string,
    className: PropTypes.any,
    onChange: PropTypes.func,
};

DatePicker.defaultProps = {
    id: null,
    formGroup: true,
    customHeader: true,
    iconLabel: true,
    arrowLabel: true,
    label: '',
    selected: new Date(),
    placeholder: 'Select Date',
    dateFormat: 'yyyy/MM/dd',
};

export default DatePicker;
