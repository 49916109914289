/**
 *
 * @param start {number}
 * @param end {number}
 * @param step {number}
 * @returns {array} "Monday, Jan 18 2021"
 */
export const range = (start, end, step = 1) => {
    let range = [];
    let typeofStart = typeof start;
    let typeofEnd = typeof end;

    if (step === 0) {
        throw TypeError("Step cannot be zero.");
    }

    if (typeofStart === "undefined" || typeofEnd === "undefined") {
        throw TypeError("Must pass start and end arguments.");
    } else if (typeofStart !== typeofEnd) {
        throw TypeError("Start and end arguments must be of same type.");
    }

    typeof step === "undefined" && (step = 1);

    if (end < start) {
        step = -step;
    }

    if (typeofStart === "number") {

        while (step > 0 ? end >= start : end <= start) {
            range.push(start);
            start += step;
        }

    } else if (typeofStart === "string") {

        if (start.length !== 1 || end.length !== 1) {
            throw TypeError("Only strings with one character are supported.");
        }

        start = start.charCodeAt(0);
        end = end.charCodeAt(0);

        while (step > 0 ? end >= start : end <= start) {
            range.push(String.fromCharCode(start));
            start += step;
        }

    } else {
        throw TypeError("Only string and number types are supported");
    }

    return range;
};

export const roundToDigit = (value, digits, doRound) => {
    const roundedValue = Math.round(value * 10 ** digits) / 10 ** digits;
    if (doRound && (Math.abs(Math.round(roundedValue) - roundedValue) <= 0.1 ** digits * 2)) {
        return Math.round(roundedValue);
    }
    return roundedValue;
};

export const roundToTwoDigits = value => roundToDigit(value, 2, false);
export const roundToTwoDigitsWithRounding = value => roundToDigit(value, 2, true);
