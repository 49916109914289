import React from 'react';
import CoreLink from "@material-ui/core/Link";


const Link = (props) => {
    return <div className="c-link">
        <CoreLink {...props} />
    </div>
}

export default Link;
