import React from 'react';
import {BLOCK_TYPE} from "../../../../fit_widget/src/components/SizeChart/Constants";
import ChartData from "../ChartData";
import ImageBlockEditor from "./ImageBlockEditor";
import TextBlockEditor from "./TextBlockEditor";
import TableBlockEditor from "./TableBlockEditor";
import AddBlock from "./AddBlock";
import VideoBlockEditor from "./VideoBlockEditor";

export default ({
                    block,
                    index,
                    saveBlock,
                    addBlock,
                    unit,
                    setUnit,
                    sizes,
                    measures,
                    dataErrors,
                    editSizeChartField,
                    newSizeChart,
                    setNewSizeChart
                }) => {

    const renderBlockEditor = () => {
        if (block === null || [BLOCK_TYPE.DIVIDER || BLOCK_TYPE.SIZE_CHART].includes(block.type)) {
            return <AddBlock addBlock={addBlock}/>
        }
        switch (block.type) {
            case BLOCK_TYPE.SIZE_CHART:
                return <ChartData
                    {...{
                        unit,
                        setUnit,
                        sizes,
                        measures,
                        dataErrors,
                        editSizeChartField,
                        newSizeChart,
                        setNewSizeChart,
                        isPreview: true,
                    }}
                />
            case BLOCK_TYPE.TABLE:
                return <TableBlockEditor
                    {...{
                        block,
                        index,
                        saveBlock,
                        unit,
                        setUnit,
                    }}
                />
            case BLOCK_TYPE.IMAGE:
                return <ImageBlockEditor
                    {...{
                        block,
                        index,
                        saveBlock,
                        sizeChart: newSizeChart
                    }}
                />
            case BLOCK_TYPE.VIDEO:
                return <VideoBlockEditor
                    {...{
                        block,
                        index,
                        saveBlock
                    }}
                />
            case BLOCK_TYPE.TEXT:
                return <TextBlockEditor
                    {...{
                        block,
                        index,
                        saveBlock
                    }}
                />
        }
    }

    return <div className="c-panel-content c-chart-layout">
        {renderBlockEditor()}
    </div>

};
