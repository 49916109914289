import React, {Fragment, useCallback, useContext, useEffect, useState} from 'react';
import {getRecommendations} from "../../../api/report";
import {BoxSpinner} from "../../Common/UIKit/Spinner";
import Button from "../../Common/UIKit/Form/Button";
import {AppContext} from "../../../context/providers";
import {capitalize} from "@material-ui/core";

const COMPACT_SIZE_RECENT_RECOMMENDATIONS_LENGTH = 5;
const RECOMMENDATIONS_LENGTH_LIMIT = 20;

const RecommendationsTable = ({compactView}) => {

    const {business} = useContext(AppContext);

    const [recommendations, setRecommendations] = useState(null);
    const [loading, setLoading] = useState(false);

    const visibleRecommendations = recommendations?.length > 0 ?
        recommendations.slice(0, Math.min(
                compactView ? COMPACT_SIZE_RECENT_RECOMMENDATIONS_LENGTH : RECOMMENDATIONS_LENGTH_LIMIT,
                recommendations.length
            )
        ) : [];

    const fetchRecommendations = useCallback(() => {
        if (loading) return;
        setLoading(true);
        getRecommendations().then(response => {
            setRecommendations(response.results);
            setLoading(false);
        });
    }, [loading]);

    useEffect(() => {
        if (recommendations === null) fetchRecommendations();
    }, [recommendations, fetchRecommendations]);

    return <Fragment>
        {
            loading || recommendations === null ? <BoxSpinner/> : (
                <Fragment>
                    <div className="c-table c-table--bordered">
                        <table>
                            <thead>
                            <tr>
                                <th>Product</th>
                                <th>Customer info</th>
                                <th>Date</th>
                                {business?.store_type === "shopify" && <th>Link</th>}
                            </tr>
                            </thead>
                            <tbody>
                            {
                                visibleRecommendations.map((record, index) => {
                                    const {
                                        product, recommendation, ...data
                                    } = record.data;
                                    const visibleData = ["gender", "height", "weight", "age"].filter(key => !!data[key]);
                                    return <tr key={index}>
                                        <td>{record.product.name}</td>
                                        <td>
                                            <div>
                                                {
                                                    visibleData.map(key =>
                                                        <Fragment key={key}><b>{capitalize(key)}</b>: {data[key]}<br/></Fragment>)
                                                }
                                                {
                                                    recommendation?.best_size && (
                                                      <Fragment><b>Recommended size</b>: {recommendation.best_size}<br/></Fragment>
                                                  )
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            {new Date(record.creation_time).toLocaleDateString()}
                                        </td>
                                        {business.store_type === "shopify" && <td>
                                            <a href={`https://${business.domain}/products/${record.product.handle}`}
                                               target="_blank">View</a>
                                        </td>}
                                    </tr>
                                })
                            }
                            {
                                visibleRecommendations.length === 0 && (
                                    <tr>
                                        <td colSpan={4} className="text-center">No data available</td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                    {
                        compactView && visibleRecommendations?.length < COMPACT_SIZE_RECENT_RECOMMENDATIONS_LENGTH && (
                            <div className="container pt-4 pb-3 text-center">
                                <Button color="light" link="/report" className="d-inline">
                                    View all <span className="fa fa-chevron-right"></span>
                                </Button>
                            </div>
                        )
                    }
                </Fragment>
            )
        }
    </Fragment>
};

export default RecommendationsTable;
