import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {AppContext} from "../../../context/providers";
import {handleApiSave} from "../../../utilities/useApi";
import {PageLoading} from "../../Common/UIKit/Spinner";
import {getProductDetails, updateProducts} from "../../../api/product";
import Button from "../../Common/UIKit/Form/Button";
import Select from "../../Common/UIKit/Form/Select";
import {withRouter} from "react-router-dom";
import Link from "@material-ui/core/Link";
import toaster from "toasted-notes";
import {getFabrics} from "../../../api/fabric";
import useFetchSizeCharts from "../../../hooks/useFetchSizeCharts";
import TextBlockEditor from "../SizeChart/Layout/TextBlockEditor";

const EditProductPage = withRouter(({history, match}) => {

    const {setProducts, fabrics, setFabrics, business} = useContext(AppContext);

    const [product, setProduct] = useState(null);
    const [fitToughness, setFitToughness] = useState(null);
    const [sizeChartId, setSizeChartId] = useState(null);
    const [fabricId, setFabricId] = useState(null);
    const [comment, setComment] = useState("");
    const [status, setStatus] = useState(null);
    const [isDirty, setIsDirty] = useState(false);
    const [loadingProduct, setLoadingProduct] = useState(false);
    const [loadingFabrics, setLoadingFabrics] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);

    const {sizeCharts, loading: loadingSizeCharts} = useFetchSizeCharts({limit: Infinity})

    const fetchProduct = useCallback(() => {
        setLoadingProduct(true);
        getProductDetails(match.params.id).then(products => {
            const product = products.results[0];
            setProduct(product);
            setComment(product.comment);
            setFitToughness(product.fit_toughness);
            setSizeChartId(product.size_chart?.id);
            setFabricId(product.fabric?.id);
            setComment(product.comment);
            setStatus(product.status);
            setIsDirty(false);
            setLoadingProduct(false);
        });
    }, [match?.params?.id]);

    const fetchFabrics = useCallback(() => {
        if (loadingFabrics) return;
        setLoadingFabrics(true);
        getFabrics().then(data => {
            setFabrics(data.results);
            setLoadingFabrics(false);
        });
    }, [loadingFabrics, setFabrics]);

    const handleSave = useCallback(() => {
        handleApiSave(
            updateProducts,
            null,
            {
                products: [product.id],
                size_chart: sizeChartId,
                fabric: fabricId,
                fit_toughness: fitToughness,
                comment: comment,
                status: status
            },
            null,
            {
                loadingFn: setSaveLoading,
            }
        ).then(() => {
            setProduct(null);
            setProducts(null);
            toaster.notify(() => <div className="alert alert-success">Product updated</div>);
        });
    }, [comment, fabricId, fitToughness, product?.id, setProducts, sizeChartId, status]);

    const fabricOptions = useMemo(() => fabrics === null ? [] : fabrics.map(fabric => ({
        value: fabric.id,
        label: <>{fabric.name}</>
    })), [fabrics]);

    const sizeChartOptions = useMemo(() => [{
        value: null,
        label: "--"
    }], []);
    if (sizeCharts && sizeChartOptions.length === 1) {
        sizeChartOptions.push(...sizeCharts.map(chart => ({
            value: chart.id,
            label: chart.brand_name
        })));
    }

    useEffect(() => {
        if (fabrics === null) fetchFabrics();
    }, [fabrics, fetchFabrics]);

    useEffect
    (() => {
        if (product === null) return;
        setIsDirty(
            fitToughness !== product.fit_toughness ||
            fabricId !== product.fabric?.id ||
            sizeChartId !== product.size_chart?.id ||
            comment !== product.comment ||
            status !== product.status
        );
    }, [fitToughness, fabricId, sizeChartId, comment, status, product]);

    useEffect(() => {
        if (product !== null || loadingProduct) return;
        fetchProduct();
    }, [product, fetchProduct, loadingProduct]);

    if (product === null || sizeCharts === null || fitToughness === null) {
        return <div className="c-panel-content"><PageLoading/></div>
    }

    return <div className="c-panel-content">
        <div className="c-table-action">
            <Link
                className="c-table-filter__item"
                onClick={() => {
                    history.push(`/product`);
                }}>
                <Button color="light">
                    All products
                </Button>
            </Link>
            <div className="c-table-filter__item-right">
                <Button
                    loading={saveLoading}
                    disabled={!isDirty}
                    onClick={handleSave}>
                    Save
                </Button>
            </div>
        </div>
        <div className="c-table c-table--sm c-table-details">
            <table>
                <thead>
                <tr>
                    <th/>
                    <th>Name</th>
                    {/*<th>Status</th>*/}
                    <th>Fabric</th>
                    {/*<th>Fabric Stretch</th>*/}
                    <th>Size chart</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="text-center">
                        {product.image_md && <img className="img" src={product.image_md} alt=""/>}
                    </td>
                    <td>
                        {product.name}
                    </td>
                    {/*<td>*/}
                    {/*    <Checkbox*/}
                    {/*        label={status === "active" ? "Active" : status === "disabled" ? "Disabled" : "Pending process"}*/}
                    {/*        onChange={(s) => {*/}
                    {/*            setStatus(s ? "active" : "disabled");*/}
                    {/*        }}*/}
                    {/*        checked={status === "active"}*/}
                    {/*    />*/}
                    {/*</td>*/}
                    <td className="w-25">
                        <Select id="fabric"
                                options={fabricOptions}
                                onChangeValue={fabricId => {
                                    setFabricId(fabricId);
                                    setFitToughness(fabrics.filter(fabric => fabric.id === fabricId)[0].fit_toughness);
                                }}
                                defaultValue={null}
                                value={fabricId}/>
                    </td>
                    {/*<td>*/}
                    {/*    <Select id="fit-toughness"*/}
                    {/*            options={fitToughnessOptions}*/}
                    {/*            onChangeValue={(v) => setFitToughness(10 - v)}*/}
                    {/*            defaultValue={null}*/}
                    {/*            value={10 - fitToughness}/>*/}
                    {/*</td>*/}
                    <td className="w-25">
                        <Select id="size-chart"
                                options={sizeChartOptions}
                                onChangeValue={setSizeChartId}
                                defaultValue={null}
                                value={sizeChartId}
                                isSearchable
                                placeholder={loadingSizeCharts ? "Loading Size Charts List..." : "Search Size Chart"}
                                isLoading={loadingSizeCharts}
                                isDisabled={loadingSizeCharts}
                        />
                    </td>
                </tr>
                </tbody>
            </table>
            {
                business?.settings?.products?.comment && (
                    <div className="c-form pt-3">
                        <div className="c-form__group">
                            <label className="c-form--label pl-3">Stylist comment:</label>
                            <div className="text-editor">
                                <TextBlockEditor
                                    index={0}
                                    block={{
                                        text: comment
                                    }}
                                    saveBlock={(block) => {
                                        setComment(block.text)
                                    }}
                                    hideTitle={true}
                                />
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    </div>
})

export default EditProductPage;
