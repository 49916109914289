import {APP_EMBED_ENABLE_SWITCH, APP_EMBED_THEME_CUSTOMIZE,} from "../../../../assets/Images";
import {SizeChartArticle} from "./SizeChart";

export const AppEmbedBlockArticle = {
    id: "how-to-enable-app-embed-block",
    is_popular: true,
    title: "How to enable app embed block?",
    body: [
        {
            tag: "p",
            body: "You can watch a tutorial video of this <a href='https://youtu.be/iySC3EnEKRw' target='_blank'>here</a>."
        },
        {
            tag: "h5",
            body: "Step 1:"
        },
        {
            tag: "p",
            body: "Make sure you have a product with a size chart. You can follow the instructions " +
                `<a href='/help/${SizeChartArticle.id}'>here</a> or watch ` +
                "<a href='https://youtu.be/otV-hlHVjNo' target='_blank'>this video</a>."
        },
        {
            tag: "h5",
            body: "Step 2:"
        },
        {
            tag: "p",
            body: "Go to your Shopify's admin panel, on the left panel click on <b>Online Store</b> > <b>Customize</b>"
        },
        {
            tag: "img",
            attrs: {
                "src": APP_EMBED_THEME_CUSTOMIZE
            }
        },
        {
            tag: "h5",
            body: "Step 3:"
        },
        {
            tag: "p",
            body: "Navigate to a product with a size chart that you have created in <b>Step 1</b>. On the left panel " +
                "click on <b>Theme settings</b> > <b>App Embeds</b>. Then turn the switch on next to " +
                "<b>Robosize Fit Advisor</b>."
        },
        {
            tag: "p",
            body: "<b>Note:</b> If you do not see the <b>App Embed Blocks</b> that means you are using an older theme, " +
                "and do not need to enable the App Embed Blocks."
        },
        {
            tag: "img",
            attrs: {
                "src": APP_EMBED_ENABLE_SWITCH
            }
        },
        {
            tag: "p",
            body: "Do not forget to hit save, and you should see the <b>Size chart</b> added to your page. " +
                "If you are using the free version the find my size button will not be present. " +
                "Congratulations! You have enabled the App Embed Block on your Theme."
        },
    ],
};