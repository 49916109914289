import React, {forwardRef} from 'react';
import RSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import {Controller} from "react-hook-form";
import PropTypes from 'prop-types';
import FormGroup from "./FormGroup";

export const ControlSelect = ({ control, name, rules, defaultValue, id, onChangeValue, ...selectProps }) => (
    <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={defaultValue}
        render={({ onChange, onBlur, value }, { invalid }) => (
            <Select {...selectProps}
                    id={id ? id : name}
                    onChangeValue={val => {
                        onChange(val);
                        if(onChangeValue) onChangeValue(val);
                    }}
                    onBlur={onBlur}
                    value={value}
                    error={!!invalid}
            />
        )}
    />
);

ControlSelect.propTypes = {
    id: PropTypes.string,
    control: PropTypes.any.isRequired,
    name: PropTypes.any,
    rules: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    defaultValue: PropTypes.any,
    formGroup: PropTypes.bool,
    description: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    errorMessage: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })
    ),
    onChangeValue: PropTypes.func,
};



// "react-select": "^3.1.1",
// const Select = ({ id, type, label, onChange, formGroup, placeholder, options, value, ...otherProps }) => {
const Select = forwardRef((props , ref) => {
    let {
        id,
        type,
        label,
        options,
        isSearchable,
        formGroup,
        value,
        defaultValue,
        description,
        error,
        errorMessage,
        className,
        onChange,
        onChangeValue,
        isClearable,
        ...otherProps
    } = props;
    const handleOnChange = e => {
        const value = Array.isArray(e) ? e.map(i => i.value) : e.value;
        onChangeValue(value);
        onChange(e);
    };
    const handleValue = value => {
        let item = null;
        if(Array.isArray(value)) {
            options.forEach(i => {
                if(item === null) {
                    if(i.options) item = i.options.filter(j => value.indexOf(j.value) > -1);
                    item = options.filter(j => value.indexOf(j.value) > -1)
                }
            });
            return item;
        }
        if(value && typeof value === 'object' && !!value.exact) {
            return value;
        }
        options.forEach(i => {
            if(item === null) {
                if(i.options) item = i.options.find(j => j.value === value);
                item = options.find(j => j.value === value)
            }
        });
        return item;
    };
    // const handleValue = value => options.find(i => i.value === value);
    const renderSelect = isClearable ? (
        <CreatableSelect
            id={id}
            className={`c-form__select ${className}`}
            classNamePrefix="select"
            onChange={handleOnChange}
            options={options}
            value={handleValue(value)}
            // value={value}
            ref={ref}
            // defaultValue={!!defaultValue ? handleValue(defaultValue) : undefined}
            // isDisabled={isDisabled}
            // isLoading={isLoading}
            // isClearable={isClearable}
            isSearchable={isSearchable}
            {...otherProps}
        />
    ) : (
        <RSelect
            id={id}
            className={`c-form__select ${className}`}
            classNamePrefix="select"
            onChange={handleOnChange}
            options={options}
            value={handleValue(value)}
            // value={value}
            ref={ref}
            // defaultValue={!!defaultValue ? handleValue(defaultValue) : undefined}
            // isDisabled={isDisabled}
            // isLoading={isLoading}
            // isClearable={isClearable}
            // maxMenuHeight={200}
            // menuPlacement="top"
            isSearchable={isSearchable}
            {...otherProps}
        />
    );
    return formGroup ? (
        <FormGroup label={label}
                   id={id}
                   description={description}
                   className={otherProps?.formClassName}
                   errorMessage={!!errorMessage ? errorMessage : ''}
                   error={error}>
            {renderSelect}
        </FormGroup>
    ) : renderSelect;
});

Select.propTypes = {
    id: PropTypes.string,
    formGroup: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })
    ),
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    formClassName: PropTypes.any,
    onChange:  PropTypes.func,
    onChangeValue:  PropTypes.func,
};

Select.defaultProps = {
    id: null,
    formGroup: true,
    isSearchable: false,
    label: '',
    options: [],
    error: false,
    errorMessage: null,
    className: '',
    onChange: () => {},
    onChangeValue: () => {}
};

export default Select;
