import React, {Fragment, useContext, useEffect, useState} from 'react';
import {AppContext} from "../../../context/providers";
import {BoxSpinner, DotsSpinner} from "../../Common/UIKit/Spinner";
import {withRouter} from "react-router-dom";
import {createCharge, getCharge, subscribeToShopifyEnterprise} from "../../../api/payment";
import {getAllQueryParams} from "../../../utilities/URI";
import Button from "../../Common/UIKit/Form/Button";
import toaster from "toasted-notes";

const ShopifyPaymentPage = withRouter(({history}) => {
    const {business} = useContext(AppContext);

    const chargeId = getAllQueryParams().id;

    if (!chargeId) {
        toaster.notify(() => <div className="alert alert-danger">
            Invalid link, please specify the charge ID.
        </div>);
        return <Fragment/>
    }

    const [charge, setCharge] = useState(null);
    const [loading, setLoading] = useState(false);
    const [processingPayment, setProcessingPayment] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (loading) return;
        if (charge === null && chargeId) {
            setLoading(true);
            getCharge(chargeId).then(charge => {
                setCharge(charge);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                setError(error);
            })
        }
    }, []);

    useEffect(() => {
        if (charge && business && charge?.business !== business?.id) {
            setError("This charge is not for your business. Please check the link first.");
        }
    }, [charge, business]);

    const handlePay = () => {
        setProcessingPayment(true);
        if (charge.charge_type === "subscription") {
            subscribeToShopifyEnterprise({
                id: charge.id,
            }).then(resp => {
                setProcessingPayment(false);
                if (resp.confirm_url) {
                    window.location.replace(resp.confirm_url);
                } else {
                    window.location.reload();
                }
            })
        } else {
            createCharge({
                id: charge.id,
                price: charge.price
            }).then(resp => {
                setProcessingPayment(false);
                if (resp.confirm_url) {
                    window.location.replace(resp.confirm_url);
                } else {
                    window.location.reload();
                }
            })
        }
    }

    return <div className="c-panel-content">
        <div className="container text-center mb-3">
            <h4>Application charge</h4>
        </div>
        <div className="container text-center">
            {
                charge === null ? (
                    <BoxSpinner/>
                ) : (
                    <>
                        {
                            charge.charge_type !== "subscription" && (
                                <>
                                    This is a one time charge for a service you have requested.
                                    <br/>
                                </>
                            )
                        }
                        <div className="pt-3">
                            {
                                error !== null ? (
                                    <div className="alert alert-danger">
                                        {error}
                                    </div>
                                ) : (
                                    <Fragment>
                                        <table className="table table-bordered">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <b>Service</b>
                                                </td>
                                                <td>
                                                    {charge.charge_type_display}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>Amount</b>
                                                </td>
                                                <td>
                                                    ${charge.price ? parseFloat(charge.price).toFixed(2) : "Unknown"}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <div className="container text-center py-3">
                                            <Button className="d-inline" color="primary"
                                                    onClick={handlePay}>
                                                {
                                                    processingPayment ?
                                                        <DotsSpinner/> : charge.charge_type === 'subscription' ? 'Subscribe' : 'Pay now'
                                                }
                                            </Button>
                                        </div>
                                    </Fragment>
                                )
                            }
                        </div>
                    </>
                )
            }
        </div>
    </div>

});

export default ShopifyPaymentPage;