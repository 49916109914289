import React, {Fragment, useCallback, useContext, useEffect, useState} from 'react';
import 'react-quill/dist/quill.snow.css';
import {AppContext} from "../../../context/providers";
import Button from "../../Common/UIKit/Form/Button";
import {PageLoading} from "../../Common/UIKit/Spinner";
import {getSizeCharts} from "../../../api/sizeChart";
import {getFabrics} from "../../../api/fabric";
import {withRouter} from "react-router-dom";
import Link from "@material-ui/core/Link";

const ProcessProductDetails = withRouter(({history, product, fabric, sizeChart, setFabric, setSizeChart}) => {

    const {sizeCharts, setSizeCharts, fabrics, setFabrics, setSetupInstructions} = useContext(AppContext);

    const brandSizeCharts = sizeCharts && product?.brand ? sizeCharts.filter(chart => chart.brand_name.toLowerCase().includes(product.brand.toLowerCase())) : [];
    const otherBrandSizeCharts = sizeCharts && product?.brand ? sizeCharts.filter(chart => !chart.brand_name.toLowerCase().includes(product.brand.toLowerCase())) : [];

    const [loadingSizeCharts, setLoadingSizeCharts] = useState(false);
    const [loadingFabrics, setLoadingFabrics] = useState(false);

    const fetchSizeCharts = useCallback(() => {
        setLoadingSizeCharts(true);
        getSizeCharts().then(response => {
            setSizeCharts(response.results);
            setSetupInstructions({size_charts_added: response.results.length > 0});
            if (response.results.length === 1) {
                setSizeChart(response.results[0]);
            }
            setLoadingSizeCharts(false);
        });
    }, [setSizeCharts, setSizeChart]);

    const fetchFabrics = useCallback(() => {
        if (loadingFabrics) return;
        setLoadingFabrics(true);
        getFabrics().then(data => {
            setFabrics(data.results);
            setLoadingFabrics(false);
        });
    }, [loadingFabrics, setFabrics]);

    useEffect(() => {
        if (sizeCharts === null) fetchSizeCharts();
        if (fabrics === null) fetchFabrics();
    }, [sizeCharts, fabrics, fetchSizeCharts, fetchFabrics]);

    if (loadingSizeCharts || loadingFabrics || sizeCharts === null || fabrics === null) {
        return <PageLoading/>
    }

    return <div className="c-sync">
        <div className="c-sync-image col col-sm-4">
            {product.name}
            {
                product.available_sizes && <Fragment>
                    <br/>
                    <div className="py-3">
                        Available sizes: {product.available_sizes}
                    </div>
                </Fragment>
            }
            <div className="c-sync-image-wrap">
                {product.image_md ? <img src={product.image_md} alt=""/> : "No image available"}
            </div>
        </div>
        <div className="c-sync-options col col-sm-4">
            {
                product.brand ? (
                    <Fragment>
                        Size chart:<br/>
                        {
                            brandSizeCharts.length > 0 ? (
                                brandSizeCharts.map(brandSizeChart => (
                                    <Button
                                        key={brandSizeChart.id}
                                        onClick={() => {
                                            setSizeChart(brandSizeChart);
                                        }}
                                        color={sizeChart && brandSizeChart.id === sizeChart.id ? "main" : "light"}
                                    >
                                        {brandSizeChart.brand_name}
                                    </Button>
                                ))
                            ) : (
                                <Fragment>
                                    No size chart for brand
                                    <Link
                                        onClick={() => {
                                            history.push(`/size-chart`);
                                        }}>
                                        <Button color="light">
                                            Create size chart
                                        </Button>
                                    </Link>
                                </Fragment>
                            )
                        }
                        {
                            otherBrandSizeCharts.length > 0 && (
                                <Fragment>
                                    Other brand size charts:<br/>
                                    {
                                        otherBrandSizeCharts.map(brandSizeChart => (
                                            <Button
                                                key={brandSizeChart.id}
                                                onClick={() => {
                                                    setSizeChart(brandSizeChart);
                                                }}
                                                color={sizeChart && brandSizeChart.id === sizeChart.id ? "main" : "light"}
                                            >
                                                {brandSizeChart.brand_name}
                                            </Button>
                                        ))
                                    }
                                </Fragment>
                            )
                        }
                    </Fragment>
                ) : (
                    <Fragment>
                        {
                            sizeCharts.length > 0 ? (
                                brandSizeCharts.map(brandSizeChart => (
                                    <Button
                                        key={brandSizeChart.id}
                                        onClick={() => {
                                            setSizeChart(brandSizeChart);
                                        }}
                                        color={sizeChart && brandSizeChart.id === sizeChart.id ? "main" : "light"}
                                    >
                                        {brandSizeChart.brand_name}
                                    </Button>
                                ))
                            ) : (
                                <Fragment>
                                    No size chart created
                                    <Link
                                        onClick={() => {
                                            history.push(`/size-chart`);
                                        }}>
                                        <Button color="light">
                                            Create size chart
                                        </Button>
                                    </Link>
                                </Fragment>
                            )
                        }
                    </Fragment>
                )
            }
        </div>
        <div className="c-sync-options col col-sm-4">
            Fabric:
            {
                fabrics.map(productFabric => (
                    <Button
                        key={productFabric.id}
                        onClick={() => {
                            setFabric(productFabric);
                        }}
                        color={fabric && productFabric.id === fabric.id ? "main" : "light"}
                    >
                        {productFabric.name}
                    </Button>
                ))
            }
        </div>
    </div>
});

export default ProcessProductDetails;