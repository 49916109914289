import React from 'react';

const BurgerMenu = ({ onToggle }) => {
    return (
        <div className="c-panel__burger-menu" onClick={onToggle}>
            <i className="fa fa-bars" aria-hidden="true"></i>
        </div>
    )
};

export default BurgerMenu;
