const DOMAIN = process.env.DOMAIN;
export const FULL_DOMAIN = `https://${DOMAIN}`;
export const STATIC_MEDIA_URI = `${FULL_DOMAIN}/static/media/widget`;


export const PRODUCT_SIZE_NAME_CHOICES = [
    "0", "2", "4", "6", "8", "10", "12", "14", "16", "18", "20", "22", "24",
    "XX-Small", "X-Small", "Small", "Medium", "Large", "X-Large", "XX-Large"];

export const INTERVIEW_METHODS = {
    CUP_BODY_FIT: "cup__body__fit",
    SIZE_FIT_ADJUST: "size__fit__adjust"
}

export const SOLUTIONS = {
    ROBO_FIT: "robo_advisor",
    ROBO_SIZE: "robo_size"
}

export const RECOMMENDATION_METHOD = {
    MEASURES: "measures",
    TOP_TWO: "top_two",
    SINGLE: "single",
    SOCIAL_PROOF: "social"
}
