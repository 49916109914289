import React, {Fragment, useCallback, useEffect, useState} from 'react';
import 'react-quill/dist/quill.snow.css';
import {withRouter} from "react-router-dom";
import {getPendingProducts, updateProducts} from "../../../api/product";
import {PageLoading} from "../../Common/UIKit/Spinner";
import toaster from "toasted-notes";
import Button from "../../Common/UIKit/Form/Button";
import ProcessProductDetails from "./ProcessProductDetails";
import {handleApiSave} from "../../../utilities/useApi";

const ProcessProductPage = withRouter(({history}) => {

    const [products, setProducts] = useState(null);
    const [index, setIndex] = useState(null);
    const [loadingProducts, setLoadingProducts] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);

    const [sizeChart, setSizeChart] = useState(null);
    const [fabric, setFabric] = useState(null);

    const validate = () => sizeChart !== null && fabric !== null;

    const fetchProducts = useCallback(() => {
        setLoadingProducts(true);
        getPendingProducts().then(products => {
            if (products.results.length === 0) {
                toaster.notify(() => <div className="alert alert-success mt-4">No product is pending processed</div>);
                history.push("/sync");
            } else {
                setProducts(products.results);
                setIndex(0);
                setLoadingProducts(false);
            }
        });
    }, [history]);

    useEffect(() => {
        if (products === null || index === null) return;
        setSizeChart(products[index].size_chart);
        setFabric(products[index].fabric);
    }, [products, index]);

    useEffect(() => {
        if (products === null) {
            fetchProducts();
        }
    }, [products, fetchProducts]);

    const handleShowingNextProduct = () => {
        if (index < products.length - 1) {
            setIndex(index + 1);
            return;
        }
        const remaining = products.map((product, index) => ([product.status === "pending", index])).filter(item => item[0]);
        if (remaining.length > 0) {
            setIndex(remaining[0][1]);
        } else {
            toaster.notify(() => <div className="alert alert-success mt-4">Congratulations, you are done!</div>);
            history.push("/sync");
        }
    }

    const handleDisable = () => {
        handleApiSave(
            updateProducts,
            null,
            {
                products: [products[index].id],
                status: "disabled",
            },
            null,
            {
                loadingFn: setSaveLoading,
            }
        ).then(() => {
            products[index].status = "disabled";
            handleShowingNextProduct();
        });
    }

    const handleSave = () => {
        handleApiSave(
            updateProducts,
            null,
            {
                products: [products[index].id],
                fabric: fabric.id,
                fit_toughness: fabric.fit_toughness,
                size_chart: sizeChart.id,
                status: "active",
            },
            null,
            {
                loadingFn: setSaveLoading,
            }
        ).then(() => {
            products[index].status = "active";
            products[index].fabric = fabric;
            products[index].size_chart = sizeChart;
            handleShowingNextProduct();
        });
    };

    if (products === null || loadingProducts) {
        return <div className="c-panel-content"><PageLoading/></div>
    }

    return <Fragment>
        <div className="c-panel-content">
            <div className="c-table-filter">
                <Button
                    className=""
                    onClick={() => {
                        setIndex(index - 1);
                    }}
                    disabled={index === 0}
                >Previous</Button>
                <div className="c-table-filter__item-right">
                    <Button
                        onClick={handleDisable}
                        loading={saveLoading}
                        disabled={products[index].status === "disabled"}
                        color="warning"
                    >Mark as disabled</Button>
                    <Button
                        onClick={() => {
                            setIndex(index + 1);
                        }}
                        disabled={index >= products.length - 1}
                        color="info"
                    >Skip</Button>
                    <Button
                        onClick={handleSave}
                        loading={saveLoading}
                        disabled={!validate()}
                    >Save & Next</Button>
                </div>
            </div>
        </div>
        <div className="c-panel-content">
            <ProcessProductDetails
                product={products[index]}
                fabric={fabric}
                setFabric={setFabric}
                sizeChart={sizeChart}
                setSizeChart={setSizeChart}
            />
        </div>
    </Fragment>

});

export default ProcessProductPage;