
export const IBSelectorPosition = {
    BEFORE: "before",
    AFTER: "after"
}

export const IBSelectorPositionOptions = [
    {
        value: IBSelectorPosition.BEFORE,
        label: "Before"
    },
    {
        value: IBSelectorPosition.AFTER,
        label: "After"
    }
]

export const IBSelectorType = {
    ID: "id",
    CLASS: "class",
    RAW: "raw"
}

export const IBSelectorTypeOptions = [
    {
        value: IBSelectorType.ID,
        label: "ID"
    },
    {
        value: IBSelectorType.CLASS,
        label: "Class"
    },
    {
        value: IBSelectorType.RAW,
        label: "Raw"
    }
]

export const recommendationMethod = {
    MEASURES: "measures",
    SOCIAL: "social",
    TOP_TWO: "top_two",
}

export const recommendationMethodOptions = [
    {
        value: recommendationMethod.MEASURES,
        label: "Size chart match"
    },
    {
        value: recommendationMethod.TOP_TWO,
        label: "Top two sizes"
    },
    {
        value: recommendationMethod.SOCIAL,
        label: "Social proof"
    }
]