import {callApi} from "../utilities/ApiHandler";
import {ApiMethods, ApiRoutes} from "../config/apiConfig";

const {GET, POST} = ApiMethods;

export const getPendingSubscriptionDetails = async (id) => {
    const response = await callApi({
        method: GET,
        url: ApiRoutes.payment.subscription.shopify.pending.details,
        params: {id}
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};


export const getShopifySubscriptionDetails = async () => {
    const response = await callApi({
        method: GET,
        url: ApiRoutes.payment.subscription.shopify.details
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const getStripeSubscriptionDetails = async () => {
    const response = await callApi({
        method: GET,
        url: ApiRoutes.payment.subscription.stripe.details
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const getSubscription = async () => {
    const response = await callApi({
        method: GET,
        url: ApiRoutes.payment.subscription.get
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const CancelShopifySubscription = async (id) => {
    const response = await callApi({
        method: POST,
        url: ApiRoutes.payment.subscription.shopify.cancel,
        params: {
            id
        }
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const subscribeToShopify = async (body) => {
    const response = await callApi({
        method: POST,
        url: ApiRoutes.payment.subscription.shopify.subscribe,
        params: body
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const subscribeToShopifyEnterprise = async (body) => {
    const response = await callApi({
        method: POST,
        url: ApiRoutes.payment.subscription.shopify.subscribeEnterprise,
        params: body
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const createCharge = async (body = {}) => {
    const response = await callApi({
        method: POST,
        url: ApiRoutes.payment.charge.shopify.create,
        params: body
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const getCharge = async (id) => {
    const response = await callApi({
        method: GET,
        url: ApiRoutes.payment.charge.shopify.get,
        params: {id}
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const activatePendingShopifySubscription = async (id) => {
        const response = await callApi({
        method: POST,
        url: ApiRoutes.payment.subscription.shopify.activate,
        params: {id}
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
}

export const updateSubscriptionToShopify = async (body) => {
    const response = await callApi({
        method: POST,
        url: ApiRoutes.payment.subscription.shopify.update,
        params: body
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const connectToStripe = async () => {
    const response = await callApi({
        method: POST,
        url: ApiRoutes.payment.connect
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const completeConnectToStripe = async () => {
    const response = await callApi({
        method: POST,
        url: ApiRoutes.payment.complete
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const getPaymentDetails = async () => {
    const response = await callApi({
        method: GET,
        url: ApiRoutes.payment.method
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

