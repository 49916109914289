import React, {Fragment} from 'react';
import {withRouter} from "react-router-dom";
import Button from "../../Common/UIKit/Form/Button";
import HelpArticlePage from "./HelpArticlePage";
import HelpArticlesIndex from "./HelpArticlesIndex";


const HelpPage = withRouter(({match}) => {
    if (match?.params?.id) {
        const HelpArticlePage = require("./HelpArticlePage").default;
        return <HelpArticlePage/>
    }

    return <Fragment>
        <HelpArticlesIndex/>
        <div className="c-panel-content">
            <div className="h4 px-2">Talk to an agent</div>
            <div className="container-fluid">
                If you have any questions on how to do something, we would be happy to help. Either send us an email
                at <a href="mailto:support@robosize.com">support@robosize.com</a> or use the link below to book a time
                with an agent:

                <div className="container-fluid text-center py-5">
                    <Button
                        color="primary"
                        className="d-inline"
                        link="https://calendly.com/robosize-ltd/support"
                        target="_blank"
                        lg={true}
                    >Talk to an agent</Button>
                </div>
            </div>
            <hr/>
            <div className="h4 px-2 pt-3">Billing issues</div>
            <div className="container-fluid">
                If you have any question or request about billing please contact us via <a
                href="mailto:billing@robosize.com">billing@robosize.com</a>
            </div>
            <hr/>
            <div className="h4 px-2 pt-3">Technical support</div>
            <div className="container-fluid">
                If you have any technical issues please contact us via <a
                href="mailto:tech@robosize.com">tech@robosize.com</a>
            </div>
        </div>
    </Fragment>
});

export default HelpPage;