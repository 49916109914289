import React, {Fragment} from 'react';
import Button from "../../Common/UIKit/Form/Button";
import Dropdown from "../../Common/UIKit/Dropdown";
import {conditionOperatorLabels, conditionOperators, conditionSubjects} from "./Constants";
import {productMatchCondition} from "../../../utilities/String";
import Select, {ControlSelect} from "../../Common/UIKit/Form/Select";
import AutoCompleteInput from "../../Common/UIKit/AutoCompleteInput";
import {getProductSuggestion} from "../../../api/product";
import Input from "../../Common/UIKit/Form/Input";

const ProductMatchConditionSet = ({conditions, operator, onChange}) => {

    const handleUpdateCondition = (condition, newKey) => {
        Object.assign(condition, newKey);
        onChange([...conditions]);
    }

    return <Fragment>
        {
            conditions.map((condition, index) => (
                <Fragment key={index}>
                    <div className="c-condition">
                        <div className="c-condition-subject">
                            <Select
                                value={condition.subject || ""}
                                defaultValue="vendor"
                                onChangeValue={(subject) => handleUpdateCondition(condition, {subject})}
                                options={conditionSubjects.map(subject => ({
                                    value: subject,
                                    label: productMatchCondition(subject)
                                }))}
                            />
                        </div>
                        <div className="c-condition-operator mx-2">
                            <Select
                                value={condition.operator || ""}
                                isDisabled={condition.subject === "all_products"}
                                defaultValue="equals"
                                onChangeValue={(operator) => handleUpdateCondition(condition, {operator})}
                                options={conditionOperators.map(operator => ({
                                    value: operator,
                                    label: conditionOperatorLabels[operator]
                                }))}
                            />
                        </div>
                        <div className="c-condition-value mr-2">
                            <Input
                                disabled={condition.subject === "all_products"}
                                value={condition.value || ""}
                                onChange={(event) => handleUpdateCondition(condition, {value: event.target.value})}
                                placeholder="Value"
                            />
                            <AutoCompleteInput
                                value={condition.value || ""}
                                subject={condition.subject}
                                onSelect={(value) => handleUpdateCondition(condition, {value})}
                                lookupFunc={getProductSuggestion}/>
                        </div>
                        <div className="c-condition-delete">
                            <Button color="light" onClick={() => {
                                conditions.splice(conditions.indexOf(condition), 1);
                                onChange([...conditions]);
                            }}>
                                <span className="fa fa-trash"></span>
                            </Button>
                        </div>
                    </div>
                </Fragment>
            ))
        }
        <div className="container py-3 px-0">
            <Button color="light" onClick={() => {
                conditions.push({
                    subject: "vendor",
                    operator: "equals",
                    value: null,
                });
                onChange([...conditions]);
            }}>
                Add another condition
            </Button>
        </div>
    </Fragment>
};

export default ProductMatchConditionSet;