import React, {useRef} from 'react';
import useInsightFilters from "./useInsightFilters";
import InsightFilter from "./InsightFilter";
import useInsightData from "./useInsightData";
import InsightCharts from "./InsightCharts";

const InsightPage = () => {

    const {query, handleQueryChange, filters, handleDeleteFilter, clearFilters} = useInsightFilters();

    const {data} = useInsightData({filters});

    const container = useRef(null);

    return <div className="c-panel-content container">
        <div className="c-list__item" ref={container}>
            <InsightFilter filters={filters} handleDeleteFilter={handleDeleteFilter} query={query}
                           data={data}
                           handleQueryChange={handleQueryChange} clearFilters={clearFilters}/>
            <InsightCharts data={data}/>
        </div>
    </div>
};

export default InsightPage;
