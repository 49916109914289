import {STRING} from "../../i18n/base";

export const GENDER = {
        MALE: "male",
        FEMALE: "female"
    },
    UNIT_METHOD = {
        METRIC: "metric",
        IMPERIAL: "imperial",
    },
    LENGTH_UNIT = {
        CM: "cm",
        INCH: "in"
    },
    WEIGHT_UNIT = {
        KG: "kg",
        POUNDS: "lb"
    },
    INTERVIEW_STEP = {
        GENDER: "gn",
        BASIC_QUESTIONS: "bq",
        BODY_SHAPE: "bs",
        KNOWN_BRAND: "kb",
        KNOWN_CHART: "kc",
        BRA_CUP: "bc",
        FIT_PREFERENCE: "fp",
        FITTING: "ft",
        SELFIE_MENU: "sm",
        SELFIE: "sf",
        VIRTUAL_TRY_ON: "vtr",
        MEASURE_ADJUSTMENT: "ma",
        MEASURE_MANUAL: "mm",
        SIZE_CHART: "sc"
    },
    INTERVIEW_STEP_MODAL_CLASS = {
        GENDER: "if_gender",
        BASIC_QUESTIONS: "if_basic-questions",
        BODY_SHAPE: "if_body-shape",
        KNOWN_BRAND: "if_known-brand",
        BRA_CUP: "if_bra-cup",
        FITTING: "if_fitting",
        VIRTUAL_TRY_ON: "if_try-on",
        MEASURE_ADJUSTMENT: "if_measure-adjustment",
        ENTER_MEASURES: "if_enter-measures",
        SIZE_CHART: "if_size-chart",
    },
    BODY_TYPE = {
        RECTANGLE: {
            IMAGE: "rectangle.png",
            NAME: "rectangle",
        },
        CIRCLE: {
            IMAGE: "circle.png",
            NAME: "circle"
        },
        TRIANGLE: {
            IMAGE: "triangle.png",
            NAME: "triangle"
        },
        REVERSE_TRIANGLE: {
            IMAGE: "reverse_triangle.png",
            NAME: "reverse_triangle",
        },
        HOUR_GLASS: {
            IMAGE: "hour_glass.png",
            NAME: "hour_glass"
        },
    },
    BODY_SHAPE_OFFSET_FIELD = {
        BUST: "bustOffset",
        WAIST: "waistOffset",
        HIP: "hipOffset"
    },
    MODAL_SIZE = {
        MEDIUM: "md",
        LARGE: "lg"
    },
    MEASURE = {
        BUST: "bust",
        WAIST: "waist",
        HIP: "hip",
        INSIDE_LEG: "inside_leg",
        NECK: "neck",
        SLEEVE: "sleeve",
        LENGTH: "length",
    },
    MEASURE_RANGE = {
        [MEASURE.BUST]: [70, 150],
        [MEASURE.WAIST]: [50, 150],
        [MEASURE.HIP]: [70, 150],
        [MEASURE.INSIDE_LEG]: [60, 100],
        [MEASURE.NECK]: [30, 50],
        [MEASURE.SLEEVE]: [50, 80],
        [MEASURE.LENGTH]: [50, 100],
    },
    SHAPE_ADJUSTMENTS = [
        {
            offset: -1,
            name: "bust",
            bustOffset: STRING.BUST_OFFSET_SMALL,
            waistOffset: STRING.WAIST_OFFSET_SMALL,
            hipOffset: STRING.HIP_OFFSET_SMALL
        },
        {
            offset: 0,
            name: "waist",
            bustOffset: STRING.BUST_OFFSET_AVERAGE,
            waistOffset: STRING.WAIST_OFFSET_AVERAGE,
            hipOffset: STRING.HIP_OFFSET_AVERAGE
        },
        {
            offset: 1,
            name: "hip",
            bustOffset: STRING.BUST_OFFSET_LARGE,
            waistOffset: STRING.WAIST_OFFSET_LARGE,
            hipOffset: STRING.HIP_OFFSET_LARGE
        }
    ];