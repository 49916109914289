import React, {forwardRef, useState} from 'react';
import { RemoveRedEye } from '@material-ui/icons';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const InputText = forwardRef(({ id, value, type: defaultType, unit, unitPos, onChange, onChangeText, className, ...otherProps }, ref) => {
    const [type, setType] = useState(defaultType);
    const handleOnChange = e => {
        onChangeText(e.target.value);
        onChange(e);
    };

    const changePasswordType = () => {
        setType(type === 'password' ? 'text' : 'password');
    };
    return (
        <div className={classnames('c-form__controller', { 'c-form__controller--disabled': otherProps.disabled }, className)}>
            <input type={type}
                   id={id}
                   ref={ref}
                   onChange={handleOnChange}
                   value={value}
                   {...otherProps} />

            {defaultType === 'password' ? (
                <span
                    className={classnames('c-form__controller-unit c-form__controller-password', {
                        'c-form__controller-password--active': type === 'text'
                    })}
                    onClick={changePasswordType}
                >
                    <RemoveRedEye />
                </span>
            ) : unit && (
                <span className="c-form__controller-unit">{unit}</span>
            )}
        </div>
    )
});

InputText.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    unit: PropTypes.string,
    unitPos: PropTypes.oneOf(['before', 'after']),
    onChange: PropTypes.func,
    onChangeText: PropTypes.func
};

InputText.defaultProps = {
    id: null,
    type: 'text',
    unit: '',
    unitPos: 'after',
    onChange: () => {},
    onChangeText: () => {},
};

export default InputText;
