import {ApiRoutes, StatusCodes} from '../config/apiConfig';
import API from '../services/APIService';
import {Types} from "../config/actionTypes";
import asyncLocalStorage from "./AsyncLocalStorage";
import {handleError500} from "./HandleErrors";
import {getCookies, setCookie} from "../utilities/Cookie";
import {ApiMethods} from "../config/apiConfig";

const {GET, POST} = ApiMethods;

export const PAGE_SIZE = 50;

const fetchCSRFToken = async () => {
    const response = await callApi({
        method: GET,
        url: ApiRoutes.user.csrf,
    });
    if (response.data) {
        setCookie("csrftoken", response.data.data.token);
        return response.data.token;
    }
}

export const callApi = ({
                            method,
                            url,
                            params = null,
                            headers = null,
                            absolute = false,
                            needStatus = false,
                            pure = false,
                            authorization = true
                        }) => {
    const boot = async () => {
        const cookies = getCookies();
        if (method === POST && !cookies.csrftoken) {
            await fetchCSRFToken();
        }
        let token = await asyncLocalStorage.getItem(Types.USER_TOKEN);
        let Authorization = null;
        if (!!token) Authorization = 'Bearer ' + token;

        let response = await handler(Authorization);
        if ([StatusCodes.UnAuthorized, StatusCodes.Forbidden].includes(response.status) && authorization) {
            try {
                const refreshToken = await asyncLocalStorage.getItem(Types.USER_REFRESH);
                if (!!refreshToken) {
                    const refreshData = await API['post'](
                        ApiRoutes.auth.refresh,
                        {refresh: refreshToken},
                        {'Content-Type': 'application/json'},
                        false
                    );
                    if (refreshData.status === StatusCodes.Success && refreshData.data) {
                        await asyncLocalStorage.setItem(Types.USER_TOKEN, refreshData.data.access);
                        Authorization = 'Bearer ' + refreshData.data.access;
                        response = await handler(Authorization);
                    } else {
                        window.location.replace("/login");
                        throw new Error('Access Denied');
                    }
                } else {
                    window.location.replace("/login");
                    throw new Error('Access Denied');
                }
            } catch (e) {
                await asyncLocalStorage.removeItem(Types.USER_TOKEN);
                await asyncLocalStorage.removeItem(Types.USER_REFRESH);
                window.location.reload();
            }
        }

        return new Promise((resolve, reject) => {
            handleError500(response);
            resolve(response)
        });
    };

    const handler = async (Authorization = null, csrfToken = null) => {
        try {
            const apiHeaders = {'Content-Type': 'application/json', ...headers, 'X-CSRFToken': getCookies().csrftoken};
            if (!!Authorization) apiHeaders.Authorization = Authorization;
            const s = await API[method](url, params, apiHeaders, absolute);

            const {data, status} = s;

            if (status !== StatusCodes.Success && status !== StatusCodes.Created && status !== StatusCodes.Deleted) {
                return {errors: data, status};
            } else {
                return s;
            }

        } catch (error) {
            return {
                data: {},
                status: StatusCodes.Error,
            };
        }
    };

    return boot();
    // return handler();
};
