import React, {useState} from 'react';
import toaster from "toasted-notes";
import Input from "../../Common/UIKit/Form/Input";
import Button from "../../Common/UIKit/Form/Button";
import {login} from "../../../api";
import {Controller, useForm} from "react-hook-form";
import {handleApiSave} from "../../../utilities/useApi";
import AuthenticationLayout from "../../Common/Authentication/AuthenticationLayout";
import {NavLink} from "react-router-dom";
import {getAllQueryParams} from "../../../utilities/URI";


const LoginPage = () => {
    const [loading, setLoading] = useState(false);

    const { control, errors, handleSubmit } = useForm();

    const onSubmit = ({ email, password }) => {
        // if (!recaptcha) return toaster.notify('Please Verify non-Robot Task');
        if (email && password && email.length > 0 && password.length > 0) {
            setLoading(true);
            // login(email, password, recaptcha)
            handleApiSave(
                login,
                undefined,
                { email, password },
                null,
                { loadingFn: setLoading, }
            ).then(data => {
                const next = getAllQueryParams().next;
                window.location.replace(next ? decodeURI(next) : "/");
            })
        }
        else toaster.notify('Please Enter Email and Password');
    };

    // const verifyCallBack = response => setRecaptcha(response);

    return (
        <AuthenticationLayout>
            <div className="c-auth__head">
                <h1>Log in for partners</h1>
                <p>Access your partner account to manage your business</p>
            </div>
            <form className="e-form mb-3" onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    control={control}
                    name="email"
                    rules={{ required: 'Email is required' }}
                    defaultValue=""
                    render={({ onChange, onBlur, value }) => (
                        <Input label="Email address"
                               type="email"
                               id="pure-email"
                               formGroup
                               onChangeText={onChange}
                               onBlur={onBlur}
                               value={value}
                               errorMessage={errors?.email?.message}
                               error={!!errors.email}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="password"
                    rules={{ required: 'Email is required' }}
                    defaultValue=""
                    render={({ onChange, onBlur, value }) => (
                        <Input label="Password"
                               type="password"
                               id="pure-password"
                               formGroup
                               onChangeText={onChange}
                               onBlur={onBlur}
                               value={value}
                               errorMessage={errors?.password?.message}
                               error={!!errors.password}
                        />
                    )}
                />
                {/*<GoogleRecaptcha sitekey={process.env.REACT_APP_RECAPTCHA_KEY} verifyCallback={verifyCallBack} />*/}

                {/*<NavLink to="/forgot" className="c-auth__link">Forgot password?</NavLink>*/}
                <Button block lg loading={loading}>Log in</Button>
            </form>
            {/*<GoogleIdentityLogin />*/}
            <div className="c-auth__links">
                <p>Don’t have a partner account?</p>
                <NavLink to={`/register${window.location.search}`} className="c-auth__link">Sign up your business</NavLink>
            </div>
        </AuthenticationLayout>
    );
};

export default LoginPage;
