import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const InputFile = forwardRef(({ id, value, unit, unitPos, onChange, onChangeFile, ...otherProps }, ref) => {
    const handleOnChange = e => {
        onChangeFile(e.target.files[0]);
        onChange(e);
    };
    return (
        <div className={classnames('c-form__controller', { 'c-form__controller--disabled': otherProps.disabled})}>
            <input type="file"
                   id={id}
                   ref={ref}
                   onChange={handleOnChange}
                   value={value}
                   {...otherProps} />
            {unit && <span className="c-form__controller-unit">{unit}</span>}
        </div>
    )
});

InputFile.propTypes = {
    id: PropTypes.string,
    unit: PropTypes.string,
    unitPos: PropTypes.oneOf(['before', 'after']),
    onChange: PropTypes.func,
    onChangeFile: PropTypes.func
};

InputFile.defaultProps = {
    id: null,
    unit: '',
    unitPos: 'after',
    onChange: () => {},
    onChangeFile: () => {},
};

export default InputFile;
