import {callApi} from "../utilities/ApiHandler";
import {ApiMethods, ApiRoutes, createURL} from "../config/apiConfig";
import {getParams} from "./common";

const {GET, PATCH, POST} = ApiMethods;

export const getProducts = async (page, limit, search, needsChartOnly) => {
    const response = await callApi({
        method: GET,
        url: ApiRoutes.product.base,
        params: {
            offset: page * limit,
            page,
            limit,
            search,
            no_chart_only: needsChartOnly
        }
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const getPendingProducts = async () => {
    const response = await callApi({
        method: GET,
        url: ApiRoutes.product.base,
        params: {
            status: "pending"
        }
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};


export const getProductDetails = async (id) => {
    const response = await callApi({
        method: GET,
        url: `${ApiRoutes.product.base}?hash=${id}`,
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};


export const updateProducts = async params => {
    const response = await callApi({
        method: PATCH,
        url: ApiRoutes.product.base,
        params: getParams(params),
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const createProduct = async (body) => {
    const response = await callApi({
        method: POST,
        url: ApiRoutes.product.base,
        params: body,
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
}

export const getSyncTasks = async () => {
    const response = await callApi({
        method: GET,
        url: ApiRoutes.product.sync.list
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
}

export const createSyncTask = async () => {
    const response = await callApi({
        method: POST,
        url: ApiRoutes.product.sync.list
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
}

export const getProductSuggestion = async ({subject, query}) => {
    const subjectSlug = subject.replace("_", "-");
    const response = await callApi({
        method: GET,
        url: `${createURL(ApiRoutes.product.suggest, {subject: subjectSlug})}?query=${query}`
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
}