import React, {Fragment, useContext} from 'react';
import {withRouter} from "react-router-dom";
import {AppContext} from "../../../../context/providers";
import * as classnames from "classnames";
import Button from "../Form/Button";
import {saveBusinessSettings} from "../../../../api/business";
import {confirmAlert} from "react-confirm-alert";

const CreateSizeChartInstruction = withRouter(({history, compact}) => {

    const {setupInstructions, setSetupInstructions} = useContext(AppContext);
    const {
        size_charts_added,
    } = setupInstructions || {};

    const handleDone = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setSetupInstructions({size_charts_added: true});
        saveBusinessSettings({size_charts_added: true}).then(() => {});
    }

    return <div className={classnames("c-card-item c-card-body", {
            "c-card-item--disabled": size_charts_added,
            "c-card-item--clickable": !size_charts_added
        }
    )} onClick={() => {
        confirmAlert({
            title: "",
            message: "In order to show recommendation or just the size chart on your product page, you should enter " +
                "your charts and match them with the products. " +
                "You can watch a video on how to do that or just do it if you know how.",
            buttons: [
                {
                    label: 'Watch video',
                    onClick: () => {
                        window.open('https://youtu.be/otV-hlHVjNo', '_blank').focus();
                    }
                },
                {
                    label: 'Create a chart',
                    onClick: () => {
                        history.push("/size-chart/create");
                    }
                },
                {
                    label: 'Cancel',
                }
            ]
        })
    }}>
        <b>Step 2: </b>
        Add your size charts
        {
            size_charts_added ?
                <span className="fa fa-lg fa-check-circle float-right ml-2 text-success"></span> : (
                    <Fragment>
                        <Button color="success" xs={compact} className="d-inline-block ml-2 float-right"
                                onClick={handleDone}>
                            Mark as done
                        </Button>
                    </Fragment>
                )
        }
    </div>

});

export default CreateSizeChartInstruction;