import React, {useCallback, useEffect, useState} from 'react';
import {getInsight} from "../../../api/insight";

const useInsightData = ({filters}) => {

    const [data, setData] = useState(null);

    const mapFiltersToParams = useCallback((filters) => {
        const params = {};
        filters.forEach(filter => {
            params[`${filter.field}${filter.operator}`] = filter.value;
        });
        return params;
    }, []);

    const fetchData = useCallback(() => {
        setData(null);
        getInsight(mapFiltersToParams(filters)).then(response => {
            setData(response);
        });
    }, [filters, mapFiltersToParams]);

    useEffect(() => {
        if (data === null) fetchData();
    }, [fetchData, data]);

    useEffect(() => {
        fetchData();
    }, [fetchData, filters]);

    return {
        data,
    }

};

export default useInsightData;
