import React, {useContext} from 'react';
import {withRouter} from "react-router-dom";
import {AppContext} from "../../../context/providers";
import {BoxSpinner, PageLoading} from "../../Common/UIKit/Spinner";
import ShopifySetupPage from "./ShopifySetupPage";
import ShopifySubscriptionPage from "./ShopifySubscriptionPage";
import BillingStripePage from "./BillingStripePage";
import {subscribeToShopify, updateSubscriptionToShopify} from "../../../api/payment";
import {plans} from "./plans";

const BillingPage = withRouter(({history, match}) => {
    const {business} = useContext(AppContext);

    if (!business ) {
        return <div className="c-panel-content"><BoxSpinner/></div>
    }

    let nameMatches = plans.filter(plan => plan.name === business?.subscription?.plan_name);
    const plan = nameMatches.length > 0 ? nameMatches[0] : null;

    if (business.has_custom_pricing) {
        history.push("/billing-custom");
        return <PageLoading/>
    } else {
        if (business.subscription?.status === "active") {
            return <ShopifySubscriptionPage/>
        }
        return <ShopifySetupPage saveFunc={plan ? updateSubscriptionToShopify : subscribeToShopify}/>
    }

});

export default BillingPage;