import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import Routes from "./Routes";
import 'react-confirm-alert/src/react-confirm-alert.css';
import "toasted-notes/src/styles.css";
import 'rc-slider/assets/index.css';
import AppProvider from "./context/providers";


Sentry.init({
    dsn: "https://0205378ed02b4db3937d6b3f779024fe@o1056935.ingest.sentry.io/6043395",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.01,
});

const App = () => {
    return (
        <AppProvider>
            <BrowserRouter>
                <Routes/>
            </BrowserRouter>
        </AppProvider>
    );
};

export default App;
