import {capitalize} from "../utils/common";

export const STRING = {
    IB_BUTTON_TEXT: "ib_button_text", // It is broken button text
    // IB_BUTTON_TEXT: "ib_button_text_",
    WELCOME_TITLE_L1: "welcome_title_l1",
    WELCOME_TITLE_L2: "welcome_title_l2",
    WELCOME_SUBTITLE: "welcome_subtitle",
    MALE: "male",
    FEMALE: "female",
    BASIC_QUESTIONS_TITLE: "basic_questions_title",
    HEIGHT: "height",
    WEIGHT: "weight",
    AGE: "age",
    AGE_HINT: "age_hint",
    COLLAR: "collar",
    NECK: "neck",
    LENGTH: "length",
    LENGTH_REQUIRED: "length_required",
    NEXT: "next",
    CONTINUE: "continue",
    START_OVER: "start_over",
    SAVE_CHANGES: "save_changes",
    CLOSE: "close",
    BODY_SHAPE: "body_shape",
    BODY_SHAPE_SUBTITLE: "body_shape_subtitle",
    BODY_FORM_SUBTITLE: "body_form_subtitle",
    BUST: "bust",
    CHEST: "chest",
    WAIST: "waist",
    HIP: "hip",
    THIGH: "thigh",
    ANKLE: "ankle",
    INSIDE_LEG: "inside_leg",
    SLEEVE: "sleeve",
    SELECT_FIT_PREFERENCE: "select_fit_preference",
    REGULAR_FIT: "regular_fit",
    TIGHT_FIT: "tight_fit",
    LOOSE_FIT: "loose_fit",
    BUST_OFFSET_SMALL: "bustOffset_small",
    BUST_OFFSET_AVERAGE: "bustOffset_average",
    BUST_OFFSET_LARGE: "bustOffset_large",
    WAIST_OFFSET_SMALL: "waistOffset_small",
    WAIST_OFFSET_AVERAGE: "waistOffset_average",
    WAIST_OFFSET_LARGE: "waistOffset_large",
    HIP_OFFSET_SMALL: "hipOffset_small",
    HIP_OFFSET_AVERAGE: "hipOffset_average",
    HIP_OFFSET_LARGE: "hipOffset_large",
    BRA_SIZE: "bra_size",
    BRA_BAND: "bra_band",
    CUP_SIZE: "cup_size",
    MORE: "more",
    BRAND_NAME_PLACEHOLDER: "brand_name_placeholder",
    COMPARE_SIZES: "compare_sizes",
    COMPARE_WITH_KNOWN_BRAND: "compare_with_known_brand",
    SKIP_QUESTION: "skip_question",
    BRAND_NOT_HELPFUL: "brand_not_helpful",
    BRAND_SIZES: "brand_sizes",
    BRAND_TO_COMPARE: "brand_to_compare",
    SIZE: "size",
    FINDING_BEST_SIZE: "finding_best_size",
    COULD_NOT_FIND_SIZE_FOR_PRODUCT: "could_not_find_size_for_product",
    COULD_NOT_ACCESS_CAMERA: "could_not_access_camera",
    COULD_NOT_USE_IMAGE: "could_not_use_image",
    BEST_FIT: "best_fit",
    YOUR_RECOMMENDED_FIT: "your_recommended_fit",
    YOUR_SECOND_BEST_FIT: "your_second_best_fit",
    SOCIAL_PROOF_L1: "social_proof_l1",
    SOCIAL_PROOF_L2: "social_proof_l2",
    ADJUST_MEASUREMENTS: "adjust_measurements",
    NEED_MORE_MEASUREMENTS: "need_more_measurements",
    ENTER_MEASUREMENTS_MANUALLY: "enter_measurements_manually",
    ENTER_MEASUREMENTS: "enter_measurements",
    SHORT: "short",
    LONG: "long",
    TIGHT: "tight",
    LOOSE: "loose",
    SNUG: "snug",
    WIDE: "wide",
    IDEAL: "ideal",
    SLIGHTLY: "slightly",
    SIZE_CHART: "size_chart",
    RECOMMENDED_PRODUCTS: "recommended_products",
    FACE_CAMERA_DIRECTLY: "face_camera_directly",
    TURN_LEFT: "turn_left",
    TURN_RIGHT: "turn_right",
    KEEP_HEAD_STRAIGHT: "keep_head_straight",
    CAPTURE: "capture",
    PROCESSING: "processing",
    YOUR_RECOMMENDED_FIT_SINGLE: "your_recommended_fit_single",
    YOUR_RECOMMENDED_FIT_TWO: "your_recommended_fit_two",
    VIRTUAL_TRY_ON_MENU_SAMPLES: "virtual_try_on_menu_samples",
    VIRTUAL_TRY_ON_MENU_SELFIE: "virtual_try_on_menu_selfie",
    USE_CAMERA: "use_camera",
    CHOOSE_IMAGE: "choose_image",
    SKIP: "skip",
}

export const STRING_MAP = {
    [STRING.IB_BUTTON_TEXT]: "Find my size",
    [STRING.WELCOME_TITLE_L1]: "Welcome to ",
    [STRING.WELCOME_TITLE_L2]: "our virtual fitting room",
    [STRING.WELCOME_SUBTITLE]: "Here you can find your size with a few clicks",
    [STRING.MALE]: capitalize(STRING.MALE),
    [STRING.FEMALE]: capitalize(STRING.FEMALE),
    [STRING.BASIC_QUESTIONS_TITLE]: "Fill in the details to try this product",
    [STRING.HEIGHT]: capitalize(STRING.HEIGHT),
    [STRING.WEIGHT]: capitalize(STRING.WEIGHT),
    [STRING.AGE]: capitalize(STRING.AGE),
    [STRING.AGE_HINT]: "Age helps us find the weight distribution.",
    [STRING.COLLAR]: capitalize(STRING.COLLAR),
    [STRING.NECK]: capitalize(STRING.NECK),
    [STRING.LENGTH]: capitalize(STRING.LENGTH),
    [STRING.LENGTH_REQUIRED]: "Length is required",
    [STRING.NEXT]: capitalize(STRING.NEXT),
    [STRING.CONTINUE]: capitalize(STRING.CONTINUE),
    [STRING.START_OVER]: "Start over",
    [STRING.SAVE_CHANGES]: "Save changes",
    [STRING.CLOSE]: "Close",
    [STRING.BODY_SHAPE]: "Body shape",
    [STRING.BODY_SHAPE_SUBTITLE]: "To help us give more accurate results, adjust the proportions if necessary",
    [STRING.BODY_FORM_SUBTITLE]: "Select the closest to your {{measure}}",
    [STRING.BUST]: capitalize(STRING.BUST),
    [STRING.CHEST]: capitalize(STRING.CHEST),
    [STRING.WAIST]: capitalize(STRING.WAIST),
    [STRING.HIP]: capitalize(STRING.HIP),
    [STRING.THIGH]: capitalize(STRING.THIGH),
    [STRING.ANKLE]: capitalize(STRING.ANKLE),
    [STRING.INSIDE_LEG]: capitalize(STRING.INSIDE_LEG),
    [STRING.SLEEVE]: capitalize(STRING.SLEEVE),
    [STRING.SELECT_FIT_PREFERENCE]: "Select your fit preference",
    [STRING.TIGHT_FIT]: "Tighter",
    [STRING.REGULAR_FIT]: "Regular",
    [STRING.LOOSE_FIT]: "Looser",
    [STRING.BUST_OFFSET_SMALL]: "Straighter",
    [STRING.BUST_OFFSET_AVERAGE]: "Regular / I don't know",
    [STRING.BUST_OFFSET_LARGE]: "Wider",
    [STRING.WAIST_OFFSET_SMALL]: "Flatter",
    [STRING.WAIST_OFFSET_AVERAGE]: "Regular / I don't know",
    [STRING.WAIST_OFFSET_LARGE]: "Curvier",
    [STRING.HIP_OFFSET_SMALL]: "Straighter",
    [STRING.HIP_OFFSET_AVERAGE]: "Regular / I don't know",
    [STRING.HIP_OFFSET_LARGE]: "Wider",
    [STRING.BRA_SIZE]: "Bra size",
    [STRING.BRA_BAND]: "Band",
    [STRING.CUP_SIZE]: "Cup size",
    [STRING.MORE]: capitalize(STRING.MORE),
    [STRING.BRAND_NAME_PLACEHOLDER]: "Type the name of your brand",
    [STRING.COMPARE_SIZES]: "Compare sizes for a perfect fit",
    [STRING.COMPARE_WITH_KNOWN_BRAND]: "Compare sizes with the brand you normally wear:",
    [STRING.SKIP_QUESTION]: "Skip this question",
    [STRING.BRAND_NOT_HELPFUL]: "{{name}}'s sizes do not help to fit this product. Please use another brand.",
    [STRING.BRAND_SIZES]: "{{name}} comes in these sizes:",
    [STRING.BRAND_TO_COMPARE]: "Brand to compare sizes",
    [STRING.SIZE]: capitalize(STRING.SIZE),
    [STRING.FINDING_BEST_SIZE]: "We are finding the perfect size for you...",
    [STRING.COULD_NOT_FIND_SIZE_FOR_PRODUCT]: "Could not find a good size of this product",
    [STRING.COULD_NOT_ACCESS_CAMERA]: "Could not access camera, please make sure the access is available",
    [STRING.COULD_NOT_USE_IMAGE]: "Could not use the image, please try again",
    [STRING.BEST_FIT]: "Your best fit is {{size}}",
    [STRING.YOUR_RECOMMENDED_FIT]: "Your recommended fit is {{size}}",
    [STRING.YOUR_SECOND_BEST_FIT]: "Your second best fit is {{size}}",
    [STRING.SOCIAL_PROOF_L1]: "{{percentage}}% of our customers",
    [STRING.SOCIAL_PROOF_L2]: "with similar fit as you buy {{size}}",
    [STRING.ADJUST_MEASUREMENTS]: "Adjust measurements",
    [STRING.NEED_MORE_MEASUREMENTS]: "We need more measurements",
    [STRING.ENTER_MEASUREMENTS_MANUALLY]: "We could not get your measurements automatically. Please fill your measurements below.",
    [STRING.ENTER_MEASUREMENTS]: "Please enter your measurements to find your best fit",
    [STRING.SHORT]: capitalize(STRING.SHORT),
    [STRING.LONG]: capitalize(STRING.LONG),
    [STRING.TIGHT]: capitalize(STRING.TIGHT),
    [STRING.LOOSE]: capitalize(STRING.LOOSE),
    [STRING.SNUG]: capitalize(STRING.SNUG),
    [STRING.WIDE]: capitalize(STRING.WIDE),
    [STRING.IDEAL]: capitalize(STRING.IDEAL),
    [STRING.SLIGHTLY]: capitalize(STRING.SLIGHTLY),
    [STRING.SIZE_CHART]: "Size chart",
    [STRING.RECOMMENDED_PRODUCTS]: "More great fits:",
    [STRING.FACE_CAMERA_DIRECTLY]: "Face camera directly",
    [STRING.TURN_LEFT]: "Turn your head left",
    [STRING.TURN_RIGHT]: "Turn your head right",
    [STRING.KEEP_HEAD_STRAIGHT]: "Keep your head straight please",
    [STRING.CAPTURE]: "Capture (timer 3s)",
    [STRING.PROCESSING]: "Processing...",
    [STRING.YOUR_RECOMMENDED_FIT_SINGLE]: "Your best fit is {{size}}",
    [STRING.YOUR_RECOMMENDED_FIT_TWO]: "Your fit is between {{size_1}} and {{size_2}}",
    [STRING.VIRTUAL_TRY_ON_MENU_SAMPLES]: "You can try the product on any of the following",
    [STRING.VIRTUAL_TRY_ON_MENU_SELFIE]: "Or you can try it on with a selfie",
    [STRING.USE_CAMERA]: "Use camera",
    [STRING.CHOOSE_IMAGE]: "Choose image",
    [STRING.SKIP]: "Skip"
}
