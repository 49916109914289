import {BLOCK_TYPE} from "../../../fit_widget/src/components/SizeChart/Constants";
import {UNIT_METHOD} from "../../../fit_widget/src/components/BodyShape/Constants";

export const measures = {
    BUST: "bust",
    WAIST: "waist",
    HIP: "hip",
    INSIDE_LEG: "inside_leg",
    NECK: "neck",
    SLEEVE: "sleeve",
    LENGTH: "length",
    THIGH: "thigh",
    HEIGHT: "height",
    WEIGHT: "weight"
}

export const nonUnitMeasures = [measures.HEIGHT, measures.WEIGHT];

export const validMeasures = [
    measures.BUST,
    measures.WAIST,
    measures.HIP,
    measures.INSIDE_LEG,
    measures.NECK,
    measures.SLEEVE,
    measures.LENGTH,
    measures.THIGH,
    measures.HEIGHT,
    measures.WEIGHT,
];

export const SIZE_CHART_GENDER = {

    "All": "all",
    "Male": "male",
    "Female": "female"
}

export const conditionSubjects = ["vendor", "collection", "product_type", "tag", "all_products"];

export const conditionOperators = ["equals", "not_equals", "starts_with", "ends_with", "contains", "not_contains"];

export const conditionOperatorLabels = {
    equals: "is equal to",
    not_equals: "is not equal to",
    starts_with: "starts with",
    ends_with: "ends with",
    contains: "contains",
    not_contains: "not contains",
}

export const blockInit = {
    [BLOCK_TYPE.TABLE]: {
        type: BLOCK_TYPE.TABLE,
        unit: UNIT_METHOD.IMPERIAL,
        data: {},
    },
    [BLOCK_TYPE.DIVIDER]: {
        type: BLOCK_TYPE.DIVIDER,
    },
    [BLOCK_TYPE.TEXT]: {
        type: BLOCK_TYPE.TEXT,
        text: "<p></p>",
    },
    [BLOCK_TYPE.IMAGE]: {
        type: BLOCK_TYPE.IMAGE,
        src: "",
        style: {},
    },
    [BLOCK_TYPE.VIDEO]: {
        type: BLOCK_TYPE.VIDEO,
        src: "",
        width: 420,
        height: 315,
        style: {}
    }
}

export const blockIcons = {
    [BLOCK_TYPE.TABLE]: "fa-table",
    [BLOCK_TYPE.DIVIDER]: "fa-minus",
    [BLOCK_TYPE.TEXT]: "fa-align-left",
    [BLOCK_TYPE.IMAGE]: "fa-image",
    [BLOCK_TYPE.VIDEO]: "fa-youtube-play"
}
