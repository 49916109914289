import React from 'react';
import SizeChartTable from "./SizeChartTable";
import LayoutSizeChart from "./LayoutSizeChart";

export default ({product, defaultUnit = null, editWrapper = null, editWrapperProps = {}}) => {

    if (product?.size_chart_layout && JSON.stringify(product.size_chart_layout) !== "{}") {
        return <LayoutSizeChart
            sizeChart={product.size_chart}
            layout={product.size_chart_layout}
            defaultUnit={defaultUnit}
            editWrapper={editWrapper}
            editWrapperProps={editWrapperProps}
        />
    } else {
        return <SizeChartTable sizeChartData={product.size_chart} defaultUnit={defaultUnit}/>
    }
};
