import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import {withRouter} from "react-router-dom";
import {getQueryUri, setQueryUri} from "../../../utilities/URI";
import {PAGE_SIZE} from "../../../utilities/ApiHandler";

const Pagination = withRouter(({ data, limit = PAGE_SIZE, className, onChange, history}) => {
  const { count, current } = data;
  if (!count) return null;

  const currentPage = current || +getQueryUri('page') || 1;
  const lastPage = Math.ceil(count / limit);

  const nextPage = lastPage !== currentPage ? currentPage + 1 : null;
  const next2Page = nextPage && lastPage !== currentPage && lastPage !== nextPage ? currentPage + 2 : null;
  const next3Page = next2Page && lastPage !== currentPage && lastPage !== next2Page ? currentPage + 3 : null;

  const prevPage = currentPage > 1 ? currentPage - 1 : null;
  const prev2Page = currentPage > 2 ? currentPage - 2 : null;
  const prev3Page = currentPage > 3 ? currentPage - 3 : null;
  const handleActive = page => currentPage === page;
  const link = page =>
    setQueryUri({
      ...getQueryUri(),
      page,
    });
  const Link = ({ page, children }) => (
    <a className={`c-pagination__link ${handleActive(page) && 'active'}`} href={link(page)} onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      history.push(link(page));
      onChange(page);
    }}>
      {children}
    </a>
  );
  const PageNo = ({ page }) => <Link page={page}>{page}</Link>;

  if(lastPage === 1) return null;
  return (
    <div className={classnames('c-pagination', className)}>
      {prevPage && (
        <Link page={prevPage}>
          <ChevronLeft />
        </Link>
      )}
      {(currentPage !== 1 && +prevPage !== 1 && +prev2Page !== 1 && prev3Page !== 1) && <PageNo page={1} />}

      {prev3Page && <PageNo page={prev3Page} />}
      {prev2Page && <PageNo page={prev2Page} />}
      {prevPage && <PageNo page={prevPage} />}

      <PageNo page={currentPage} />

      {nextPage && lastPage !== nextPage && <PageNo page={nextPage} />}
      {next2Page && lastPage !== next2Page && <PageNo page={next2Page} />}
      {next3Page && lastPage !== next3Page && <PageNo page={next3Page} />}

      {(lastPage && currentPage !== lastPage) && <PageNo page={lastPage} />}

      {nextPage && (
        <Link page={nextPage}>
          <ChevronRight />
        </Link>
      )}
    </div>
  );
});

Pagination.propTypes = {
  className: PropTypes.any,
  data: PropTypes.any.isRequired,
  limit: PropTypes.number,
};

export default Pagination;
