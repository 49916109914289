import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Tab = ({ children, initIndex, tabs, isModal, tabClassName, tabHeadClassName, selected, onSelect }) => {
    const [index, setIndex] = useState(initIndex);
    useEffect(() => {
        setIndex(selected);
    }, [selected]);

    const handleChangeTab = (ind) => {
        setIndex(ind);
        if(onSelect) onSelect(ind);
    };

    return (
        <div className={`c-tab ${isModal ? 'c-tab--modal ' : ''}${tabClassName}`}>
            <ul className={`c-tab__head ${tabHeadClassName}`}>
                {tabs.map((tab, key) => (
                    <li key={key}
                        className={+index === key ? 'active' : ''}
                        onClick={() => handleChangeTab(key)}>
                        {tab.toUpperCase()}
                    </li>
                ))}
            </ul>
            {children && (
                <div className="c-tab__body">
                    {Array.isArray(children) ? children.map((item, key) => (
                        <div key={key} className={`c-tab__item ${+index === key ? 'active' : ''}`}>
                            {item}
                        </div>
                    )) : children}
                </div>
            )}
        </div>
    )
};

Tab.propTypes = {
    initIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    tabs: PropTypes.array,
    selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onSelect: PropTypes.func,
};

Tab.defaultProps = {
    initIndex: 0,
    tabs: [],
    selected: 0,
    tabClassName: '',
    tabHeadClassName: '',
};

export default Tab;
