import {callApi, PAGE_SIZE} from "../utilities/ApiHandler";
import {ApiMethods, ApiRoutes, createURL} from "../config/apiConfig";

const {GET, PATCH, POST, DELETE} = ApiMethods;

export const getSizeCharts = async (page = 0, limit = PAGE_SIZE, search = "") => {
    const response = await callApi({
        method: GET,
        url: ApiRoutes.sizeChart.list,
        params: {
            offset: page * limit,
            page,
            limit,
            search,
        }
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const getSizeChart = async (id) => {
    const response = await callApi({
        method: GET,
        url: createURL(ApiRoutes.sizeChart.get, {id})
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const updateSizeChart = async (id, body) => {
    const response = await callApi({
        method: PATCH,
        url: createURL(ApiRoutes.sizeChart.update, {id}),
        params: body,
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const deleteSizeChart = async id => {
    const response = await callApi({
        method: DELETE,
        url: createURL(ApiRoutes.sizeChart.delete, {id}),
        params: {},
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const createSizeChart = async (body) => {
    const response = await callApi({
        method: POST,
        url: ApiRoutes.sizeChart.create,
        params: body,
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const getSizeChartProductMatchCount = async ({operator, conditions}) => {
    const response = await callApi({
        method: POST,
        url: ApiRoutes.sizeChart.productMatch,
        params: {
            operator,
            conditions
        }
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const getVerifiedSizeChartSuggestion = async ({query}) => {
    const response = await callApi({
        method: GET,
        url: `${ApiRoutes.sizeChart.listVerified}?search=${query}`
    });
    if (response.data) {
        return Promise.resolve(response.data.results.slice(0, 5));
    } else {
        return Promise.reject(response);
    }
}

export const getSizeChartImages = async ({sizeChart}) => {
    const response = await callApi({
        method: GET,
        url: `${ApiRoutes.sizeChart.image}`
    });
    if (response.data) {
        return Promise.resolve(response.data.results.slice(0, 5));
    } else {
        return Promise.reject(response);
    }
}

export const saveSizeChartImage = async (params) => {
    const response = await callApi({
        method: POST,
        url: ApiRoutes.sizeChart.image,
        headers: {'Content-Type': 'undefined'},
        params
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
}