import {STRING_MAP} from "./base";

export const localize = (key, params) => {
    let res = STRING_MAP[key] ? STRING_MAP[key] : key.replace("_", " ");
    if (params) {
        Object.keys(params).forEach(param => {
            res = res.replaceAll(`{{${param}}}`, params[param]);
        });
    }
    return res;
}

