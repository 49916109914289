import {
    FABRIC__NAME,
    FABRIC__STRETCH,
    PRODUCT__SEARCH,
    PRODUCT__UPDATE_FABRIC,
} from "../../../../assets/Images";

export const FabricArticle = {
    id: "how-to-create-fabric",
    is_popular: true,
    title: "How to create and use a fabric?",
    body: [
        {
            tag: "p",
            body: "On the left panel click on <b>Fabrics</b> > <b>Create a fabric</b>. " +
                "Then follow the steps below:"
        },
        {
            tag: "h5",
            body: "Step 1:"
        },
        {
            tag: "p",
            body: "Enter a name for the fabric (e.g. Jersey, Polyester, etc.)"
        },
        {
            tag: "img",
            attrs: {
                "src": FABRIC__NAME
            }
        },
        {
            tag: "h5",
            body: "Step 2:"
        },
        {
            tag: "p",
            body: "Select a stretch for the fabric on the right side; the stretchier the fabric the higher the value. " +
                "Increasing the stretch of the fabric allows customer's body to deviate further from the perfect fit."
        },
        {
            tag: "img",
            attrs: {
                "src": FABRIC__STRETCH
            }
        },
        {
            tag: "h5",
            body: "Step 3:"
        },
        {
            tag: "p",
            body: "Click save and you have created a fabric. In order to use the fabric click on <b>Products</b> on " +
                "the left panel. Then search for the products you would like to use the fabric to be used for."
        },
        {
            tag: "img",
            attrs: {
                "src": PRODUCT__SEARCH
            }
        },
        {
            tag: "h5",
            body: "Step 4:"
        },
        {
            tag: "p",
            body: "The under <b>Bulk Action</b> select <b>Update fabrics</b>. Select the fabric you would like to use " +
                "and click save."
        },
        {
            tag: "img",
            attrs: {
                "src": PRODUCT__UPDATE_FABRIC
            }
        },
        {
            tag: "p",
            body: "Congratulations! You have created a fabric and matched it with your products."
        },
    ],
};