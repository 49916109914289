import React, {Fragment, useEffect, useState} from 'react';

const AutoCompleteInput = ({value, lookupFunc, subject, onSelect, itemToLabel = (item) => item}) => {
    const [loading, setLoading] = useState(null);
    const [data, setData] = useState(null);
    const [isShow, setIsShow] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    useEffect(() => {
        if (value) {
            if (!isSelected && data !== null) setIsShow(true);
            setIsSelected(false);
            setLoading(true);
            lookupFunc({subject, query: value})
                .then((response) => setData(response))
                .finally(() => setLoading(false));
        }
    }, [value]);
    return !!value && isShow ? (
        <Fragment>
            <div className="c-backdrop c-backdrop--transparent" onClick={() => setIsShow(false)}/>
            <div className="c-desktop-header__search-autocomplete">
                {loading || !data ? (
                    <span className="c-desktop-header__search-autocomplete-loading">Loading...</span>
                ) : (
                    <ul className="c-desktop-header__search-autocomplete-list">
                        {data.map((i, _) => (
                            <li className="c-desktop-header__search-autocomplete-item" key={_}>
                                <div
                                    onClick={() => {
                                        onSelect(i);
                                        setIsSelected(true);
                                        setIsShow(false);
                                    }}
                                    className="c-desktop-header__search-autocomplete-link"
                                >
                                    {itemToLabel(i)}
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </Fragment>
    ) : null;
};

export default AutoCompleteInput;
