export const FAQItemsBilling = [
    {
        question: "Do I need a smart sizing solution?",
        answer: "If you sell apparel products you can benefit from a smart sizing solution. " +
            "Just like a brick-and-mortar store that needs a fitting room, your online store needs an easy way " +
            "for your customers to find the right size.\n" +
            "Using a simple size chart is not a good solution these days as the customers would have to measure themselves. " +
            "This is extra friction for them to purchase and they might not measure themselves correctly.\n" +
            "Robosize asks the customer a few questions they already know (e.g. height, weight) and recommends the size " +
            "that fits them well."
    },
    {
        question: "Can I use Robosize on my website?",
        answer: "Yes! Robosize works will all platforms. It takes only 5 minutes to install " +
            "and we are here to help you with any questions you might have."
    },
    {
        question: "Do I need a developer or technical background to use Robosize?",
        answer: "Using Robosize absolutely does not require any technical background or development.",
    },
    {
        question: "Is Robosize optimized for mobile?",
        answer: "Majority of online purchases happen using phone. Hence, Robosize is built with " +
            "mobile phone experience in mind and works perfectly on mobile devices."
    },
    {
        question: "Do I need to enter my credit card information to use Robosize?",
        answer: "If you are a Shopify customer you do not need to enter your credit card information. " +
            "Otherwise, we ask you to enter your credit card into our payment processing provider, Stripe, which " +
            "is one of the most secured and trusted payment processing companies in the world " +
            "(trusted by Amazon, Google, Zoom, etc)."
    },
    {
        question: "How would I be able get support if I am stuck?",
        answer: "You can always contact our technical support team via support@robosize.com, or " +
            "our payment support team via support@robosize.com."
    },
];