import {
    BUTTON_PLACEMENT_SETTINGS
} from "../../../../assets/Images";

export const ButtonPlacementPageBuilderArticle = {
    id: "how-to-customize-button-placement-page-builder",
    is_popular: true,
    title: "How to customize button placement using page builder?",
    body: [
        {
            tag: "h5",
            body: "Step 1:"
        },
        {
            tag: "p",
            body: "Add a new block to your page with the following code or add the code to an existing block. " +
              "You can add a custom liquid block by clicking add section and selecting custom liquid.<br/><br/>"
        },
        {
            tag: "code",
            body: "<div class=\"size-recommender\"></div>"
        },
        {
            tag: "h5",
            body: "Step 2:"
        },
        {
            tag: "p",
            body: "Go to settings and fill out button placement information as shown below:"
        },
        {
            tag: "img",
            attrs: {
                "src": BUTTON_PLACEMENT_SETTINGS
            }
        },
        {
            tag: "h5",
            body: "Step 3:"
        },
        {
            tag: "p",
            body: "Verify that your changes place by visiting a product page with an attached size chart."
        },
    ],
};