import {getOrderedKeys} from "../fit_widget/src/utils/common";

export const validateSizeChartData = (newSizeChart, sizes, measures, dataErrors, setDataErrors) => {
    const errors = {};
    let isChartValid = true;
    const validateSize = (sizeName, size) => {
        let isSizeValid = true;
        if (!size) {
            isSizeValid = false;
        } else {
            const sizeMeasures = getOrderedKeys(size);
            sizeMeasures.forEach(measure => {
                if (!size[measure]) {
                    errors[`${sizeName}-${measure}`] = true;
                    isSizeValid = false;
                }
            });
            if (JSON.stringify(sizeMeasures) !== JSON.stringify(measures)) isSizeValid = false;
        }
        return isSizeValid;
    }
    sizes.forEach(size => {
        if (!validateSize(size, newSizeChart.data[size])) {
            isChartValid = false;
        }
    });
    if (JSON.stringify(errors) !== JSON.stringify(dataErrors)) setDataErrors(errors);
    return isChartValid;
}