import React, {Fragment, useContext, useState} from 'react';
import toaster from "toasted-notes";
import {AppContext} from "../../../context/providers";
import {BoxSpinner} from "../../Common/UIKit/Spinner";
import {withRouter} from "react-router-dom";

const ShopifySetupCompletePage = withRouter(() => {
    const {business} = useContext(AppContext);

    if (business) {
        if (business.subscription?.status === "active") {
            window.location.replace("/dashboard");
        } else {
            toaster.notify(() => <div className="alert alert-danger mt-2">
                Subscription did not complete.
            </div>);
            window.location.replace("/billing");
            return <Fragment/>;
        }
    } else {
        return <div className="c-panel-content">
            <BoxSpinner/>
        </div>
    }

});

export default ShopifySetupCompletePage;