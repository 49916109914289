import React, {Fragment} from 'react';
import Input from "../../Common/UIKit/Form/Input";
import {SearchOutlined} from "@material-ui/icons";
import {Chip, Dialog, DialogTitle} from "@material-ui/core";
import Button from "../../Common/UIKit/Form/Button";

const InsightFilter = ({filters, query, data, handleQueryChange, handleDeleteFilter, clearFilters}) => {

    const [openHint, setOpenHint] = React.useState(false);

    const renderFilter = (filter, index) => {
        const {field, operator, value} = filter;
        return <Chip style={{
            marginLeft: '10px'
        }} key={index} label={`${field}${operator}=${value}`} variant="outlined" onDelete={() => {
            handleDeleteFilter(index);
        }}/>
    }

    return (
        <Fragment>
            <div className="c-table c-table--sm">
                <div className="c-table-filter">
                    <div className="c-table-filter__item c-table-filter__search">
                        <Input
                            onChange={handleQueryChange}
                            initialvalue={query}
                            value={query}
                            icon={<SearchOutlined/>}
                            placeholder="Filter"
                            clearable="true"
                        />
                    </div>
                    <div className="c-table-filter__item">
                        <Chip label='?' color='info' onClick={() => {
                            setOpenHint(true);
                        }}/>
                    </div>
                    {data?.count > 0 &&
                        <div className="c-table-filter__item">
                            <span className='text-muted'>{data.count} users match (past 30 days)</span>
                        </div>
                    }
                    <div className="c-table-filter__item-right">
                        <Button color='secondary' onClick={clearFilters}>
                            Reset filters
                        </Button>
                    </div>
                </div>
                <div className='container'>
                    <div className="row">
                        {filters.map(renderFilter)}
                    </div>
                </div>
            </div>
            <Dialog open={openHint} onClose={() => {
                setOpenHint(false);
            }}>
                <DialogTitle>How to filter recommendations:</DialogTitle>
                <div className='container p-4'>
                    <div className='container'>
                        You can filter recommendations by adding filters to the search bar and add the filter by adding
                        a comma after the filter.
                        The format is field=value or field__operator=value (the operator is separated by double
                        underscore).
                        <br/>
                        <br/>
                        Example filters:
                        <ul style={{
                            padding: '15px'
                        }}>
                            <li>
                                age=40
                            </li>
                            <li>
                                age__lt=40 (age less than 40, note the double underscore)
                            </li>
                            <li>
                                age__lte=40 (age less than or equal to 40)
                            </li>
                            <li>
                                gender=female
                            </li>
                            <li>
                                fit_preference=loose
                            </li>
                        </ul>
                        The following keys are supported:
                        <ul style={{
                            padding: '15px'
                        }}>
                            <li>gender (male, female)</li>
                            <li>age</li>
                            <li>height</li>
                            <li>weight</li>
                            <li>
                                fit_preference (tight, regular, loose)
                            </li>
                            <li>bust</li>
                            <li>waist</li>
                            <li>hip</li>
                        </ul>
                        For numeric keys following operators are supported:
                        <ul style={{
                            padding: '15px'
                        }}>
                            <li>no operator (e.g. age=40)</li>
                            <li>lt (Less than)</li>
                            <li>lte (Less than or equal)</li>
                            <li>gt (Greater than)</li>
                            <li>gte (Greater than or equal)</li>
                        </ul>
                    </div>
                </div>
            </Dialog>
        </Fragment>
    );
};

export default InsightFilter;
