import React, {Fragment, useContext} from 'react';
import {AppContext} from "../../../../context/providers";
import SelectPlanInstruction from "./SelectPlanInstruction";
import CreateSizeChartInstruction from "./CreateSizeChartInstruction";
import AddAppEmbedInstruction from "./AddAppEmbedInstruction";

const Header = () => {

    const {business, setupInstructions} = useContext(AppContext);

    const {
        plan_selected,
        size_charts_added,
        app_embed_added,
    } = setupInstructions || {};

    const requiresAppEmbed = business?.is_app_embed;

    const get_instruction_render = () => {
        if (window.location.pathname === "/dashboard" || window.location.pathname === "/setup") return <Fragment/>
        if (!plan_selected) return <SelectPlanInstruction compact={true}/>
        if (!size_charts_added) return <CreateSizeChartInstruction compact={true}/>
        if (requiresAppEmbed && !app_embed_added) return <AddAppEmbedInstruction compact={true}/>
        // if (!widget_customized) return <CustomizeWidgetInstruction compact={true}/>
    }

    return <div className="c-panel-header__items c-panel-header__items-fill">{get_instruction_render()}</div>

};

export default Header;
