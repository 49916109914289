import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {Types} from "./config/actionTypes";
import Layout from "./components/Common/Layouts/Layout";
import LoginPage from "./components/Pages/Authentication/LoginPage";
import RegisterPage from "./components/Pages/Authentication/RegisterPage";
import EditProductPage from "./components/Pages/Product/EditProductPage";
import ProductListPage from "./components/Pages/Product/ProductListPage";
import SizeChartListPage from "./components/Pages/SizeChart/SizeChartListPage";
import EditSizeChartPage from "./components/Pages/SizeChart/EditSizeChartPage";
import SyncListPage from "./components/Pages/Sync/SyncListPage";
import ProcessProductPage from "./components/Pages/Sync/ProcessProductsPage";
import FabricListPage from "./components/Pages/Fabric/FabricListPage";
import EditFabricPage from "./components/Pages/Fabric/EditFabricPage";
import ReportPage from "./components/Pages/Report/ReportPage";
import InsightPage from "./components/Pages/Insight/InsightPage";
import BillingPage from "./components/Pages/Billing/BillingPage";
import DashboardPage from "./components/Pages/Homepage/DashboardPage";
import ShopifySetupCompletePage from "./components/Pages/Billing/ShopifySetupCompletePage";
import AllSetupInstructions from "./components/Common/UIKit/SetupInstruction/AllSetupInstructions";
import ShopifyUpdateSubscriptionPage from "./components/Pages/Billing/ShopifyUpdateSubscriptionPage";
import FinalizePage from "./components/Pages/Authentication/FinalizePage";
import SettingsPage from "./components/Pages/Settings/SettingsPage";
import LoginShopifyPage from "./components/Pages/Authentication/LoginShopifyPage";
import BillingStripePage from "./components/Pages/Billing/BillingStripePage";
import HelpPage from "./components/Pages/Help/HelpPage";
import ShopifyPaymentPage from "./components/Pages/Billing/ShopifyPaymentPage";
import ShopifyPaymentCompletePage from "./components/Pages/Billing/ShopifyPaymentCompletePage";
import ShopifyCustomSubscriptionPage from "./components/Pages/Billing/ShopifyCustomSubscriptionPage";
import OrderListPage from "./components/Pages/Order/OrderListPage";
import BIHomepage from "./components/Pages/BI/BIHomepage";
import LoginAsPartner from "./components/Pages/LoginAsPartner/LoginAsPartner";
import StoreListPage from "./components/Pages/Stores/StoreListPage";
import EditStorePage from "./components/Pages/Stores/EditStorePage";

export const PrivateRoute = ({component: Component, ...rest}) => {
    const token = localStorage.getItem(Types.USER_TOKEN);
    const refresh_token = localStorage.getItem(Types.USER_REFRESH);
    return (
        <Route
            render={props => (
                token || refresh_token
                    ? <Component {...props} />
                    : <Redirect to={{
                        pathname: `/login?next=${encodeURIComponent(window.location.pathname + "?" + window.location.search)}`,
                        state: {from: props.location}
                    }}/>
            )}
            {...rest}
        />
    );
};
export const AuthRoute = ({component: Component, ...rest}) => {
    const token = localStorage.getItem(Types.USER_TOKEN);
    return (
        <Route render={props => (
            !token
                ? <Component {...props} />
                : <Redirect to={{pathname: '/', state: {from: props.location}}}/>
        )} {...rest} />
    );
};

const Routes = () => (
    <>
        <Switch>
            <Route key="login-shopify" path="/login-shopify" exact component={LoginShopifyPage}/>
            <Route key="login" path="/login" exact={false} component={LoginPage}/>
            <Route key="register" path="/register" exact component={RegisterPage}/>
            <Route key="finalize" path="/finalize" exact component={FinalizePage}/>
            <Layout>
                <Switch>
                    <PrivateRoute key="setup" path="/setup" exact component={AllSetupInstructions}/>

                    <PrivateRoute key="homepage" path="/" exact
                                  component={() => <Redirect to={{pathname: '/dashboard'}}/>}/>
                    <PrivateRoute key="dashboard" path="/dashboard" exact component={DashboardPage}/>
                    <PrivateRoute key="product" path="/product" exact component={ProductListPage}/>
                    <PrivateRoute key="product-edit" path="/product/:id" exact component={EditProductPage}/>

                    <PrivateRoute key="size_chart" path="/size-chart" exact component={SizeChartListPage}/>
                    <PrivateRoute key="size_chart-create" path="/size-chart/create" exact
                                  component={EditSizeChartPage}/>
                    <PrivateRoute key="size_chart-edit" path="/size-chart/edit/:id" exact
                                  component={EditSizeChartPage}/>

                    <PrivateRoute key="fabric" path="/fabric" exact component={FabricListPage}/>
                    <PrivateRoute key="fabric-create" path="/fabric/create" exact component={EditFabricPage}/>
                    <PrivateRoute key="fabric-edit" path="/fabric/edit/:id" exact component={EditFabricPage}/>

                    <PrivateRoute key="sync-products" path="/sync" exact component={SyncListPage}/>
                    <PrivateRoute key="process-products" path="/sync/process" exact component={ProcessProductPage}/>

                    <PrivateRoute key="report" path="/report" exact component={ReportPage}/>

                    <PrivateRoute key="insight" path="/insight" exact component={InsightPage}/>

                    <PrivateRoute key="store" path="/store" exact component={StoreListPage}/>
                    <PrivateRoute key="store-create" path="/store/create" exact component={EditStorePage}/>
                    <PrivateRoute key="store-edit" path="/store/:id" exact component={EditStorePage}/>

                    <PrivateRoute key="order" path="/order" exact component={OrderListPage}/>

                    <PrivateRoute key="billing-payment-shopify" path="/billing/payment/shopify" exact
                                  component={ShopifyPaymentPage}/>
                    <PrivateRoute key="billing-payment-shopify-complete" path="/billing/payment/shopify/complete" exact
                                  component={ShopifyPaymentCompletePage}/>
                    <PrivateRoute key="billing-subscription-shopify-activate" path="/billing/shopify/activate" exact
                                  component={ShopifyCustomSubscriptionPage}/>
                    <PrivateRoute key="billing-custom-view" path="/billing-custom" exact component={BillingStripePage}/>
                    <PrivateRoute key="billing-view" path="/billing-custom/:view" exact component={BillingStripePage}/>
                    <PrivateRoute key="billing" path="/billing" exact component={BillingPage}/>
                    <PrivateRoute key="billing-update" path="/billing/update" exact
                                  component={ShopifyUpdateSubscriptionPage}/>
                    <PrivateRoute key="billing-shopify-complete" path="/billing/shopify/complete" exact
                                  component={ShopifySetupCompletePage}/>

                    <PrivateRoute key="settings" path="/settings" exact component={SettingsPage}/>

                    <Route key="help" path="/help/" exact component={HelpPage}/>
                    <Route key="help-article" path="/help/:id" exact component={HelpPage}/>
                    <Route key="public-help" path="/public-help/" exact component={HelpPage}/>
                    <Route key="public-help-article" path="/public-help/:id" exact component={HelpPage}/>

                    <Route key="bi" path="/internal/bi" exact component={BIHomepage}/>
                    <Route key="login-as-partner" path="/internal/login-as-partner" exact component={LoginAsPartner}/>

                    {/*<PrivateRoute key="notFound" path="*" exact component={NotFoundPage} />*/}
                </Switch>
            </Layout>

            {/*<Route key="notFound" path="*" component={NotFoundPage} />*/}
        </Switch>

    </>
);


export default Routes;
