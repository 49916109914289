export const capitalizeFirstLetter = string =>
    string.charAt(0).toUpperCase() + string.slice(1);

export const formatLabel = label => capitalizeFirstLetter(label.replace('_', ' '));

export const getDomain = () => window?.__rs_domain ? window.__rs_domain : (
    window?.Robosize?.shop ? window.Robosize.shop : window.location.hostname
);

export const makeUniqueFilter = (value, index, self) => self.indexOf(value) === index;

export const uniqueWithAttr = (arr, attr) => {
    const tempObj = {}, res = [];
    for (var i = 0, l = arr.length; i < l; ++i) {
        if (!tempObj.hasOwnProperty(arr[i][attr])) {
            res.push(arr[i]);
            tempObj[arr[i][attr]] = 1;
        }
    }
    return res;
}

export const toComparableSize = viewMeasures => size => {
    const comparableSize = {};
    viewMeasures && viewMeasures.forEach(measure => {
        comparableSize[measure] = measureToFloat(size[measure]);
    });
    comparableSize.__id = size.id;
    return comparableSize;
}

export const intCompare = (a, b) => parseInt(a) > parseInt(b) ? 1 : -1;

export const sumArray = array => array.reduce((a, b) => a + b);

export const sortWithIndices = (toSort, compFunc) => {
    for (let i = 0; i < toSort.length; i++) {
        toSort[i] = [toSort[i], i];
    }
    toSort.sort(compFunc);
    toSort.sortIndices = [];
    for (let j = 0; j < toSort.length; j++) {
        toSort.sortIndices.push(toSort[j][1]);
        toSort[j] = toSort[j][0];
    }
    return toSort.sortIndices;
}

export const sortBodySizes = (allSizes) => {
    const sortRes = allSizes.map(size => sumArray(Object.keys(size).map(measure => measure[0] != "_" ? size[measure] : 0)));
    return sortWithIndices(sortRes, intCompare).map(ind => allSizes[ind]);
}

export const findNextBiggerSizeByMeasure = (allSizes, currentSize, modifiedMeasure) => {
    let newSize = Object.assign({}, currentSize);
    newSize[modifiedMeasure] = (measureToFloat(currentSize[modifiedMeasure]) || 0) + 0.1;

    // const biggerSizes = allSizes.filter(productSize => !newSize[modifiedMeasure] || !productSize[modifiedMeasure] || measureToFloat(productSize[modifiedMeasure]) >= measureToFloat(newSize[modifiedMeasure]));
    // Old method that would not allow other measures to be smaller
    const modifiableMeasures = Object.keys(newSize).filter(measure => !measure.includes("__"));
    let biggerSizes = allSizes.filter(productSize => modifiableMeasures.every(measure => (
        !newSize[measure] || !productSize[measure] || measureToFloat(productSize[measure]) >= measureToFloat(newSize[measure])
    )));
    if (biggerSizes.length === 0) {
        biggerSizes = allSizes.filter(productSize => !newSize[modifiedMeasure] || !productSize[modifiedMeasure] || measureToFloat(productSize[modifiedMeasure]) >= measureToFloat(newSize[modifiedMeasure]));
        if (biggerSizes.length === 0) {
            return currentSize;
        }
    }
    newSize = biggerSizes[0];
    let minMeasureValue = newSize[modifiedMeasure];
    biggerSizes.forEach(size => {
        if (size[modifiedMeasure] < minMeasureValue) {
            newSize = size;
            minMeasureValue = size[modifiedMeasure];
        }
    });

    const nextSizes = biggerSizes.filter(size => size[modifiedMeasure] === minMeasureValue);
    const sortedSizes = nextSizes.map(
        size => (
            {
                dist: modifiableMeasures.filter(measure => size[measure]).map(
                    measure => currentSize[measure] ? size[measure] - currentSize[measure] : 0
                ).reduce((a, b) => a + b, 0),
                size
            }
        )
    ).sort((a, b) => a.dist - b.dist);

    return sortedSizes[0].size;
};

export const findSizeByMeasures = (allSizes, size) => {
    const modifiableMeasures = Object.keys(size).filter(measure => !measure.includes("__"));
    const matchedSizes = allSizes.filter(productSize => modifiableMeasures.every(measure => (
        !size[measure] || productSize[measure] === size[measure]
    )));
    return matchedSizes.length > 0 ? matchedSizes[0] : null;
}

export const findNextSmallerSizeByMeasure = (allSizes, currentSize, modifiedMeasure) => {
    let newSize = Object.assign({}, currentSize);
    newSize[modifiedMeasure] = (measureToFloat(currentSize[modifiedMeasure]) || 0) - 0.1;
    // const smallerSizes = allSizes.filter(productSize => !newSize[modifiedMeasure] || !productSize[modifiedMeasure] || measureToFloat(productSize[modifiedMeasure]) <= measureToFloat(newSize[modifiedMeasure]));
    // Old method that would not allow other measures to be bigger
    const modifiableMeasures = Object.keys(newSize).filter(measure => !measure.includes("__"));
    let smallerSizes = allSizes.filter(productSize => modifiableMeasures.every(measure => (
        !newSize[measure] || !productSize[measure] || measureToFloat(productSize[measure]) <= measureToFloat(newSize[measure])
    )));
    if (smallerSizes.length === 0) {
        smallerSizes = allSizes.filter(productSize => !newSize[modifiedMeasure] || !productSize[modifiedMeasure] || measureToFloat(productSize[modifiedMeasure]) <= measureToFloat(newSize[modifiedMeasure]));
        if (smallerSizes.length === 0) {
            return currentSize;
        }
    }
    newSize = smallerSizes[0];
    let maxMeasureValue = newSize[modifiedMeasure];
    smallerSizes.forEach(size => {
        if (size[modifiedMeasure] > maxMeasureValue) {
            newSize = size;
            maxMeasureValue = size[modifiedMeasure];
        }
    });

    const nextSizes = smallerSizes.filter(size => size[modifiedMeasure] === maxMeasureValue);
    const sortedSizes = nextSizes.map(
        size => (
            {
                dist: modifiableMeasures.filter(measure => size[measure]).map(
                    measure => currentSize[measure] ? currentSize[measure] - size[measure] : 0
                ).reduce((a, b) => a + b, 0),
                size
            }
        )
    ).sort((a, b) => a.dist - b.dist);

    return sortedSizes[0].size;
};

export const getViewedBodyMeasures = (bodySize) => (
    bodySize ? Object.keys(bodySize).filter(measure =>
        bodySize[measure] != null && !measure.includes("__") && measure !== "id"
    ) : []
).sort((a, b) => a == "body_size" ? -1 : b == "body_size" ? 1 : 0);

export const measureToFloat = measure => !!parseFloat(measure) ? parseFloat(measure) : measure.charCodeAt(0);

export const breastCupFloatToName = cupSizeNum => String.fromCharCode(cupSizeNum).toUpperCase();

let originalViewPort = null;

const setViewPort = () => {
    if (!document.querySelector('meta[name="viewport"]')) {
        const viewPortMeta = document.createElement("meta");
        viewPortMeta.setAttribute("name", "viewport");
        viewPortMeta.setAttribute("content", "width=device-width, initial-scale=1.0");
        document.head.appendChild(viewPortMeta);
        originalViewPort = null;
    } else {
        const viewPort = document.querySelector('meta[name="viewport"]');
        originalViewPort = viewPort.getAttribute("content");
        viewPort.setAttribute("content", "width=device-width, initial-scale=1.0");
    }
}

const resetViewPort = () => {
    const viewPort = document.querySelector('meta[name="viewport"]');
    if (originalViewPort !== null) {
        viewPort.setAttribute("content", originalViewPort);
        originalViewPort = null;
    } else {
        viewPort.parentNode.removeChild(viewPort);
    }
}

export const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export const ciIncludes = (items, item) => items.map(i => i.toLowerCase()).includes(item);

export const isBodyShapeValid = (bodyShape) => bodyShape && bodyShape.height && bodyShape.weight;

export const deepClone = json => JSON.parse(JSON.stringify(json));

export const deepAssign = (target, ...sources) => {
    sources.forEach(source => {
        if (source) {
            Object.keys(source).forEach(key => {
                const value = source[key];
                if (typeof value === 'object' && value !== undefined && value !== null) {
                    if (target[key] !== undefined && target[key] !== null && typeof target[key] !== 'object') {
                        throw `Cannot assign object to non-object key (${key})`;
                    }
                    if (target[key] === undefined) {
                        if (Object.prototype.toString.call(value) === '[object Array]') {
                            target[key] = deepClone(value);
                        } else {
                            target[key] = {};
                            deepAssign(target[key], value);
                        }
                    } else {
                        deepAssign(target[key], value);
                    }
                } else {
                    if (target[key] !== undefined && target[key] !== null && (typeof target[key]) !== (typeof value)) {
                        throw `Cannot assign different type to key (${key}). ${typeof target[key]} <-- ${typeof value}`;
                    }
                    target[key] = value;
                }
            });
        }
    });
    return target;
}

export const getOrderedKeys = (obj) => obj ? (obj._order ? obj._order : Array.from(Object.keys(obj))).filter(size => size[0] !== "_") : [];

export const euclideanDistance = (a, b) => Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));

// export const getProjection = (a, b, p) => {
//     const atob = {x: b.x - a.x, y: b.y - a.y};
//     const atop = {x: p.x - a.x, y: p.y - a.y};
//     const len = atob.x * atob.x + atob.y * atob.y;
//     let dot = atop.x * atob.x + atop.y * atob.y;
//     const t = Math.min(1, Math.max(0, dot / len));
//     return new Point(a.x + atob.x * t, a.y + atob.y * t);
// }

export const classNames = (...classes) => {
    return classes.reduce((acc, c) => {
        const updateAcc = [...acc];
        if (typeof c === 'string') {
            updateAcc.push(c);
        } else if (c && typeof c === 'object') {
            const c1 = Object.keys(c).filter((k) => Boolean(c[k]));
            if (c1.length > 0) {
                updateAcc.push(...c1.map((k) => k));
            }
        }
        return updateAcc;
    }, []).join(' ');
}