import React, {useState} from 'react';
import toaster from "toasted-notes";
import {NavLink} from 'react-router-dom';
import {ControlInput} from "../../Common/UIKit/Form/Input";
import Button from "../../Common/UIKit/Form/Button";
import {register} from "../../../api";
import {useForm} from "react-hook-form";
import {handleApiSave} from "../../../utilities/useApi";
import AuthenticationLayout from "../../Common/Authentication/AuthenticationLayout";
import {getAllQueryParams} from "../../../utilities/URI";

const RegisterPage = () => {
    const [saveLoading, setSaveLoading] = useState(false);
    const {control, errors, handleSubmit, setError} = useForm();
    const policy = true;

    const params = getAllQueryParams();

    const handleSave = body => {
        if (!policy) return toaster.notify('Please Agree Private Policy');
        // if (!recaptcha) return toaster.notify('Please Verify non-Robot Task');

        handleApiSave(
            register,
            undefined,
            {
                ...body,
                params
            },
            null,
            {loadingFn: setSaveLoading, setError}
        ).then(data => {
            toaster.notify(() => <div className="alert alert-success mt-4">Your registration was successfully</div>);
            window.location.reload();
        });
    };
    // const verifyCallBack = response => setRecaptcha(response);

    return (
        <AuthenticationLayout>
            <div className="c-auth__head">
                <h1>Sign up for merchants</h1>
                <p>Create your free partner account</p>
            </div>
            <form className="e-form mb-3" onSubmit={handleSubmit(handleSave)}>
                <div className="c-form__group-row c-form__group-row--2">
                    <ControlInput
                        rules={{required: 'First name is required'}}
                        control={control}
                        label="First Name"
                        name="first_name"
                        placeholder={'First Name'}
                        errorMessage={errors?.first_name?.message}
                    />
                    <ControlInput
                        rules={{required: 'Last name is required'}}
                        control={control}
                        label="Last Name"
                        name="last_name"
                        placeholder={'Last Name'}
                        errorMessage={errors?.last_name?.message}
                    />
                </div>
                <ControlInput
                    rules={{required: 'Email is required'}}
                    control={control}
                    label="Email"
                    type="email"
                    name="email"
                    placeholder={'Email'}
                    errorMessage={errors?.email?.message}
                />
                <ControlInput
                    rules={{required: 'Password is required'}}
                    control={control}
                    label="Password"
                    type="password"
                    name="password"
                    placeholder={'Password'}
                    errorMessage={errors?.password?.message}
                />
                {/*<div className="e-checkbox__container">*/}
                {/*    <Checkbox id="pure-policy"*/}
                {/*              onChange={setPolicy}*/}
                {/*              value={policy}*/}
                {/*              label={(*/}
                {/*                  <>*/}
                {/*                      I agree to*/}
                {/*                      <a href="/"> privacy policy </a>,*/}
                {/*                      <a href="/"> website terms </a>*/}
                {/*                      and <a href="/"> partner terms </a>*/}
                {/*                  </>*/}
                {/*              )}*/}
                {/*    />*/}
                {/*</div>*/}
                <Button lg disabled={!policy} loading={saveLoading} block>Register</Button>
            </form>
            <div className="c-auth__links">
                <p>Already have an account?</p>
                <NavLink to={`/login${window.location.search}`} className="c-auth__link">Sign in now</NavLink>
            </div>
        </AuthenticationLayout>
    );
};

export default RegisterPage;
