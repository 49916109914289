import React, {useState} from 'react';
import Button from "../../Common/UIKit/Form/Button";
import {connectToStripe} from "../../../api/payment";
import {handleApiSave} from "../../../utilities/useApi";
import toaster from "toasted-notes";

const ConnectStartPage = () => {

    const [connecting, setConnecting] = useState(false);

    const handleConnectToStripe = () => {
        handleApiSave(
            connectToStripe,
            null,
            null,
            null,
            {
                loadingFn: setConnecting
            }
        ).then(response => {
            window.open(response.url);
        }).catch(() => {
            toaster.notify(() => <div className="alert alert-danger mt-2">
                Could not connect, please contact us to resolve the issue
            </div>);
        })
    }

    return <div className="c-panel-content text-center">
        <div className="h4">
            Connect with
            <a href="https://stripe.com" target="_blank" rel="noreferrer">
                <img height="30px" src="/stripe-logo.svg" alt=""/>
            </a>
        </div>
        <div className="pt-3">
            Robosize uses a secure and well-established payment system called
            <a href="https://stripe.com" target="_blank" rel="noreferrer"> Stripe</a>.
        </div>
        <div className="py-5">
            <Button
                className="c-button--inline"
                loading={connecting}
                onClick={handleConnectToStripe}
            >
                Connect
            </Button>
        </div>
    </div>;
};

export default ConnectStartPage;