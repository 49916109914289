import React, {Fragment, useEffect, useState} from 'react';
import Button from "../../Common/UIKit/Form/Button";
import ProductMatchConditionSet from "./ProductMatchConditionSet";
import {getSizeChartProductMatchCount} from "../../../api/sizeChart";
import {BoxSpinner} from "../../Common/UIKit/Spinner";
import classnames from "classnames";

const ChartProductMatch = ({condition, productMatchError, onConditionChange, isOpen, handleOpen}) => {

    const [matchCount, setMatchCount] = useState(null);

    const updateMethod = method => {
        const newCondition = Object.assign({}, condition, {method});
        if (method === "automatic" && newCondition.conditions.length === 0) newCondition.conditions.push({
            subject: "vendor",
            operator: "equals",
            value: null,
        });
        onConditionChange(newCondition);
    }
    const updateOperator = operator => onConditionChange(Object.assign({}, condition, {operator}));
    const updateConditions = conditions => onConditionChange(Object.assign({}, condition, {conditions}));

    useEffect(() => {
        if (productMatchError) {
            setMatchCount(null);
        } else {
            getSizeChartProductMatchCount(condition).then(response => {
                setMatchCount(response.count);
            });
        }
    }, [condition?.conditions, condition?.operator, productMatchError])

    return <div className="c-list">
        <div className="container">
            <h3 className="c-list__title">4. What products this chart is for?</h3>
            {
                !isOpen && (
                    <Button color="light" className="float-right" onClick={handleOpen}>
                        <span className="fa fa-pencil"></span>
                    </Button>
                )
            }
        </div>
        {
            isOpen ? (
                <Fragment>
                    <div className="c-list__item">
                        <div className="c-list-split">
                            <div className="container">
                                <div className="c-button-group c-policy-buttons d-inline">
                                    <span className="cursor-pointer" onClick={() => updateMethod("manual")}>
                                    <input type="radio" name="method"
                                           value="manual"
                                           checked={condition.method === "manual"}
                                           onChange={() => updateMethod("manual")}/>
                                        &nbsp; Manual
                                        </span>
                                    <br/>
                                    <div className="hint mb-3">
                                        Add this size chart to products one by one.
                                    </div>
                                    <span className="cursor-pointer" onClick={() => updateMethod("automatic")}>
                                    <input type="radio" name="method"
                                           value="automatic"
                                           checked={condition.method === "automatic"}
                                           onChange={() => updateMethod("automatic")}/>
                                        &nbsp; Automated
                                    </span>
                                    <br/>
                                    <div className="hint">
                                        This size chart will automatically be used for existing and future products that
                                        match the conditions.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        condition.method === "automatic" && (
                            <div className="c-list__item">
                                <div className="container">
                                    Products must match: &nbsp;
                                    <span className="cursor-pointer mr-2" onClick={() => updateOperator("all")}>
                                    <input type="radio" name="operator"
                                           value="all"
                                           className="d-inline"
                                           checked={condition.operator === "all"}
                                           onChange={() => updateOperator("all")}/>
                                        &nbsp; all conditions
                                    </span>
                                    &nbsp;
                                    <span className="cursor-pointer" onClick={() => updateOperator("any")}>
                                    <input type="radio" name="operator"
                                           value="any"
                                           className="d-inline"
                                           checked={condition.operator === "any"}
                                           onChange={() => updateOperator("any")}/>
                                        &nbsp; any condition
                                    </span>
                                    {
                                        condition.method == "automatic" && !productMatchError && (
                                            <Fragment>
                                                {
                                                    matchCount !== null && (
                                                        <span>
                                                            <h5 className="d-inline ml-2">
                                                                <div className={classnames("badge", {
                                                                    "badge-warning": matchCount === 0,
                                                                    "badge-success": matchCount > 0
                                                                })}>
                                                                    ({matchCount} products match)
                                                                </div>
                                                            </h5>
                                                        </span>
                                                    )
                                                }
                                            </Fragment>
                                        )
                                    }
                                </div>
                                <div className="container pt-3">
                                    <ProductMatchConditionSet conditions={condition.conditions}
                                                              operator={condition.operator}
                                                              onChange={updateConditions}/>
                                </div>
                            </div>
                        )
                    }
                </Fragment>
            ) : (
                <div className="c-list__item">
                    {
                        condition.method === "manual" ? (
                            <div className="px-3 text-success">
                                Manual
                            </div>
                        ) : (
                            <Fragment>
                                {productMatchError ? (
                                    <div className="px-3 text-danger">
                                        {productMatchError}
                                    </div>
                                ) : (
                                    <div className="px-3 text-success">
                                        Automatic
                                    </div>
                                )}
                            </Fragment>
                        )
                    }
                </div>
            )
        }
    </div>
};

export default ChartProductMatch;