import {callApi} from "../utilities/ApiHandler";
import {ApiMethods, ApiRoutes, createURL} from "../config/apiConfig";

const {GET} = ApiMethods;

export const getOrders = async (page, limit, search) => {
    const response = await callApi({
        method: GET,
        url: ApiRoutes.business.order.list,
        params: {
            offset: page * limit,
            page,
            limit,
            search,
        }
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};
