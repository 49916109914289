import config from "../../baseConfig";
import {GENDER, UNIT_METHOD} from "../../../components/BodyShape/Constants";
import {deepAssign} from "../../../utils/common";


export default deepAssign({}, config, {
    appClass: "six-am-work-shirt",
    integrationButton: {
        selector: ".product-form",
        selectorBefore: true,
        wrapperClass: "six-am-work-shirt",
        showSizeChart: true
    },
    interview: {
        forceGender: GENDER.MALE,
        askBodyShapes: true,
        askKnownBrand: true,
        askTShirt: true,
        basicQuestions: {
            defaultUnit: UNIT_METHOD.IMPERIAL,
            hideAge: true,
            ageOptional: true,
            askNeck: true,
            neckOptional: true,
        },
        bodyShape: {
            hideHip: true,
        }
    }
});
