import {range} from "./number";
export const YearsRange = range(1990, new Date().getFullYear() + 1, 1);
export const MonthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];
export const MonthBriefNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const DayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const DayBriefNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

/**
 *
 * @param date {Date}
 */
export const getDateInfo = date => {
    const hours = date.getHours();
    const min = date.getMinutes();
    const currentDate = date.getDate();
    return {
        time: ('0' + hours).substr(-2) + ':' + ('0' + min).substr(-2),
        time12: ('0' + (hours.toString() === "12" ? "12" : hours % 12)).substr(-2) + ':' + ('0' + min).substr(-2),
        proper: (hours - 12) < 0 ? 'am' : 'pm',
        // dayName: DayNames[date.getDay() - 1],
        dayName: DayNames[date.getDay()],
        dayBriefName: DayBriefNames[date.getDay()],
        monthName: MonthNames[date.getMonth()],
        monthBriefName: MonthBriefNames[date.getMonth()],
        date: currentDate,
    };
};

/**
 *
 * @param date {Date}
 * @returns {string}
 */
export const getStringFromDate = date => {
    if(typeof date === "string") date = getDateFromString(date);
    return `${('0' + date.getFullYear()).slice(-4)}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
        '0' + date.getDate()
    ).slice(-2)}`;
};

/**
 *
 * @param time {Date}
 * @param zeroSec
 * @returns {string}
 */
export const getStringFromTime = (time, zeroSec = true) => {
    const hours = time.getHours();
    const min = time.getMinutes();
    const sec = time.getSeconds();
    return ('0' + hours).substr(-2) + ':' + ('0' + min).substr(-2) + ':' + (zeroSec ? '00' : ('0' + sec).substr(-2))
};

/**
 *
 * @param stringDate {string || Date} // "Monday, Jan 18 2021" || 2021-01-18 || 2021/01/18
 * @param timeString {string} // "10:15:00 AM "
 * @param addTime {string} // "01:00:00"
 * @param isUTC {boolean}
 * @returns {Date}
 */
export const getDateFromString = (stringDate= '', timeString = '', addTime= '', isUTC = false) => {
    if(!stringDate) return new Date();
    if(typeof stringDate !== 'string') stringDate = getStringFromDate(stringDate);

    let month = null;
    let date = null;
    let year = null;
    let hours = new Date().getHours();
    let min = new Date().getMinutes();

    const format1 = stringDate.split('-');
    const format2 = stringDate.split('/');
    if(format1.length === 3) {
        month = format1[1] - 1;
        date = format1[2];
        year = format1[0];
    } else if(format2.length === 3){
        month = format2[1] - 1;
        date = format2[2];
        year = format2[0];
    } else {
        const format3 = stringDate.substr(stringDate.indexOf(', ') + 2);
        const d1 = format3.split(' ');
        month = MonthBriefNames.indexOf(d1[0]);
        date = d1[1];
        year = d1[2];

        if(!timeString && d1.length > 3)
            timeString = `${d1[3]} ${d1[4]}`;

    }

    if(!!timeString) {
        timeString = timeString.replace(':00 ', '');
        let addHours = timeString.indexOf('PM') > -1 ? +timeString.split(':')[0] < 12 ? 12 : 0 : 0;

        const time = timeString.replace('PM','').replace('AM', '');
        let addMin = 0;
        if(!!addTime) {
            addHours += +addTime.split(':')[0];
            addMin += +addTime.split(':')[1];
        }
        hours = +time.split(':')[0] + addHours;
        min = +time.split(':')[1] + addMin;
    }
    const newDate = new Date(+year, +month, +date, +hours, +min, 0);

    if (isUTC) {
        const arr = String(newDate).split(' (');
        const arr1 = arr[0].split(' ');
        arr1.pop();
        return new Date(arr1.join(' ') + ' UTC');
    }

    return newDate;
};

/**
 *
 * @param date {Date}
 * @param time {boolean}
 * @param isUTC {boolean}
 * @returns {string} "Monday, Jan 18 2021"
 */
export const getFormatBriefDate = (date, time = false, isUTC = false) => {
    if(typeof date === "string") date = getDateFromString(date, undefined, undefined, isUTC);
    const fDate = `${DayBriefNames[date.getDay()]}, ${getDateInfo(date).monthBriefName} ${date.getDate()} ${date.getFullYear()}`;
    const fTime = `${getDateInfo(date).time12} ${getDateInfo(date).proper}`;
    return `${fDate}${time ? ', '+fTime: ''}`;
};

/**
 *
 * @param date {Date}
 * @returns {string}
 */
export const getFormatDate = date => {
    return `${date.getDate()} ${getDateInfo(date).monthName} ${date.getFullYear()}`;
};

/**
 *
 * @param time {String}
 * @param clock {Boolean}
 * @returns {string}
 */
export const getFormatTime = (time, clock = false) => {
    const arrTime = time.split(':');
    let hour = +arrTime[0];
    hour = +hour > 0 ? hour : '00';
    let min = +arrTime[1];
    min = +min > 0 ? min : '00';
    let hour12 = 'am';
    if(+hour >= 12) hour12 = 'pm';

    if(clock) {
        hour = +hour > 12 ? hour % 12 : hour;
        // hour = +hour < 10 ? '0' + hour : hour;
        min = +min < 10 ? '0' + +min : min;
        return `${hour}:${min}${hour12}`;
    }

    return `${hour !== '00' ? hour + 'h ': ''}${min !== '00' ? min + 'min': ''}`
};

export const getSumDuration = (...durations) => {
    if (Array.isArray(durations[0])) durations = durations[0];
    const result = durations.reduce((a, d) => {
        const arrTime = d.split(':');
        let hour = +arrTime[0];
        let min = +arrTime[1];
        const tArrTime = a.split(':');
        let tHour = +tArrTime[0];
        let tMin = +tArrTime[1];
        let addHour = Math.floor((tMin + min) / 60);

        let resultMin = (tMin + min) % 60;
        let resultHour = addHour + hour + tHour;

        return `${resultHour}:${resultMin}`
    }, '00:00');
    return getFormatTime(result);
};
