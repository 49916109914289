import React, {useEffect, useState} from 'react';
import Header from "./Header/Header";
import classnames from "classnames";
import Sidebar from "./Sidebar/Sidebar";
import Backdrop from "../UIKit/Backdrop";

const Layout = ({children}) => {
    const [showSetup, setShowSetup] = useState(null);
    const [sidebarOpen, setSidebarOpen] = useState(true);

    const handleOpenSidebar = () => setSidebarOpen(true);
    const handleToggleSidebar = () => setSidebarOpen(!sidebarOpen);

    return <>
        {window.innerWidth <= 576 && <Backdrop onClick={handleOpenSidebar} show={!sidebarOpen} />}
            <div className={classnames('c-panel', { 'sidebar-min': !sidebarOpen })}>
                {!window.location.pathname.includes("public-help") && (
                  <Sidebar
                    onClose={handleOpenSidebar}
                    onSetup={type => setShowSetup(type)}
                />
                )}
                <div className={classnames("c-panel-container", {
                    'full-width': window.location.pathname.includes("public-help")
                })}>
                    <Header onToggle={handleToggleSidebar} sidebarOpen={sidebarOpen} />
                    <div className="c-panel-body">
                        {children}
                    </div>
                </div>
            </div>
    </>
};

export default Layout;
