import React from 'react';
import { NavLink } from "react-router-dom";
import PropTypes from 'prop-types';
import {DotsSpinner} from "../Spinner";

const Button = ({ children, xs, sm, lg, block, link, color, loading, className, node, ...otherProps }) => {
    const classes = ['c-button'];
    switch (color) {
        case 'light':
            classes.push('c-button--light');
            break;
        case 'dark':
            classes.push('c-button--dark');
            break;
        case 'main':
            classes.push('c-button--main');
            break;
        case 'success':
            classes.push('c-button--success');
            break;
        case 'primary':
            classes.push('c-button--primary');
            break;
        case 'danger':
            classes.push('c-button--danger');
            break;
        case 'warning':
            classes.push('c-button--warning');
            break;
        case 'info':
            classes.push('c-button--info');
            break;
        default:
            classes.push('c-button--main');
    }
    if(lg) classes.push('c-button--lg');
    if(sm) classes.push('c-button--sm');
    if(xs) classes.push('c-button--xs');
    if(block) classes.push('c-button--block');
    if(loading) classes.push('c-button--loading');
    const CustomTag = `${node}`;

    return node ? (
        <CustomTag className={`${classes.join(' ')} ${className}`} {...otherProps}>
            {loading ? <DotsSpinner sm /> : children}
        </CustomTag>
    ) : link ? (link.includes('http://') || link.includes('https://')) ? (
        <a href={link} className={`${classes.join(' ')} ${className}`}{...otherProps}>
            {loading ? <DotsSpinner sm /> : children}
        </a>
    ) : (
        <NavLink to={link} strict sensitive className={`${classes.join(' ')} ${className}`}{...otherProps}>
            {loading ? <DotsSpinner sm /> : children}
        </NavLink>
    ) : (
        <button className={`${classes.join(' ')} ${className}`} {...otherProps}>
            {loading ? <DotsSpinner sm /> : children}
        </button>
    )
};

Button.propTypes = {
    color: PropTypes.string,
    sm: PropTypes.bool,
    loading: PropTypes.bool,
    block: PropTypes.bool,
    link: PropTypes.string,
};

Button.defaultProps = {
    color: '',
    sm: false,
    block: false,
    loading: false,
    link: null,
    className: ''
};

export default Button;
