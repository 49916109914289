/* ====== User And Authentication ======*/
export {
    authGoogle,
    login,
    register,
    forgot,
    forgotConfirm,
    forgotToken,
    changePassword,
} from './auth';
export {
    getProfile,
    updateProfile,
    changeEmail,
    changeIdentity,
    sendVerificationIdentity,
    verifyCodeIdentity,
} from './user';
export {
    getProducts
} from './product';
