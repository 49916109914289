import React, {Fragment, useCallback, useContext, useEffect, useState} from 'react';
import {getPopularProducts} from "../../../api/report";
import {BoxSpinner} from "../../Common/UIKit/Spinner";
import Button from "../../Common/UIKit/Form/Button";
import {AppContext} from "../../../context/providers";

const COMPACT_SIZE_POPULAR_PRODUCTS_LENGTH = 5;

const PopularProductsTable = ({compactView}) => {

    const {business} = useContext(AppContext);

    const [products, setProducts] = useState(null);
    const [loading, setLoading] = useState(false);

    const visibleProducts = compactView ? (products?.length > 0 ? products.slice(0, COMPACT_SIZE_POPULAR_PRODUCTS_LENGTH) : []) : products;

    const fetchPopularProducts = useCallback(() => {
        if (loading) return;
        setLoading(true);
        getPopularProducts().then(response => {
            setProducts(response);
            setLoading(false);
        });
    }, [loading]);

    useEffect(() => {
        if (products === null) fetchPopularProducts();
    }, [products, fetchPopularProducts]);

    return <Fragment>
        {
            loading || products === null ? <BoxSpinner/> : (
                <Fragment>
                    <div className="c-table c-table--bordered">
                        <table>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Product</th>
                                <th>View count</th>
                                <th>Open count</th>
                                <th>View recommendation count</th>
                                <th>Sizing status</th>
                                {business?.store_type === "shopify" && <th>Link</th>}
                            </tr>
                            </thead>
                            <tbody>
                            {
                                visibleProducts.map((record, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{record.product_name}</td>
                                        <td>{record.view_count}</td>
                                        <td>{record.open_count}</td>
                                        <td>{record.view_recommendation_count}</td>
                                        <td>{record.status === "active" ? (
                                            record.size_chart ? "Live" : "Needs chart"
                                        ) : record.status === "disabled" ? "Disabled" : "Pending Process"}
                                        </td>
                                        {business.store_type === "shopify" && <td>
                                            <a href={`https://${business.domain}/products/${record.path}`}
                                               target="_blank">View</a>
                                        </td>}
                                    </tr>
                                ))
                            }
                            {
                                visibleProducts.length === 0 && (
                                    <tr>
                                        <td colSpan={4} className="text-center">No data available</td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                    {
                        compactView && visibleProducts?.length < COMPACT_SIZE_POPULAR_PRODUCTS_LENGTH && (
                            <div className="container pt-4 pb-3 text-center">
                                <Button color="light" link="/report" className="d-inline">
                                    View all <span className="fa fa-chevron-right"></span>
                                </Button>
                            </div>
                        )
                    }
                </Fragment>
            )
        }
    </Fragment>
};

export default PopularProductsTable;
