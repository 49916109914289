import ls from "local-storage";
import {getDomain} from "./common";

const API_BASE_URL = `https://${process.env.DOMAIN}`;
const API_BASE_PATH = '/app/widget';

export const INJECTOR_BASE_URL = `https://${process.env.INJECT_DOMAIN}`;

const ID_COOKIE_NAME = "rs_id";

const parseJSON = newApi => response => {
    if (
        response.status === 204 ||
        response.status === 205 ||
        response.redirected
    ) {
        return null;
    }
    return new Promise(resolve => {
        response.json().then(response => newApi ? resolve(response) : resolve(response.data));
    });
};

const getLocation = href => {
    const l = document.createElement('a');
    l.href = href;
    return l;
};

const checkStatus = response => {
    if (response.status >= 200 && response.status < 300 && !response.redirected) {
        return response;
    }

    if (response.redirected) {
        const url = getLocation(response.url);
        // history.push(`${url.pathname}${url.search}`);

        const error = new Error('Redirected');
        error.response = response;
        throw error;
    }

    const error = new Error(response.statusText);
    error.response = response;
    throw error;
};

export const getFromApi = ({path, options = {}, absolute, newApi = false}) => {
    const url = new URL(`${API_BASE_URL}${absolute ? '' : API_BASE_PATH}${path}`);
    Object.keys(options).forEach(key =>
        url.searchParams.append(key, options[key]),
    );
    return fetch(url.href, {
        headers: {
            'Content-Type': 'application/json',
            'd': getDomain(),
            'p': window.location.pathname + window.location.search,
        },
    }).then(checkStatus).then(parseJSON(newApi));
};


const getCookie = name => {
    const value = `${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
};

const setCookie = (name, value, daysToLive) => {
    let cookie = `${name}=${encodeURIComponent(value)};path=/`;
    if (typeof daysToLive === "number") {
        cookie += "; max-age=" + (daysToLive * 24 * 60 * 60);
    }
    document.cookie = cookie;
};

export const getFromWidgetApi = ({path, data = {}, options = {}, absolute}) => {
    let cookieId = getCookie(ID_COOKIE_NAME);
    let savedProfile = JSON.parse(localStorage.RSStore).profile;
    if (!cookieId && savedProfile) {
        cookieId = savedProfile.id;
    }
    if (!cookieId) {
        getFromApi({
            path: "/get-id/"
        }).then(({id}) => {
            setCookie(ID_COOKIE_NAME, id);
            const savedStore = JSON.parse(localStorage.RSStore);
            savedProfile = savedStore.profile || {};
            ls.set("RSStore", Object.assign({}, savedStore, {
                profile: Object.assign({}, savedProfile, {id})
            }));
            return getFromApi({
                path,
                data,
                options: Object.assign({}, options, {[ID_COOKIE_NAME]: id}),
                absolute
            });
        })
    } else {
        return getFromApi({
            path,
            data,
            options: Object.assign({}, options, {[ID_COOKIE_NAME]: cookieId}),
            absolute
        });
    }
};


export const postToWidgetApi = ({path, data = {}, options = {}, absolute}) => {
    let cookieId = getCookie(ID_COOKIE_NAME);
    let savedProfile = JSON.parse(localStorage.RSStore).profile;
    if (!cookieId && savedProfile) {
        cookieId = savedProfile.id;
    }
    if (!cookieId) {
        getFromApi({
            path: "/get-id/"
        }).then(({id}) => {
            setCookie(ID_COOKIE_NAME, id);
            const savedStore = JSON.parse(localStorage.RSStore);
            savedProfile = savedStore.profile || {};
            ls.set("RSStore", Object.assign({}, savedStore, {
                profile: Object.assign({}, savedProfile, {id})
            }));
            return postToApi({
                path,
                data,
                options: Object.assign({}, options, {[ID_COOKIE_NAME]: id}),
                absolute
            });
        })
    } else {
        return postToApi({
            path,
            data,
            options: Object.assign({}, options, {[ID_COOKIE_NAME]: cookieId}),
            absolute
        });
    }
};

export const postToApi = ({path, data = {}, options = {}, absolute, isJson = true, fullURL = null}) => {
    const url = new URL(fullURL ? fullURL : `${API_BASE_URL}${absolute ? '' : API_BASE_PATH}${path}`);
    Object.keys(options).forEach(key =>
        url.searchParams.append(key, options[key]),
    );
    // url.searchParams.append("_d", window.location.hostname);
    // url.searchParams.append("_p", window.location.pathname + window.location.search);

    return new Promise(function (resolve, reject) {
        const req = new XMLHttpRequest();
        req.open("POST", url.href);
        if (isJson) {
            req.setRequestHeader("Content-Type", "application/json");
        }
        req.setRequestHeader("d", getDomain());
        req.setRequestHeader("p", window.location.pathname + window.location.search);
        req.withCredentials = true;
        req.onload = function () {
            if (req.status == 200) {
                resolve(req.response);
            } else {
                reject(Error(req.statusText));
            }
        };
        req.onerror = function () {
            reject(Error("Network Error"));
        };
        req.send(isJson ? JSON.stringify(data) : data);
    });
};

export const getIconUrl = () => `${API_BASE_URL}/static/media/logo/logo_large.png`;
export const recordActivityPath = "/activity/record";
export const recordOrder = "/order/record/"
