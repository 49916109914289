import React, {Fragment, useState} from 'react';
import SizeChartTable from "./SizeChartTable";
import {BLOCK_TYPE} from "./Constants";

let YoutubeComp = null;

export default ({layout, defaultUnit = null, editWrapper: EditWrapper = null, editWrapperProps = {}}) => {

    const [youtubeCompLoaded, setYoutubeCompLoaded] = useState(YoutubeComp !== null);

    const fetchYoutubeComp = () => {
        import("react-youtube").then(YouTube => {
            if (YoutubeComp === null) YoutubeComp = YouTube.default;
            setYoutubeCompLoaded(true);
        });
    }

    const renderBlock = (block, index) => {
        let blockComp = null;
        switch (block.type) {
            case BLOCK_TYPE.SIZE_CHART:
                blockComp = <SizeChartTable sizeChartData={block.data} defaultUnit={defaultUnit} />
                break;
            case BLOCK_TYPE.TABLE:
                blockComp = <SizeChartTable sizeChartData={block.data} defaultUnit={defaultUnit} isTable={true}/>
                break;
            case BLOCK_TYPE.TEXT:
                blockComp = <div className="if_container" style={block.style || {}}
                                 dangerouslySetInnerHTML={{__html: block.text}}/>
                break;
            case BLOCK_TYPE.IMAGE:
                blockComp = <div className="if_container if_text-center" style={block.container_style || {}}>
                    <img className="if_image" src={block.src} style={block.style || {}}/>
                </div>
                break;
            case BLOCK_TYPE.DIVIDER:
                blockComp = <hr className="if_hr" style={block.style || {}}/>
                break;
            case BLOCK_TYPE.VIDEO:
                if (!youtubeCompLoaded) {
                    fetchYoutubeComp();
                    blockComp = <div className="if_loader-sm"/>
                } else {
                    try {
                        const videoID = block.src.substr(block.src.indexOf("/embed/") + 7).split("?")[0];
                        blockComp = <div className="if_video-wrap" style={block.style || {}}>
                            <YoutubeComp videoId={videoID} opts={{
                                height: "auto",
                                width: "auto",
                            }}/>
                        </div>
                    } catch (e) {
                        blockComp = <div className="if_text-center if_text-error"><span className="fa fa-cross"/></div>
                    }

                }
                break;
        }
        if (block.isHidden) {
            blockComp = <Fragment/>
        }
        return EditWrapper ?
            <EditWrapper {...editWrapperProps} block={block} index={index}>{blockComp}</EditWrapper> : blockComp;
    }

    return <div className="if_container">
        {layout.blocks.map((block, index) => (
            <div key={index} className="if_container">
                {renderBlock(block, index)}
            </div>
        ))}
    </div>

};
