import {callApi} from "../utilities/ApiHandler";
import {ApiMethods, ApiRoutes, createURL} from "../config/apiConfig";

const {GET, PATCH, POST, DELETE} = ApiMethods;

export const getBusinessSettings = async () => {
    const response = await callApi({
        method: GET,
        url: ApiRoutes.business.settings.list
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};


export const saveBusinessSettings = async (settings) => {
    const response = await callApi({
        method: PATCH,
        url: ApiRoutes.business.settings.list,
        params: settings
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

export const validateBusinessIntegration = async () => {
    const response = await callApi({
        method: GET,
        url: ApiRoutes.business.integration.validate
    });
    if (response.data) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
};

