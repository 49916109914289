import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Backdrop from "./Backdrop";
import Button from "./Form/Button";

const Modal = props => {
    const {
        children,
        show,
        lg,
        sticky,
        tabIndex,
        auto,
        title,
        description,
        bodyClass,
        rightHeader,
        leftHeader,
        buttonComponent,
        bigButtonComponent,
        saveBtnComponent,
        saveBtnText,
        closeBtnText,
        saveBtnDisabled,
        saveLoading,
        backdropCloseable,
        onClose,
        onSave,
        saveBtn,
        closeBtn,
        disableFooter,
        disableHeader
    } = props;
    const [open, setOpen] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            if(show) setOpen(true);
            else setOpen(false);
        }, 300);
    }, [show]);

    const classesModal = ['c-modal'];

    if(open) classesModal.push('open');
    if(lg) classesModal.push('c-modal--lg');
    if(sticky) classesModal.push('c-modal--sticky');
    if(auto) classesModal.push('c-modal--auto-width');
    if(tabIndex) classesModal.push(`c-modal--tab-${tabIndex}`);

    return show ? (
        <>
            <Backdrop show={show} tabIndex={tabIndex} onClick={backdropCloseable ? onClose : () => {}} />
            <div className={classesModal.join(' ')} onClick={backdropCloseable ? onClose : () => {}}>
                <div className={`c-modal-content`} onClick={e => e.stopPropagation()}>
                    {!disableHeader && (
                        <div className="c-modal-header">
                            {/*{closeBtn && <button className="c-modal-header__close" onClick={onClose}>Close</button>}*/}
                            {closeBtn && <button className="c-modal-header__close" onClick={onClose}><i className="fas fa-times" /></button>}
                            {leftHeader}
                            {(title || description) && (
                                <div className="c-modal-header-head">
                                    {title && <h4 className="c-modal-header__title">{title}</h4>}
                                    {description && <p className="c-modal-header__description">{description}</p>}
                                </div>
                            )}
                            {rightHeader}
                        </div>
                    )}
                    <div className={`c-modal-body${!!bodyClass ? ` ${bodyClass}` : ''}`}>
                        {children}
                    </div>
                    {!disableFooter && (
                        <div className="c-modal-footer">
                            {closeBtn && <Button color={'light'} onClick={onClose}>{closeBtnText}</Button>}
                            <div className="c-modal-footer__buttons">
                                {buttonComponent}
                                {saveBtn && (saveBtnComponent ? {saveBtnComponent} : <Button disabled={saveBtnDisabled} loading={saveLoading} onClick={onSave}>{saveBtnText}</Button>)}
                            </div>
                        </div>
                    )}

                    {bigButtonComponent && (
                        <div className="c-modal-footer__big-button">
                            {bigButtonComponent}
                        </div>
                    )}
                </div>
            </div>
        </>
    ) : null;
};

Modal.propTypes = {
    lg: PropTypes.bool,
    sticky: PropTypes.bool,
    show: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    closeBtnText: PropTypes.string,
    saveBtnText: PropTypes.string,
    saveBtnDisabled: PropTypes.bool,
    tabIndex: PropTypes.number,
    saveBtn: PropTypes.bool,
    saveLoading: PropTypes.bool,
    backdropCloseable: PropTypes.bool,
    closeBtn: PropTypes.bool,
    disableFooter: PropTypes.bool,
    disableHeader: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func,
};

Modal.defaultProps = {
    lg: false,
    sticky: false,
    auto: false,
    show: false,
    saveBtn: true,
    saveBtnDisabled: false,
    saveLoading: false,
    backdropCloseable: true,
    closeBtn: true,
    disableFooter: false,
    disableHeader: false,
    saveBtnText: 'Save',
    closeBtnText: 'Close',
    onClose: () => {},
    onSave: () => {},
};

export default Modal;
