import React, {Fragment, useCallback, useContext, useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {AppContext} from "../../../context/providers";
import {getProducts} from "../../../api";
import Checkbox from "../../Common/UIKit/Form/Checkbox";
import NotFoundData from "../../Common/UIKit/NotFoundData";
import {BoxSpinner, PageLoading} from "../../Common/UIKit/Spinner";
import {SearchOutlined} from "@material-ui/icons";
import Input from "../../Common/UIKit/Form/Input";
import Button from "../../Common/UIKit/Form/Button";
import AssignSizeChartModal from "./AssignSizeChartModal";
import AssignFabricModal from "./AssignFabricModal";
import Link from "../../Common/UIKit/Link";
import AssignStatusModal from "./AssignStatusModal";
import Pagination from "../../Common/UIKit/Pagination";
import {getQueryUri} from "../../../utilities/URI";
import {PAGE_SIZE} from "../../../utilities/ApiHandler";
import {getFabrics} from "../../../api/fabric";
import Select from "../../Common/UIKit/Form/Select";
import {bulkAction, bulkActions} from "./ProductConstants";

const ProductListPage = withRouter(({history}) => {

    const {
        products,
        setProducts,
        fabrics,
        setFabrics,
    } = useContext(AppContext);

    const [page, setPage] = useState(getQueryUri("page") || 1);
    const [data, setData] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [loadingProducts, setLoadingProducts] = useState(false);
    const [loadingFabrics, setLoadingFabrics] = useState(false);

    const [query, setQuery] = useState("");
    const [needsChartOnly, setNeedsChartOnly] = useState(getQueryUri("needs_chart") === "true");

    const [assigningFabric, setAssigningFabric] = useState(false);
    const [assigningSizeChart, setAssigningSizeChart] = useState(false);
    const [assigningStatus, setAssigningStatus] = useState(false);

    const allSelected = products ? products.length === selectedProducts?.length : false;

    const fetchProducts = useCallback(() => {
        setLoadingProducts(true);
        getProducts(page ? parseInt(page) - 1 : 0, PAGE_SIZE, query, needsChartOnly).then(products => {
            setData(products);
            setProducts(products.results);
            setLoadingProducts(false);
        });
    }, [page, query, setProducts, needsChartOnly]);

    const toggleSelectAllProducts = useCallback(
        () => setSelectedProducts(allSelected ? [] : products.map(product => product.id)),
        [allSelected, products]
    );

    const toggleSelectedProduct = useCallback(id => () => {
        const index = selectedProducts.indexOf(id);
        let newSelectedProducts = selectedProducts.slice();
        if (index < 0) {
            newSelectedProducts.push(id);
        } else {
            newSelectedProducts.splice(index, 1);
        }
        setSelectedProducts(newSelectedProducts);
    }, [selectedProducts]);

    useEffect(() => {
        const fetchFabrics = () => {
            if (loadingFabrics) return;
            setLoadingFabrics(true);
            getFabrics().then(response => {
                setFabrics(response.results);
                setLoadingFabrics(false);
            })
        }

        if (products === null) {
            fetchProducts();
        }
        if (fabrics === null) {
            fetchFabrics();
        }
    }, [products, fetchProducts, fabrics]);

    useEffect(() => {
        const timeOutId = setTimeout(() => fetchProducts(), 500);
        return () => clearTimeout(timeOutId);
    }, [query, fetchProducts]);

    if (loadingFabrics) {
        return <div className="c-panel-content"><PageLoading/></div>
    }

    return <div className="c-panel-content">
        {
            assigningSizeChart && <AssignSizeChartModal
                products={selectedProducts}
                onClose={() => {
                    setAssigningSizeChart(false);
                }}
                onSave={() => {
                    setSelectedProducts([]);
                }}
            />
        }
        {
            assigningFabric && <AssignFabricModal
                products={selectedProducts}
                onClose={() => {
                    setAssigningFabric(false);
                }}
                onSave={() => {
                    setSelectedProducts([]);
                }}
            />
        }
        {
            assigningStatus && <AssignStatusModal
                products={selectedProducts}
                onClose={() => {
                    setAssigningStatus(false);
                }}
                onSave={() => {
                    setSelectedProducts([]);
                }}
            />
        }
        <div>
            {
                loadingProducts || (products && (products.length > 0 || query !== "" || needsChartOnly)) ? (
                    <Fragment>
                        <div className="c-table c-table--sm">
                            <div className="c-table-filter">
                                <div className="c-table-filter__item c-table-filter__search">
                                    <label>Search</label>
                                    <Input
                                        onChange={e => {
                                            setQuery(e.target.value);
                                        }}
                                        value={query}
                                        initialvalue={query}
                                        icon={<SearchOutlined/>}
                                        placeholder="Name, vendor"
                                        clearable="true"
                                    />
                                </div>
                                <div className="c-table-filter__item">
                                    <label>Filter products</label>
                                    <Button color={needsChartOnly ? "dark" : "light"} onClick={() => {
                                        setNeedsChartOnly(!needsChartOnly);
                                    }}>
                                        {
                                            needsChartOnly ? "View all products" : "View products without a size chart"
                                        }
                                    </Button>
                                </div>
                                <div className="c-table-filter__item-right">
                                    <div className="c-table-filter__item">
                                        <Select
                                            className="c-form__select-sized"
                                            isDisabled={selectedProducts.length === 0}
                                            id="bulkAction"
                                            label="Bulk Action"
                                            options={bulkActions}
                                            value={null}
                                            onChangeValue={(value) => {
                                                switch (value) {
                                                    case bulkAction.UPDATE_FABRIC:
                                                        setAssigningFabric(true);
                                                        break;
                                                    case bulkAction.UPDATE_SIZE_CHART:
                                                        setAssigningSizeChart(true);
                                                        break;
                                                    case bulkAction.UPDATE_STATUS:
                                                        setAssigningStatus(true);
                                                        break;
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <table>
                                <thead>
                                <tr>
                                    <th colSpan={2}>
                                        <Checkbox
                                            label={`${allSelected ? "Deselect all" : "Select all"} ${selectedProducts.length > 0 ? `(${selectedProducts.length})` : ""} `}
                                            onChange={toggleSelectAllProducts}
                                            checked={allSelected}
                                        />
                                    </th>
                                    <th>Name</th>
                                    <th>Sizing status</th>
                                    <th>Fabric</th>
                                    <th>Size chart</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    loadingProducts ? (
                                        <tr>
                                            <td colSpan={6}>
                                                <BoxSpinner/>
                                            </td>
                                        </tr>
                                    ) : (
                                        products.length > 0 ? (
                                            products.map(product => (
                                                <tr key={product.id}>
                                                    <td>
                                                        <Checkbox
                                                            onChange={toggleSelectedProduct(product.id)}
                                                            checked={selectedProducts.includes(product.id)}
                                                        />
                                                    </td>
                                                    <td>
                                                        {product.image_md && <img className="img" src={product.image_md} alt=""/>}
                                                    </td>
                                                    <td>
                                                        {product.name}
                                                    </td>
                                                    <td>
                                                        {product.status === "active" ? (
                                                            product.size_chart ? "Live" : "Needs chart"
                                                        ) : product.status === "disabled" ? "Disabled" : "Pending Process"}
                                                    </td>
                                                    <td>
                                                        {product.fabric ? product.fabric.name : "-"}
                                                    </td>
                                                    <td>
                                                        {
                                                            product.size_chart ? (
                                                                <Link onClick={() => {
                                                                    history.push(`/size-chart/edit/${product.size_chart.id}`);
                                                                }}>
                                                                    {product.size_chart.brand_name}
                                                                </Link>
                                                            ) : "-"
                                                        }
                                                    </td>
                                                    <td>
                                                        <Link onClick={() => {
                                                            history.push(`/product/${product.id}`);
                                                        }}>
                                                            <Button>
                                                                Edit
                                                            </Button>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={6}>
                                                    <NotFoundData icon="fas fa-search"
                                                                  title="No product matched the filter"/>
                                                </td>
                                            </tr>
                                        ))
                                }
                                </tbody>
                            </table>
                        </div>
                        {
                            data && !loadingProducts && <Pagination data={data} onChange={(page) => {
                                setPage(page);
                                setLoadingProducts(true);
                                setProducts(null);
                            }}/>
                        }
                    </Fragment>
                ) : <NotFoundData icon="fas fa-shopping-basket" title="No product added yet"/>
            }
        </div>
    </div>
})

export default ProductListPage;
