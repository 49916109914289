import React, {Fragment, useCallback, useEffect, useState} from 'react';
import Tab from "../../../Common/UIKit/Tab";
import ImageSelector from "../../../Common/UIKit/ImageSelector";
import * as classnames from "classnames";
import {handleApiSave} from "../../../../utilities/useApi";
import {saveSizeChartImage} from "../../../../api/sizeChart";
import {Controller, useForm} from "react-hook-form";
import {deepClone} from "../../../../fit_widget/src/utils/common";
import Slider from "@material-ui/core/Slider";

export default ({
                    block,
                    index,
                    saveBlock,
                    sizeChart,
                }) => {

    const tabs = ['Upload image', 'From URL'];
    const [tabIndex, setTabIndex] = useState(0);
    const [image, setImage] = useState(null);
    const [imageURL, setImageURL] = useState(block.src || "");
    const [fetchImageError, setFetchImageError] = useState(null);

    const {control, errors, handleSubmit, setError} = useForm();

    const handleSave = data => {
        const formData = new FormData();
        formData.append('image', data.file.file);
        handleApiSave(
            saveSizeChartImage,
            null,
            formData,
            null,
            {loadingFn: () => {}, fetchImageError}
        ).then(res => {
            setImage(image);
            saveBlock(Object.assign({}, block, {src: res.image}), index);
        });
    };

    const fetchImageFromURL = useCallback(() => {
        if (!imageURL || block.src === imageURL) return;
        const image = new Image();
        image.onload = () => {
            setImage(image);
            saveBlock(Object.assign({}, block, {src: imageURL}), index);
            setFetchImageError(false);
        };
        image.onerror = () => {
            setFetchImageError(true);
        }
        image.src = imageURL;
    }, [imageURL]);

    useEffect(() => {
        const timeOutId = setTimeout(() => fetchImageFromURL(), 300);
        return () => clearTimeout(timeOutId);
    }, [imageURL, fetchImageFromURL]);

    const renderUpload = () => (
        <form onSubmit={handleSubmit(handleSave)}>
            <Controller
                control={control}
                name="file"
                defaultValue={block.src}
                render={({onChange, value}) => (
                    <ImageSelector id="file"
                                   className="c-settings__image-uploader"
                                   onChange={(v) => {
                                       onChange(v);
                                       handleSubmit(handleSave)(v);
                                   }}
                                   onDelete={onChange}
                                   value={value}
                    />
                )}
            />
        </form>
    );

    const renderFromURL = () => (
        <Fragment>
            <label>Image url</label>
            <input className={classnames("form-control", {
                "is-invalid": fetchImageError,
                "is-valid": imageURL && !fetchImageError
            })} type="url" value={imageURL || ""} onChange={({target: {value}}) => {
                setImageURL(value);
            }}/>
        </Fragment>
    );

    const renderTabContent = () => {
        switch (tabIndex) {
            case 0:
                return renderUpload();
            case 1:
                return renderFromURL();
            default:
                return renderUpload();
        }
    }


    return <div className="c-chart-layout c-chart-layout-image">
        <div className="container">
            <h4>Image</h4>
        </div>
        <div className="container py-3">
            <Tab tabs={tabs}
                 tabClassName="py-3"
                 tabHeadClassName={'p-0'}
                 onSelect={setTabIndex}
                 selected={tabIndex}
            />
            <div className="container px-0 py-3">
                {renderTabContent()}
            </div>
        </div>
        <hr/>
        <div className="container py-3">
            <h4>Style</h4>
            <div className="row">
                <div className="col-md-6">
                    <label>
                        Width
                        ({block.style?.width ? block.style?.width : "Auto"})
                    </label>
                    <Slider
                        defaultValue={0}
                        value={(block.style?.width || "").replace("px", "")}
                        min={0}
                        max={500}
                        step={10}
                        color="primary"
                        onChange={(e, value) => {
                            const style = deepClone(block.style || {});
                            style.width = value > 0 ? `${value}px` : "";
                            saveBlock(Object.assign({}, block, {style}), index);
                        }}
                    />
                </div>
                <div className="col-md-6">
                    <label>
                        Height
                        ({block.style?.height ? block.style?.height : "Auto"})
                    </label>
                    <Slider
                        defaultValue={0}
                        value={(block.style?.height || "").replace("px", "")}
                        min={0}
                        max={500}
                        step={10}
                        color="primary"
                        onChange={(e, value) => {
                            const style = deepClone(block.style || {});
                            style.height = value > 0 ? `${value}px` : "";
                            saveBlock(Object.assign({}, block, {style}), index);
                        }}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <label>
                        Margin top
                        ({block.style?.marginTop ? block.style?.marginTop : "0px"})
                    </label>
                    <Slider
                        defaultValue={0}
                        value={(block.style?.marginTop || "").replace("px", "")}
                        min={0}
                        max={50}
                        step={5}
                        color="primary"
                        onChange={(e, value) => {
                            const style = deepClone(block.style || {});
                            style.marginTop = value > 0 ? `${value}px` : "";
                            saveBlock(Object.assign({}, block, {style}), index);
                        }}
                    />
                </div>
                <div className="col-md-6">
                    <label>
                        Margin bottom
                        ({block.style?.marginBottom ? block.style?.marginBottom : "0px"})
                    </label>
                    <Slider
                        defaultValue={0}
                        value={(block.style?.marginBottom || "").replace("px", "")}
                        min={0}
                        max={50}
                        step={5}
                        color="primary"
                        onChange={(e, value) => {
                            const style = deepClone(block.style || {});
                            style.marginBottom = value > 0 ? `${value}px` : "";
                            saveBlock(Object.assign({}, block, {style}), index);
                        }}
                    />
                </div>
            </div>
        </div>
    </div>

};
