export const Types = {
    USER_TOKEN: '_token',
    CSRF_TOKEN: '_csrf',
    USER_REFRESH: '_refresh',
};

export const BusinessTypes = {
    SET_BUSINESS: 'SET_BUSINESS',
};

export const StorageTypes = {
    CATCH_KEY: '__catch',
};
