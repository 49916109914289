import config from "../../baseConfig";
import {deepAssign} from "../../../utils/common";
import {GENDER} from "../../../components/BodyShape/Constants";

export default deepAssign({}, config, {
    integrationButton: {
        selector: ".product-size",
        wrapperClass: "tulika"
    },
    interview: {
        forceGender: GENDER.FEMALE,
        askBodyShapeAdjustments: true,
        skipBraSize: true,
    }
});